import { globalMutes } from '../mutation-types.vue.js'
export default (store) => {
  store.subscribeAction({
    before: (action, state) => {
      if (action.type.includes('UPDATE')) {
        store.commit(globalMutes.INCREMENT_REQUEST_COUNT, action.type)
      }
    },
    after: (action, state) => {
      if (action.type.includes('UPDATE')) {
        store.commit(globalMutes.DECREMENT_REQUEST_COUNT, action.type)
      }
    }
  })
}
