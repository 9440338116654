import { schema } from 'normalizr'
export const links = new schema.Entity('links')
export const moduleDataMapCategories = new schema.Entity('moduleDataMapCategories')
export const moduleDataMapCategoryLinks = new schema.Entity('moduleDataMapCategoryLinks')
export const moduleDataCatalogGroups = new schema.Entity('moduleDataCatalogGroups')
export const moduleDataCatalogDetailLinks = new schema.Entity('moduleDataCatalogDetailLinks', { links: [links] })
export const moduleDataCatalogDetails = new schema.Entity('moduleDataCatalogDetails', { links: [moduleDataCatalogDetailLinks] })
export const moduleDataMapDetailLinks = new schema.Entity('moduleDataMapDetailLinks')
export const dataInterfaceItems = new schema.Entity('dataInterfaceItems', { links: [links] })
export const moduleDataCatalogs = new schema.Entity('moduleDataCatalogs')
