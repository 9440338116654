import _ from 'lodash'
import initialState from './initialState.vue.js'
import generateStateMutations from '@/vue/store/utils/generateStateMutations.vue.js'
export default {
  RESET_STATE (state) {
    _.assign(state, initialState())
  },
  SET_FOCUSED_ITEM_ID (state, id) {
    state.focusedItemId = id
  },
  SET_SELECTED_ITEM_ID (state, id) {
    state.selectedItemId = id
  },
  SET_COLOUR_FILTER (state, value) {
    state.entityValueColourFilter = value
  },
  SET_OPEN_PANEL_SECTION (state, value) {
    state.openPanelSection = value
  },
  RESET_COLOUR_FILTER (state) {
    state.entityValueColourFilter = initialState().entityValueColourFilter
  },
  ...generateStateMutations()
}
