import Vue from 'vue'
import _ from 'lodash'
import { normalize } from 'normalizr'
import generateActions from '@/vue/store/utils/generateActions.vue.js'
import { modules } from './schema.vue.js'

const modulesEndpoint = params => (`/api/projects/${params.projectId}/project_scenario_modules`)

const moduleMethodSchemas = {
  index: [modules],
  quick_index: [modules],
  get: modules,
  create: modules,
  update: modules,
  update_positions: [modules],
  destroy: modules
}

export default {
  ...generateActions(
    'modulesStore',
    'module',
    modulesEndpoint,
    moduleMethodSchemas
  ),
  COPY_MODULE ({ state, commit }, { params, payload }) {
    const source = state.modules[params.id]
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.post(`/api/projects/${params.projectId}/project_scenario_modules/${source.id}/clone.json`, _.assign({}, source, payload)).then(response => {
        const normalized = normalize(response.data, modules)
        commit('ADD_NORMALIZED_DATA', normalized)
        resolve(normalized)
      }, error => {
        reject(error)
      })
    })
  },
  UPDATE_LOCAL_MODULES ({ commit, state }, payload) {
    commit('ADD_NORMALIZED_DATA', normalize(payload, [modules]))
    return new Promise((resolve) => resolve())
  },
  REMOVE_LOCAL_MODULES ({ commit, state }, payload) {
    return new Promise(resolve => {
      const normalized = normalize(payload, [modules])
      commit('REMOVE_NORMALIZED_DATA', normalized)
      resolve(normalized)
    })
  },
  CREATE_SHARED_MODULE_LINK  ({ commit }, { params }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.post(`/api/projects/${params.projectId}/project_scenario_modules/${params.moduleId}/module_access_urls.json`).then(response => {
        const normalized = normalize(response.data, modules)
        commit('ADD_NORMALIZED_DATA', normalized)
        resolve(normalized)
      }, error => {
        reject(error)
      })
    })
  },
  DESTROY_SHARED_MODULE_LINK ({ commit }, { params }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.delete(`/api/projects/${params.projectId}/project_scenario_modules/${params.moduleId}/module_access_urls/${params.id}.json`).then(response => {
        const normalized = normalize(response.data, modules)
        commit('ADD_NORMALIZED_DATA', normalized)
        resolve(normalized)
      }, error => {
        reject(error)
      })
    })
  },
}
