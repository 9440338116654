import Vue from 'vue'
import _ from 'lodash'
import { normalize, denormalize } from 'normalizr'

const generateActions = (namespace, entity, endpointFn, methodSchemas) => {
  const entityUpper = entity.toUpperCase()
  const actions = {}

  const methods = _.keys(methodSchemas)

  if (_.includes(methods, 'index')) {
    actions[`LOAD_${entityUpper}S`] = ({ state, commit }, { params, payload } = {}) => {
      const baseEndpoint = endpointFn(params)
      return new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get(`${baseEndpoint}.json`, { params: payload })
          .then(
            response => {
              if (!_.isObject(response.data) || _.isEmpty(response.data)) { return resolve(response.data) }
              const normalized = normalize(response.data, methodSchemas.index)
              commit('ADD_NORMALIZED_DATA', normalized)
              resolve(normalized)
            },
            error => {
              reject(error)
            }
          )
      })
    }
  }

  if (_.includes(methods, 'quick_index')) {
    actions[`QUICK_LOAD_${entityUpper}S`] = ({ state, commit }, { params, payload } = {}) => {
      const baseEndpoint = endpointFn(params)
      return new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get(baseEndpoint + '/quick_index.json', { params: payload })
          .then(
            response => {
              if (!_.isObject(response.data) || _.isEmpty(response.data)) { return resolve(response.data) }
              const normalized = normalize(response.data, methodSchemas.quick_index)
              commit('ADD_NORMALIZED_DATA', normalized)
              resolve(normalized)
            },
            error => {
              reject(error)
            }
          )
      })
    }
  }

  if (_.includes(methods, 'get')) {
    actions[`LOAD_${entityUpper}`] = ({ state, commit }, { params, payload } = { params: {} }) => {
      const baseEndpoint = endpointFn(params)
      return new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get(`${baseEndpoint}/${params.id}.json`, { params: payload })
          .then(
            response => {
              if (!_.isObject(response.data) || _.isEmpty(response.data)) { return resolve(response.data) }
              const normalized = normalize(response.data, methodSchemas.get)
              commit('ADD_NORMALIZED_DATA', normalized)
              resolve(normalized)
            },
            error => {
              reject(error)
            }
          )
      })
    }
  }

  if (_.includes(methods, 'create')) {
    actions[`CREATE_${entityUpper}`] = ({ state, commit }, { params, payload } = {}) => {
      const baseEndpoint = endpointFn(params)
      return new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post(`${baseEndpoint}.json`, payload)
          .then(
            response => {
              if (!_.isObject(response.data) || _.isEmpty(response.data)) { return resolve(response.data) }
              const normalized = normalize(response.data, methodSchemas.create)
              commit('ADD_NORMALIZED_DATA', normalized)
              resolve(normalized)
            },
            error => {
              reject(error)
            }
          )
      })
    }
  }

  if (_.includes(methods, 'update')) {
    actions[`UPDATE_${entityUpper}`] = ({ state, commit, rootGetters }, { params } = { params: {} }) => {
      const normalizedId = methodSchemas.update.getId(params)
      const entity = denormalize(normalizedId, methodSchemas.update, state)
      const baseEndpoint = endpointFn(params)
      return new Promise((resolve, reject) => {
        Vue.prototype.$http
          .put(`${baseEndpoint}/${params.id}.json`, entity)
          .then(
            response => {
              if (!_.isObject(response.data) || _.isEmpty(response.data)) { return resolve(response.data) }
              const normalized = normalize(response.data, methodSchemas.update)
              if (rootGetters.noRequestInProgress(`${namespace}/UPDATE_${entityUpper}`)) {
                commit('ADD_NORMALIZED_DATA', normalized)
              }
              resolve(normalized)
            },
            error => {
              reject(error)
            }
          )
      })
    }

    actions[`UPDATE_${entityUpper}_PROPERTIES`] = ({ commit, dispatch }, { params, payload } = {}) => {
      commit('ADD_NORMALIZED_DATA', normalize(payload, methodSchemas.update))
      return dispatch(`UPDATE_${entityUpper}`, { params })
    }
  }

  if (_.includes(methods, 'update_positions')) {
    actions[`UPDATE_${entityUpper}_POSITIONS`] = ({ commit, rootGetters }, { params, payload } = {}) => {
      commit('ADD_NORMALIZED_DATA', normalize(payload, methodSchemas.update_positions))
      const baseEndpoint = endpointFn(params)
      return new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post(`${baseEndpoint}/update_positions.json`, payload)
          .then(
            response => {
              if (!_.isObject(response.data) || _.isEmpty(response.data)) { return resolve(response.data) }
              const normalized = normalize(response.data, methodSchemas.update_positions)
              if (rootGetters.noRequestInProgress(`${namespace}/UPDATE_${entityUpper}_POSITIONS`)) {
                commit('ADD_NORMALIZED_DATA', normalized)
              }
              resolve(normalized)
            },
            error => {
              reject(error)
            }
          )
      })
    }
  }

  if (_.includes(methods, 'destroy')) {
    actions[`DELETE_${entityUpper}`] = ({ state, commit }, { params, payload } = { params: {} }) => {
      const baseEndpoint = endpointFn(params)
      return new Promise((resolve, reject) => {
        Vue.prototype.$http.delete(`${baseEndpoint}/${params.id}.json`, { params: payload }).then(response => {
          if (!_.isObject(response.data) || _.isEmpty(response.data)) { return resolve(response.data) }
          const normalized = normalize(response.data, methodSchemas.destroy)
          commit('REMOVE_NORMALIZED_DATA', normalized)
          resolve(normalized)
        }, error => {
          reject(error)
        })
      })
    }
  }
  return actions
}

export default generateActions
