
import getters from './dataCatalogManagementPage/getters.vue.js'
import mutations from './dataCatalogManagementPage/mutations.vue.js'
import initialState from './dataCatalogManagementPage/initialState.vue.js'

export default {
  namespaced: true,
  state: initialState(),
  getters,
  mutations
}
