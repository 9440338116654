
import actions from './profilePageStore/actions.vue.js'
import getters from './profilePageStore/getters.vue.js'
import mutations from './profilePageStore/mutations.vue.js'
import initialState from './profilePageStore/initialState.vue.js'

export default {
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions
}
