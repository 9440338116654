
import getters from './assessmentStructureAdminPage/getters.vue.js'
import mutations from './assessmentStructureAdminPage/mutations.vue.js'
import initialState from './assessmentStructureAdminPage/initialState.vue.js'

export default {
  namespaced: true,
  state: initialState(),
  getters,
  mutations
}
