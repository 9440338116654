
import actions from './modulesStore/actions.vue.js'
import getters from './modulesStore/getters.vue.js'
import mutations from './modulesStore/mutations.vue.js'
import initialState from './modulesStore/initialState.vue.js'

export default {
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions
}
