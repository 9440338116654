
import getters from './diagramModuleState/getters.vue.js'
import mutations from './diagramModuleState/mutations.vue.js'
import initialState from './diagramModuleState/initialState.vue.js'

export default {
  namespaced: true,
  state: initialState(),
  getters,
  mutations
}
