import generateIndexes from '@/vue/store/utils/generateIndexes.vue.js'
import {
  dataInterfaceItems,
  moduleDataCatalogs,
  moduleDataCatalogDetails,
  moduleDataCatalogGroups,
  moduleDataMapCategories,
  moduleDataMapDetailLinks,
  moduleDataMapCategoryLinks

} from './schema.vue.js'

const staticDataInterfaceItemsIndexProperties = ['data_catalog_detail_id', 'module_id', 'data_catalog_id']
const staticDataCatalogDetailsIndexProperties = ['group_id', 'data_catalog_id', 'parent_id', 'level', 'data_map_category_ids']
const staticDataCatalogGroupsIndexProperties = ['data_catalog_id']
const dynamicDataCatalogsIndexProperties = ['data_map_ids']
const staticDataMapCategoriesIndexProperties = ['data_map_id']
const staticDataCatalogDetailLInksIndexProperties = [
  'data_map_id',
  'detail_id',
  'group_id',
  'data_catalog_id',
  'linked_detail_id',
  'linked_group_id',
  'linked_catalog_id'
]

const staticCapabilityCategoryLinksIndexProperties = [
  'data_map_id',
  'detail_id',
  'group_id',
  'data_catalog_id',
  'category_id'
]

const dynamicDataInterfaceItemsIndexProperties = [
  'stakeholder_ids',
  'stakeholder_role_ids',
  'product_and_service_item_ids',
  'product_and_service_category_ids',
  'tag_category_ids',
  'tag_ids'
]

export const indexes = {
  [dataInterfaceItems.key]: generateIndexes(staticDataInterfaceItemsIndexProperties, dynamicDataInterfaceItemsIndexProperties),
  [moduleDataCatalogDetails.key]: generateIndexes(staticDataCatalogDetailsIndexProperties),
  [moduleDataCatalogGroups.key]: generateIndexes(staticDataCatalogGroupsIndexProperties),
  [moduleDataCatalogs.key]: generateIndexes(dynamicDataCatalogsIndexProperties),
  [moduleDataMapCategories.key]: generateIndexes(staticDataMapCategoriesIndexProperties),
  [moduleDataMapDetailLinks.key]: generateIndexes(staticDataCatalogDetailLInksIndexProperties),
  [moduleDataMapCategoryLinks.key]: generateIndexes(staticCapabilityCategoryLinksIndexProperties)
}
