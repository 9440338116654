import _ from 'lodash'

export default {
  viewMode: state => state.viewMode,
  orderColumn: state => state.orderColumn,
  orderDirection: state => state.orderDirection,
  cachedProjectIds: state => cacheKey => {
    return _.get(state.projectsCache, cacheKey, [])
  },
  searchString: state => state.searchString
}
