import generateIndexes from '@/vue/store/utils/generateIndexes.vue.js'
import {
  dataMapCatalogPosition,
  dataMapDetailMappings,
  dataMapCategories,
  dataMapCategoryMappings,
  dataMapCatalogs,
  rolePermissions,
  userPermissions
} from './schema.vue.js'

const staticDataMapDataCatalogsIndexProperties = ['data_map_id']
const staticDataMapDetailMappingsIndexProperties = ['data_map_id']
const staticDataMapCategoriesIndexProperties = ['data_map_id']
const staticDataMapCategoryMappingsIndexProperties = ['data_map_id']
const dynamicDataMapCatalogsIndexProperties = ['data_map_ids']
const staticRolePermissionsProperties = ['data_map_id', 'user_ids', 'role_id']
const staticUserPermissionsProperties = ['data_map_id']

export const indexes = {
  [dataMapCatalogPosition.key]: generateIndexes(staticDataMapDataCatalogsIndexProperties),
  [dataMapDetailMappings.key]: generateIndexes(staticDataMapDetailMappingsIndexProperties),
  [dataMapCategories.key]: generateIndexes(staticDataMapCategoriesIndexProperties),
  [dataMapCategoryMappings.key]: generateIndexes(staticDataMapCategoryMappingsIndexProperties),
  [dataMapCatalogs.key]: generateIndexes([], dynamicDataMapCatalogsIndexProperties),
  [rolePermissions.key]: generateIndexes(staticRolePermissionsProperties, [], 'role_id'),
  [userPermissions.key]: generateIndexes(staticUserPermissionsProperties)
}
