//Polyfills
import 'core-js/stable';
import 'regenerator-runtime/runtime';


// Stylesheet Import

import VuetifyStyles from '../node_modules/vuetify/dist/vuetify.min.css'
import BaseStyles from './assets/vue-css/base.scss'
import VuetifyTableOverrides from '@/assets/vue-css/custom-table.scss';
import ModuleControlStyles from '@/assets/vue-css/module-controls.scss';
import VuetifyDialogOverrides from '@/assets/vue-css/custom-dialog.scss';
import InspireTreeStyles from  '@/assets/vue-css/custom-tree.scss';
import VuetifyButtonsOverrides from  '@/assets/vue-css/custom-button.scss';
import HeaderBarCSS from '@/assets/vue-css/header-bar.scss';
import CommonStyles from '@/assets/vue-css/styles.scss';
import Icons from '@/assets/vue-css/icomoon-fonts.scss';
import ColourPickerStyles from 'tui-color-picker/dist/tui-color-picker.css';
import JointJsStyles from  '@/assets/vue-css/jointjs-styles.scss';
import MaterialDesignIcons from  '@/assets/fonts/materialdesignicons.min.css';
import TinyStyles from  '@/assets/vue-css/tiny-mce.scss';

import '@/vue/shared/lodashMixins/unionAssign.js'
import '@/vue/shared/plugins/jointjsVuePlugin/jointjsModelOveride.js'
import '@/assets/images/image-icon1.svg'
import '@/assets/images/UserIcon.png'
// Entry points and Root Components

import main from './vue/main.vue.js'
import Vue from 'vue';
import Vuetify from 'vuetify'
import store from '@/vue/store/store.vue.js'
import router from '@/vue/router/router.vue.js'
import App from '@/vue/App.vue'

let opts = {
  theme: {
    dark:false,
    themes: {
      light: {
        primary: '#113253',
        secondary: '#D2D6E2',
        accent: '#5BADCA',
        info: '#5BADCA'
      }
    }
  }
}

// Init
new Vue({
  router,  
  store,
  vuetify: new Vuetify(opts),
  el: '#app',
  render: h => h(App),
})
