
import actions from './modulesAdminStore/actions.vue.js'
import getters from './modulesAdminStore/getters.vue.js'
import mutations from './modulesAdminStore/mutations.vue.js'
import initialState from './modulesAdminStore/initialState.vue.js'

export default {
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions
}
