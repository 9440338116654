import _ from 'lodash'
import initialState from './initialState.vue.js'

export default {
  RESET_STATE (state) {
    _.assign(state, initialState())
  },
  SET_PROPERTIES_VISIBLE (state, value) {
    state.propertiesVisible = value
  },
  SET_COMMENTS_VISIBLE (state, value) {
    state.commentsVisible = value
  }
}
