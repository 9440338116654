import generateGetters from '@/vue/store/utils/generateGetters.vue.js'
import { indexes } from './indexes.vue.js'
import _ from 'lodash'
import {
  assessments,
  assessmentStatuses,
  responseStatuses,
  rolePermissions,
  userPermissions,
  questions,
  questionOptions,
  questionTypes,
  answers,
  answerOptions,
  sections,
  groups,
  responses,
  myResponses,
  myAnswers,
  userResponses,
  userAnswers
} from './schema.vue.js'

export default {
  ...generateGetters(assessments),
  ...generateGetters(assessmentStatuses),
  ...generateGetters(responseStatuses),
  ...generateGetters(questionTypes),
  ...generateGetters(sections, indexes),
  ...generateGetters(questions, indexes),
  ...generateGetters(questionOptions, indexes),
  ...generateGetters(answers, indexes),
  ...generateGetters(answerOptions, indexes),
  ...generateGetters(groups, indexes),
  ...generateGetters(responses, indexes),
  ...generateGetters(myResponses, indexes),
  ...generateGetters(myAnswers, indexes),
  ...generateGetters(userResponses, indexes),
  ...generateGetters(userAnswers, indexes),
  ...generateGetters(rolePermissions),
  ...generateGetters(userPermissions),
  questionsBySectionAndGroupIds: (state, getters) => (sectionId, groupId) => {
    const questionsBySectionId = _.get(state, `indexes.questionsByAssessmentSectionId[${sectionId}]`, [])
    const questionsByGroupId = _.get(state, `indexes.questionsByAssessmentGroupId[${groupId}]`, [])
    const validIds = _.intersection(questionsBySectionId, questionsByGroupId)
    return getters.questionsByIds(validIds)
  },
  // Folowing not using indexes, as the objects dont have an id attribute
  // (they have a tuple id of role_id and assessment_id)
  // Could write custom index code, or adapt index generation, if performance becomes
  // an issue...

  rolePermissionsByAssessmentId: (state) => (assessmentId) => {
    return _.filter(state.rolePermissions, { assessment_id: assessmentId })
  },
  userPermissionsByAssessmentId: (state) => (assessmentId) => {
    return _.filter(state.userPermissions, { assessment_id: assessmentId })
  },
  myResponseByAssessmentId: (state) => (assessmentId) => {
    return _.find(state.myResponses, { assessment_id: assessmentId })
  }
}
