import _ from 'lodash'
import generateGetters from '@/vue/store/utils/generateGetters.vue.js'
import { imageViewers } from './schema.vue.js'
import { indexes } from './indexes.vue.js'

export default {
  ...generateGetters(imageViewers, indexes),

  imageViewerByModuleId: (state, getters) => moduleId => {
    return _.head(getters.imageViewersByModuleId(moduleId))
  }
}
