import generateActions from '@/vue/store/utils/generateActions.vue.js'
import {
  comments
} from './schema.vue.js'

const commentsEndpoint = params => ('/api/comments')

const commentMethodSchemas = {
  index: [comments],
  create: comments,
  update: comments,
  destroy: comments
}

export default {
  ...generateActions(
    'commentsStore',
    'comment',
    commentsEndpoint,
    commentMethodSchemas
  )
}
