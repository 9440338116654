import generateIndexes from '@/vue/store/utils/generateIndexes.vue.js'
import { checklistItems } from './schema.vue.js'

const staticChecklistItemIndexProperties = [
  'module_id'
]

const dynamicChecklistItemsIndexProperties = [
  'tag_ids',
  'stakeholder_ids'
]

export const indexes = {
  [checklistItems.key]: generateIndexes(staticChecklistItemIndexProperties, dynamicChecklistItemsIndexProperties)
}
