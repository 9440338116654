import _ from 'lodash'
import initialState from './initialState.vue.js'
import generateStateMutations from '@/vue/store/utils/generateStateMutations.vue.js'
export default {
  RESET_STATE (state) {
    _.assign(state, initialState())
  },
  SET_VIEW_MODE (state, value) {
    state.viewMode = value
  },
  SET_SEARCH_STRING (state, value) {
    state.searchString = value
  },
  SET_FOCUSED_STAKEHOLDER_ID (state, id) {
    state.focusedStakeholderId = id
  },
  SET_SELECTED_STAKEHOLDER_ID (state, id) {
    state.selectedStakeholderId = id
  },
  SET_SEARCH_MESSAGE (state, msg) {
    state.searchMessage = msg
  },
  ...generateStateMutations()
}
