import Vue from 'vue'
import { normalize } from 'normalizr'
import generateActions from '@/vue/store/utils/generateActions.vue.js'
import {
  projects,
  projectsWithResultCount,
  roles,
  sections,
  moduleTypes,
  moduleStatuses,
  moduleCategories,
  statuses,
  markets,
  projectResources,
  projectHistoryEvents,
  projectDataMaps,
  projectDataCatalogs,
  rolePermissions,
  userPermissions,
  associatedDataElements
} from './schema.vue.js'

const projectsEndpoint = () => ('/api/projects')
const sectionsEndpoint = params => (`/api/projects/${params.projectId}/sections`)
const rolesEndpoint = () => ('/api/roles')
const moduleTypesEndpoint = () => ('/api/scenario_modules')
const moduleStatusesEndpoint = () => ('/api/project_scenario_module_statuses')
const moduleCategoriesEndpoint = () => ('/api/scenario_module_categories')
const statusesEndpoint = () => ('/api/project_statuses')
const marketsEndpoint = () => ('/api/markets')
const projectResourcesEndpoint = params => (`/api/projects/${params.projectId}/project_resources`)
const projectDataMapsEndpoint = params => (`/api/projects/${params.projectId}/data_maps`)
const projectDataCatalogsEndpoint = params => (`/api/projects/${params.projectId}/data_catalogs`)
const projectHistoryEventsEndpoint = params => (`/api/projects/${params.projectId}/project_history_events`)
const rolePermissionsEndpoint = params => `/api/projects/${params.projectId}/role_permissions`
const userPermissionsEndpoint = params => `/api/projects/${params.projectId}/user_permissions`
const associatedDataElementsEndpoint = params => `/api/projects/${params.projectId}/associated_data_elements`

const projectMethodSchemas = {
  get: projects,
  index: projectsWithResultCount,
  quick_index: projectsWithResultCount,
  create: projects,
  update: projects,
  destroy: projects
}

const rolePermissionMethodSchemas = {
  index: [rolePermissions],
  create: rolePermissions,
  update: rolePermissions,
  destroy: rolePermissions
}

const userPermissionMethodSchemas = {
  index: [userPermissions],
  create: userPermissions,
  update: userPermissions,
  destroy: userPermissions
}

const sectionMethodSchemas = {
  index: [sections],
  create: sections,
  update: sections,
  update_positions: [sections],
  destroy: sections
}

const moduleTypeMethodSchemas = {
  index: [moduleTypes]
}

const moduleStatusesMethodSchemas = {
  index: [moduleStatuses]
}

const moduleCategoryMethodSchemas = {
  index: [moduleCategories]
}

const statusMethodSchemas = {
  index: [statuses]
}

const marketMethodSchemas = {
  index: [markets]
}

const projectResourceMethodSchemas = {
  index: [projectResources],
  create: projectResources,
  destroy: projectResources
}

const projectHistoryEventMethodSchemas = {
  index: [projectHistoryEvents]
}

const associatedDataElementsMethodSchemas = {
  index: [associatedDataElements]
}

export default {
  ...generateActions(
    'projectsStore',
    'project',
    projectsEndpoint,
    projectMethodSchemas
  ),
  ...generateActions(
    'projectsStore',
    'role_permission',
    rolePermissionsEndpoint,
    rolePermissionMethodSchemas
  ),
  ...generateActions(
    'projectsStore',
    'user_permission',
    userPermissionsEndpoint,
    userPermissionMethodSchemas
  ),
  ...generateActions(
    'projectsStore',
    'role',
    rolesEndpoint,
    { index: [roles] }
  ),
  ...generateActions(
    'projectsStore',
    'project_data_map',
    projectDataMapsEndpoint,
    { index: [projectDataMaps] }
  ),
  ...generateActions(
    'projectsStore',
    'project_data_catalog',
    projectDataCatalogsEndpoint,
    { index: [projectDataCatalogs] }
  ),
  ...generateActions(
    'projectsStore',
    'section',
    sectionsEndpoint,
    sectionMethodSchemas
  ),
  ...generateActions(
    'projectsStore',
    'module_type',
    moduleTypesEndpoint,
    moduleTypeMethodSchemas
  ),
  ...generateActions(
    'projectsStore',
    'module_status',
    moduleStatusesEndpoint,
    moduleStatusesMethodSchemas
  ),
  ...generateActions(
    'projectsStore',
    'module_category',
    moduleCategoriesEndpoint,
    moduleCategoryMethodSchemas
  ),
  ...generateActions(
    'projectsStore',
    'status',
    statusesEndpoint,
    statusMethodSchemas
  ),
  ...generateActions(
    'projectsStore',
    'market',
    marketsEndpoint,
    marketMethodSchemas
  ),
  ...generateActions(
    'projectsStore',
    'project_resource',
    projectResourcesEndpoint,
    projectResourceMethodSchemas
  ),
  ...generateActions(
    'projectsStore',
    'project_history_event',
    projectHistoryEventsEndpoint,
    projectHistoryEventMethodSchemas
  ),
  ...generateActions(
    'projectsStore',
    'associated_data_element',
    associatedDataElementsEndpoint,
    associatedDataElementsMethodSchemas
  ),
  CREATE_PROJECT_FROM ({ commit }, { params, payload }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.post(`/api/projects/${params.source_id}/create_project_from.json`, payload).then(response => {
        const normalized = normalize(response.data, projects)
        commit('ADD_NORMALIZED_DATA', normalized)
        resolve(normalized)
      }, error => {
        reject(error)
      })
    })
  },
  COPY_PROJECT ({ commit }, { params, payload }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.post(`/api/projects/${params.source_id}/clone`, payload).then(response => {
        const normalized = normalize(response.data, projects)
        commit('ADD_NORMALIZED_DATA', normalized)
        resolve(normalized)
      }, error => {
        reject(error)
      })
    })
  },
  CREATE_SHARED_PROJECT_LINK ({ commit }, { params }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.post(`/api/projects/${params.projectId}/project_access_urls.json`).then(response => {
        const normalized = normalize(response.data, projects)
        commit('ADD_NORMALIZED_DATA', normalized)
        resolve(normalized)
      }, error => {
        reject(error)
      })
    })
  },
  DESTROY_SHARED_PROJECT_LINK ({ commit }, { params }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.delete(`/api/projects/${params.projectId}/project_access_urls/${params.id}.json`).then(response => {
        const normalized = normalize(response.data, projects)
        commit('ADD_NORMALIZED_DATA', normalized)
        resolve(normalized)
      }, error => {
        reject(error)
      })
    })
  },
  CREATE_PROJECT_TEMPATE_FROM ({ state, commit }, { params }) {
    const project = state.projects[params.projectId]
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.post(`/api/projects/${params.projectId}/create_template_from.json`, project).then(response => {
        const normalized = normalize(response.data, projects)
        commit('ADD_NORMALIZED_DATA', normalized)
        resolve(normalized)
      }, error => {
        reject(error)
      })
    })
  },
  CREATE_PROJECT_SAMPLE_FROM ({ state, commit }, { params }) {
    const project = state.projects[params.projectId]
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.post(`/api/projects/${params.projectId}/create_sample_from.json`, project).then(response => {
        const normalized = normalize(response.data, projects)
        commit('ADD_NORMALIZED_DATA', normalized)
        resolve(normalized)
      }, error => {
        reject(error)
      })
    })
  },
  UPLOAD_PROJECT_IMAGE ({ commit }, { params, payload }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.post(`/api/projects/${params.projectId}/upload_image.json`, payload).then(response => {
        const normalized = normalize(response.data, projects)
        commit('ADD_NORMALIZED_DATA', normalized)
        resolve(normalized)
      }, error => {
        reject(error)
      })
    })
  },
  UPLOAD_PROJECT_PDF ({ commit }, { params, payload }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.post(`/api/projects/${params.projectId}/upload_pdf.json`, payload).then(response => {
        const normalized = normalize(response.data, projects)
        commit('ADD_NORMALIZED_DATA', normalized)
        resolve(normalized)
      }, error => {
        reject(error)
      })
    })
  },
  UPDATE_PROJECT_RESOURCE ({ commit }, { params, payload }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.put(`/api/projects/${params.projectId}/project_resources/${params.id}.json`, payload).then(response => {
        const normalized = normalize(response.data, projectResources)
        commit('ADD_NORMALIZED_DATA', normalized)
        resolve(normalized)
      }, error => {
        reject(error)
      })
    })
  },
  EXPORT_PROJECT ({ commit, dispatch }, { params, urlParams }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .get(
          `/api/projects/${params.id}/export.${params.exportType}${urlParams}`, {
            responseType: 'blob'
          })
        .then(
          response => {
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
    })
  },
  EXPORT_ASSOCIATED_DATA_ELEMENTS ({ commit, dispatch }, params) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .get(
          `/api/projects/${params.id}/associated_data_elements/export.json`, {
            responseType: 'blob'
          })
        .then(
          response => {
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
    })
  },
  UPDATE_LOCAL_PROJECTS ({ commit, state }, payload) {
    commit('ADD_NORMALIZED_DATA', normalize(payload, [projects]))
    return new Promise((resolve) => resolve())
  },
  COPY_SECTION ({ commit }, { params, payload }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.post(`/api/projects/${params.project_id}/sections/${params.section_id}/clone`, payload).then(response => {
        const normalized = normalize(response.data, sections)
        commit('ADD_NORMALIZED_DATA', normalized)
        resolve(normalized)
      }, error => {
        reject(error)
      })
    })
  },
  REMOVE_LOCAL_ASSOCIATED_DATA_ELEMENTS ({ commit, state }, payload) {
    return new Promise(resolve => {
      const normalized = normalize(payload, [associatedDataElements])
      commit('REMOVE_NORMALIZED_DATA', normalized)
      resolve(normalized)
    })
  }
}
