import * as joint from '@clientio/rappid'
import _ from 'lodash'
import Tr3dentLink from '../base/baseLink.vue.js'

export default Tr3dentLink.extend({
  initialize: function () {
    this.on('change:relationship_type', (cell, relType) => {
      this.prop('link_color', relType.relationship_color)
    })

    this.on('change:link_color', (cell, color) => {
      this.setLinkColor(color)
    })
    Tr3dentLink.prototype.initialize.apply(this, arguments)
  },
  setLinkColor: function (color) {
    this.attr('line/stroke', color)
    this.attr('line/sourceMarker/fill', color)
    this.attr('line/sourceMarker/stroke', color)
    this.attr('line/targetMarker/fill', color)
    this.attr('line/targetMarker/stroke', color)
    this.attr('.connection/stroke', color)
  },
  markup: joint.shapes.standard.Link.prototype.markup,
  highlightParams: {
    deep: true
  },
  defaults: _.defaultsDeep({
    size: { width: 90, height: 60 },
    type: 'tr3dent.Link',
    router: {
      name: 'normal'
    },
    connector: {
      name: 'rounded'
    },
    labels: [{
      attrs: {
        text: {
          'font-family': 'Roboto,sans-serif',
          'font-weight': 'Normal',
          text: '',
          fill: '#000000',
          fontSize: 13
        },
        rect: {
          fill: '#ffffff',
          stroke: '#ffffff'
        }
      },
      position: {
        distance: 0.5
      }
    }],
    attrs: {
      line: {
        type: 'normal',
        stroke: '#000000',
        strokeDasharray: '0',
        strokeWidth: 2,
        fill: 'none',
        sourceMarker: {
          type: 'path',
          d: 'M 0 0 0 0',
          stroke: '#000000',
          fill: '#000000'
        },
        targetMarker: {
          type: 'path',
          d: 'M 0 -5 -10 0 0 5 z',
          stroke: '#000000',
          fill: '#000000'
        }
      }
    }
  }, joint.shapes.standard.Link.prototype.defaults)
})
