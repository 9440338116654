
import generateGetters from '@/vue/store/utils/generateGetters.vue.js'
import { users, roles, permissions } from './schema.vue.js'
import { indexes } from './indexes.vue.js'

export default {
  ...generateGetters(users, indexes),
  ...generateGetters(roles, indexes),
  ...generateGetters(permissions)
}
