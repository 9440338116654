const initialState = () => {
  return {
    selectedItemId: null,
    openPanelSection: 'properties',
    colourFilter: {
      tag_ids: [],
      tag_category_ids: [],
      stakeholder_ids: [],
      stakeholder_role_ids: [],
      selected_stakeholder_role_ids: [],
      product_and_service_item_ids: [],
      product_and_service_category_ids: [],
      selected_product_and_service_category_ids: [],
      user_ids: [],
      operator: 'OR'
    }
  }
}
export default initialState
