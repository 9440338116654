const initialState = () => {
  return {
    viewMode: 'list',
    searchString: '',
    searchMessage: '',
    focusedProductId: null,
    selectedProductId: null
  }
}
export default initialState
