<template>
  <Dialog
    :active="internalActiveState"
    content-class="v-dialog--common v-dialog--thin"
    @close="internalActiveState = false"
  >
    <template #header>
      {{ notification.title }}
    </template>

    <template #body>
      <div
        v-if="!!notification.image"
      >
        <v-img
          contain
          max-height="500"
          :src="notification.image.url"
        />
      </div>

      <!-- eslint-disable vue/no-v-html -->
      <div
        class="py-3 font-weight-medium"
        v-html="cleanedContent"
      />
      <!-- eslint-enable vue/no-v-html -->
    </template>

    <template #actions>
      <v-spacer />
      <v-btn
        class="v-btn--tr3-tretiary"
        test-hook="close-btn"
        text
        @click="internalActiveState = false"
      >
        Got It!
      </v-btn>
    </template>
  </Dialog>
</template>

<script>
import DOMPurify from 'dompurify'
import _ from 'lodash'
import Dialog from '@/vue/shared/components/Dialog.vue'

export default {
  name: 'NewFeatureNotification',
  components: {
    Dialog
  },
  props: {
    active: {
      type: Boolean,
      required: true
    },
    notification: {
      type: Object,
      required: true
    }
  },
  computed: {
    internalActiveState: {
      get () {
        return this.active
      },
      set (value) {
        if (!value) {
          this.$emit('close', null)
        }
      }
    },
    cleanedContent () {
      const content = _.get(this.notification, 'content', '')
      return DOMPurify.sanitize(content)
    }
  }
}
</script>
