import generateIndexes from '@/vue/store/utils/generateIndexes.vue.js'
import { users, roles } from './schema.vue.js'

const staticUserProperties = [
  'role_ids'
]

const staticRoleProperties = [
  'user_ids'
]

export const indexes = {
  [users.key]: generateIndexes(staticUserProperties),
  [roles.key]: generateIndexes(staticRoleProperties)
}
