
import actions from './releaseNotesAdminStore/actions.vue.js'
import getters from './releaseNotesAdminStore/getters.vue.js'
import mutations from './releaseNotesAdminStore/mutations.vue.js'
import initialState from './releaseNotesAdminStore/initialState.vue.js'

export default {
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions
}
