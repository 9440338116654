import { entityAttributes } from './schema.vue.js'
import generateActions from '@/vue/store/utils/generateActions.vue.js'

const entityAttributeEndpoint = () => {
  return '/api/entity_attributes'
}

const methodSchemas = {
  index: [entityAttributes]
}

export default {
  ...generateActions(
    'entityAttributesStore',
    'entity_attribute',
    entityAttributeEndpoint,
    methodSchemas
  )
}
