export default {
  SET_DATA_CATALOG_PROPERTIES_VISIBLE (state, value) {
    state.dataCatalogPropertiesVisible = value
  },
  SET_DATA_CATALOG_DETAIL_ATTRIBUTES_VISIBLE (state, value) {
    state.dataCatalogDetailAttributesVisible = value
  },
  SET_SELECTED_DETAIL_ID (state, { detailId, treeId }) {
    state.selectedDetailId = detailId
    state.selectedTreeId = treeId
  },
  CLEAR_SELECTED_DETAIL_ID (state) {
    state.selectedDetailId = null
    state.selectedTreeId = null
  },
  SET_VIEW_MODE (state, value) {
    state.viewMode = value
  }
}
