
import generateGetters from '@/vue/store/utils/generateGetters.vue.js'
import { checklistItems, links } from './schema.vue.js'
import { indexes } from './indexes.vue.js'
import _ from 'lodash'

const matchingIds = (colourFilter, state) => {
  const filterFn = (colourFilter.operator === 'AND') ? _.intersection : _.union
  let matching = []

  if (colourFilter.stakeholder_ids.length > 0) {
    _.each(colourFilter.stakeholder_ids, (shId) => {
      matching.push(state.indexes.checklistItemsByStakeholderIds[shId])
    })
  }

  if (colourFilter.tag_ids.length > 0) {
    _.each(colourFilter.tag_ids, (tagId) => {
      matching.push(state.indexes.checklistItemsByTagIds[tagId])
    })
  }

  matching = _.map(matching, (match) => {
    return _.compact(match)
  })

  const filteredIds = filterFn(...matching)

  return filteredIds
}

const isActive = (colorFilter) => {
  return (
    !!colorFilter.tag_ids.length ||
    !!colorFilter.stakeholder_ids.length
  )
}

export default {
  ...generateGetters(checklistItems, indexes),
  ...generateGetters(links),

  filteredChecklistItemsByModule: (state) => (moduleId, colorFilter) => {
    const itemsByModule = _.get(state, `indexes.checklistItemsByModuleId[${moduleId}]`, [])

    let validIds = itemsByModule

    if (isActive(colorFilter)) { // isActive() can be a method private to the getters file, just like the actual matchingIds() method is
      validIds = _.intersection(itemsByModule, matchingIds(colorFilter, state))
    }
    const items = _.map(validIds, (id) => _.get(state, `checklistItems.[${id}]`, null))
    return _.compact(items)
  }
}
