import _ from 'lodash'
import store from '@/vue/store/store.vue.js'
import { globalMutes } from '@/vue/store/mutation-types.vue.js'
import { profileActs } from '@/vue/store/action-types.vue.js'
import VueRouter from 'vue-router'
import routes from './routes.vue.js'

const testPattern = /^\d+$/
const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  const params = _.get(to, 'params', {})
  for (const key in params) {
    if (key.endsWith('Id')) {
      if (testPattern.test(params[key])) {
        params[`${key}Int`] = _.parseInt(params[key])
      } else {
        // Decode shared link id
        try {
          let pId = decodeURI(params[key])
          pId = atob(pId)
          pId = pId.split('|')[0]
          params[`${key}Int`] = _.parseInt(pId)
        } catch (e) {
          delete params[`${key}Int`] 
        }
      }
    }
  }
  next()
})

router.beforeEach((to, from, next) => {
  const projectId = _.get(to, 'params.projectId', null)
  const moduleId = _.get(to, 'params.moduleId', null)

  if (moduleId && !testPattern.test(moduleId)) {
    store.commit(globalMutes.SET_X_SESSION_TOKEN, projectId + ':' + moduleId)
  } else if(projectId && !testPattern.test(projectId)) {
    store.commit(globalMutes.SET_X_SESSION_TOKEN, projectId)
  } 
  else {
    store.commit(globalMutes.CLEAR_X_SESSION_TOKEN)
  }

  next()
})

router.beforeEach((to, from, next) => {
  store.commit('CLEAR_ITEM_PARAMS')
  next()
})

router.beforeEach((to, from, next) => {
  store.commit('SET_HEADER_BAR_TITLE', null)

  /* Only wait for load if no data present */
  const promises = []
  const systemPromise = store.dispatch(profileActs.LOAD_SYSTEM)
  if (!store.getters.system) {
    promises.push(systemPromise)
  }
  if (!_.get(to, 'meta.publicPage', false)) {
    const userPromise = store.dispatch(profileActs.LOAD_CURRENT_USER)
    const companyPromise = store.dispatch(profileActs.LOAD_CURRENT_COMPANY)

    if (!store.getters.currentUser) {
      promises.push(userPromise)
    }
    if (!store.getters.currentCompany) {
      promises.push(companyPromise)
    }
  }

  Promise.all(promises).finally(() => {
    const isRestricted = _.get(store, 'getters.currentUser.restricted', false)
    const isSysAdmin = _.get(store, 'getters.currentUser.system_admin', false)
    const navigationAllowed = _.includes(['/', '/profilepage'], to.path)
    const logoutRedirect = _.get(store.getters, 'currentCompany.login_page_url', null)

    // Hack to satisfy CSG requirement to logout to correct login page.
    // Needs to be removed when we implement tenant subdomains.
    if (logoutRedirect) {
      localStorage.setItem('logoutUrl', logoutRedirect)
    } else {
      localStorage.removeItem('logoutUrl')
    }

    if (!isSysAdmin && !!isRestricted && !navigationAllowed) {
      next({ name: 'ProfilePage' })
    } else {
      next()
    }
  })
})

router.beforeEach((to, from, next) => {
  // If navigating to a project page, register it as recently viewed
  const inProject = _.some(to.matched, x => _.includes(['ProjectPage', 'ModulePage'], x.name))
  const projectId = _.get(to, 'params.projectIdInt', null)
  if (inProject && projectId) {
    store.dispatch(profileActs.REGISTER_VIEWED_PROJECT, projectId)
  }
  next()
})

router.beforeEach((to, from, next) => {
  const queryItem = _.get(to, 'query.item', null)
  if (queryItem) {
    const base64Str = decodeURIComponent(queryItem)
    const jsonStr = window.atob(base64Str)
    const json = JSON.parse(jsonStr)

    const paramType = _.get(json, 'itemType', null)
    const paramId = _.get(json, 'itemId', null)
    if (!paramType || !paramId) { return }
    const params = {
      itemType: paramType,
      itemId: paramId
    }
    store.commit('SET_ITEM_PARAMS', params)
  }
  next()
})

export default router
