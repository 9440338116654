const initialState = () => {
  return {
    selectedItemId: '',
    focusedItemId: null,
    openPanelSection: 'properties',
    entityValueColourFilter: {
      operator: 'AND',
      tag_ids: [],
      tag_category_ids: [],
      tag_ids_from_categories: [],
      stakeholder_ids: [],
      stakeholder_role_ids: [],
      stakeholder_ids_from_roles: [],
      product_and_service_item_ids: [],
      product_and_service_category_ids: [],
      product_and_service_item_ids_from_categories: [],
      assignments: []
    }
  }
}
export default initialState
