import _ from 'lodash'
import Vue from 'vue'

class ApplicationTour {
  constructor () {
    this._tourSteps = {}
    this.tour = Vue.observable({
      names: []
    })
    ApplicationTour.instance = this
    return ApplicationTour.instance
  }

  registerTourSteps (key, steps) {
    this._tourSteps[key] = steps
    this.tour.names = _.chain(this._tourSteps)
      .flatMap()
      .map('tour')
      .uniq()
      .value()
  }

  unregisterTourSteps (key) {
    delete this._tourSteps[key]
    this.tour.names = _.chain(this._tourSteps)
      .flatMap()
      .map('tour')
      .uniq()
      .value()
  }

  clearAllSteps () {
    this._tourSteps = {}
  }

  getAllTourSteps (tour) {
    // Build list of tour steps
    return _.chain(this._tourSteps)
      .flatMap() // convert object property values to an array
      .filter({ tour }) // filter by tour name
      .orderBy('index') // order groups of steps by index
      .compact() // Remove nulls
      .value()
  }

  getCallbacks (tour) {
    return _.reduce([
      'onStart',
      'onPreviousStep',
      'onNextStep',
      'onStop',
      'onSkip',
      'onFinish'
    ], (cbs, cbName) => {
      cbs[cbName] = _.chain(this._tourSteps)
        .flatMap() // convert object property values to an array
        .filter({ tour })
        .orderBy('index')
        .flatMap(`${cbName}Callbacks`)
        .over()
        .value()
      return cbs
    }, {})
  }
}

const instance = new ApplicationTour()

export default instance
