<template>
  <v-app class="transform-app">
    <router-view name="theAppNavigationBar" />
    <router-view name="theHeaderBar" />
    <router-view class="overflow-y-auto" />
    <router-view name="theFooter" />

    <router-view
      v-if="showUpdateAvailable"
      name="updateAvailableNotification"
    />

    <Tour
      v-for="tour in tours"
      :key="tour"
      :name="tour"
    />
    <NewFeatureNotification
      v-if="showNewFeatureNotification"
      :active="showNewFeatureNotification"
      :notification="notification"
      @close="notificationSeen()"
    />

    <Sidebar
      title="What's New"
      :active="releaseNotesVisible"
      @close="closeReleaseNotes()"
    >
      <ReleaseNotes
        v-if="releaseNotesVisible"
      />
    </Sidebar>

    <AlertBar
      :active="!!alertText"
      :text="alertText"
      @close="closeAlert"
    />

    <TermsAndConditionsDialog
      v-if="!sharedUser && showTermsAndConditionsDialog"
      :active="showTermsAndConditionsDialog"
      @close="onCloseTermsAndConditions()"
    />

    <TheSuccessSnackbar />
    <SavingSpinner />
  </v-app>
</template>
<script>
import _ from 'lodash'
import { mapGetters as globalGetters } from 'vuex'
import { profileActs } from '@/vue/store/action-types.vue.js'
import { globalMutes } from '@/vue/store/mutation-types.vue.js'
import SavingSpinner from '@/vue/shared/components/SavingSpinner.vue'
import TheSuccessSnackbar from '@/vue/shared/components/TheSuccessSnackbar.vue'
import Tour from '@/vue/shared/components/tour/Tour.vue'
import ApplicationTour from '@/vue/shared/components/tour/services/applicationTour.vue.js'
import NewFeatureNotification from '@/vue/shared/components/NewFeatureNotification.vue'
import ReleaseNotes from '@/vue/shared/components/ReleaseNotes.vue'
import Sidebar from '@/vue/shared/components/Sidebar.vue'
import AlertBar from '@/vue/shared/components/AlertBar.vue'
import TermsAndConditionsDialog from '@/vue/shared/components/TermsAndConditionsDialog.vue'

export default {
  name: 'App',
  components: {
    Tour,
    NewFeatureNotification,
    ReleaseNotes,
    Sidebar,
    AlertBar,
    TermsAndConditionsDialog,
    SavingSpinner,
    TheSuccessSnackbar
  },
  data () {
    return {
      showTermsAndConditionsDialog: false,
      showNewFeatureNotification: false,
      notification: null
    }
  },
  computed: {
    ...globalGetters([
      'showUpdateAvailable',
      'releaseNotesVisible',
      'alertText'
    ]),
    tours () {
      return ApplicationTour.tour.names
    },
    sharedUser () {
      return _.get(this.currentUser, 'shared_user', true)
    },
    showHompageTour () {
      const showTour = _.get(this.system, 'show_application_tours', false) &&
        this.currentUser.accepted_eula &&
        !this.currentUser.viewed_intro_tour

      return showTour && !!_.chain(this.$route)
        .get('matched', [])
        .map('name')
        .includes('ProjectHomePage')
        .value()
    }
  },
  watch: {
    system (newSystem, oldSystem) {
      const newImage = _.get(newSystem, 'favicon_image', null)
      const oldImage = _.get(oldSystem, 'favicon_image', null)
      if (!!newImage && newImage !== oldImage) {
        const link = document.querySelector("link[rel*='icon']") || document.createElement('link')
        link.type = 'image/x-icon'
        link.rel = 'icon'
        link.href = newImage
        document.getElementsByTagName('head')[0].appendChild(link)
      }

      const newText = _.get(newSystem, 'browser_tab_text', null)
      const oldText = _.get(oldSystem, 'browser_tab_text', null)

      if (!!newText && newText !== oldText) {
        document.title = newText
      }
    },
    currentUser (newUser, oldUser) {
      if (newUser) {
        // Dont show new feature if we pop the tour
        if ((!newUser.viewed_intro_tour || (!!oldUser && !oldUser.viewed_intro_tour)) &&
            _.includes(_.map(this.$router.currentRoute.matched, 'name'), 'ProjectHomePage')) {
          return
        }
        if ((!newUser.viewed_landing_tour || (!!oldUser && !oldUser.viewed_landing_tour)) &&
         _.includes(_.map(this.$router.currentRoute.matched, 'name'), 'ProjectPage')) {
          return
        }
        const valid = _.chain(newUser.valid_notifications)
          .orderBy('created_at')
          .filter((n) => {
            return _.includes(_.map(this.$router.currentRoute.matched, 'name'), n.page_name)
          })
          .first()
          .value()
        if (valid) {
          setTimeout(() => {
            this.notification = valid
            this.showNewFeatureNotification = true
          }, 1000)
        }

        if (!newUser.accepted_eula) {
          this.showTermsAndConditionsDialog = true
        }
      }
    }
  },
  mounted () {
    this.showTermsAndConditionsDialog = !_.get(this.currentUser, 'accepted_eula', true)
  },
  methods: {
    closeReleaseNotes () {
      this.$store.commit(globalMutes.SET_RELEASE_NOTES_VISIBLE, false)
    },
    notificationSeen () {
      this.showNewFeatureNotification = false
      this.$store.dispatch(profileActs.REGISTER_VIEWED_NOTIFICATION, this.notification.id).finally(() => {
        this.notification = null
      })
    },
    closeAlert () {
      this.$store.commit(globalMutes.CLOSE_ALERT)
    },
    onCloseTermsAndConditions () {
      this.showTermsAndConditionsDialog = false
      if (this.showHompageTour) {
        this.$tours.homePageTour.start()
      }
    }
  }
}
</script>
