import generateActions from '@/vue/store/utils/generateActions.vue.js'
import { modules, moduleCategories } from './schema.vue.js'
import Vue from 'vue'
import { normalize } from 'normalizr'

const ModulesAdminEndpoint = (params) => {
  return '/api/admin/scenario_modules'
}

const moduleCategoriesAdminEndpoint = (params) => {
  return '/api/admin/scenario_module_categories'
}

const modulesMethodSchemas = {
  index: [modules]
}

const moduleCategoriesMethodSchemas = {
  index: [moduleCategories]
}

export default {
  ...generateActions(
    'modulesAdminStore',
    'modules',
    ModulesAdminEndpoint,
    modulesMethodSchemas
  ),
  ...generateActions(
    'modulesAdminStore',
    'module_categories',
    moduleCategoriesAdminEndpoint,
    moduleCategoriesMethodSchemas
  ),
  UPDATE_MODULE_PROPERTIES ({ state, commit }, scenarioModuleFormData) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.put(`/api/admin/scenario_modules/${scenarioModuleFormData.get('id')}`, scenarioModuleFormData).then(response => {
        commit('ADD_NORMALIZED_DATA', normalize(response.data, modules))
        resolve(response)
      },
      error => {
        commit(`/api/admin/scenario_modules/${scenarioModuleFormData.get('id')}`, [])
        reject(error)
      }
      )
    })
  }
}
