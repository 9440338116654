import generateStateGetters from '@/vue/store/utils/generateStateGetters.vue.js'
export default {
  focusedItemId: (state) => state.focusedItemId,
  searchString: (state) => state.searchString,
  searchMessage: (state) => state.searchMessage,
  selectedItemId: (state) => state.selectedItemId,
  entityValueColourFilter: state => state.entityValueColourFilter,
  entityValueFilterActive: (state) => {
    return (
      !!state.entityValueColourFilter.assignments.length ||
      !!state.entityValueColourFilter.tag_ids.length ||
      !!state.entityValueColourFilter.tag_category_ids.length ||
      !!state.entityValueColourFilter.tag_ids_from_categories.length ||
      !!state.entityValueColourFilter.stakeholder_ids.length ||
      !!state.entityValueColourFilter.stakeholder_role_ids.length ||
      !!state.entityValueColourFilter.stakeholder_ids_from_roles.length ||
      !!state.entityValueColourFilter.product_and_service_item_ids.length ||
      !!state.entityValueColourFilter.product_and_service_category_ids.length ||
      !!state.entityValueColourFilter.product_and_service_item_ids_from_categories.length
    )
  },
  ...generateStateGetters()
}
