/* istanbul ignore file */
/*
  This code is next to impossible to test without fully mocking the store.
  It also relys on a lot of async behaviour that is diffucult to simulate, espicially
  around the store watcher, and the saved callback subscriptions.

  We do have a TODO to test this in future, but for now, the effort is not worth the gain.
*/

import Vue from 'vue'
import store from '@/vue/store/store.vue.js'
import router from '@/vue/router/router.vue.js'
import _ from 'lodash'

let isRefreshing = false
/*
  401 Unauthorized means an invalid access token, try to refresh and attempt request again
*/

const unauthorized = (error) => {
  const originalReq = error.config
  if (!isRefreshing) {
    isRefreshing = true
    Vue.prototype.$http.get('/api/oauth/tokens.json').then((response) => {
      store.commit('SET_ACCESS_TOKEN', response.data)
      if (store.getters.allRequestsFailed) {
        isRefreshing = false
        _.map(store.getters.tokenSubscribers, (cb) => cb(response.data))
        store.commit('CLEAR_TOKEN_SUBSCRIBERS', null)
      } else {
        const unwatch = store.watch(() => store.getters.allRequestsFailed, (failed) => {
          if (failed) {
            unwatch()
            isRefreshing = false
            _.map(store.getters.tokenSubscribers, (cb) => cb(response.data))
            store.commit('CLEAR_TOKEN_SUBSCRIBERS', null)
          }
        })
      }
    }).catch(() => {
      isRefreshing = false
      store.commit('SET_ACCESS_TOKEN', null)
      store.commit('CLEAR_TOKEN_SUBSCRIBERS', null)
    })
  }

  const retryPromise = new Promise((resolve, reject) => {
    store.commit('ADD_TOKEN_SUBSCRIBER', (token) => {
      originalReq.headers.Authorization = 'Bearer ' + token
      resolve(Vue.prototype.$http(originalReq))
    })
  })
  return retryPromise
}

/*
  403 Forbidden, tried to access an unpermitted resource, boot to the forbidden page
*/

const forbidden = () => {
  store.commit('SET_ACCESS_TOKEN', null)
  // Hack to handle CSG, remove when implementing tenant subdomains.
  const redirectTo = localStorage.getItem('logoutUrl') || '/'
  localStorage.removeItem('logoutUrl')
  router.push({ path: redirectTo })
}

/*
  404 Resource or Page Not found, boot to the not found page
*/
const notFound = () => {
  router.push({ path: '/not-found' })
}

/*
 Non-standard response code. We use this
 to allow the backend specifiy browser redirect locations from API endpoints.

 301 and 302 status codes are handled transparently by browsers in general and
 axios specifically, so the *request* is redirected. We cannot intercept it to
 get the redirect location to redirect the browser.

 A 402 Payment Required response is not widely used, so here we repurpose it
 to inform the frontend that  a full page redirect is required in the response
 of an XMLHttpRequest.
*/

const paymentRequired = (error) => {
  if (_.get(error, 'response.headers.location', null)) {
    window.location.assign(error.response.headers.location)
  }
}

/*
  503 Service unavailable: Server is down, assume for maintenance
*/

const serviceUnavailable = () => {
  window.location.assign('/maintenance.html')
}

const responseCodes = {
  request: null,
  response: [
    (response) => (response),
    (error) => {
      const statusCode = _.get(error, 'response.status', null)
      switch (statusCode) {
        case 401: {
          if (error.config.skipErrorRedirect) { break }
          return unauthorized(error)
        }
        case 402: {
          paymentRequired(error)
          break
        }
        case 403: {
          if (error.config.skipErrorRedirect) { break }
          forbidden(error)
          break
        }
        case 404: {
          if (error.config.skipErrorRedirect) { break }
          notFound(error)
          break
        }
        case 503: {
          serviceUnavailable(error)
          break
        }
      }
      return Promise.reject(error)
    }
  ]
}
export default responseCodes
