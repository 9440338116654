import * as joint from '@clientio/rappid'
import _ from 'lodash'
import BusinessProcessElement from '../base/baseBusinessProcessElement.vue.js'

const DataObject = BusinessProcessElement.extend({
  initialize: function () {
    this.on('change:icon_type', (cell, value) => {
      this.attr('dataTypeIcon/iconType', value)
    })
    this.on('change:collection', (cell, value) => {
      this.attr('collectionIcon/collection', value)
    })
    BusinessProcessElement.prototype.initialize.apply(this, arguments)
  },
  displayModeAttributes: {
    stencil: {
      size: { width: 30, height: 40 },
      attrs: {
        label: {
          text: ''
        }
      }
    },
    preview: {
      size: { width: 60, height: 90 },
      position: { x: 10, y: 2 }
    },
    canvas: {
      size: { width: 60, height: 90 }
    }
  },
  markup: joint.shapes.bpmn2.DataObject.prototype.markup,
  defaults: _.defaultsDeep({
    size: { width: 30, height: 25 },
    shape: 'Data Object',
    type: 'businessProcess.DataObject',
    attrs: {
      body: {
        fill: '#ffffff',
        stroke: '#000000',
        fillOpacity: 1
      },
      label: {
        text: 'Data Object'
      }
    }
  }, joint.shapes.bpmn2.DataObject.prototype.defaults)
})
DataObject.attributes = joint.shapes.bpmn2.DataObject.attributes
DataObject.DATA_OBJECT_TYPE_ICONS = joint.shapes.bpmn2.DataObject.DATA_OBJECT_TYPE_ICONS
DataObject.DATA_OBJECT_COLLECTION_ICONS = joint.shapes.bpmn2.DataObject.DATA_OBJECT_COLLECTION_ICONS
export default DataObject
