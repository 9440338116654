import Vue from 'vue'
import _ from 'lodash'
import { normalize } from 'normalizr'
import generateActions from '@/vue/store/utils/generateActions.vue.js'
import {
  dataCatalogs,
  dataCatalogDetails,
  dataCatalogGroups,
  rolePermissions,
  userPermissions,
  dataCatalogDetailLinks
} from './schema.vue.js'

const dataCatalogsEndpoint = () => {
  return '/api/data_catalogs'
}

const dataCatalogDetailsEndpoint = params => {
  return `/api/data_catalogs/${params.dataCatalogId}/data_catalog_details`
}

const dataCatalogGroupsEndpoint = params => {
  return `/api/data_catalogs/${params.dataCatalogId}/data_catalog_groups`
}

const dataCatalogDetailLinksEndpoint = params => {
  return `/api/data_catalogs/${params.dataCatalogId}/data_catalog_details/${params.dataCatalogDetailId}/data_catalog_detail_links`
}

const rolePermissionsEndpoint = params => `/api/data_catalogs/${params.dataCatalogId}/role_permissions`
const userPermissionsEndpoint = params => `/api/data_catalogs/${params.dataCatalogId}/user_permissions`

const dataCatalogsMethodSchemas = {
  index: [dataCatalogs],
  create: dataCatalogs,
  destroy: dataCatalogs,
  update: dataCatalogs,
  get: dataCatalogs
}

const rolePermissionMethodSchemas = {
  index: [rolePermissions],
  create: rolePermissions,
  update: rolePermissions,
  destroy: rolePermissions
}

const userPermissionMethodSchemas = {
  index: [userPermissions],
  create: userPermissions,
  update: userPermissions,
  destroy: userPermissions
}

const dataCatalogDetailsMethodSchemas = {
  index: [dataCatalogDetails],
  create: dataCatalogDetails,
  update: dataCatalogDetails,
  update_positions: [dataCatalogDetails]
}

const dataCatalogGroupsMethodSchemas = {
  index: [dataCatalogGroups],
  create: dataCatalogGroups,
  destroy: dataCatalogGroups,
  update: dataCatalogGroups,
  update_positions: [dataCatalogGroups]
}

const dataCatalogDetailLinksSchemas = {
  index: [dataCatalogDetailLinks],
  destroy: dataCatalogDetailLinks,
  update: dataCatalogDetailLinks
}

export default {
  ...generateActions(
    'dataCatalogsStore',
    'data_catalog',
    dataCatalogsEndpoint,
    dataCatalogsMethodSchemas
  ),
  ...generateActions(
    'dataCatalogsStore',
    'role_permission',
    rolePermissionsEndpoint,
    rolePermissionMethodSchemas
  ),
  ...generateActions(
    'dataCatalogsStore',
    'user_permission',
    userPermissionsEndpoint,
    userPermissionMethodSchemas
  ),
  ...generateActions(
    'dataCatalogsStore',
    'data_catalog_detail',
    dataCatalogDetailsEndpoint,
    dataCatalogDetailsMethodSchemas
  ),
  ...generateActions(
    'dataCatalogsStore',
    'data_catalog_group',
    dataCatalogGroupsEndpoint,
    dataCatalogGroupsMethodSchemas
  ),
  ...generateActions(
    'dataCatalogsStore',
    'data_catalog_detail_link',
    dataCatalogDetailLinksEndpoint,
    dataCatalogDetailLinksSchemas
  ),

  DELETE_DATA_CATALOG_DETAIL ({ commit, dispatch, getters }, { params }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .delete(`/api/data_catalogs/${params.dataCatalogId}/data_catalog_details/${params.id}.json`)
        .then(
          response => {
            const toBeDeleted = []
            const toBeProcessed = [response.data]

            while (toBeProcessed.length) {
              const current = toBeProcessed.shift()
              toBeProcessed.push.apply(toBeProcessed, getters.dataCatalogDetailsByParentId(current.id))
              toBeDeleted.push(current)
            }
            const normalized = normalize(toBeDeleted, [dataCatalogDetails])
            commit('REMOVE_NORMALIZED_DATA', normalized)
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
    })
  },

  CREATE_DATA_CATALOG_DETAIL_LINK ({ commit, dispatch, getters }, { params, payload }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .post(`/api/data_catalogs/${params.dataCatalogId}/data_catalog_details/${params.dataCatalogDetailId}/data_catalog_detail_links`, payload)
        .then(
          response => {
            commit('ADD_NORMALIZED_DATA', normalize(response.data, dataCatalogDetailLinks))
            const detail = getters.dataCatalogDetailsById(response.data.data_catalog_detail_id)
            const normalized = normalize({
              id: detail.id,
              links: _.concat(detail.links, [response.data.id])
            }, dataCatalogDetails)
            commit('ADD_NORMALIZED_DATA', normalized)
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
    })
  },

  UPLOAD_DATA_CATALOG_TEMPLATE ({ commit, state }, { payload }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .post('/api/data_catalogs/file_upload.json',
          payload
        )
        .then(
          response => {
            const normalized = normalize(response.data, dataCatalogs)
            commit('ADD_NORMALIZED_DATA', normalized)
            resolve(normalized)
          },
          error => {
            reject(error)
          }
        )
    })
  },

  COPY_DATA_CATALOG ({ state, commit }, { payload }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .post(`/api/data_catalogs/${payload.id}/clone.json`, payload)
        .then(
          response => {
            const normalized = normalize(response.data, dataCatalogs)
            commit('ADD_NORMALIZED_DATA', normalized)
            resolve(normalized)
          },
          error => {
            reject(error)
          }
        )
    })
  },

  GET_DELTA ({ state, commit }, { payload }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .get(`/api/data_catalogs/${payload.source_id}/delta.json`, {
          responseType: 'blob',
          params: {
            destination_id: payload.destination_id
          }
        })
        .then(
          response => {
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
    })
  },

  COPY_TO_GLOBAL_DATA_CATALOG ({ state, commit }, { payload }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .post(`/api/data_catalogs/${payload.id}/create_global_from.json`, payload)
        .then(
          response => {
            const normalized = normalize(response.data, dataCatalogs)
            commit('ADD_NORMALIZED_DATA', normalized)
            resolve(normalized)
          },
          error => {
            reject(error)
          }
        )
    })
  },

  COPY_TO_LOCAL_DATA_CATALOG ({ state, commit }, { payload }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .post(`/api/data_catalogs/${payload.id}/create_local_from.json`, payload)
        .then(
          response => {
            const normalized = normalize(response.data, dataCatalogs)
            commit('ADD_NORMALIZED_DATA', normalized)
            resolve(normalized)
          },
          error => {
            reject(error)
          }
        )
    })
  }
}
