import _ from 'lodash'
import { _profileMutes } from '../../mutation-types.vue'

export default {
  [_profileMutes.SET_SYSTEM] (state, data) {
    state.system = _.assign({}, state.system, data)
  },
  [_profileMutes.SET_CURRENT_USER] (state, data) {
    state.currentUser = _.assign({}, state.currentUser, data)
  },
  [_profileMutes.SET_PROFILE_PICTURE] (state, value) {
    state.currentUser.profile_image = value
  },
  [_profileMutes.SET_COMPANY_LOGO] (state, value) {
    state.currentCompany.logo = value
  },
  [_profileMutes.SET_CURRENT_COMPANY] (state, value) {
    state.currentCompany = value
  },
  [_profileMutes.REMOVE_VALID_NOTIFICATION] (state, value) {
    state.currentUser.valid_notifications = _.reject(state.currentUser.valid_notifications, { id: value.notification_id })
  }
}
