
import generateGetters from '@/vue/store/utils/generateGetters.vue.js'
import {
  dataInterfaceItems,
  moduleDataCatalogDetails,
  moduleDataCatalogs,
  moduleDataCatalogGroups,
  moduleDataMapCategories,
  moduleDataMapDetailLinks,
  moduleDataMapCategoryLinks,
  moduleDataCatalogDetailLinks,
  links
} from './schema.vue.js'
import { indexes } from './indexes.vue.js'
import _ from 'lodash'

const filterResultDetailIds = (module, colourFilter, state, getters) => {
  const filterFn = (colourFilter.operator === 'AND') ? _.intersection : _.union
  const moduleId = module.id
  const mapId = module.data_map_id

  const itemsByModule = _.get(state, `indexes.dataInterfaceItemsByModuleId[${moduleId}]`, [])
  const matchingItemIds = []

  if (colourFilter.tag_ids.length > 0) {
    _.each(colourFilter.tag_ids, (tagId) => {
      matchingItemIds.push(state.indexes.dataInterfaceItemsByTagIds[tagId])
    })
  }

  if (colourFilter.tag_category_tag_ids.length > 0) {
    _.each(colourFilter.tag_category_tag_ids, (tagId) => {
      matchingItemIds.push(state.indexes.dataInterfaceItemsByTagIds[tagId])
    })
  }

  if (colourFilter.stakeholder_ids.length > 0) {
    _.each(colourFilter.stakeholder_ids, (shId) => {
      matchingItemIds.push(state.indexes.dataInterfaceItemsByStakeholderIds[shId])
    })
  }

  if (colourFilter.stakeholder_role_stakeholder_ids.length > 0) {
    _.each(colourFilter.stakeholder_role_stakeholder_ids, (shId) => {
      matchingItemIds.push(state.indexes.dataInterfaceItemsByStakeholderIds[shId])
    })
  }

  if (colourFilter.product_and_service_item_ids.length > 0) {
    _.each(colourFilter.product_and_service_item_ids, (psItemId) => {
      matchingItemIds.push(state.indexes.dataInterfaceItemsByProductAndServiceItemIds[psItemId])
    })
  }

  if (colourFilter.product_and_service_category_item_ids.length > 0) {
    _.each(colourFilter.product_and_service_category_item_ids, (psItemId) => {
      matchingItemIds.push(state.indexes.dataInterfaceItemsByProductAndServiceItemIds[psItemId])
    })
  }

  let matchingDetailIds = _.map(matchingItemIds, (criteriaMatches) => {
    const validItemIds = _.intersection(criteriaMatches, itemsByModule)
    const validItems = getters.dataInterfaceItemsByIds(validItemIds)
    return _.map(validItems, 'data_catalog_detail_id')
  })

  if (colourFilter.data_catalog_ids.length > 0) {
    _.each(colourFilter.data_catalog_ids, (linkedSourceId) => {
      const linksByMap = _.get(state, `indexes.moduleDataMapDetailLinksByDataMapId[${mapId}]`, [])
      const linksToByCatalog = _.get(state, `indexes.moduleDataMapDetailLinksByDataCatalogId[${linkedSourceId}]`, [])
      const linksFromByCatalog = _.get(state, `indexes.moduleDataMapDetailLinksByLinkedCatalogId[${linkedSourceId}]`, [])

      const linksToIds = _.intersection(linksByMap, linksToByCatalog)
      const linksFromIds = _.intersection(linksByMap, linksFromByCatalog)

      const linksTo = getters.moduleDataMapDetailLinksByIds(linksToIds)
      const linksFrom = getters.moduleDataMapDetailLinksByIds(linksFromIds)

      const linksToDetailId = _.map(linksTo, 'linked_detail_id')
      const linksFromDetailId = _.map(linksFrom, 'detail_id')

      matchingDetailIds.push(_.union(linksToDetailId, linksFromDetailId))
    })
  }

  if (colourFilter.data_map_category_ids.length > 0) {
    _.each(colourFilter.data_map_category_ids, (catId) => {
      const linksByMap = _.get(state, `indexes.moduleDataMapCategoryLinksByDataMapId[${mapId}]`, [])
      const linksByCategory = _.get(state, `indexes.moduleDataMapCategoryLinksByCategoryId[${catId}]`, [])

      const linkIds = _.intersection(linksByMap, linksByCategory)
      const links = getters.moduleDataMapCategoryLinksByIds(linkIds)

      const linkedDetailIds = _.map(links, 'detail_id')

      matchingDetailIds.push(linkedDetailIds)
    })
  }

  matchingDetailIds = _.map(matchingDetailIds, (criteriaMatches) => {
    return _.compact(criteriaMatches)
  })

  let filteredIds = filterFn(...matchingDetailIds)

  if (colourFilter.levels.length > 0) {
    let byLevels = []
    _.each(colourFilter.levels, (level) => {
      byLevels = _.union(byLevels, state.indexes.moduleDataCatalogDetailsByLevel[level])
    })
    filteredIds = _.intersection(byLevels, filteredIds)
  }

  return filteredIds
}

const isActive = (colorFilter) => {
  return (
    !!colorFilter.tag_ids.length ||
    !!colorFilter.stakeholder_ids.length ||
    !!colorFilter.stakeholder_role_ids.length ||
    !!colorFilter.product_and_service_item_ids.length ||
    !!colorFilter.product_and_service_category_ids.length ||
    !!colorFilter.tag_category_ids.length ||
    !!colorFilter.data_map_category_ids.length ||
    !!colorFilter.data_catalog_ids.length
  )
  // TODO Should level be included in the is Active check???
}

export default {
  ...generateGetters(dataInterfaceItems, indexes),
  ...generateGetters(moduleDataCatalogDetails, indexes),
  ...generateGetters(moduleDataCatalogs, indexes),
  ...generateGetters(moduleDataCatalogGroups, indexes),
  ...generateGetters(moduleDataMapCategories, indexes),
  ...generateGetters(moduleDataMapCategoryLinks, indexes),
  ...generateGetters(moduleDataMapDetailLinks, indexes),
  ...generateGetters(moduleDataCatalogDetailLinks),
  ...generateGetters(links),

  blankCapaItem: () => ({
    text: null,
    links: [],
    stakeholder_ids: [],
    product_and_service_item_ids: [],
    tag_ids: [],
    comment_count: 0,
    unread_comment_count: 0,
    unread_comments: false
  }),

  filteredDataCatalogDetailsByModuleAndSourceId: (state, getters) => (module, catalogId, colorFilter) => {
    let validIds
    if (isActive(colorFilter)) {
      validIds = filterResultDetailIds(module, colorFilter, state, getters)
    } else {
      validIds = _.get(state, `indexes.moduleDataCatalogDetailsByParentId[${null}]`, [])
    }

    const itemsBySource = _.get(state, `indexes.moduleDataCatalogDetailsByDataCatalogId[${catalogId}]`, [])
    const validIdForSource = _.intersection(validIds, itemsBySource)
    return getters.moduleDataCatalogDetailsByIds(validIdForSource)
  },

  rootDataCatalogDetailsByDataCatalogId: (state, getters) => (catalogId) => {
    const sourceItemIds = _.get(state, `indexes.moduleDataCatalogDetailsByDataCatalogId[${catalogId}]`, [])
    const rootItemIds = _.get(state, `indexes.moduleDataCatalogDetailsByParentId[${null}]`, [])

    const validIds = _.intersection(sourceItemIds, rootItemIds)
    return getters.moduleDataCatalogDetailsByIds(validIds)
  },

  rootDataCatalogDetailsByGroupId: (state, getters) => (groupId) => {
    const itemsByGroup = _.get(state, `indexes.moduleDataCatalogDetailsByGroupId[${groupId}]`, [])
    const rootItems = _.get(state, `indexes.moduleDataCatalogDetailsByParentId[${null}]`, [])

    const validIds = _.intersection(rootItems, itemsByGroup)

    return getters.moduleDataCatalogDetailsByIds(validIds)
  },

  dataInterfaceItemByDataCatalogDetailIdAndModuleId: (state, getters) => (detailId, moduleId) => {
    const itemsByDetail = _.get(state, `indexes.dataInterfaceItemsByDataCatalogDetailId[${detailId}]`, [])
    const itemsByView = _.get(state, `indexes.dataInterfaceItemsByModuleId[${moduleId}]`, [])

    const validId = _.intersection(itemsByView, itemsByDetail)
    if (validId.length === 0) {
      return _.assign({}, getters.blankCapaItem)
    }

    return getters.dataInterfaceItemsById(_.head(validId))
  },

  dataMapDetailLinksByDetailIdAndDataMapId: (state, getters) => (detailId, mapId) => {
    const linksByMap = _.get(state, `indexes.moduleDataMapDetailLinksByDataMapId[${mapId}]`, [])
    const linksTo = _.get(state, `indexes.moduleDataMapDetailLinksByDetailId[${detailId}]`, [])
    const linksFrom = _.get(state, `indexes.moduleDataMapDetailLinksByLinkedDetailId[${detailId}]`, [])
    const linkIds = _.union(linksTo, linksFrom)
    const linkIdsByView = _.intersection(linksByMap, linkIds)
    return getters.moduleDataMapDetailLinksByIds(linkIdsByView)
  },

  dataMapCategoryLinksByDetailIdAndDataMapId: (state, getters) => (detailId, mapId) => {
    const linksByMap = _.get(state, `indexes.moduleDataMapCategoryLinksByDataMapId[${mapId}]`, [])
    const linkIds = _.get(state, `indexes.moduleDataMapCategoryLinksByDetailId[${detailId}]`, [])
    const linkIdsByView = _.intersection(linksByMap, linkIds)
    return getters.moduleDataMapCategoryLinksByIds(linkIdsByView)
  },

  filteredDataCatalogDetailsByModuleAndGroupId: (state, getters) => (module, groupId, catalogId, colorFilter) => {
    if (isActive(colorFilter)) {
      const detailsBySource = _.get(state, `indexes.moduleDataCatalogDetailsByDataCatalogId[${catalogId}]`, [])
      const filteredDetailIds = filterResultDetailIds(module, colorFilter, state, getters)
      const detailsByGroup = _.get(state, `indexes.moduleDataCatalogDetailsByGroupId[${groupId}]`, [])
      const groupDetailIds = _.intersection(filteredDetailIds, detailsByGroup, detailsBySource)
      return getters.moduleDataCatalogDetailsByIds(groupDetailIds)
    }
  },

  rootDetailByDetailId: (state) => (detailId) => {
    let detail = _.get(state, `moduleDataCatalogDetails.[${detailId}]`, null)
    while (!!detail && !!detail.parent_id) {
      detail = _.get(state, `moduleDataCatalogDetails.[${detail.parent_id}]`, null)
    }
    return detail
  },

  catalogIsLoaded: (state, getters) => (catalogId) => {
    return getters.moduleDataCatalogDetailsByDataCatalogId(catalogId).length > 0
  },

  pathToDetail: (state) => (detailId) => {
    let detail = _.get(state, `moduleDataCatalogDetails.[${detailId}]`, null)
    const pathIdsToDetail = [detail.id]
    while (!!detail && !!detail.parent_id) {
      pathIdsToDetail.unshift(detail.parent_id)
      detail = state.moduleDataCatalogDetails[detail.parent_id]
    }
    return pathIdsToDetail
  }
}
