
import generateGetters from '@/vue/store/utils/generateGetters.vue.js'
import {
  dataCatalogs,
  dataCatalogGroups,
  dataCatalogDetails,
  userPermissions,
  rolePermissions,
  dataCatalogDetailLinks
} from './schema.vue.js'
import { indexes } from './indexes.vue.js'
import _ from 'lodash'

export default {
  ...generateGetters(dataCatalogs, indexes),
  ...generateGetters(dataCatalogGroups, indexes),
  ...generateGetters(dataCatalogDetails, indexes),
  ...generateGetters(userPermissions, indexes),
  ...generateGetters(rolePermissions, indexes),
  ...generateGetters(dataCatalogDetailLinks),

  userDisplayPermissionByDataCatalogId: (state) => (dataCatalogId) => {
    let userPerm = {}
    const dataCatalogUserPermissions = _.filter(state.userPermissions, { data_catalog_id: dataCatalogId })
    const dataCatalogRolePermissions = _.filter(state.rolePermissions, { data_catalog_id: dataCatalogId })
    const permLookUp = {
      read: 1,
      write: 2,
      admin: 3
    }
    userPerm = _.reduce(dataCatalogUserPermissions, (obj, value) => {
      obj[value.user_id] = value.permission_key
      return obj
    }, userPerm)

    userPerm = _.reduce(dataCatalogRolePermissions, (obj, value) => {
      _.each(value.user_ids, (id) => {
        if (obj[id]) {
          if (permLookUp[value.permission_key] > permLookUp[obj[id]]) {
            obj[id] = value.permission_key
          }
        } else {
          obj[id] = value.permission_key
        }
      })
      return obj
    }, userPerm)

    return userPerm
  },

  rolePermissionsByDataCatalogId: (state) => (dataCatalogId) => {
    return _.filter(state.rolePermissions, { data_catalog_id: dataCatalogId })
  },
  userPermissionsByDataCatalogId: (state) => (dataCatalogId) => {
    return _.filter(state.userPermissions, { data_catalog_id: dataCatalogId })
  }
}
