<template>
  <v-dialog
    v-model="internalActiveState"
    v-bind="config"
    v-on="$listeners"
    @keydown.esc="internalActiveState = false"
  >
    <v-card
      class="overflow-y-hidden"
    >
      <v-card-title
        v-if="!!$slots.header"
        class="text-h6"
      >
        <slot name="header" />
      </v-card-title>

      <v-card-text
        v-if="!!$slots.body"
        class="text-body-1"
      >
        <slot name="body" />
      </v-card-text>

      <v-card-actions v-if="!!$slots.actions">
        <slot name="actions" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash'
export default {
  name: 'Dialog',
  computed: {
    internalActiveState: {
      get () {
        return _.get(this.$attrs, 'active', false)
      },
      set (value) {
        if (!value) {
          this.$emit('close')
        }
      }
    },
    config () {
      const defaultProps = {
        active: false,
        width: '600',
        scrollable: true,
        'content-class': 'v-dialog--common'
      }

      return _.assign({}, defaultProps, this.$attrs)
    }
  }
}
</script>
