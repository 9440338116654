import actions from './profile/actions.vue.js'
import getters from './profile/getters.vue.js'
import mutations from './profile/mutations.vue.js'
import initialState from './profile/initialState.vue.js'

export default {
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions
}
