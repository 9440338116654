import generateActions from '@/vue/store/utils/generateActions.vue.js'
import { userCompanys } from './schema.vue.js'

const userCompaniesEndpoint = (params) => {
  return '/api/user_companies'
}

const methodSchemas = {
  index: [userCompanys]
}

export default {
  ...generateActions(
    'profilePageStore',
    'user_company',
    userCompaniesEndpoint,
    methodSchemas
  )
}
