export default {
  selectedItemId: state => state.selectedItemId,
  openPanelSection: state => state.openPanelSection,
  colourFilter: state => state.colourFilter,
  colourFilterActive: (state) => {
    return (
      !!state.colourFilter.tag_ids.length ||
      !!state.colourFilter.tag_category_ids.length ||
      !!state.colourFilter.stakeholder_ids.length ||
      !!state.colourFilter.stakeholder_role_ids.length ||
      !!state.colourFilter.product_and_service_item_ids.length ||
      !!state.colourFilter.product_and_service_category_ids.length ||
      !!state.colourFilter.user_ids.length
    )
  }
}
