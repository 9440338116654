import { schema } from 'normalizr'
export const dataMaps = new schema.Entity('dataMaps')
export const dataMapCatalogs = new schema.Entity('dataMapCatalogs')
export const dataMapCatalogPosition = new schema.Entity('dataMapCatalogPosition')
export const dataMapDetailMappings = new schema.Entity('dataMapDetailMappings')
export const dataMapCategories = new schema.Entity('dataMapCategories')
export const dataMapCategoryMappings = new schema.Entity('dataMapCategoryMappings')
export const rolePermissions = new schema.Entity('rolePermissions', {}, {
  idAttribute: (rp) => (`${rp.role_id}-${rp.data_map_id}`)
})
export const userPermissions = new schema.Entity('userPermissions', {}, {
  idAttribute: (up) => (`${up.user_id}-${up.data_map_id}`)
})
