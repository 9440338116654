import Gateway from '@/vue/shared/plugins/jointjsVuePlugin/shapes/businessProcess/gateway.vue.js'
import { types } from '@clientio/rappid-visio'

const { VisioSectionType } = types
const FONT_SIZE = 13

export default function (vsdShape) {
  const data = vsdShape.getComputedSection(VisioSectionType.Property)
  const element = new Gateway()

  const gatewayType = data.getProperty('BpmnGatewayType')
  const isMarkerVisible = data.getProperty('BpmnMarkerVisible') === '1'
  const exclusiveType = data.getProperty('BpmnExclusiveType')

  element.attr('label/fill', vsdShape.getComputedSection(VisioSectionType.Character).rows[0].cells.color)
  element.attr('body/fill', vsdShape.cells.fillForegnd)
  element.attr('body/stroke', vsdShape.cells.lineColor)

  if (gatewayType) {
    // Available Gateway Types
    // data.getProperty('BpmnGatewayType', 'format').split(';');
    const iconType = {
      Exclusive: 'exclusive',
      'Exclusive Event (Instantiate)': 'exclusive_event',
      Inclusive: 'inclusive',
      Parallel: 'parallel',
      'Parallel Event (Instantiate)': 'parallel_event',
      Complex: 'complex'
    }[gatewayType]
    element.prop('gateway_type', iconType)
  }

  if (exclusiveType === 'Data' && gatewayType === 'Exclusive' && !isMarkerVisible) {
    element.prop('gateway_type', 'exclusive_blank')
  }

  if (exclusiveType === 'Event' && gatewayType === 'Exclusive') {
    element.prop('gateway_type', 'event')
  }

  const lane = data.getProperty('Function')
  if (lane) {
    element.prop({ lane })
  }

  // Element Name
  const name = data.getProperty('BpmnName')
  if (name) {
    element.attr('label/text', name)
    element.attr('label/fontSize', FONT_SIZE)
  }

  // Size & Position
  const { x, y, width, height } = vsdShape.getPageBBox()
  element.set({
    position: { x, y },
    size: { width, height },
    z: 2
  })

  return element
}
