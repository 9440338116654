import * as joint from '@clientio/rappid'
import _ from 'lodash'
import BusinessProcessElement from '../base/baseBusinessProcessElement.vue.js'

const DataStore = BusinessProcessElement.extend({
  initialize: function () {
    this.attr('body/lateralArea', 10)
    this.on('change:shape_fill', (cell, color) => {
      this.setShapeFill(color)
    })
    this.on('change:shape_outline', (cell, color) => {
      this.setShapeOutline(color)
    })
    this.on('change:shape_transparency', (cell, level) => {
      this.setShapeTransparency(level)
    })
    this.on('change:shape_outline_style', (cell, strokeDasharray) => {
      this.attr({
        body: {
          strokeDasharray: strokeDasharray
        }
      })
      this.attr({
        top: {
          strokeDasharray: strokeDasharray
        }
      })
    })
    this.on('change:shape_outline_width', (cell, strokeWidth) => {
      this.attr({
        body: {
          strokeWidth: strokeWidth
        }
      })
      this.attr({
        top: {
          strokeWidth: strokeWidth
        }
      })
    })

    BusinessProcessElement.prototype.initialize.apply(this, arguments)
    // joint.shapes.bpmn2.DataStore.prototype.initialize.apply(this, arguments)
  },
  setShapeFill: function (fillColor) {
    this.attr({
      body: {
        fill: fillColor
      }
    })
    this.attr({
      top: {
        fill: fillColor
      }
    })
  },

  setShapeOutline: function (outlineColor) {
    this.attr({
      body: {
        stroke: outlineColor
      }
    })
    this.attr({
      top: {
        stroke: outlineColor
      }
    })
  },

  setShapeTransparency: function (transparencyLevel) {
    this.attr({
      body: {
        fillOpacity: transparencyLevel
      }
    })
    this.attr({
      top: {
        fillOpacity: transparencyLevel
      }
    })
  },
  displayModeAttributes: {
    stencil: {
      size: { width: 30, height: 40 },
      attrs: {
        label: {
          text: ''
        }
      }
    },
    preview: {
      size: { width: 60, height: 90 },
      position: { x: 10, y: 2 }
    },
    canvas: {
      size: { width: 60, height: 90 }
    }
  },
  updateElementDefaultColors: function (options) {
    this.attr({
      top: {
        fill: options.default_fill_colour,
        stroke: options.default_stroke_colour
      },
      body: {
        fill: options.default_fill_colour,
        stroke: options.default_stroke_colour
      }
    })
    this.attr({
      label: {
        fill: options.default_text_colour
      }
    })
  },
  markup: joint.shapes.bpmn2.DataStore.prototype.markup,
  defaults: _.defaultsDeep({
    size: { width: 30, height: 25 },
    shape: 'Data Store',
    type: 'businessProcess.DataStore',
    attrs: {
      body: {
        fill: '#ffffff',
        stroke: '#000000',
        fillOpacity: 1
      },
      top: {
        fill: '#ffffff',
        stroke: '#000000',
        fillOpacity: 1
      },
      label: {
        text: 'Data Store'
      }
    }
  }, joint.shapes.bpmn2.DataStore.prototype.defaults)
})
DataStore.attributes = joint.shapes.bpmn2.DataStore.attributes
export default DataStore
