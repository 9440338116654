
import generateGetters from '@/vue/store/utils/generateGetters.vue.js'
import { indexes } from './indexes.vue.js'
import {
  stakeholders,
  stakeholderRoles,
  stakeholderMapItems,
  stakeholderAssignedItems,
  links
} from './schema.vue.js'

export default {
  ...generateGetters(stakeholders, indexes),
  ...generateGetters(stakeholderRoles, indexes),
  ...generateGetters(stakeholderMapItems),
  ...generateGetters(stakeholderAssignedItems),
  ...generateGetters(links)
}
