import * as joint from '@clientio/rappid'
import _ from 'lodash'
import BusinessProcessElement from '../base/baseBusinessProcessElement.vue.js'

const Event = BusinessProcessElement.extend({
  initialize: function () {
    this.on('change:event_type', (cell, value) => {
      this.attr('border/borderType', value)
    })
    this.on('change:interrupting', (cell, value) => {
      this.attr('border/borderStyle', value)
    })
    this.on('change:icon_type', (cell, value) => {
      this.attr('icon/iconType', value)
    })
    BusinessProcessElement.prototype.initialize.apply(this, arguments)
  },
  displayModeAttributes: {
    stencil: {
      size: { width: 40, height: 40 },
      attrs: {
        label: {
          text: ''
        }
      }
    },
    preview: {
      size: { width: 90, height: 90 },
      position: { x: 10, y: 2 }
    },
    canvas: {
      size: { width: 90, height: 90 }
    }
  },
  updateElementDefaultColors: function (options) {
    this.attr({
      border: {
        stroke: options.default_stroke_colour
      },
      background: {
        fill: options.default_fill_colour
      }
    })
    this.attr({
      label: {
        fill: options.default_text_colour
      }
    })
  },
  markup: joint.shapes.bpmn2.Event.prototype.markup,
  defaults: _.defaultsDeep({
    size: { width: 30, height: 25 },
    shape: 'Event',
    type: 'businessProcess.Event',
    attrs: {
      background: {
        fill: '#ffffff',
        fillOpacity: 1
      },
      border: {
        stroke: '#000000'
      },
      label: {
        text: 'Event'
      }
    }
  }, joint.shapes.bpmn2.Event.prototype.defaults)
})
Event.attributes = joint.shapes.bpmn2.Event.attributes
Event.EVENT_ICONS = joint.shapes.bpmn2.Event.EVENT_ICONS

export default Event
