import { schema } from 'normalizr'
export const projects = new schema.Entity('projects')
export const roles = new schema.Entity('roles')
export const projectsWithResultCount = new schema.Object({ projects: [projects] })
export const sections = new schema.Entity('sections')
export const moduleTypes = new schema.Entity('moduleTypes')
export const moduleStatuses = new schema.Entity('moduleStatuses')
export const moduleCategories = new schema.Entity('moduleCategories')
export const statuses = new schema.Entity('statuses')
export const markets = new schema.Entity('markets')
export const projectResources = new schema.Entity('projectResources')
export const projectHistoryEvents = new schema.Entity('projectHistoryEvents')
export const dataMaps = new schema.Entity('dataMaps')
export const projectDataMaps = new schema.Entity('projectDataMaps')
export const projectDataCatalogs = new schema.Entity('projectDataCatalogs')
export const associatedDataElements = new schema.Entity('associatedDataElements')
export const rolePermissions = new schema.Entity('rolePermissions', {}, {
  idAttribute: (rp) => (`${rp.role_id}-${rp.project_id}`)
})
export const userPermissions = new schema.Entity('userPermissions', {}, {
  idAttribute: (up) => (`${up.user_id}-${up.project_id}`)
})
