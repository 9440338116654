import _ from 'lodash'
import Vue from 'vue'
import { normalize } from 'normalizr'
import generateActions from '@/vue/store/utils/generateActions.vue.js'
import {
  stakeholders,
  stakeholderRoles,
  stakeholderMapItems,
  stakeholderAssignedItems,
  uploadedCsvItems,
  links
} from './schema.vue.js'

const stakeholdersEndpoint = params => (`/api/projects/${params.projectId}/stakeholders`)
const stakeholderRolesEndpoint = params => (`/api/projects/${params.projectId}/stakeholder_roles`)
const stakeholderMapItemsEndpoint = params => (`/api/projects/${params.projectId}/stakeholder_map_items`)
const stakeholderAssignedItemsEndpoint = params => (`/api/projects/${params.projectId}/stakeholders/assigned_items_index`)
const linksEndpoint = () => {
  return '/api/links'
}

const linkSchemas = {
  update: links,
  destroy: links,
  index: [links]
}

export default {
  ...generateActions(
    'stakeholdersStore',
    'stakeholder',
    stakeholdersEndpoint,
    {
      index: [stakeholders],
      create: stakeholders,
      update: stakeholders,
      update_positions: [stakeholders],
      destroy: stakeholders
    }
  ),
  ...generateActions(
    'stakeholdersStore',
    'stakeholder_role',
    stakeholderRolesEndpoint,
    {
      index: [stakeholderRoles],
      create: stakeholderRoles,
      update: stakeholderRoles,
      update_positions: [stakeholderRoles],
      destroy: stakeholderRoles
    }
  ),
  ...generateActions(
    'stakeholdersStore',
    'stakeholder_map_item',
    stakeholderMapItemsEndpoint,
    {
      index: stakeholderMapItems
    }
  ),
  ...generateActions(
    'stakeholdersStore',
    'stakeholder_assigned_item',
    stakeholderAssignedItemsEndpoint,
    {
      index: [stakeholderAssignedItems]
    }
  ),
  ...generateActions(
    'stakeholdersStore',
    'link',
    linksEndpoint,
    linkSchemas
  ),

  EXPORT_STAKEHOLDERS ({ commit, dispatch }, { params, exportType }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .get(
          `${stakeholdersEndpoint(params)}/export.${exportType}`, {
            responseType: 'blob'
          })
        .then(
          response => {
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
    })
  },

  UPLOAD_STAKEHOLDER_CSV ({ commit }, { params, payload }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .post(
          `${stakeholdersEndpoint(params)}/import_upload.json`,
          payload
        )
        .then(
          response => {
            commit('ADD_NORMALIZED_DATA', normalize(response.data, uploadedCsvItems))
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
    })
  },
  CREATE_DEFAULT_STAKEHOLDER ({ dispatch, state }, { params }) {
    const highest = _.maxBy(_.values(state.stakeholders), 'position')
    const position = _.get(highest, 'position', -1) + 1
    return dispatch('CREATE_STAKEHOLDER', {
      params,
      payload: {
        name: 'Untitled',
        stakeholder_role_ids: [],
        description: '',
        link_title: '',
        link_url: '',
        position: position
      }
    })
  },
  ADD_STAKEHOLDERS ({ commit }, payload) {
    commit('ADD_NORMALIZED_DATA', normalize(payload, [stakeholders]))
    return new Promise(resolve => resolve())
  },
  UPDATE_LOCAL_STAKEHOLDER_ITEMS ({ commit, state }, payload) {
    commit('ADD_NORMALIZED_DATA', normalize(payload, [stakeholders]))
    return new Promise((resolve) => resolve())
  },
  CREATE_LINK ({ commit, dispatch, getters }, { payload }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .post('/api/links.json', payload)
        .then(
          response => {
            commit('ADD_NORMALIZED_DATA', normalize(response.data, links))
            const stakeholder = getters.stakeholdersById(response.data.linkable_id)
            const normalized = normalize({
              id: stakeholder.id,
              links: _.concat(stakeholder.links, [response.data.id])
            }, stakeholders)
            commit('ADD_NORMALIZED_DATA', normalized)
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
    })
  }
}
