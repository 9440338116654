import { schema } from 'normalizr'
export const dataCatalogs = new schema.Entity('dataCatalogs')
export const dataCatalogGroups = new schema.Entity('dataCatalogGroups')
export const dataCatalogDetailLinks = new schema.Entity('dataCatalogDetailLinks')
export const dataCatalogDetails = new schema.Entity('dataCatalogDetails', { links: [dataCatalogDetailLinks] })
export const rolePermissions = new schema.Entity('rolePermissions', {}, {
  idAttribute: (rp) => (`${rp.role_id}-${rp.data_catalog_id}`)
})
export const userPermissions = new schema.Entity('userPermissions', {}, {
  idAttribute: (up) => (`${up.user_id}-${up.data_catalog_id}`)
})
