import _ from 'lodash'
import initialState from './initialState.vue.js'
import generateStateMutations from '@/vue/store/utils/generateStateMutations.vue.js'
export default {
  RESET_STATE (state) {
    _.assign(state, initialState())
  },
  SET_SEARCH_STRING (state, value) {
    state.searchString = value
  },
  SET_SEARCH_MESSAGE (state, msg) {
    state.searchMessage = msg
  },
  SET_FOCUSED_CM_USER_STORY_ID (state, id) {
    state.focusedCmUserStoryId = id
  },
  SET_SELECTED_CM_USER_STORY_ID (state, id) {
    state.selectedCmUserStoryId = id
  },
  SET_FOCUSED_CM_ITERATION_ID (state, id) {
    state.focusedCmIterationId = id
  },
  SET_SELECTED_CM_ITERATION_ID (state, id) {
    state.selectedCmIterationId = id
  },
  SET_FOCUSED_CM_CATEGORY_ID (state, id) {
    state.focusedCmCatergoryId = id
  },
  SET_SELECTED_CM_CATEGORY_ID (state, id) {
    state.selectedCmCategoryId = id
  },
  SET_CM_CATEGORYS_LOADED (state, value) {
    state.cmCategorysLoaded = value
  },
  SET_CM_ITERATIONS_LOADED (state, value) {
    state.cmIterationsLoaded = value
  },
  SET_CM_USER_STORY_ITEMS_LOADED (state, value) {
    state.cmUserStoryItemsLoaded = value
  },
  SET_VIEW (state, view) {
    state.selectedView = view
    state.previouslySelectedViews = _.union(state.previouslySelectedViews, view)
  },
  ...generateStateMutations()
}
