import generateActions from '@/vue/store/utils/generateActions.vue.js'
import { moduleCategories, moduleTypes, dataMaps } from './schema.vue.js'
import Vue from 'vue'
import { normalize } from 'normalizr'

const moduleCategoriesAdminEndpoint = (params) => {
  return '/api/admin/scenario_module_categories'
}

const moduleAdminEndpoint = (params) => {
  return '/api/admin/scenario_modules'
}

const dataMapsEndpoint = (params) => {
  return '/api/data_maps'
}

const moduleCategoriesMethodSchemas = {
  index: [moduleCategories],
  get: moduleCategories,
  create: moduleCategories,
  update: moduleCategories,
  destroy: moduleCategories
}

export default {
  ...generateActions(
    'moduleCategoriesAdminStore',
    'module_category',
    moduleCategoriesAdminEndpoint,
    moduleCategoriesMethodSchemas
  ),
  MOVE_MODULE_CATEGORY ({ commit }, { params }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.post(`${moduleCategoriesAdminEndpoint()}/${params.id}/move_item.json`, { move_direction: params.direction })
        .then(response => {
          const normalized = normalize(response.data, [moduleCategories])
          commit('ADD_NORMALIZED_DATA', normalized)
          resolve(normalized)
        }, error => {
          reject(error)
        })
    })
  },
  LOAD_MODULE_TYPES ({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .get(`${moduleAdminEndpoint()}.json`)
        .then(
          response => {
            const normalized = normalize(response.data, [moduleTypes])
            commit('ADD_NORMALIZED_DATA', normalized)
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
    })
  },
  LOAD_DATA_MAPS ({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .get(`${dataMapsEndpoint()}.json`)
        .then(
          response => {
            const normalized = normalize(response.data, [dataMaps])
            commit('ADD_NORMALIZED_DATA', normalized)
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
    })
  }
}
