<template>
  <router-view :name="viewName" />
</template>
<script>
export default {
  name: 'NestedView',
  props: {
    viewName: {
      type: [String],
      required: false,
      default: 'default'
    }
  }
}
</script>
