export default {
  SET_SEARCH_STRING (state, value) {
    state.searchString = value
  },
  SET_SORT_COLUMN (state, value) {
    state.sortColumn = value
  },
  SET_SORT_DESC (state, value) {
    state.sortDesc = value
  },
  SET_FILTER (state, value) {
    state.filter = value
  }
}
