
import generateGetters from '@/vue/store/utils/generateGetters.vue.js'
import { entityAttributes } from './schema.vue.js'
import { indexes } from './indexes.vue.js'
import _ from 'lodash'

export default {
  ...generateGetters(entityAttributes, indexes),
  entityAttributesByKeyAndModuleTypeId: (state, getters) => (key, moduleTypeId) => {
    const byModuleTypeIds = _.get(state, `indexes.entityAttributesByModuleTypeId[${moduleTypeId}]`, [])
    const byAttributeKeys = _.get(state, `indexes.entityAttributesByKey[${key}]`, [])

    const validIds = _.intersection(byModuleTypeIds, byAttributeKeys)
    return getters.entityAttributesByIds(validIds)
  }
}
