import _ from 'lodash'
export default function () {
  return {
    computed: {
      $entityMap () {
        return {
          stakeholder: _.get(this.currentCompany, 'entity_map.stakeholder', 'Stakeholder'),
          stakeholder_plural: _.get(this.currentCompany, 'entity_map.stakeholder_plural', 'Stakeholders'),
          stakeholder_role: _.get(this.currentCompany, 'entity_map.stakeholder_role', 'Stakeholder Role'),
          stakeholder_role_plural: _.get(this.currentCompany, 'entity_map.stakeholder_role_plural', 'Stakeholder Roles'),
          product_and_service: _.get(this.currentCompany, 'entity_map.product_and_service', 'Product & Service'),
          product_and_service_plural: _.get(this.currentCompany, 'entity_map.product_and_service_plural', 'Product & Services'),
          product_and_service_category: _.get(this.currentCompany, 'entity_map.product_and_service_category', 'Product & Service Category'),
          product_and_service_category_plural: _.get(this.currentCompany, 'entity_map.product_and_service_category_plural', 'Product & Services Categories'),
          tag: _.get(this.currentCompany, 'entity_map.tag', 'Tag'),
          tag_plural: _.get(this.currentCompany, 'entity_map.tag_plural', 'Tags'),
          tag_category: _.get(this.currentCompany, 'entity_map.tag_category', 'Tag Category'),
          tag_category_plural: _.get(this.currentCompany, 'entity_map.tag_category_plural', 'Tag Categories')
        }
      }
    }
  }
}
