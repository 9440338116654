import generateActions from '@/vue/store/utils/generateActions.vue.js'
import { projectStatuses } from './schema.vue.js'
import Vue from 'vue'
import { normalize } from 'normalizr'

const projectStatusesAdminEndpoint = (params) => {
  return '/api/admin/project_statuses'
}

const projectStatusesMethodSchemas = {
  index: [projectStatuses],
  create: projectStatuses,
  update: projectStatuses,
  destroy: projectStatuses
}

export default {
  ...generateActions(
    'projectStatusesAdminStore',
    'project_status',
    projectStatusesAdminEndpoint,
    projectStatusesMethodSchemas
  ),
  MOVE_PROJECT_STATUS ({ commit }, { params }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.post(`${projectStatusesAdminEndpoint()}/${params.id}/move_item.json`, { move_direction: params.direction })
        .then(response => {
          const normalized = normalize(response.data, [projectStatuses])
          commit('ADD_NORMALIZED_DATA', normalized)
          resolve(normalized)
        }, error => {
          reject(error)
        })
    })
  }
}
