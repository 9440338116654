import store from '@/vue/store/store.vue.js'
import { globalMutes } from '@/vue/store/mutation-types.vue.js'
import _ from 'lodash'

const counters = {
  request: [
    (request) => {
      if (request.method === 'get') {
        store.commit(globalMutes.BEGIN_DOWNLOAD_REQUEST)
      } else {
        store.commit(globalMutes.BEGIN_UPLOAD_REQUEST)
      }
      return request
    }
  ],
  response: [
    (response) => {
      const method = _.get(response, 'config.method', null)
      if (method === 'get') {
        store.commit(globalMutes.RESOLVE_DOWNLOAD_REQUEST)
      } else {
        store.commit(globalMutes.RESOLVE_UPLOAD_REQUEST)
      }
      return response
    },
    (error) => {
      const method = _.get(error, 'config.method', null)
      if (method === 'get') {
        store.commit(globalMutes.RESOLVE_DOWNLOAD_REQUEST)
      } else {
        store.commit(globalMutes.RESOLVE_UPLOAD_REQUEST)
      }
      return Promise.reject(error)
    }
  ]
}

export default counters
