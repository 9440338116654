import Vue from 'vue'
import { normalize } from 'normalizr'
import _ from 'lodash'
import generateActions from '@/vue/store/utils/generateActions.vue.js'
import {
  dataInterfaceItems,
  moduleDataMapCategories,
  moduleDataMapCategoryLinks,
  moduleDataCatalogGroups,
  moduleDataCatalogDetails,
  moduleDataMapDetailLinks,
  moduleDataCatalogs,
  links
} from './schema.vue.js'

const linksEndpoint = () => {
  return '/api/links'
}

const dataCatalogGroupsEndpoint = (params) => {
  return `/api/projects/${params.projectId}` +
  `/project_scenario_modules/${params.moduleId}` +
  `/data_catalogs/${params.catalogId}` +
  '/data_catalog_groups'
}

const dataCatalogDetailsEndpoint = (params) => {
  return `/api/projects/${params.projectId}` +
  `/project_scenario_modules/${params.moduleId}` +
  `/data_catalogs/${params.catalogId}` +
  '/data_catalog_details'
}

const dataInterfaceItemsEndpoint = (params) => {
  return `/api/projects/${params.projectId}/project_scenario_modules/${params.moduleId}/data_interface_items`
}

const dataMapCategoriesEndpoint = (params) => {
  return `/api/projects/${params.projectId}/project_scenario_modules/${params.moduleId}/data_map_categories`
}

const dataCatalogsEndpoint = (params) => {
  return `/api/projects/${params.projectId}/project_scenario_modules/${params.moduleId}/data_catalogs`
}

const dataMapDetailLinksEndpoint = (params) => {
  return `/api/projects/${params.projectId}/project_scenario_modules/${params.moduleId}/data_map_detail_links`
}

const dataMapCategoryLinksEndpoint = (params) => {
  return `/api/projects/${params.projectId}/project_scenario_modules/${params.moduleId}/data_map_category_links`
}

const methodSchemas = {
  index: [dataInterfaceItems],
  create: dataInterfaceItems,
  update: dataInterfaceItems,
  destroy: dataInterfaceItems
}

const linkSchemas = {
  update: links,
  index: [links]
}

export default {
  ...generateActions(
    'dataInterfaceStore',
    'data_interface_item',
    dataInterfaceItemsEndpoint,
    methodSchemas
  ),

  ...generateActions(
    'dataInterfaceStore',
    'link',
    linksEndpoint,
    linkSchemas
  ),

  ...generateActions(
    'dataInterfaceStore',
    'data_map_category',
    dataMapCategoriesEndpoint,
    { index: [moduleDataMapCategories] }
  ),

  ...generateActions(
    'dataInterfaceStore',
    'data_catalog',
    dataCatalogsEndpoint,
    { index: [moduleDataCatalogs] }
  ),

  ...generateActions(
    'dataInterfaceStore',
    'data_catalog_group',
    dataCatalogGroupsEndpoint,
    { index: [moduleDataCatalogGroups] }
  ),

  ...generateActions(
    'dataInterfaceStore',
    'data_catalog_detail',
    dataCatalogDetailsEndpoint,
    { index: [moduleDataCatalogDetails] }
  ),

  ...generateActions(
    'dataInterfaceStore',
    'data_map_detail_link',
    dataMapDetailLinksEndpoint,
    { index: [moduleDataMapDetailLinks] }
  ),

  ...generateActions(
    'dataInterfaceStore',
    'data_map_category_link',
    dataMapCategoryLinksEndpoint,
    { index: [moduleDataMapCategoryLinks] }
  ),

  ADD_LINK ({ commit, getters }, data) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .post('/api/links.json', data)
        .then(
          response => {
            commit('ADD_NORMALIZED_DATA', normalize(response.data, links))
            const item = getters.dataInterfaceItemsById(response.data.linkable_id)
            const normalized = normalize({
              id: item.id,
              links: _.concat(item.links, [response.data.id])
            }, dataInterfaceItems)
            commit('ADD_NORMALIZED_DATA', normalized)
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
    })
  },

  DELETE_LINK ({ commit, dispatch, getters }, data) {
    const link = getters.linksById(data.payload.id)
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .delete(`/api/links/${link.id}.json`)
        .then(
          response => {
            commit('REMOVE_NORMALIZED_DATA', normalize(response.data, links))
            const item = getters.dataInterfaceItemsById(response.data.linkable_id)
            const normalized = normalize({
              id: item.id,
              links: _.pull(item.links, response.data.id)
            }, dataInterfaceItems)
            commit('ADD_NORMALIZED_DATA', normalized)

            // Handle if item interface item should be deleted or not
            const blankItem = getters.blankCapaItem
            const dataItem = getters.dataInterfaceItemsById(response.data.linkable_id)
            const dataItemIsBlank = _.chain(_.keys(blankItem))
              .map((key) => { return _.isEqual(dataItem[key], blankItem[key]) })
              .every()
              .value()

            if (dataItemIsBlank) {
              dispatch('DELETE_DATA_INTERFACE_ITEM', {
                params: {
                  ...data.params,
                  id: dataItem.id
                }
              }).then(() => {
                resolve(response)
              })
            } else {
              resolve(response)
            }
          },
          error => {
            reject(error)
          }
        )
    })
  },

  MODIFY_USER_DATA ({ dispatch, getters, state }, data) {
    if (!data.payload.id) {
      return dispatch('CREATE_DATA_INTERFACE_ITEM', data)
    } else {
      const blankItem = getters.blankCapaItem
      const dataItem = getters.dataInterfaceItemsById(data.id)
      const dataToCompare = _.assign({}, dataItem, data.payload)
      const dataItemIsBlank = _.chain(_.keys(blankItem))
        .map((key) => { return _.isEqual(dataToCompare[key], blankItem[key]) })
        .every()
        .value()

      if (!dataItemIsBlank) {
        return dispatch('UPDATE_DATA_INTERFACE_ITEM_PROPERTIES', data)
      } else {
        return dispatch('DELETE_DATA_INTERFACE_ITEM', data)
      }
    }
  },

  LOAD_DATA_CATALOG_CONTENT ({ dispatch, state }, params) {
    return Promise.all([
      dispatch('LOAD_DATA_CATALOG_GROUPS', params),
      dispatch('LOAD_DATA_CATALOG_DETAILS', params)
    ])
  },

  UPDATE_LOCAL_DATA_INTERFACE_ITEMS ({ commit, state }, payload) {
    commit('ADD_NORMALIZED_DATA', normalize(payload, [dataInterfaceItems]))
    return new Promise((resolve) => resolve())
  },

  EXPORT_DATA_INTERFACE ({ commit, dispatch }, { params, exportType }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .get(
          `${dataInterfaceItemsEndpoint(params)}/export.${exportType}`, {
            responseType: 'blob'
          })
        .then(
          response => {
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
    })
  },

  UPDATE_LOCAL_DATA_CATALOG_DETAILS ({ commit, state }, payload) {
    commit('ADD_NORMALIZED_DATA', normalize(payload, [moduleDataCatalogDetails]))
    return new Promise((resolve) => resolve())
  }
}
