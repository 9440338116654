
import generateGetters from '@/vue/store/utils/generateGetters.vue.js'
import { companies, users, modules, providers, roles, removedProjects } from './schema.vue.js'
import { indexes } from './indexes.vue.js'
import _ from 'lodash'

export default {
  ...generateGetters(companies, null),
  ...generateGetters(users, null),
  ...generateGetters(modules, null),
  ...generateGetters(providers, null),
  ...generateGetters(roles, indexes),
  ...generateGetters(removedProjects, null),
  theEmailTemplate: (state) => (_.first(_.values(state.emailTemplates)))
}
