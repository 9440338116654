import getters from './appNavigationMenu/getters.vue.js'
import mutations from './appNavigationMenu/mutations.vue.js'
import initialState from './appNavigationMenu/initialState.vue.js'

export default {
  namespaced: true,
  state: initialState(),
  getters,
  mutations
}
