/* istanbul ignore file */
/* Simple code, difficult to test dynamic imports */
import Vue from 'vue'
import moduleUrlKeyMap from '@/vue/shared/filters/moduleUrlKeyMap.vue.js'
import NestedView from './NestedView.vue'

import StandardVisioimport from '@/vue/shared/plugins/jointjsVuePlugin/standardVisioImport.vue.js'
import BpmnVisioImport from '@/vue/shared/plugins/jointjsVuePlugin/bpmnVisioImport.vue.js'

const testPattern = /^\d+$/
const theTextModulePage = () => import('@/vue/pages/TextModulePage.vue')
const theTextModulePageHeader = () => import('@/vue/components/TextModulePageHeader.vue')

const theDiagramModulePage = () => import('@/vue/pages/DiagramModulePage.vue')
const theDiagramModulePageHeader = () => import('@/vue/components/DiagramModulePageHeader.vue')

const theCanvasModulePage = () => import('@/vue/pages/CanvasModulePage.vue')
const theCanvasModulePageHeader = () => import('@/vue/components/CanvasModulePageHeader.vue')

const theFreeTextModulePage = () => import('@/vue/pages/FreeTextModulePage.vue')
const theFreeTextModulePageHeader = () => import('@/vue/components/FreeTextModulePageHeader.vue')

const theEmbeddedSuitePage = () => import('@/vue/pages/EmbeddedSuitePage.vue')
const theEmbeddedSuitePageHeader = () => import('@/vue/components/EmbeddedSuitePageHeader.vue')

// Public Pages
const TheReleaseNotePage = () => import('@/vue/shared/components/ReleaseNotePage.vue')
const TheSplashPage = () => import('@/vue/public/splashPage/TheSplashPage.vue')
const TheProjectHomePage = () => import('@/vue/public/projectHomePage/TheProjectHomePage.vue')
const TheTeamHomePage = () => import('@/vue/public/teamHomePage/TheTeamHomePage.vue')
const TheExampleHomePage = () => import('@/vue/public/exampleHomePage/TheExampleHomePage.vue')
const TheTemplateHomePage = () => import('@/vue/public/templateHomePage/TheTemplateHomePage.vue')
const TheProjectPage = () => import('@/vue/public/projectPage/TheProjectPage.vue')
const TheModulePage = () => import('@/vue/public/modulePage/TheModulePage.vue')
const TheProfilePage = () => import('@/vue/public/profilePage/TheProfilePage.vue')
const TheNotFoundPage = () => import('@/vue/public/notFoundPage/TheNotFoundPage.vue')
const TheForbiddenPage = () => import('@/vue/public/forbiddenPage/TheForbiddenPage.vue')
const headerText = () => import('@/vue/shared/components/HeaderText.vue')

// Company Admin Pages
const TheDetailsCompanyPage = () => import('@/vue/companyAdmin/TheDetailsCompanyPage/TheDetailsCompanyPage.vue')
const TheUsersCompanyPage = () => import('@/vue/companyAdmin/TheUsersCompanyPage/TheUsersCompanyPage.vue')
const TheTeamsCompanyPage = () => import('@/vue/companyAdmin/TheTeamsCompanyPage/TheTeamsCompanyPage.vue')
const TheRestoreProjectsCompanyPage = () => import('@/vue/companyAdmin/TheRestoreProjectsCompanyPage/TheRestoreProjectsCompanyPage.vue')
const TheMostRecentUserEventsCompanyPage = () => import('@/vue/companyAdmin/TheMostRecentUserEventsCompanyPage/TheMostRecentUserEventsCompanyPage.vue')
const TheDailyActiveUsersCompanyPage = () => import('@/vue/companyAdmin/TheDailyActiveUsersCompanyPage/TheDailyActiveUsersCompanyPage.vue')
const TheMonthlyActiveUsersCompanyPage = () => import('@/vue/companyAdmin/TheMonthlyActiveUsersCompanyPage/TheMonthlyActiveUsersCompanyPage.vue')
const TheRecentProjectActivityCompanyPage = () => import('@/vue/companyAdmin/TheRecentProjectActivityCompanyPage/TheRecentProjectActivityCompanyPage.vue')

// System Admin Pages
const TheCompaniesAdminPage = () => import('@/vue/admin/companiesAdminPage/TheCompaniesAdminPage.vue')
const TheCompanyDetailsAdminPage = () => import('@/vue/admin/companyDetailsAdminPage/TheCompanyDetailsAdminPage.vue')
const TheDriverItemTypesAdminPage = () => import('@/vue/admin/driverItemTypesAdminPage/TheDriverItemTypesAdminPage.vue')
const TheMarketsAdminPage = () => import('@/vue/admin/marketsAdminPage/TheMarketsAdminPage.vue')
const TheFeatureNotificationAdminPage = () => import('@/vue/admin/featureNotificationAdminPage/TheFeatureNotificationAdminPage.vue')
const TheModuleCategoriesAdminPage = () => import('@/vue/admin/moduleCategoriesAdminPage/TheModuleCategoriesAdminPage.vue')
const TheModulesAdminPage = () => import('@/vue/admin/modulesAdminPage/TheModulesAdminPage.vue')
const TheProjectStatusesAdminPage = () => import('@/vue/admin/projectStatusesAdminPage/TheProjectStatusesAdminPage.vue')
const TheModuleStatusesAdminPage = () => import('@/vue/admin/moduleStatusesAdminPage/TheModuleStatusesAdminPage.vue')
const TheRemovedProjectsAdminPage = () => import('@/vue/admin/removedProjectsAdminPage/TheRemovedProjectsAdminPage.vue')
const TheRiskItemsProbabilitiesAdminPage = () => import('@/vue/admin/riskItemProbabilitiesAdminPage/TheRiskItemsProbabilitiesAdminPage.vue')
const TheRiskItemsImpactsAdminPage = () => import('@/vue/admin/riskItemsImpactsAdminPage/TheRiskItemsImpactsAdminPage.vue')
const TheStakeholderPerspectiveEcoRolesAdminPage = () => import('@/vue/admin/stakeholderPerspectiveEcoRolesAdminPage/TheStakeholderPerspectiveEcoRolesAdminPage.vue')
const TheSystemAdminPage = () => import('@/vue/admin/systemAdminPage/TheSystemAdminPage.vue')
const TheRecentProjectActivityAdminPage = () => import('@/vue/admin/recentProjectActivityAdminPage/TheRecentProjectActivityAdminPage.vue')
const TheMostViewedProjectsAdminPage = () => import('@/vue/admin/mostViewedProjectsAdminPage/TheMostViewedProjectsAdminPage.vue')
const TheMostRecentUserEventsAdminPage = () => import('@/vue/admin/mostRecentUserEventsAdminPage/TheMostRecentUserEventsAdminPage.vue')
const TheDailyActiveUsersAdminPage = () => import('@/vue/admin/dailyActiveUsersAdminPage/TheDailyActiveUsersAdminPage.vue')
const TheMonthlyActiveUsersAdminPage = () => import('@/vue/admin/monthlyActiveUsersAdminPage/TheMonthlyActiveUsersAdminPage.vue')
const TheUserRetentionAdminPage = () => import('@/vue/admin/userRetentionAdminPage/TheUserRetentionAdminPage.vue')
const TheUserStickinessAdminPage = () => import('@/vue/admin/userStickinessAdminPage/TheUserStickinessAdminPage.vue')
const TheBusiestTimeOfTheDayAdminPage = () => import('@/vue/admin/busiestTimeOfTheDayAdminPage/TheBusiestTimeOfTheDayAdminPage.vue')
const TheRecentlyInvitedUsersAdminPage = () => import('@/vue/admin/recentlyInvitedUsersAdminPage/TheRecentlyInvitedUsersAdminPage.vue')
const TheTrialEndingSoonAdminPage = () => import('@/vue/admin/trialEndingSoonAdminPage/TheTrialEndingSoonAdminPage.vue')
const TheReleaseNotesAdminPage = () => import('@/vue/admin/releaseNotesAdminPage/TheReleaseNotesAdminPage.vue')
const TheReleaseNoteDetailsAdminPage = () => import('@/vue/admin/releaseNoteDetailsAdminPage/TheReleaseNoteDetailsAdminPage.vue')
const TheUserDetailsPage = () => import('@/vue/admin/userDetailsAdminPage/TheUserDetailsPage.vue')
const TheUsersAdminPage = () => import('@/vue/admin/usersAdminPage/TheUsersAdminPage.vue')

// Nested Views
// Splash Page
const TheLogin = () => import('@/vue/public/splashPage/components/TheLogin.vue')
const TheLoginRedirect = () => import('@/vue/public/splashPage/components/TheLoginRedirect.vue')
const TheOldInvitation = () => import('@/vue/public/splashPage/components/TheOldInvitation.vue')
const TheOldResetPassword = () => import('@/vue/public/splashPage/components/TheOldResetPassword.vue')

// Main Container page content
const updateAvailableNotification = () => import('@/vue/shared/components/UpdateAvailableNotification.vue')
const theHeaderBar = () => import('@/vue/shared/components/TheHeaderBar.vue')
const ProjectPageHeaderText = () => import('@/vue/public/projectPage/components/ProjectPageHeaderText.vue')
const ModulePageHeaderText = () => import('@/vue/public/modulePage/components/ModulePageHeaderText.vue')
const HomePageHeaderText = () => import('@/vue/shared/components/HomePageHeaderText.vue')
const theFooter = () => import('@/vue/shared/components/TheFooter.vue')
const theAppNavigationBar = () => import('@/vue/shared/components/TheNavigator.vue')

// Secondary Header Bars

const TheExampleHomePageHeaderControls = () => import('@/vue/public/exampleHomePage/components/TheExampleHomePageHeaderControls.vue')
const TheProjectHomePageHeaderControls = () => import('@/vue/public/projectHomePage/components/TheProjectHomePageHeaderControls.vue')
const TheTeamHomePageHeaderControls = () => import('@/vue/public/teamHomePage/components/TheTeamHomePageHeaderControls.vue')
const TheTemplateHomePageHeaderControls = () => import('@/vue/public/templateHomePage/components/TheTemplateHomePageHeaderControls.vue')
const TheProjectHeaderBarFilter = () => import('@/vue/public/projectHomePage/components/TheProjectHeaderBarFilter.vue')
const TheExampleHeaderBarFilter = () => import('@/vue/public/exampleHomePage/components/TheExampleHeaderBarFilter.vue')
const TheTemplateHeaderBarFilter = () => import('@/vue/public/templateHomePage/components/TheTemplateHeaderBarFilter.vue')

// Data Catlogs Page
const TheDataCatalogsPage = () => import('@/vue/public/dataCatalogsPage/TheDataCatalogsPage.vue')
const DataCatalogsHeaderControls = () => import('@/vue/public/dataCatalogsPage/components/DataCatalogsHeaderControls.vue')
const TheDataCatalogDetailPage = () => import('@/vue/public/dataCatalogDetailPage/TheDataCatalogDetailPage.vue')
const TheDataCatalogsHeaderBarFilterControl = () => import('@/vue/public/dataCatalogsPage/components/TheDataCatalogsHeaderBarFilterControl.vue')
const TheDataCatalogHeaderText = () => import('@/vue/public/dataCatalogDetailPage/components/TheDataCatalogHeaderText.vue')
const TheDataCatalogManagementPage = () => import('@/vue/public/dataCatalogManagementPage/TheDataCatalogManagementPage.vue')
const DataCatalogManagementHeaderControls = () => import('@/vue/public/dataCatalogManagementPage/components/DataCatalogManagementHeaderControls.vue')
const TheDataCatalogManagementHeaderText = () => import('@/vue/public/dataCatalogManagementPage/components/TheDataCatalogManagementHeaderText.vue')

// Data Maps Page
const TheDataMapManagementPage = () => import('@/vue/public/dataMapManagementPage/TheDataMapManagementPage.vue')
const TheDataMapHeaderText = () => import('@/vue/public/dataMapManagementPage/components/TheDataMapHeaderText.vue')
const TheDataMapElementMappingPage = () => import('@/vue/public/dataMapElementMappingPage/TheDataMapElementMappingPage.vue')
const TheDataMapLinkHeaderText = () => import('@/vue/public/dataMapElementMappingPage/components/TheDataMapLinkHeaderText.vue')
const TheDataMapLinkHeaderControls = () => import('@/vue/public/dataMapElementMappingPage/components/TheDataMapLinkHeaderControls.vue')
const TheDataMapCategoryMappingPage = () => import('@/vue/public/dataMapCategoryMappingPage/TheDataMapCategoryMappingPage.vue')
const DataMapCategoryLinkHeaderControls = () => import('@/vue/public/dataMapCategoryMappingPage/components/DataMapCategoryLinkHeaderControls.vue')
const TheDataMapCategoryHeaderText = () => import('@/vue/public/dataMapCategoryMappingPage/components/TheDataMapCategoryHeaderText.vue')
const TheDataMapsPage = () => import('@/vue/public/dataMapsPage/TheDataMapsPage.vue')
const TheDataMapsPageHeaderControls = () => import('@/vue/public/dataMapsPage/components/TheDataMapsPageHeaderControls.vue')
const TheDataMapsHeaderBarFilterControl = () => import('@/vue/public/dataMapsPage/components/TheDataMapsHeaderBarFilterControl.vue')

// Assessment Pages
const TheAssessmentsHeaderControls = () => import('@/vue/public/assessmentsPage/components/TheAssessmentsHeaderControls.vue')
const AssessmentHeaderText = () => import('@/vue/public/assessmentDetailPage/components/AssessmentHeaderText.vue')
const TheAssessmentStructureHeaderControls = () => import('@/vue/public/TheAssessmentStructureAdminPage/components/TheAssessmentStructureHeaderControls.vue')
const ResponseAnswersHeaderText = () => import('@/vue/public/responseAnswersPage/components/ResponseAnswersHeaderText.vue')

const TheAssessmentsPage = () => import('@/vue/public/assessmentsPage/TheAssessmentsPage.vue')
const TheAssessmentDetailPage = () => import('@/vue/public/assessmentDetailPage/TheAssessmentDetailPage.vue')

const TheAssessmentMgmtDetailPage = () => import('@/vue/public/TheAssessmentDetailsAdminPage/TheAssessmentDetailsAdminPage.vue')
const TheAssessmentStructurePage = () => import('@/vue/public/TheAssessmentStructureAdminPage/TheAssessmentStructureAdminPage.vue')
const TheAssessmentQuestionsPage = () => import('@/vue/public/TheAssessmentQuestionsAdminPage/TheAssessmentQuestionsAdminPage.vue')
const TheAssessmentPermissionsPage = () => import('@/vue/public/TheAssessmentPermissionsAdminPage/TheAssessmentPermissionsAdminPage.vue')
const TheAssessmentResponsesPage = () => import('@/vue/public/TheAssessmentResponsesAdminPage/TheAssessmentResponsesAdminPage.vue')

const TheResponseAnswersPage = () => import('@/vue/public/responseAnswersPage/TheResponseAnswersPage.vue')

// Modules

const theDataInterfaceModule = () => import('@/vue/public/modulePage/modules/dataInterfaceModule/TheDataInterfaceModule.vue')
const theDataInterfaceModuleheaderControls = () => import('@/vue/public/modulePage/modules/dataInterfaceModule/components/TheDataInterfaceModuleHeaderControls.vue')

const theChangeManagementModule = () => import('@/vue/public/modulePage/modules/changeManagementModule/TheChangeManagementModule.vue')
const theChangeManagementModuleHeaderControls = () => import('@/vue/public/modulePage/modules/changeManagementModule/components/TheChangeManagementHeaderControls.vue')

const theChecklistsModule = () => import('@/vue/public/modulePage/modules/checklistsModule/TheChecklistsModule.vue')
const theChecklistsModuleHeaderControls = () => import('@/vue/public/modulePage/modules/checklistsModule/components/TheChecklistsModuleHeaderControls.vue')

const theImageViewerModule = () => import('@/vue/public/modulePage/modules/imageViewerModule/TheImageViewerModule.vue')
const theImageViewerHeaderControls = () => import('@/vue/public/modulePage/modules/imageViewerModule/components/TheImageViewerHeaderControls.vue')

const theKanbanModule = () => import('@/vue/public/modulePage/modules/kanbanModule/TheKanbanModule.vue')
const theKanbanHeaderControls = () => import('@/vue/public/modulePage/modules/kanbanModule/components/TheKanbanHeaderControls.vue')

const CommonControlsMenu = () => import('@/vue/public/modulePage/components/CommonControlsMenu.vue')

export default [
  {
    path: '/',
    component: TheSplashPage,
    meta: { publicPage: true },
    children: [
      { path: '', alias: 'login', name: 'Login', component: TheLogin, meta: { publicPage: true } },
      { path: 'login/:providerId', name: 'CustomProviderLogin', component: TheLogin, meta: { publicPage: true } },
      { path: 'oauth-redirect', name: 'LoginRedirect', component: TheLoginRedirect, meta: { publicPage: true } },
      { path: 'accept-invitation', name: 'AcceptInvitation', component: TheOldInvitation, meta: { publicPage: true } },
      { path: 'set-new-password', name: 'RequestResetPassword', component: TheOldResetPassword, meta: { publicPage: true } }
    ]
  },

  // The Admin Pages
  {
    path: '/',
    components: {
      default: NestedView,
      theAppNavigationBar,
      theHeaderBar,
      theFooter,
      updateAvailableNotification
    },
    meta: { navigatorKey: 'system-admin' },
    children: [
      { path: 'admin', redirect: 'admin/companies' },
      { path: 'admin/companies', components: { default: TheCompaniesAdminPage, headerText }, name: 'CompaniesAdminPage', meta: { headerText: 'Companies Administration' } },
      { path: 'admin/company/:companyId', components: { default: TheCompanyDetailsAdminPage, headerText }, name: 'CompanyDetailsAdminPage', meta: { headerText: 'Company Administration' } },
      { path: 'admin/users', components: { default: TheUsersAdminPage, headerText }, name: 'UsersAdminPage', meta: { headerText: 'Users Administration' } },
      { path: 'admin/user/:userId', components: { default: TheUserDetailsPage, headerText }, name: 'UserDetailsPage', meta: { headerText: 'User Administration' } },
      { path: 'admin/removed-projects', components: { default: TheRemovedProjectsAdminPage, headerText }, name: 'RemovedProjectsAdminPage', meta: { headerText: 'Removed Projects Administration' } },
      { path: 'admin/system', components: { default: TheSystemAdminPage, headerText }, name: 'SystemAdminPage', meta: { headerText: 'System Administration' } },
      { path: 'admin/modules', components: { default: TheModulesAdminPage, headerText }, name: 'ModulesAdminPage', meta: { headerText: 'Modules Administration' } },
      { path: 'admin/module-categories', components: { default: TheModuleCategoriesAdminPage, headerText }, name: 'ModuleCategoriesAdminPage', meta: { headerText: 'Module Categories Administration' } },
      { path: 'admin/driver-item-types', components: { default: TheDriverItemTypesAdminPage, headerText }, name: 'DriverItemTypesAdminPage', meta: { headerText: 'Driver Item Types Administration' } },
      { path: 'admin/ecosystem-roles', components: { default: TheStakeholderPerspectiveEcoRolesAdminPage, headerText }, name: 'StakeholderPerspectiveEcoRolesAdminPage', meta: { headerText: 'Ecosystem Roles Administration' } },
      { path: 'admin/markets', components: { default: TheMarketsAdminPage, headerText }, name: 'MarketsAdminPage', meta: { headerText: 'Markets Administration' } },
      { path: 'admin/feature-notifications', components: { default: TheFeatureNotificationAdminPage, headerText }, name: 'FeatureNotificationAdminPage', meta: { headerText: 'Feature Notification Administration' } },
      { path: 'admin/project-statuses', components: { default: TheProjectStatusesAdminPage, headerText }, name: 'ProjectStatusesAdminPage', meta: { headerText: 'Project Statuses Administration' } },
      { path: 'admin/module-statuses', components: { default: TheModuleStatusesAdminPage, headerText }, name: 'ModuleStatusesAdminPage', meta: { headerText: 'Module Statuses Administration' } },
      { path: 'admin/risk-item-impacts', components: { default: TheRiskItemsImpactsAdminPage, headerText }, name: 'RiskItemsImpactsAdminPage', meta: { headerText: 'Risk Item Impacts Administration' } },
      { path: 'admin/risk-item-probabilities', components: { default: TheRiskItemsProbabilitiesAdminPage, headerText }, name: 'RiskItemsProbabilitiesAdminPage', meta: { headerText: 'Risk Item Probabilities Administration' } },
      { path: 'admin/release-notes', components: { default: TheReleaseNotesAdminPage, headerText }, name: 'TheReleaseNotesAdminPage', meta: { headerText: 'Release Notes Administration' } },
      { path: 'admin/trials-ending-soon', components: { default: TheTrialEndingSoonAdminPage, headerText }, name: 'TheTrialEndingSoonAdminPage', meta: { headerText: 'Trials Ending Soon' } },
      { path: 'admin/recent-project-activity', components: { default: TheRecentProjectActivityAdminPage, headerText }, name: 'TheRecentProjectActivityAdminPage', meta: { headerText: 'Recent Project Activity' } },
      { path: 'admin/most-viewed-projects', components: { default: TheMostViewedProjectsAdminPage, headerText }, name: 'TheMostViewedProjectsAdminPage', meta: { headerText: 'Most Viewed Projects' } },
      { path: 'admin/most-recent-user-events', components: { default: TheMostRecentUserEventsAdminPage, headerText }, name: 'TheMostRecentUserEventsAdminPage', meta: { headerText: 'Most Recent User Events' } },
      { path: 'admin/daily-active-users', components: { default: TheDailyActiveUsersAdminPage, headerText }, name: 'TheDailyActiveUsersAdminPage', meta: { headerText: 'Daily Active Users' } },
      { path: 'admin/monthly-active-users', components: { default: TheMonthlyActiveUsersAdminPage, headerText }, name: 'TheMonthlyActiveUsersAdminPage', meta: { headerText: 'Monthly Active Users' } },
      { path: 'admin/user-retention', components: { default: TheUserRetentionAdminPage, headerText }, name: 'TheUserRetentionAdminPage', meta: { headerText: 'User Retention' } },
      { path: 'admin/user-stickiness', components: { default: TheUserStickinessAdminPage, headerText }, name: 'TheUserStickinessAdminPage', meta: { headerText: 'User Stickiness' } },
      { path: 'admin/busiest-hours', components: { default: TheBusiestTimeOfTheDayAdminPage, headerText }, name: 'TheBusiestTimeOfTheDayAdminPage', meta: { headerText: 'Busiest Time Of The Day' } },
      { path: 'admin/recent-invite-users', components: { default: TheRecentlyInvitedUsersAdminPage, headerText }, name: 'TheRecentlyInvitedUsersAdminPage', meta: { headerText: 'Recently Created Users' } },
      { path: 'admin/release-notes/:releaseNoteId', components: { default: TheReleaseNoteDetailsAdminPage, headerText }, name: 'TheReleaseNoteDetailsAdminPage', meta: { headerText: 'Release Note Administration' } }
    ]
  },

  // The Release Note Page
  {
    path: '/',
    components: {
      default: TheReleaseNotePage,
      theHeaderBar,
      theFooter,
      updateAvailableNotification
    },
    children: [
      {
        path: 'release-notes/:releaseNoteId',
        name: 'TheReleaseNotePage',
        components: { headerText },
        meta: { headerText: 'Release Notes' }
      }
    ]
  },

  // Profile Page
  {
    path: '/',
    components: {
      default: TheProfilePage,
      theAppNavigationBar,
      theHeaderBar,
      theFooter,
      updateAvailableNotification
    },
    children: [
      {
        path: 'profilepage',
        name: 'ProfilePage',
        components: { headerText },
        meta: { headerText: 'Profile Page' }
      }
    ]
  },

  // Company Admin Pages
  {
    path: '/',
    components: {
      default: NestedView,
      theAppNavigationBar,
      theHeaderBar,
      theFooter,
      updateAvailableNotification
    },
    meta: { navigatorKey: 'company-admin' },
    children: [
      { path: 'company', redirect: 'company/details' },
      { path: 'company/details', components: { default: TheDetailsCompanyPage, headerText }, name: 'DetailsCompanyPage', meta: { headerText: 'Company Settings' } },
      { path: 'company/users', components: { default: TheUsersCompanyPage, headerText }, name: 'UsersCompanyPage', meta: { headerText: 'Company Settings' } },
      { path: 'company/teams', components: { default: TheTeamsCompanyPage, headerText }, name: 'TeamsCompanyPage', meta: { headerText: 'Company Settings' } },
      { path: 'company/restore-projects', components: { default: TheRestoreProjectsCompanyPage, headerText }, name: 'RestoreProjectsCompanyPage', meta: { headerText: 'Company Settings' } },
      { path: 'company/most-recent-user-events', components: { default: TheMostRecentUserEventsCompanyPage, headerText }, name: 'TheMostRecentUserEventsCompanyPage', meta: { headerText: 'Most Recent User Events' } },
      { path: 'company/daily-active-users', components: { default: TheDailyActiveUsersCompanyPage, headerText }, name: 'TheDailyActiveUsersCompanyPage', meta: { headerText: 'Daily Active Users' } },
      { path: 'company/monthly-active-users', components: { default: TheMonthlyActiveUsersCompanyPage, headerText }, name: 'TheMonthlyActiveUsersCompanyPage', meta: { headerText: 'Monthly Active Users' } },
      { path: 'company/recent-project-activity', components: { default: TheRecentProjectActivityCompanyPage, headerText }, name: 'TheRecentProjectActivityCompanyPage', meta: { headerText: 'Recent Project Activity' } }
    ]
  },

  // Redirects for newer url structure
  { path: '/home/', redirect: '/recently-viewed' },
  { path: '/data-catalog-admin/:dataCatalogId', redirect: '/data-catalogs/:dataCatalogId' },
  { path: '/data-map-admin/:dataMapId', redirect: '/data-maps/:dataMapId' },
  { path: '/data-map-admin/:dataMapId/category-link-management', redirect: '/data-maps/:dataMapId/category-link-management' },
  { path: '/data-map-admin/:dataMapId/link-management', redirect: '/data-maps/:dataMapId/link-management' },
  {
    path: '/projects/:projectId/modules/:moduleId',
    alias: '/projects/:projectId/scenario_modules/:moduleId',
    beforeEnter: (to, from, next) => {
      Vue.prototype.$http
        .get(`/api/projects/${to.params.projectId}/project_scenario_modules/${to.params.moduleId}.json`)
        .then(response => next({ path: `/projects/${to.params.projectId}/${moduleUrlKeyMap(response.data.type_key)}/${to.params.moduleId}` }))
        .catch(() => next({ path: '/' }))
    }
  },

  // Project Home Page Routes
  {
    path: '/',
    components: {
      default: NestedView,
      theAppNavigationBar,
      theHeaderBar,
      theFooter,
      updateAvailableNotification
    },
    meta: { navigatorKey: 'projects' },
    children: [
      {
        name: 'ProjectHomePage',
        path: '',
        components: { default: NestedView, headerText: HomePageHeaderText, headerControls: TheProjectHomePageHeaderControls, headerExtension: TheProjectHeaderBarFilter },
        children: [
          { path: 'recently-viewed', name: 'RecentlyViewed', component: TheProjectHomePage, meta: { headerText: 'Most Recently Viewed' } },
          { path: 'favorites', name: 'Favorites', component: TheProjectHomePage, meta: { headerText: 'Favorites' } },
          { path: 'my-projects', name: 'MyProjects', component: TheProjectHomePage, meta: { headerText: 'My Projects' } },
          { path: 'all-projects', name: 'AllProjects', component: TheProjectHomePage, meta: { headerText: 'All Projects' } }
        ]
      }]
  },

  // Teams Home Page Routes
  {
    path: '/',
    components: {
      default: NestedView,
      theAppNavigationBar,
      theHeaderBar,
      theFooter,
      updateAvailableNotification
    },
    meta: { navigatorKey: 'teams' },
    children: [
      {
        name: 'TeamHomePage',
        path: '',
        components: { default: NestedView, headerText: HomePageHeaderText, headerControls: TheTeamHomePageHeaderControls },
        children: [
          { path: 'teams/:roleId', name: 'Teams', component: TheTeamHomePage }
        ]
      }]
  },

  // Example Home Page Routes
  {
    path: '/',
    components: {
      default: NestedView,
      theAppNavigationBar,
      theHeaderBar,
      theFooter,
      updateAvailableNotification
    },
    meta: { navigatorKey: 'examples' },
    children: [
      {
        name: 'ExampleHomePage',
        path: '',
        components: { default: NestedView, headerText: HomePageHeaderText, headerControls: TheExampleHomePageHeaderControls, headerExtension: TheExampleHeaderBarFilter },
        children: [
          { path: 'examples', name: 'Examples', component: TheExampleHomePage, meta: { headerText: 'Example Projects' } },
          { path: 'unpublished-examples', name: 'UnpublishedExamples', component: TheExampleHomePage, meta: { headerText: 'Unpublished Examples' } }
        ]
      }]
  },

  // Template Home Page Routes
  {
    path: '/',
    components: {
      default: NestedView,
      theAppNavigationBar,
      theHeaderBar,
      theFooter,
      updateAvailableNotification
    },
    meta: { navigatorKey: 'templates' },
    children: [
      {
        name: 'TemplateHomePage',
        path: '',
        components: { default: NestedView, headerText: HomePageHeaderText, headerControls: TheTemplateHomePageHeaderControls, headerExtension: TheTemplateHeaderBarFilter },
        children: [
          { path: 'templates', name: 'Templates', component: TheTemplateHomePage, meta: { headerText: 'Project Templates' } },
          { path: 'unpublished-templates', name: 'UnpublishedTemplates', component: TheTemplateHomePage, meta: { headerText: 'Unpublished Templates' } }
        ]
      }]
  },

  // Assessment Page Routes
  {
    path: '/',
    components: {
      default: NestedView,
      theAppNavigationBar,
      theHeaderBar,
      theFooter,
      updateAvailableNotification
    },
    meta: { navigatorKey: 'assessments' },
    children: [
      { path: 'assessments', name: 'TheAssessmentsPage', components: { default: TheAssessmentsPage, headerText, headerControls: TheAssessmentsHeaderControls }, meta: { headerText: 'Overview Dashboard' } },
      { path: 'assessments/:assessmentId', name: 'TheAssessmentsDetailPageNoSection', components: { default: TheAssessmentDetailPage, headerText: AssessmentHeaderText } },
      { path: 'assessments/:assessmentId/sections/:sectionId', name: 'TheAssessmentDetailPage', components: { default: TheAssessmentDetailPage, headerText: AssessmentHeaderText } },
      { path: 'assessments/:assessmentId/details', name: 'AssessmentDetailPage', components: { default: TheAssessmentMgmtDetailPage } },
      { path: 'assessments/:assessmentId/structure', name: 'AssessmentStructurePage', components: { default: TheAssessmentStructurePage, headerText, headerControls: TheAssessmentStructureHeaderControls }, meta: { headerText: 'Structure' } },
      { path: 'assessments/:assessmentId/questions', name: 'AssessmentQuestionsPage', components: { default: TheAssessmentQuestionsPage } },
      { path: 'assessments/:assessmentId/permissions', name: 'AssessmentPermissionsPage', components: { default: TheAssessmentPermissionsPage } },
      { path: 'assessments/:assessmentId/responses', name: 'TheResponsesPage', components: { default: TheAssessmentResponsesPage } },
      { path: 'assessments/:assessmentId/responses/:responseId/', name: 'TheResponseAnswersPageNoSection', components: { default: TheResponseAnswersPage, headerText: ResponseAnswersHeaderText } },
      { path: 'assessments/:assessmentId/responses/:responseId/sections/:sectionId', name: 'TheResponseAnswersPage', components: { default: TheResponseAnswersPage, headerText: ResponseAnswersHeaderText } }
    ]
  },

  {
    path: '/',
    components: {
      default: NestedView,
      theAppNavigationBar,
      theHeaderBar,
      theFooter,
      updateAvailableNotification
    },
    meta: { navigatorKey: 'data' },
    children: [
      { path: 'data-catalogs', components: { default: TheDataCatalogsPage, headerText, headerControls: DataCatalogsHeaderControls, headerExtension: TheDataCatalogsHeaderBarFilterControl }, name: 'DataCatalogsCompanyPage', meta: { headerText: 'Data Catalog' } },
      {
        path: 'data-catalogs/:dataCatalogId',
        name: 'DataCatalogDetailPage',
        components: { default: TheDataCatalogDetailPage, headerText: TheDataCatalogHeaderText }
      },
      {
        path: 'data-catalogs/:dataCatalogId/data-catalog-management',
        components: { default: TheDataCatalogManagementPage, headerControls: DataCatalogManagementHeaderControls, headerText: TheDataCatalogManagementHeaderText },
        name: 'DataMapCategoryLinkAdminPage'
      }
    ]
  },

  {
    path: '/',
    components: {
      default: NestedView,
      theAppNavigationBar,
      theHeaderBar,
      theFooter,
      updateAvailableNotification
    },
    meta: { navigatorKey: 'data' },
    children: [
      { path: 'data-maps', components: { default: TheDataMapsPage, headerText, headerControls: TheDataMapsPageHeaderControls, headerExtension: TheDataMapsHeaderBarFilterControl }, name: 'DataMapsPage', meta: { headerText: 'Data Maps' } },
      {
        path: 'data-maps/:dataMapId',
        name: 'DataMapManagementPage',
        components: { default: TheDataMapManagementPage, headerText: TheDataMapHeaderText }
      },
      {
        path: 'data-maps/:dataMapId/link-management',
        components: { default: TheDataMapElementMappingPage, headerText: TheDataMapLinkHeaderText, headerControls: TheDataMapLinkHeaderControls },
        name: 'TheDataMapElementMappingPage'
      },
      {
        path: 'data-maps/:dataMapId/category-link-management',
        components: { default: TheDataMapCategoryMappingPage, headerControls: DataMapCategoryLinkHeaderControls, headerText: TheDataMapCategoryHeaderText },
        name: 'TheDataMapCategoryMappingPage'
      }
    ]
  },

  // Project Page Routes
  {
    path: '/',
    components: {
      default: TheProjectPage,
      theAppNavigationBar,
      theHeaderBar,
      theFooter,
      updateAvailableNotification
    },
    meta: { navigatorKey: 'projects' },
    children: [
      {
        path: 'projects/:projectId',
        name: 'ProjectPage',
        components: { headerText: ProjectPageHeaderText }
      }
    ]
  },
  {
    path: '/',
    name: 'BpmnModulePage',
    components: {
      default: NestedView,
      theAppNavigationBar,
      theHeaderBar,
      headerBarContent: ModulePageHeaderText,
      theFooter,
      updateAvailableNotification
    },
    meta: { navigatorKey: 'projects', isModulePage: true },
    children: [
      {
        path: 'projects/:projectId/bpmn/:moduleId',
        name: 'BpmnsModule',
        components: {
          default: theDiagramModulePage,
          headerMenu: CommonControlsMenu,
          headerText: ModulePageHeaderText,
          headerControls: theDiagramModulePageHeader
        },
        props: {
          default: { moduleTypeKey: 'Bpmns' },
          headerControls: {
            moduleTypeKey: 'Bpmns',
            visioImporter: BpmnVisioImport
          }
        }
      }
    ]
  },
  // New Module Page Routes
  {
    path: '/',
    name: 'LessonsLearnedModulePage',
    components: {
      default: NestedView,
      theAppNavigationBar,
      theHeaderBar,
      headerBarContent: ModulePageHeaderText,
      theFooter,
      updateAvailableNotification
    },
    meta: { navigatorKey: 'projects', isModulePage: true },
    children: [
      {
        path: 'projects/:projectId/lessons-learned/:moduleId',
        name: 'LessonsLearnedModule',
        components: {
          default: theTextModulePage,
          headerMenu: CommonControlsMenu,
          headerText: ModulePageHeaderText,
          headerControls: theTextModulePageHeader
        },
        props: {
          default: { moduleTypeKey: 'LearnedLessons' },
          headerControls: { moduleTypeKey: 'LearnedLessons' }
        }
      }
    ]
  },
  {
    path: '/',
    name: 'ProblemStatementsModulePage',
    components: {
      default: NestedView,
      theAppNavigationBar,
      theHeaderBar,
      headerBarContent: ModulePageHeaderText,
      theFooter,
      updateAvailableNotification
    },
    meta: { navigatorKey: 'projects', isModulePage: true },
    children: [
      {
        path: 'projects/:projectId/problem-statements/:moduleId',
        name: 'ProblemStatementsModule',
        components: {
          default: theTextModulePage,
          headerMenu: CommonControlsMenu,
          headerText: ModulePageHeaderText,
          headerControls: theTextModulePageHeader
        },
        props: {
          default: { moduleTypeKey: 'ProblemStatements' },
          headerControls: { moduleTypeKey: 'ProblemStatements' }
        }
      }
    ]
  },
  {
    path: '/',
    name: 'FreeTextModulePage',
    components: {
      default: NestedView,
      theAppNavigationBar,
      theHeaderBar,
      headerBarContent: ModulePageHeaderText,
      theFooter,
      updateAvailableNotification
    },
    meta: { navigatorKey: 'projects', isModulePage: true },
    children: [
      {
        path: 'projects/:projectId/text/:moduleId',
        name: 'FreeTextModule',
        components: {
          default: theFreeTextModulePage,
          headerMenu: CommonControlsMenu,
          headerText: ModulePageHeaderText,
          headerControls: theFreeTextModulePageHeader
        },
        props: {
          default: { moduleTypeKey: 'FreeTexts' },
          headerControls: { moduleTypeKey: 'FreeTexts' }
        }
      }
    ]
  },
  {
    path: '/',
    name: 'MiroIntegrationModulePage',
    components: {
      default: NestedView,
      theAppNavigationBar,
      theHeaderBar,
      headerBarContent: ModulePageHeaderText,
      theFooter,
      updateAvailableNotification
    },
    meta: { navigatorKey: 'projects', isModulePage: true },
    children: [
      {
        path: 'projects/:projectId/miro-boards/:moduleId',
        name: 'MiroIntegrationModule',
        components: {
          default: theEmbeddedSuitePage,
          headerMenu: CommonControlsMenu,
          headerText: ModulePageHeaderText,
          headerControls: theEmbeddedSuitePageHeader
        },
        props: {
          default: { moduleTypeKey: 'MiroIntegrations' },
          headerControls: { moduleTypeKey: 'MiroIntegrations' }
        }
      }
    ]
  },
  {
    path: '/',
    name: 'EmbeddedSuiteModulePage',
    components: {
      default: NestedView,
      theAppNavigationBar,
      theHeaderBar,
      headerBarContent: ModulePageHeaderText,
      theFooter,
      updateAvailableNotification
    },
    meta: { navigatorKey: 'projects', isModulePage: true },
    children: [
      {
        path: 'projects/:projectId/embedded-suites/:moduleId',
        name: 'EmbeddedSuiteModule',
        components: {
          default: theEmbeddedSuitePage,
          headerMenu: CommonControlsMenu,
          headerText: ModulePageHeaderText,
          headerControls: theEmbeddedSuitePageHeader
        },
        props: {
          default: { moduleTypeKey: 'EmbeddedSuites' },
          headerControls: { moduleTypeKey: 'EmbeddedSuites' }
        }
      }
    ]
  },
  {
    path: '/',
    name: 'DriversModulePage',
    components: {
      default: NestedView,
      theAppNavigationBar,
      theHeaderBar,
      headerBarContent: ModulePageHeaderText,
      theFooter,
      updateAvailableNotification
    },
    meta: { navigatorKey: 'projects', isModulePage: true },
    children: [
      {
        path: 'projects/:projectId/drivers/:moduleId',
        name: 'DriversModule',
        components: {
          default: theTextModulePage,
          headerMenu: CommonControlsMenu,
          headerText: ModulePageHeaderText,
          headerControls: theTextModulePageHeader
        },
        props: {
          default: { moduleTypeKey: 'Drivers' },
          headerControls: { moduleTypeKey: 'Drivers' }
        }
      }
    ]
  },
  {
    path: '/',
    name: 'StakeholderPerspectiveModulePage',
    components: {
      default: NestedView,
      theAppNavigationBar,
      theHeaderBar,
      headerBarContent: ModulePageHeaderText,
      theFooter,
      updateAvailableNotification
    },
    meta: { navigatorKey: 'projects', isModulePage: true },
    children: [
      {
        path: 'projects/:projectId/stakeholder-perspectives/:moduleId',
        name: 'StakeholderPerspectivesModule',
        components: {
          default: theTextModulePage,
          headerMenu: CommonControlsMenu,
          headerText: ModulePageHeaderText,
          headerControls: theTextModulePageHeader
        },
        props: {
          default: { moduleTypeKey: 'StakeholderPerspectives' },
          headerControls: { moduleTypeKey: 'StakeholderPerspectives' }
        }
      }
    ]
  },
  {
    path: '/',
    name: 'OverallValuessModulePage',
    components: {
      default: NestedView,
      theAppNavigationBar,
      theHeaderBar,
      headerBarContent: ModulePageHeaderText,
      theFooter,
      updateAvailableNotification
    },
    meta: { navigatorKey: 'projects', isModulePage: true },
    children: [
      {
        path: 'projects/:projectId/overall-values/:moduleId',
        name: 'OverallValuesModule',
        components: {
          default: theTextModulePage,
          headerMenu: CommonControlsMenu,
          headerText: ModulePageHeaderText,
          headerControls: theTextModulePageHeader
        },
        props: {
          default: { moduleTypeKey: 'OverallValues' },
          headerControls: { moduleTypeKey: 'OverallValues' }
        }
      }
    ]
  },
  {
    path: '/',
    name: 'RisksModulePage',
    components: {
      default: NestedView,
      theAppNavigationBar,
      theHeaderBar,
      headerBarContent: ModulePageHeaderText,
      theFooter,
      updateAvailableNotification
    },
    meta: { navigatorKey: 'projects', isModulePage: true },
    children: [
      {
        path: 'projects/:projectId/risks/:moduleId',
        name: 'RisksModule',
        components: {
          default: theTextModulePage,
          headerMenu: CommonControlsMenu,
          headerText: ModulePageHeaderText,
          headerControls: theTextModulePageHeader
        },
        props: {
          default: { moduleTypeKey: 'Risks' },
          headerControls: { moduleTypeKey: 'Risks' }
        }
      }
    ]
  },
  {
    path: '/',
    name: 'ProcessFlowModulePage',
    components: {
      default: NestedView,
      theAppNavigationBar,
      theHeaderBar,
      headerBarContent: ModulePageHeaderText,
      theFooter,
      updateAvailableNotification
    },
    meta: { navigatorKey: 'projects', isModulePage: true },
    children: [
      {
        path: 'projects/:projectId/process-flow/:moduleId',
        name: 'ProcessFlowsModule',
        components: {
          default: theDiagramModulePage,
          headerMenu: CommonControlsMenu,
          headerText: ModulePageHeaderText,
          headerControls: theDiagramModulePageHeader
        },
        props: {
          default: { moduleTypeKey: 'ProcessFlows' },
          headerControls: {
            moduleTypeKey: 'ProcessFlows',
            visioImporter: StandardVisioimport
          }
        }
      }
    ]
  },
  {
    path: '/',
    name: 'QuadrantModulePage',
    components: {
      default: NestedView,
      theAppNavigationBar,
      theHeaderBar,
      headerBarContent: ModulePageHeaderText,
      theFooter,
      updateAvailableNotification
    },
    meta: { navigatorKey: 'projects', isModulePage: true },
    children: [
      {
        path: 'projects/:projectId/quadrant/:moduleId',
        name: 'QuadrantModule',
        components: {
          default: theDiagramModulePage,
          headerMenu: CommonControlsMenu,
          headerText: ModulePageHeaderText,
          headerControls: theDiagramModulePageHeader
        },
        props: {
          default: { moduleTypeKey: 'Quadrants' },
          headerControls: {
            moduleTypeKey: 'Quadrants',
            visioImporter: StandardVisioimport
          }
        }
      }
    ]
  },
  {
    path: '/',
    name: 'EcosystemModulePage',
    components: {
      default: NestedView,
      theAppNavigationBar,
      theHeaderBar,
      headerBarContent: ModulePageHeaderText,
      theFooter,
      updateAvailableNotification
    },
    meta: { navigatorKey: 'projects', isModulePage: true },
    children: [
      {
        path: 'projects/:projectId/ecosystem/:moduleId',
        name: 'EcosystemModule',
        components: {
          default: theDiagramModulePage,
          headerMenu: CommonControlsMenu,
          headerText: ModulePageHeaderText,
          headerControls: theDiagramModulePageHeader
        },
        props: {
          default: { moduleTypeKey: 'Ecosystems' },
          headerControls: {
            moduleTypeKey: 'Ecosystems',
            visioImporter: StandardVisioimport
          }
        }
      }
    ]
  },
  {
    path: '/',
    name: 'BusinessCanvasModulePage',
    components: {
      default: NestedView,
      theAppNavigationBar,
      theHeaderBar,
      headerBarContent: ModulePageHeaderText,
      theFooter,
      updateAvailableNotification
    },
    meta: { navigatorKey: 'projects', isModulePage: true },
    children: [
      {
        path: 'projects/:projectId/business-canvas/:moduleId',
        name: 'BusinessCanvasModule',
        components: {
          default: theCanvasModulePage,
          headerMenu: CommonControlsMenu,
          headerText: ModulePageHeaderText,
          headerControls: theCanvasModulePageHeader
        },
        props: {
          default: { moduleTypeKey: 'BusinessCanvases' },
          headerControls: {
            moduleTypeKey: 'BusinessCanvases'
          }
        }
      }
    ]
  },
  {
    path: '/',
    name: 'PlatformModelCanvasModulePage',
    components: {
      default: NestedView,
      theAppNavigationBar,
      theHeaderBar,
      headerBarContent: ModulePageHeaderText,
      theFooter,
      updateAvailableNotification
    },
    meta: { navigatorKey: 'projects', isModulePage: true },
    children: [
      {
        path: 'projects/:projectId/platform-model-canvas/:moduleId',
        name: 'PlatformModelCanvasModule',
        components: {
          default: theCanvasModulePage,
          headerMenu: CommonControlsMenu,
          headerText: ModulePageHeaderText,
          headerControls: theCanvasModulePageHeader
        },
        props: {
          default: { moduleTypeKey: 'PlatformModelCanvases' },
          headerControls: {
            moduleTypeKey: 'PlatformModelCanvases'
          }
        }
      }
    ]
  },
  {
    path: '/',
    name: 'SwotCanvasModulePage',
    components: {
      default: NestedView,
      theAppNavigationBar,
      theHeaderBar,
      headerBarContent: ModulePageHeaderText,
      theFooter,
      updateAvailableNotification
    },
    meta: { navigatorKey: 'projects', isModulePage: true },
    children: [
      {
        path: 'projects/:projectId/swot-canvas/:moduleId',
        name: 'SwotCanvasModule',
        components: {
          default: theCanvasModulePage,
          headerMenu: CommonControlsMenu,
          headerText: ModulePageHeaderText,
          headerControls: theCanvasModulePageHeader
        },
        props: {
          default: { moduleTypeKey: 'SwotCanvases' },
          headerControls: {
            moduleTypeKey: 'SwotCanvases'
          }
        }
      }
    ]
  },
  {
    path: '/',
    name: 'MissionModelCanvasModulePage',
    components: {
      default: NestedView,
      theAppNavigationBar,
      theHeaderBar,
      headerBarContent: ModulePageHeaderText,
      theFooter,
      updateAvailableNotification
    },
    meta: { navigatorKey: 'projects', isModulePage: true },
    children: [
      {
        path: 'projects/:projectId/mission-model-canvas/:moduleId',
        name: 'MissionModelCanvasModule',
        components: {
          default: theCanvasModulePage,
          headerMenu: CommonControlsMenu,
          headerText: ModulePageHeaderText,
          headerControls: theCanvasModulePageHeader
        },
        props: {
          default: { moduleTypeKey: 'MissionModelCanvases' },
          headerControls: {
            moduleTypeKey: 'MissionModelCanvases'
          }
        }
      }
    ]
  },
  {
    path: '/',
    name: 'LeanCanvasModulePage',
    components: {
      default: NestedView,
      theAppNavigationBar,
      theHeaderBar,
      headerBarContent: ModulePageHeaderText,
      theFooter,
      updateAvailableNotification
    },
    meta: { navigatorKey: 'projects', isModulePage: true },
    children: [
      {
        path: 'projects/:projectId/lean-canvas/:moduleId',
        name: 'LeanCanvasModule',
        components: {
          default: theCanvasModulePage,
          headerMenu: CommonControlsMenu,
          headerText: ModulePageHeaderText,
          headerControls: theCanvasModulePageHeader
        },
        props: {
          default: { moduleTypeKey: 'LeanCanvases' },
          headerControls: {
            moduleTypeKey: 'LeanCanvases'
          }
        }
      }
    ]
  },
  // Module Page Routes
  {
    path: '/',
    name: 'UserStoriesModulePage',
    components: {
      default: NestedView,
      theAppNavigationBar,
      theHeaderBar,
      headerBarContent: ModulePageHeaderText,
      theFooter,
      updateAvailableNotification
    },
    meta: { navigatorKey: 'projects', isModulePage: true },
    children: [
      {
        path: 'projects/:projectId/user-stories/:moduleId',
        name: 'UserStoriesModule',
        components: {
          default: theTextModulePage,
          headerMenu: CommonControlsMenu,
          headerText: ModulePageHeaderText,
          headerControls: theTextModulePageHeader
        },
        props: {
          default: { moduleTypeKey: 'UserStories' },
          headerControls: { moduleTypeKey: 'UserStories' }
        }
      }
    ]
  },
  // Module Page Routes
  {
    path: '/',
    name: 'ModulePage',
    components: {
      default: TheModulePage,
      theAppNavigationBar,
      theHeaderBar,
      headerBarContent: ModulePageHeaderText,
      theFooter,
      updateAvailableNotification
    },
    meta: { navigatorKey: 'projects', isModulePage: true },
    children: [
      {
        path: 'projects/:projectId/data-map/:moduleId',
        name: 'DataInterfaceModule',
        components: {
          default: theDataInterfaceModule,
          headerMenu: CommonControlsMenu,
          headerText: ModulePageHeaderText,
          headerControls: theDataInterfaceModuleheaderControls
        }
      },
      {
        path: 'projects/:projectId/change-management-stories/:moduleId',
        name: 'ChangeManagementModule',
        components: {
          default: theChangeManagementModule,
          headerMenu: CommonControlsMenu,
          headerText: ModulePageHeaderText,
          headerControls: theChangeManagementModuleHeaderControls
        }
      },
      {
        path: 'projects/:projectId/checklists/:moduleId',
        name: 'ChecklistModule',
        components: {
          default: theChecklistsModule,
          headerMenu: CommonControlsMenu,
          headerText: ModulePageHeaderText,
          headerControls: theChecklistsModuleHeaderControls
        }
      },
      {
        path: 'projects/:projectId/image-viewer/:moduleId',
        name: 'ImageViewerModule',
        components: {
          default: theImageViewerModule,
          headerMenu: CommonControlsMenu,
          headerText: ModulePageHeaderText,
          headerControls: theImageViewerHeaderControls
        }
      },
      {
        path: 'projects/:projectId/kanban/:moduleId',
        name: 'KanbanModule',
        components: {
          default: theKanbanModule,
          headerMenu: CommonControlsMenu,
          headerText: ModulePageHeaderText,
          headerControls: theKanbanHeaderControls
        }
      }
    ]
  },

  /* Embedded Views */

  {
    path: '/embed/',
    name: 'EmbedPage',
    components: {
      default: TheModulePage,
      theHeaderBar
    },
    beforeEnter: (to, from, next) => {
      if (!testPattern.test(to.params.projectId)) {
        next()
      } else {
        next('not-found')
      }
    },
    meta: { isEmbedded: true },
    children: [
      {
        path: 'projects/:projectId/data-map/:moduleId',
        name: 'TheDataInterfaceModuleEmbed',
        components: {
          default: theDataInterfaceModule,
          headerControls: theDataInterfaceModuleheaderControls
        }
      }
    ]
  },

  {
    path: '/forbidden',
    components: {
      default: TheForbiddenPage,
      updateAvailableNotification
    },
    name: 'ForbiddenPage'
  },

  {
    path: '*',
    components: {
      default: TheNotFoundPage,
      updateAvailableNotification
    },
    name: 'NotFoundPage'
  }
]
