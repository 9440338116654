import tippy from 'tippy.js'
import _ from 'lodash'

export default {
  inserted (el, binding) {
    let params = el.getAttribute('tooltip-params') || '{}'
    params = JSON.parse(params)
    const defaultParams = {
      ignoreAttributes: true,
      content: binding.value,
      placement: 'bottom',
      arrow: true,
      delay: [350, null],
      duration: [300, 50],
      maxWidth: 350
    }
    tippy(el, _.assign(defaultParams, params))
    const disabled = el.getAttribute('disabled')
    if (disabled || !binding.value) {
      el._tippy.disable()
    }
  },
  unbind (el) {
    el._tippy && el._tippy.destroy()
  },
  update (el, binding, vnode) {
    if (!el._tippy) { return }
    // If on vue component, check props
    let disabled = _.get(vnode, 'componentOptions.propsData.disabled', null)
    /* Effort to emulate tippy on vue component not worth it */
    /* istanbul ignore else  */
    if (!_.isBoolean(disabled)) {
      disabled = el.getAttribute('disabled')
    }
    if (el._tippy.state.isEnabled && (!binding.value || disabled)) {
      el._tippy.disable()
    } else if (!el._tippy.state.isEnabled && (!!binding.value && !disabled)) {
      el._tippy.enable()
    }
    if (el._tippy.props.content !== binding.value) {
      el._tippy.setContent(binding.value)
    }
  }
}
