import actions from './dataCatalogStore/actions.vue.js'
import getters from './dataCatalogStore/getters.vue.js'
import mutations from './dataCatalogStore/mutations.vue.js'
import initialState from './dataCatalogStore/initialState.vue.js'

export default {
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions
}
