
import actions from './stakeholdersStore/actions.vue.js'
import getters from './stakeholdersStore/getters.vue.js'
import mutations from './stakeholdersStore/mutations.vue.js'
import initialState from './stakeholdersStore/initialState.vue.js'

export default {
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions
}
