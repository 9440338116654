import generateIndexes from '@/vue/store/utils/generateIndexes.vue.js'
import {
  questions,
  questionOptions,
  answers,
  answerOptions,
  sections,
  groups,
  responses,
  myResponses,
  myAnswers,
  userResponses,
  userAnswers
} from './schema.vue.js'

const dynamicQuestionsIndexProperties = ['assessment_section_id', 'assessment_group_id']
const staticQuestionsIndexProperties = ['assessment_id']
const staticQuestionOptionsIndexProperties = ['assessment_id', 'assessment_question_id']

const staticSectionsIndexProperties = ['assessment_id']
const staticGroupsIndexProperties = ['assessment_id']
const dynamicGroupsIndexProperties = ['assessment_section_id']

const staticResponsesIndexProperties = ['assessment_id', 'user_id']
const staticAnswersIndexProperties = ['assessment_id', 'assessment_question_id', 'response_id']
const staticAnswerOptionsIndexProperties = ['response_id', 'response_answer_id', 'assessment_question_option_id']

const staticMyResponsesIndexProperties = ['assessment_id']
const staticMyAnswersIndexProperties = ['assessment_question_id', 'response_id', 'assessment_id']

const staticUserResponsesIndexProperties = ['assessment_id']
const staticUserAnswersIndexProperties = ['assessment_question_id', 'response_id', 'assessment_id']

export const indexes = {
  [questions.key]: generateIndexes(staticQuestionsIndexProperties, dynamicQuestionsIndexProperties),
  [questionOptions.key]: generateIndexes(staticQuestionOptionsIndexProperties),
  [answers.key]: generateIndexes(staticAnswersIndexProperties),
  [answerOptions.key]: generateIndexes(staticAnswerOptionsIndexProperties),
  [sections.key]: generateIndexes(staticSectionsIndexProperties),
  [groups.key]: generateIndexes(staticGroupsIndexProperties, dynamicGroupsIndexProperties),
  [responses.key]: generateIndexes(staticResponsesIndexProperties),
  [myResponses.key]: generateIndexes(staticMyResponsesIndexProperties),
  [myAnswers.key]: generateIndexes(staticMyAnswersIndexProperties),
  [userResponses.key]: generateIndexes(staticUserResponsesIndexProperties),
  [userAnswers.key]: generateIndexes(staticUserAnswersIndexProperties)
}
