
import generateGetters from '@/vue/store/utils/generateGetters.vue.js'
import { indexes } from './indexes.vue.js'
import {
  tags,
  tagCategories,
  tagMapItems,
  tagAssignedItems
} from './schema.vue.js'

export default {
  ...generateGetters(tags, indexes),
  ...generateGetters(tagCategories, indexes),
  ...generateGetters(tagMapItems),
  ...generateGetters(tagAssignedItems)
}
