import { schema } from 'normalizr'
export const assessments = new schema.Entity('assessments')
export const assessmentStatuses = new schema.Entity('assessmentStatuses')

export const sections = new schema.Entity('sections')
export const groups = new schema.Entity('groups')

export const questionTypes = new schema.Entity('questionTypes')
export const questionOptions = new schema.Entity('questionOptions')
export const questions = new schema.Entity('questions')

export const responses = new schema.Entity('responses')
export const responseStatuses = new schema.Entity('responseStatuses')
export const answerOptions = new schema.Entity('answerOptions')
export const answers = new schema.Entity('answers')

export const myResponses = new schema.Entity('myResponses')
export const myAnswers = new schema.Entity('myAnswers')

export const userResponses = new schema.Entity('userResponses')
export const userAnswers = new schema.Entity('userAnswers')
export const rolePermissions = new schema.Entity('rolePermissions', {}, {
  idAttribute: (rp) => (`${rp.role_id}-${rp.assessment_id}`)
})
export const userPermissions = new schema.Entity('userPermissions', {}, {
  idAttribute: (up) => (`${up.user_id}-${up.assessment_id}`)
})
