import generateIndexes from '@/vue/store/utils/generateIndexes.vue.js'
import { cmUserStoryItems, cmUserStoryItemStakeholders, cmCategorys, cmIterations } from './schema.vue.js'

const staticIndexProperties = [
  'module_id'
]

const cmUserStoryItemStakeholdersProperties = [
  'change_management_story_item_id'
]

export const indexes = {
  [cmUserStoryItems.key]: generateIndexes(staticIndexProperties),
  [cmUserStoryItemStakeholders.key]: generateIndexes(cmUserStoryItemStakeholdersProperties),
  [cmCategorys.key]: generateIndexes(staticIndexProperties),
  [cmIterations.key]: generateIndexes(staticIndexProperties)
}
