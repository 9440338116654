const initialState = () => {
  return {
    dataCatalogPropertiesVisible: false,
    dataCatalogDetailAttributesVisible: false,
    selectedDetailId: null,
    selectedTreeId: null,
    viewMode: 'tree'
  }
}
export default initialState
