import store from '@/vue/store/store.vue.js'
import { globalMutes } from '@/vue/store/mutation-types.vue.js'

const headers = {
  request: [
    (request) => {
      if (store.getters.accessToken) {
        request.headers.Authorization = `Bearer ${store.getters.accessToken}`
      }
      if (store.getters.xSession) {
        request.headers['x-session-token'] = store.getters.xSessionToken
      }
      return request
    }
  ],
  response: [
    (response) => {
      const backendVersion = response.headers['app-release-version']
      const frontendVersion = RELEASE_TAG
      if (backendVersion && frontendVersion) {
        if (backendVersion === frontendVersion) {
          store.commit(globalMutes.HIDE_UPDATE_NOTIFICATION)
        } else {
          store.commit(globalMutes.SHOW_UPDATE_NOTIFICATION)
        }
      }
      return response
    }
  ]
}

export default headers
