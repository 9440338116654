
import getters from './kanbanModule/getters.vue.js'
import mutations from './kanbanModule/mutations.vue.js'
import initialState from './kanbanModule/initialState.vue.js'

export default {
  namespaced: true,
  state: initialState(),
  getters,
  mutations
}
