import Vue from 'vue'
import { normalize } from 'normalizr'
import {
  notifications
} from './schema.vue.js'

export default {
  LOAD_NOTIFICATIONS ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.post('/api/notifications/datatable.json', payload).then(response => {
        const normalized = normalize(response.data, [notifications])
        commit('ADD_NORMALIZED_DATA', normalized)
        resolve(normalized)
      }, error => {
        reject(error)
      })
    })
  },
  MARK_NOTIFICATIONS_READ ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.post('/api/notifications/bulk_update', payload).then(response => {
        const normalized = normalize(response.data, [notifications])
        commit('ADD_NORMALIZED_DATA', normalized)
        resolve(normalized)
      }, error => {
        reject(error)
      })
    })
  },
  ADD_NOTIFICATION ({ commit }, payload) {
    return new Promise(resolve => {
      const normalized = normalize(payload, notifications)
      commit('ADD_NORMALIZED_DATA', normalized)
      resolve(normalized)
    })
  },
  REMOVE_NOTIFICATION ({ commit }, payload) {
    return new Promise(resolve => {
      const normalized = normalize(payload, notifications)
      commit('REMOVE_NORMALIZED_DATA', normalized)
      resolve(normalized)
    })
  }
}
