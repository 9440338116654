import generateActions from '@/vue/store/utils/generateActions.vue.js'
import { featureNotifications } from './schema.vue.js'
import Vue from 'vue'
import { normalize } from 'normalizr'
import _ from 'lodash'

const featureNotificationsAdminEndpoint = (params) => {
  return '/api/admin/new_feature_notifications'
}

const featureNotificationsMethodSchemas = {
  index: [featureNotifications],
  create: featureNotifications,
  destroy: featureNotifications
}

export default {
  ...generateActions(
    'featureNotificationsAdminStore',
    'feature_notification',
    featureNotificationsAdminEndpoint,
    featureNotificationsMethodSchemas
  ),
  UPDATE_FEATURE_NOTIFICATION ({ state, commit }, { params, payload }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.put(`${featureNotificationsAdminEndpoint()}/${params.id}`, payload)
        .then(response => {
          if (!_.isObject(response.data) || _.isEmpty(response.data)) { return resolve(response.data) }
          const normalized = normalize(response.data, featureNotifications)
          commit('ADD_NORMALIZED_DATA', normalized)
          resolve(normalized)
        }, error => {
          reject(error)
        })
    })
  }
}
