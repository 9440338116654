import _ from 'lodash'
import initialState from './initialState.vue.js'

export default {
  RESET_STATE (state) {
    _.assign(state, initialState())
  },
  SET_SELECTED_ITEM_ID (state, id) {
    state.selectedItemId = id
  },
  SET_OPEN_PANEL_SECTION (state, panel) {
    state.openPanelSection = panel
  },
  SET_COLOUR_FILTER (state, value) {
    state.colourFilter = value
  },
  RESET_COLOUR_FILTER (state) {
    state.colourFilter = initialState().colourFilter
  }
}
