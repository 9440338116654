
import generateGetters from '@/vue/store/utils/generateGetters.vue.js'
import { indexes } from './indexes.vue.js'
import {
  productsAndServices,
  productsAndServicesCategories,
  productsAndServicesMapItems,
  productsAndServicesAssignedItems,
  links
} from './schema.vue.js'

export default {
  ...generateGetters(productsAndServices, indexes),
  ...generateGetters(productsAndServicesCategories, indexes),
  ...generateGetters(productsAndServicesMapItems),
  ...generateGetters(productsAndServicesAssignedItems),
  ...generateGetters(links)
}
