const initialState = () => {
  return {
    viewMode: 'grid',
    searchString: '',
    filter: 'all',
    sortColumn: 'name',
    sortDesc: false
  }
}
export default initialState
