
import getters from './projectHomePage/getters.vue.js'
import mutations from './projectHomePage/mutations.vue.js'
import initialState from './projectHomePage/initialState.vue.js'

export default {
  namespaced: true,
  state: initialState(),
  getters,
  mutations
}
