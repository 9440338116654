import * as joint from '@clientio/rappid'
import _ from 'lodash'
import BaseLink from '../base/baseLink.vue.js'
const flowStyles = {
  default: {
    line: {
      sourceMarker: {
        d: 'M 0 0 L 20 0 M 20 -5 L 10 5'
      },
      strokeWidth: 2
    },
    wrapper: {
      stroke: 'transparent',
      strokeWidth: 10
    }
  },
  conditional: {
    line: {
      sourceMarker: {
        d: 'M 20 0 L 10 -8 L 0 0 L 10 8 z',
        fill: '#ffffff'
      },
      strokeWidth: 2
    },
    wrapper: {
      stroke: 'transparent',
      strokeWidth: 10
    }
  },
  conversation: {
    line: {
      targetMarker: {
        d: 'M 0 0'
      },
      stroke: '#ffffff',
      strokeWidth: 4
    },
    wrapper: {
      stroke: '#000000',
      strokeWidth: 6
    }
  },
  normal: {
    wrapper: {
      stroke: 'transparent',
      strokeWidth: 10
    }
  },
  message: {
    line: {
      targetMarker: {
        fill: '#ffffff'
      },
      strokeWidth: 2,
      strokeDasharray: '4,4'
    },
    wrapper: {
      stroke: 'transparent',
      strokeWidth: 10
    }
  },
  association: {
    line: {
      targetMarker: {
        fill: '#ffffff',
        d: 'M 0 0'
      },
      strokeWidth: 2,
      strokeDasharray: '4,4'
    },
    wrapper: {
      stroke: 'transparent',
      strokeWidth: 10
    }
  }
}
const Flow = BaseLink.extend({
  initialize: function () {
    BaseLink.prototype.initialize.apply(this, arguments)

    this.on('change:flowType', (cell, flowType) => {
      this.attr(joint.util.merge({}, this.defaults.attrs, flowStyles[flowType] || {}))
      this.setLinkColor(cell.get('link_color'))
    })

    this.on('change:link_color', (cell, color) => {
      this.setLinkColor(color)
    })
  },
  setLinkColor: function (color) {
    if (this.get('flowType') !== 'conditional') {
      this.attr('line/sourceMarker/fill', color)
    }
    if (this.get('flowType') === 'conversation') {
      this.attr('wrapper/stroke', color)
    } else {
      this.attr('line/stroke', color)
    }
    this.attr('line/sourceMarker/stroke', color)
    this.attr('line/targetMarker/fill', color)
    this.attr('line/targetMarker/stroke', color)
    this.attr('.connection/stroke', color)
  },
  markup: joint.shapes.standard.Link.prototype.markup,
  highlightParams: {
    deep: true
  },
  defaults: _.defaultsDeep({
    shape: 'Flow',
    type: 'businessProcess.Flow',
    router: {
      name: 'normal'
    },
    connector: {
      name: 'normal'
    },
    labels: [
      {
        attrs: {
          text: {
            text: '',
            fill: '#000000',
            fontSize: 14
          }
        }
      }
    ],
    flowType: 'normal',
    attrs: {
      line: {
        type: 'normal',
        stroke: '#000000',
        strokeDasharray: '0',
        strokeWidth: 2,
        fill: 'none',
        sourceMarker: {
          type: 'path',
          d: 'M 0 0 0 0',
          stroke: '#000000',
          fill: '#000000'
        },
        targetMarker: {
          type: 'path',
          d: 'M 10 -5 L 0 0 L 10 5 z',
          stroke: '#000000',
          fill: '#000000'
        }
      }
    }
  }, joint.shapes.standard.Link.prototype.defaults)
})
export default Flow
