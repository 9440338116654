import _ from 'lodash'
import generateGetters from '@/vue/store/utils/generateGetters.vue.js'
import { indexes } from './indexes.vue.js'
import {
  comments
} from './schema.vue.js'

export default {
  ...generateGetters(comments, indexes),

  commentsByFilter: (state, getters) => (commentableId, commentableType, filter) => {
    let commentIds
    switch (commentableType) {
      case 'Project': {
        commentIds = _.get(state, `indexes.commentsByProjectId[${commentableId}]`, [])
        break
      }
      case 'ProjectScenarioModule': {
        commentIds = _.get(state, `indexes.commentsByModuleId[${commentableId}]`, [])
        break
      }
      default: {
        const idsByItem = _.get(state, `indexes.commentsByCommentableId[${commentableId}]`, [])
        const idsByType = _.get(state, `indexes.commentsByCommentableType[${commentableType}]`, [])
        commentIds = _.intersection(idsByItem, idsByType)
      }
    }

    if (filter !== 'all') {
      const levelFilterIds = _.get(state, `indexes.commentsByCommentLevelType[${filter}]`, [])
      commentIds = _.intersection(commentIds, levelFilterIds)
    }

    return getters.commentsByIds(commentIds)
  }
}
