import Vue from 'vue'
import _ from 'lodash'

const setIfNotExists = (object, index, value) => {
  if (!_.has(object, index)) {
    Vue.set(object, index, value)
  }
}

const generateIndexes = (staticProperties = [], dynamicProperties = [], identifier = 'id') => ({
  indexedProperties: _.union(staticProperties, dynamicProperties),
  addIndex: (entityKey, state, addedEntity) => {
    // Setup
    const properties = _.union(staticProperties, dynamicProperties)
    setIfNotExists(state, 'indexes', {})

    _.each(properties, (indexProp) => {
      const indexKey = _.camelCase(`${entityKey}_by_${indexProp}`)
      setIfNotExists(state.indexes, indexKey, {})

      const propValues = _.castArray(addedEntity[indexProp])
      _.each(propValues, (indexPropVal) => {
        setIfNotExists(state.indexes[indexKey], indexPropVal, [])
        state.indexes[indexKey][indexPropVal] = _.union(state.indexes[indexKey][indexPropVal], [addedEntity[identifier]])
      })
    })
  },
  updateIndex: (entityKey, state, updatedEntity) => {
    if (!dynamicProperties.length) { return }
    _.each(dynamicProperties, (dynamicProp) => {
      const indexKey = _.camelCase(`${entityKey}_by_${dynamicProp}`)
      _.each(state.indexes[indexKey], (entityIds, indexId) => {
        state.indexes[indexKey][indexId] = _.without(entityIds, updatedEntity[identifier])
      })

      const propValues = _.castArray(updatedEntity[dynamicProp])
      _.each(propValues, (indexPropVal) => {
        setIfNotExists(state.indexes[indexKey], indexPropVal, [])
        state.indexes[indexKey][indexPropVal] = _.union(state.indexes[indexKey][indexPropVal], [updatedEntity[identifier]])
      })
    })
  },
  removeIndex: (entityKey, state, removedEntity) => {
    const properties = _.union(staticProperties, dynamicProperties)
    _.each(properties, (indexProp) => {
      const indexKey = _.camelCase(`${entityKey}_by_${indexProp}`)
      _.each(state.indexes[indexKey], (entityIds, indexId) => {
        if (_.includes(state.indexes[indexKey][indexId], removedEntity[identifier])) {
          state.indexes[indexKey][indexId] = _.without(entityIds, removedEntity[identifier])
        }
      })
    })
  }
})

export default generateIndexes
