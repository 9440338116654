import Vue from 'vue'
import { _profileActs } from '@/vue/store/action-types.vue.js'
import { _profileMutes } from '@/vue/store/mutation-types.vue.js'
export default {
  [_profileActs.LOAD_SYSTEM] ({ state, commit }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.get('/api/system', { withCredentials: false }).then(response => {
        commit(_profileMutes.SET_SYSTEM, response.data)
        resolve(response)
      }, error => {
        commit(_profileMutes.SET_SYSTEM, null)
        reject(error)
      })
    })
  },
  [_profileActs.LOAD_CURRENT_USER] ({ state, commit }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.get('/api/current_user').then(response => {
        commit(_profileMutes.SET_CURRENT_USER, response.data)
        resolve(response)
      }, error => {
        commit(_profileMutes.SET_CURRENT_USER, null)
        reject(error)
      })
    })
  },
  [_profileActs.REGISTER_VIEWED_PROJECT] ({ state, commit }, projectId) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.post('/api/current_user/register_viewed_project.json', { project_id: projectId }).then(response => {
        resolve(response)
      }, error => {
        reject(error)
      })
    })
  },
  [_profileActs.REGISTER_VIEWED_NOTIFICATION] ({ state, commit }, notificationId) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.post('/api/current_user/register_viewed_notification.json', { notification_id: notificationId }).then(response => {
        commit(_profileMutes.REMOVE_VALID_NOTIFICATION, response.data)
        resolve(response)
      }, error => {
        reject(error)
      })
    })
  },
  [_profileActs.UPDATE_CURRENT_USER] ({ state, commit }, data) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .put('/api/current_user/', {
          id: state.currentUser.id,
          ...data
        })
        .then(
          response => {
            commit(_profileMutes.SET_CURRENT_USER, response.data)
            resolve(response)
          },
          error => {
            commit(_profileMutes.SET_CURRENT_USER, [])
            reject(error)
          }
        )
    })
  },
  [_profileActs.RESET_PASSWORD] ({ state, commit }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .get('/api/current_user/request_password_reset')
        .then(response => {
          resolve(response)
        },
        error => {
          reject(error)
        })
    })
  },
  [_profileActs.UPDATE_PROFILE_PICTURE] ({ state, commit }, data) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .post('/api/current_user/upload_profile_image', data)
        .then(
          response => {
            commit(_profileMutes.SET_CURRENT_USER, response.data)
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
    })
  },
  [_profileActs.REMOVE_PROFILE_PICTURE] ({ state, commit }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .delete('/api/current_user/remove_profile_image')
        .then(
          response => {
            commit(_profileMutes.SET_PROFILE_PICTURE, response.data)
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
    })
  },
  [_profileActs.REMOVE_CURRENT_USER] ({ state, commit }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .delete('/api/current_user/')
        .then(
          response => {
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
    })
  },
  [_profileActs.LOAD_CURRENT_COMPANY] ({ state, commit }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.get('/api/current_company').then(response => {
        commit(_profileMutes.SET_CURRENT_COMPANY, response.data)
        resolve(response)
      }, error => {
        commit(_profileMutes.SET_CURRENT_COMPANY, null)
        reject(error)
      })
    })
  }
}
