import getters from './companyDetailAdminPage/getters.vue.js'
import mutations from './companyDetailAdminPage/mutations.vue.js'
import initialState from './companyDetailAdminPage/initialState.vue.js'

export default {
  namespaced: true,
  state: initialState(),
  getters,
  mutations
}
