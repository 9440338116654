import generateStateGetters from '@/vue/store/utils/generateStateGetters.vue.js'
export default {
  openPanelSection: state => state.openPanelSection,
  focusedItemId: (state) => state.focusedItemId,
  searchString: (state) => state.searchString,
  selectedItemId: (state) => state.selectedItemId,
  checklistItemColourFilter: state => state.checklistItemColourFilter,
  checklistItemFilterActive: (state) => {
    return (
      !!state.checklistItemColourFilter.tag_ids.length ||
      !!state.checklistItemColourFilter.stakeholder_ids.length ||
      !!state.checklistItemColourFilter.stakeholder_role_ids.length
    )
  },
  ...generateStateGetters()
}
