export default {
  accessToken: state => state.accessToken,
  xSession: state => state.xSession,
  xSessionToken: state => state.xSessionToken,
  saving: state => state.uploadRequestsInProgress > 0,
  loading: state => state.downloadRequestsInProgress > 0,
  requestsInProgress: state => state.uploadRequestsInProgress + state.downloadRequestsInProgress,
  tokenSubscribers: state => state.tokenSubscribers,
  allRequestsFailed: state => state.tokenSubscribers.length === state.uploadRequestsInProgress + state.downloadRequestsInProgress,
  successSnackbar: state => state.successSnackbar,
  successMessage: state => state.successMessage,
  showUpdateAvailable: state => state.showUpdateAvailable,
  headerBarTitle: state => state.headerBarTitle,
  noRequestInProgress: (state) => (key) => (!state.requestCounter[key] || state.requestCounter[key] <= 1),
  releaseNotesVisible: state => state.releaseNotesVisible,
  alertText: state => state.alertText,
  alertId: state => state.alertId,
  closedAlertIds: state => state.closedAlertIds,
  itemParams: state => state.itemParams
}
