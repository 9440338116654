
import actions from './projectsStore/actions.vue.js'
import getters from './projectsStore/getters.vue.js'
import mutations from './projectsStore/mutations.vue.js'
import initialState from './projectsStore/initialState.vue.js'

export default {
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions
}
