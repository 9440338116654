import generateIndexes from '@/vue/store/utils/generateIndexes.vue.js'
import {
  sections,
  projectResources,
  projectHistoryEvents,
  rolePermissions,
  userPermissions,
  projectDataMaps,
  associatedDataElements
} from './schema.vue.js'

const staticSectionIndexProperties = ['project_id']
const staticRolePermissionsProperties = ['project_id', 'user_ids', 'role_id']
const staticUserPermissionsProperties = ['project_id']
const staticProjectResourceIndexProperties = ['project_id']
const stateProjectHistoryEventIndexProperties = ['project_id']
const stateAssociatedDataElementsIndexProperties = ['project_id', 'data_catalog_id', 'data_map_id', 'data_catalog_detail_id']
const dynamicProjectDataMapsIndexProperties = ['project_ids', 'data_catalog_ids']

export const indexes = {
  [sections.key]: generateIndexes(staticSectionIndexProperties),
  [associatedDataElements.key]: generateIndexes(stateAssociatedDataElementsIndexProperties),
  [rolePermissions.key]: generateIndexes(staticRolePermissionsProperties, [], 'role_id'),
  [userPermissions.key]: generateIndexes(staticUserPermissionsProperties),
  [projectResources.key]: generateIndexes(staticProjectResourceIndexProperties),
  [projectHistoryEvents.key]: generateIndexes(stateProjectHistoryEventIndexProperties),
  [projectDataMaps.key]: generateIndexes([], dynamicProjectDataMapsIndexProperties)
}
