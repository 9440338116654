import Flow from '@/vue/shared/plugins/jointjsVuePlugin/shapes/businessProcess/flow.vue.js'
import { types } from '@clientio/rappid-visio'

const { VisioSectionType } = types

export default function (vsdConnect, sourceElement, targetElement) {
  const { vertices, z } = vsdConnect.toLinkAttributes(sourceElement, targetElement)
  const link = new Flow({
    vertices,
    z,
    source: { id: sourceElement.id },
    target: { id: targetElement.id }
  })
  const linkShape = vsdConnect.getShape()
  const lineColor = linkShape.cells.lineColor
  link.attr('line/sourceMarker/fill', lineColor)
  link.attr('line/sourceMarker/stroke', lineColor)
  link.attr('line/targetMarker/fill', lineColor)
  link.attr('line/targetMarker/stroke', lineColor)
  link.attr('.connection/stroke', lineColor)

  const data = linkShape.getComputedSection(VisioSectionType.Property)
  if (!data) { return link }

  const connectionObjectType = (data.getProperty('BpmnConnectingObjectType') || 'simple').toLowerCase()
  switch (connectionObjectType) {
    case 'sequence flow': {
      const conditionType = data.getProperty('BpmnConditionType')
      if (conditionType) {
        const flowType = {
          Default: 'default',
          Expression: 'conditional',
          None: 'normal'
        }[conditionType]
        link.prop('flowType', flowType)
      }
      break
    }
    case 'association': {
      link.prop('flowType', 'association')
      break
    }
    case 'message': {
      link.prop('flowType', 'message')
      break
    }
    default: {
      link.prop('flowType', 'normal')
      break
    }
  }
  return link
}
