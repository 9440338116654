import actions from './stakeholderPerspectiveEcoRolesAdminStore/actions.vue.js'
import getters from './stakeholderPerspectiveEcoRolesAdminStore/getters.vue.js'
import mutations from './stakeholderPerspectiveEcoRolesAdminStore/mutations.vue.js'
import initialState from './stakeholderPerspectiveEcoRolesAdminStore/initialState.vue.js'

export default {
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions
}
