import _ from 'lodash'
export default {
  selectedDetailId: (state) => state.selectedDetailId,
  openPanelSection: (state) => state.openPanelSection,
  selectedCatalogId: (state) => state.selectedCatalogId,
  viewMode: (state) => state.viewMode,
  viewLoaded: (state) => (view) => _.includes(state.loadedViews, view),
  dataInterfaceItemColourFilter: (state) => state.dataInterfaceItemColourFilter,
  pathIds: (state) => state.pathIds,
  animating: (state) => state.animating,
  dataInterfaceItemColourFilterActive: (state) => {
    return (
      !!state.dataInterfaceItemColourFilter.tag_ids.length ||
      !!state.dataInterfaceItemColourFilter.tag_category_ids.length ||
      !!state.dataInterfaceItemColourFilter.stakeholder_ids.length ||
      !!state.dataInterfaceItemColourFilter.stakeholder_role_ids.length ||
      !!state.dataInterfaceItemColourFilter.product_and_service_item_ids.length ||
      !!state.dataInterfaceItemColourFilter.product_and_service_category_ids.length ||
      !!state.dataInterfaceItemColourFilter.data_map_category_ids.length ||
      !!state.dataInterfaceItemColourFilter.data_catalog_ids.length
    )
  },
  animateDetail: (state) => state.animate_detail,
  loading: (state) => state.loading
}
