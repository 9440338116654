import Pool from '@/vue/shared/plugins/jointjsVuePlugin/shapes/businessProcess/pool.vue.js'
import { types } from '@clientio/rappid-visio'

const { VisioSectionType } = types

export default function (vsdShape) {
  const data = vsdShape.getComputedSection(VisioSectionType.Property)
  const { x, y, width, height } = vsdShape.getPageBBox()
  const element = new Pool({
    poolId: vsdShape.id,
    dimensions: {
      y1: y,
      x1: x,
      y2: y + height,
      x2: x + width
    },
    poolName: data.getProperty('BpmnName'),
    attrs: {
      header: {
        fill: vsdShape.cells.fillForegnd,
        stroke: vsdShape.cells.lineColor
      },
      headerLabel: {
        text: data.getProperty('BpmnName'),
        fill: vsdShape.getComputedSection(VisioSectionType.Character).rows[0].cells.color
      },
      laneHeaders: {
        fill: '#F3F2F1'
      },
      laneLabels: {
        fontWeight: 'bold',
        fill: '#333333'
      }
    }
  })

  return element
}
