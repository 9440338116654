import * as joint from '@clientio/rappid'
import _ from 'lodash'
const linkStyles = {
  aggr: {
    line: {
      sourceMarker: {
        d: 'M 15 0 L 5 8 L -5 0 L 5 -8 Z',
        fill: '#FFFFFF',
        stroke: '#000000'
      },
      targetMarker: {
        d: 'M 0 0 0 0'
      },
      stroke: '#000000'
    }
  },
  comp: {
    line: {
      sourceMarker: {
        d: 'M 15 0 L 5 8 L -5 0 L 5 -8 Z',
        fill: '#000000',
        stroke: '#000000'
      },
      targetMarker: {
        d: 'M 0 0 0 0'
      },
      stroke: '#000000'
    }
  },
  assign: {
    line: {
      sourceMarker: {
        d: 'M 0, 0 m -3, 0 a 3,3 0 1,0 6,0 a 3,3 0 1,0 -6,0',
        fill: '#000000',
        stroke: '#000000'
      },
      targetMarker: {
        d: 'M 0 -3 -6 0 0 3 z',
        fill: '#000000',
        stroke: '#000000'
      },
      stroke: '#000000'
    }
  },
  realize: {
    line: {
      sourceMarker: {
        d: 'M 0 0 0 0'
      },
      targetMarker: {
        d: 'M 0 -5 -10 0 0 5 z',
        fill: '#ffffff',
        stroke: '#000000'
      },
      strokeDasharray: '2,5',
      stroke: '#000000'
    }
  },
  serving: {
    line: {
      sourceMarker: {
        d: 'M 0 0 0 0'
      },
      targetMarker: {
        d: 'M 0 -5 -10 0 0 5',
        fill: 'none',
        stroke: '#000000'
      },
      stroke: '#000000'
    }
  },
  access: {
    line: {
      sourceMarker: {
        d: 'M 0 0 0 0'
      },
      targetMarker: {
        d: 'M 0 -5 -10 0 0 5',
        fill: 'none',
        stroke: '#000000'
      },
      strokeDasharray: '2,5',
      stroke: '#000000'
    }
  },
  influence: {
    line: {
      sourceMarker: {
        d: 'M 0 0 0 0'
      },
      targetMarker: {
        d: 'M 0 -5 -10 0 0 5',
        fill: 'none',
        stroke: '#000000'
      },
      strokeDasharray: '10,5',
      stroke: '#000000'
    }
  },
  trigger: {
    line: {
      sourceMarker: {
        d: 'M 0 0 0 0'
      },
      targetMarker: {
        d: 'M 0 -3 -6 0 0 3 z',
        fill: '#000000',
        stroke: '#000000'
      },
      stroke: '#000000'
    }
  },
  flow: {
    line: {
      sourceMarker: {
        d: 'M 0 0 0 0'
      },
      targetMarker: {
        d: 'M 0 -3 -6 0 0 3 z',
        fill: '#000000',
        stroke: '#000000'
      },
      strokeDasharray: '10,5',
      stroke: '#000000'
    }
  },
  special: {
    line: {
      sourceMarker: {
        d: 'M 0 0 0 0'
      },
      targetMarker: {
        d: 'M 0 -5 -10 0 0 5 z',
        fill: '#ffffff',
        stroke: '#000000'
      },
      stroke: '#000000'
    }
  },
  association: {
    line: {
      sourceMarker: {
        d: 'M 0 0 0 0'
      },
      targetMarker: {
        d: 'M 0 0 0 0'
      },
      stroke: '#000000'
    }
  }
}

const labelPositions = {
  source: 30,
  middle: 0.5,
  target: -30
}

export default joint.dia.Link.extend({
  idAttribute: 'jsid',
  initialize: function (params) {
    joint.dia.Link.prototype.initialize.apply(this, arguments)
    // Removes display:none, which is getting set somewhere we cant find.
    this.removeAttr('./display')
    this._initializeListeners()
  },
  _initializeListeners: function () {
    const ctx = this
    ctx.on('change:linkType', (cell, linkType) => {
      ctx.attr(joint.util.merge({}, ctx.defaults.attrs, linkStyles[linkType] || {}))
      ctx.prop('link_color', '#000000')
    })
    ctx.on('change:labelPosition', (cell, labelLocation) => {
      ctx.prop('labels/0/position/distance', labelPositions[labelLocation])
    })

    ctx.on('change:title', (cell, textValue) => {
      ctx.setText(textValue)
    })
  },
  setText: function (text) {
    this.prop('labels/0/attrs/text/text', text)
  },
  isConnected () {
    const hasSource = _.get(this, 'attributes.source.id', false)
    const hasTarget = _.get(this, 'attributes.target.id', false)
    return hasSource && hasTarget
  },
  adjustVertices (cell) {
    const ctx = this
    // If the cell is a view, find its model.
    cell = cell.model || cell

    if (cell instanceof joint.dia.Element) {
      const test = ctx.graph.getConnectedLinks(cell)
      const group = _.groupBy(test, function (link) {
        // the key of the group is the model id of the link's source or target, but not our cell id.
        return _.omit([link.get('source').id, link.get('target').id], cell.id)[0]
      })

      _.forEach(group, function (group, key) {
        // If the member of the group has both source and target model adjust vertices.
        if (key !== 'undefined') {
          ctx.adjustVertices(_.first(group))
        }
      })
      return
    }

    // The cell is a link. Let's find its source and target models.
    let srcId = null
    let trgId = null

    if ((cell.get('source') !== undefined && 'id' in cell.get('source')) || (cell.previous('source') !== undefined && 'id' in cell.previous('source'))) { srcId = cell.get('source').id || cell.previous('source').id }
    if ((cell.get('target') !== undefined && 'id' in cell.get('target')) || (cell.previous('target') !== undefined && 'id' in cell.previous('target'))) { trgId = cell.get('target').id || cell.previous('target').id }

    // If one of the ends is not a model, the link has no siblings.
    if (!srcId || !trgId) return

    const siblings = _.filter(ctx.graph.getLinks(), function (sibling) {
      const _srcId = sibling.get('source').id
      const _trgId = sibling.get('target').id

      return (_srcId === srcId && _trgId === trgId) || (_srcId === trgId && _trgId === srcId)
    })

    switch (siblings.length) {
      case 0: {
        // The link was removed and had no siblings.
        break
      }
      case 1: {
        // There is only one link between the source and target. No vertices needed.
        cell.unset('vertices')
        break
      }
      default: {
        // There is more than one siblings. We need to create vertices.
        // First of all we'll find the middle point of the link.
        const srcCenter = ctx.graph.getCell(srcId).getBBox().center()
        const trgCenter = ctx.graph.getCell(trgId).getBBox().center()
        const midPoint = joint.g.line(srcCenter, trgCenter).midpoint()

        // Then find the angle it forms.
        const theta = srcCenter.theta(trgCenter)

        // This is the maximum distance between links
        const gap = 40

        _.each(siblings, function (sibling, index) {
          // We want the offset values to be calculated as follows 0, 20, 20, 40, 40, 60, 60 ..
          const offset = gap * Math.ceil(index / 2)

          // Now we need the vertices to be placed at points which are 'offset' pixels distant
          // from the first link and forms a perpendicular angle to it. And as index goes up
          // alternate left and right.
          //
          //  ^  odd indexes
          //  |
          //  |---->  index 0 line (straight line between a source center and a target center.
          //  |
          //  v  even indexes
          const sign = index % 2 ? 1 : -1
          const angle = joint.g.toRad(theta + sign * 90)

          // We found the vertex.
          const vertex = joint.g.point.fromPolar(offset, angle, midPoint)
          // Only update newest link, dont change siblings
          if (cell.get('id') === sibling.get('id')) {
            sibling.set('vertices', [{ x: vertex.x, y: vertex.y }])
          }
        })
      }
    }
  }
})
