<template>
  <Dialog
    :active="internalActiveState"
    content-class="v-dialog--common v-dialog--thin"
    :persistent="true"
  >
    <template #header>
      Terms and Conditions
    </template>

    <template #body>
      Welcome to {{ system.system_title }}. Please make sure you understand and accept our terms before using the product.
      If you require any further information before accepting our terms please send us an email at
      <a
        :href="`mailto:${system.support_email}`"
      >
        {{ system.support_email }}
      </a>.
      <v-row
        no-gutters
        class="mt-4"
        align="center"
      >
        <v-checkbox
          v-model="checkbox"
          color="#2A96D3"
          hide-details
          class="shrink mt-0"
          test-hook="accept-checkbox"
        />
        <label
          class="pt-1"
        >
          I agree to the
          <a
            :href="system.eula_link"
            target="_blank"
          >
            Terms &amp; Conditions
          </a>
        </label>
      </v-row>
    </template>

    <template #actions>
      <v-spacer />
      <v-btn
        test-hook="decline-btn"
        class="v-btn--tr3-tretiary"
        text
        @click="decline()"
      >
        Decline
      </v-btn>
      <v-btn
        v-if="checkbox"
        test-hook="accept-btn"
        class="v-btn--primary"
        text
        :loading="inProgress"
        @click="confirm()"
      >
        Accept
      </v-btn>
    </template>
  </Dialog>
</template>

<script>
import Dialog from '@/vue/shared/components/Dialog.vue'
import { profileActs } from '@/vue/store/action-types.vue.js'

export default {
  name: 'TermsAndConditionsDialog',
  components: {
    Dialog
  },
  props: {
    active: {
      required: true,
      type: Boolean
    }
  },
  data () {
    return {
      checkbox: false,
      inProgress: false
    }
  },
  computed: {
    internalActiveState: {
      get () {
        return this.active
      },
      set (value) {
        if (!value) {
          this.$emit('close', null)
        }
      }
    }
  },
  methods: {
    decline () {
      // sign user out
      this.$http.get('/api/oauth/logouts.json').then(() => {
        this.$store.commit('SET_ACCESS_TOKEN', null)
      })
        .catch(() => {
          this.$router.push('/')
        })
    },
    confirm () {
      this.inProgress = true
      // update user to have accepted eula
      this.$store.dispatch(profileActs.UPDATE_CURRENT_USER, {
        accepted_eula: true
      }).then(response => {
        this.inProgress = false
        this.internalActiveState = false
      }, error => {
        this.handleError(error)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
