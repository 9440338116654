
import actions from './entityValueLinksStore/actions.vue.js'
import getters from './entityValueLinksStore/getters.vue.js'
import mutations from './entityValueLinksStore/mutations.vue.js'
import initialState from './entityValueLinksStore/initialState.vue.js'

export default {
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions
}
