
import generateGetters from '@/vue/store/utils/generateGetters.vue.js'
import { system, systemCompanies, systemEmailTemplates } from './schema.vue.js'
import _ from 'lodash'

export default {
  ...generateGetters(system, null),
  ...generateGetters(systemCompanies, null),
  ...generateGetters(systemEmailTemplates, null),
  theSystem: (state) => (_.first(_.values(state.system))),
  theEmailTemplate: (state) => (_.first(_.values(state.systemEmailTemplates)))
}
