import moment from 'moment'

const filters = {
  timestampToDatetimeString: function (timestamp) {
    if (moment(timestamp).isValid()) {
      return moment(timestamp).format('YYYY-MM-DD hh:mm')
    }
    return 'No data available'
  },
  truncateText: function (text, length, addEllipsis = true) {
    if (text && text.length && text.length > length) {
      text = text.substring(0, length)
      if (addEllipsis) {
        text = text + '...'
      }
    }
    return text
  },
  padStart: function (string, padBy) {
    return string.toString().padStart(padBy, '0')
  }
}

export default filters
