import Vue from 'vue'
import generateActions from '@/vue/store/utils/generateActions.vue.js'
import { moduleStatuses } from './schema.vue.js'
import { normalize } from 'normalizr'

const marketStatusesAdminEndpoint = (params) => {
  return '/api/admin/project_scenario_module_statuses'
}

const moduleStatusesMethodSchemas = {
  index: [moduleStatuses],
  create: moduleStatuses,
  update: moduleStatuses,
  destroy: moduleStatuses
}

export default {
  ...generateActions(
    'moduleStatusesAdminStore',
    'module_statuses',
    marketStatusesAdminEndpoint,
    moduleStatusesMethodSchemas
  ),

  MOVE_MODULE_STATUS ({ commit }, { id, direction }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.post(`/api/admin/project_scenario_module_statuses/${id}/move_item`, { move_direction: direction }).then(response => {
        commit('ADD_NORMALIZED_DATA', normalize(response.data, [moduleStatuses]))
        resolve(response)
      }, error => {
        reject(error)
      })
    })
  }
}
