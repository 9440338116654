<template>
  <v-navigation-drawer
    v-model="internalActiveState"
    nw-hook="sidebar"
    fixed
    temporary
    right
    width="400"
    class="sidebar overflow-y-hidden"
  >
    <v-card
      flat
      tile
      elevation="0"
    >
      <v-card-title
        class="sidebar__header pa-0 pl-2"
      >
        <v-container class="pa-0">
          <v-row
            align="center"
            no-gutters
          >
            <v-col cols="1">
              <v-icon
                v-tooltip="`Close ${title}`"
                class="sidebar__icon notranslate"
                @click.stop="$emit('close', null)"
              >
                chevron_left
              </v-icon>
            </v-col>
            <v-col
              class="text-subtitle-1 truncate-text-to-1"
              cols="11"
            >
              {{ title }}
            </v-col>
          </v-row>
        </v-container>
      </v-card-title>
      <v-card-text
        class="sidebar__content overflow-y-auto pa-0"
      >
        <slot />
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>
<script>
export default {
  name: 'Sidebar',
  props: {
    active: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  computed: {
    internalActiveState: {
      get () {
        return this.active
      },
      set (value) {
        if (!value) {
          this.$emit('close', null)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebar {
  &__icon {
    color: $light-alpha !important;
  }

  &__header {
    background-color: $highlight-beta;
    color: $light-alpha;
    height: 43px;
  }

  &__content {
    height: calc(100vh - 43px);
    background-color: $light-alpha;
  }
}
</style>
