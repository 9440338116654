import Vue from 'vue'
import { normalize } from 'normalizr'
import {
  busiestHours,
  recentProjects,
  mostViewedProjects,
  trialsEndingCompanies,
  mostRecentUserEvents
} from './schema.vue.js'

export default {
  LOAD_BUSIEST_HOURS ({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.get('/api/admin/systems/get_previous_days_busiest_hours').then(response => {
        const normalized = normalize(response.data, [busiestHours])
        commit('ADD_NORMALIZED_DATA', normalized)
        resolve(normalized)
      }, error => {
        reject(error)
      })
    })
  },
  LOAD_RECENT_PROJECTS ({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.get('/api/admin/project_analytics/get_recent_projects_activity').then(response => {
        const normalized = normalize(response.data, [recentProjects])
        commit('ADD_NORMALIZED_DATA', normalized)
        resolve(normalized)
      }, error => {
        reject(error)
      })
    })
  },

  LOAD_MOST_VIEWED_PROJECTS ({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.get('/api/admin/project_analytics/get_most_viewed_projects').then(response => {
        const normalized = normalize(response.data, [mostViewedProjects])
        commit('ADD_NORMALIZED_DATA', normalized)
        resolve(normalized)
      }, error => {
        reject(error)
      })
    })
  },

  LOAD_TRIAL_ENDING_COMPANIES ({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.get('/api/admin/companies/get_trial_ending_soon_companies').then(response => {
        const normalized = normalize(response.data, [trialsEndingCompanies])
        commit('ADD_NORMALIZED_DATA', normalized)
        resolve(normalized)
      }, error => {
        reject(error)
      })
    })
  },

  LOAD_MOST_RECENT_USER_EVENTS ({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.get('/api/admin/analytics/most_recent_user_events').then(response => {
        const normalized = normalize(response.data, [mostRecentUserEvents])
        commit('ADD_NORMALIZED_DATA', normalized)
        resolve(normalized)
      }, error => {
        reject(error)
      })
    })
  }
}
