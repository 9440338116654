import generateIndexes from '@/vue/store/utils/generateIndexes.vue.js'
import { entityValues } from './schema.vue.js'

const staticEntityValuesIndexProperties = [
  'entity_attribute_id',
  'module_id',
  'project_id'
]

const dynamicEntityValuesIndexProperties = [
  'tag_ids',
  'stakeholder_ids',
  'product_and_service_item_ids',
  'data_catalog_detail_entity_value_ids'
]

export const indexes = {
  [entityValues.key]: generateIndexes(staticEntityValuesIndexProperties, dynamicEntityValuesIndexProperties)
}
