import _ from 'lodash'
import * as joint from '@clientio/rappid'
import { VisioShape } from '@clientio/rappid-visio'
import { convertVisioShapeToRappidAttributes, convertVisioGroupToRappidAttributes } from '@/libs/rappidJointJs/build/package-visio/src/display/shape.mjs'
 
Object.assign(joint.dia.Cell.prototype, {
    getTitle: function () {
        return this.prop(this.TEXT_ATTR_PATH) || ''
    }
})
 
Object.assign(VisioShape.prototype, {
    toElementAttributes: function (opts) {
      const origin = this.getPageOrigin()
      let attributes
    
      switch (this.type) {
        case 'Group': {
          attributes = convertVisioGroupToRappidAttributes(this, {
            origin: origin,
            isRoot: true
          }, opts)
          break
        }
        case 'Shape':
        case 'Foreign': {
          attributes = convertVisioShapeToRappidAttributes(this, {
            origin: origin,
            isRoot: true
          }, opts)
          break
        }
        default: { return null }
      }
    
      attributes.z = this.getPageZIndex()
      return attributes
    }
  })
 
/* Patches to fix incorrect id matchups in jointjs selection. Hopefully be fixed in an update soon. Keep an eye out */
 
Object.assign(joint.ui.Selection.prototype, {
  createSelectionBox: function(element) {
    var elementView = element.findView(this.options.paper);
    if (elementView) {
        var viewBBox = elementView.getBBox({ useModelGeometry: this.options.useModelGeometry });
        const box = document.createElement("div") 
        box.classList.add("selection-box")        
        box.setAttribute("data-model", element.get(element.getIdAttribute()))
        box.setAttribute("style", `left:${viewBBox.x}px;top:${ viewBBox.y}px;width:${viewBBox.width}px; height:${viewBBox.height}px`)  
        this.el.appendChild(box)
        this.showSelected();
        this._boxCount++;
    }
  },
  destroySelectionBox: function(element) {
 
    this.$('[data-model="' + element.get(element.getIdAttribute()) + '"]').remove();
 
    if (this.$el.children('.selection-box').length === 0) {
        this.hide();
    }
 
    this._boxCount = Math.max(0, this._boxCount - 1);
},
})