import actions from './systemAdminStore/actions.vue.js'
import getters from './systemAdminStore/getters.vue.js'
import mutations from './systemAdminStore/mutations.vue.js'
import initialState from './systemAdminStore/initialState.vue.js'

export default {
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions
}
