import DataObject from '@/vue/shared/plugins/jointjsVuePlugin/shapes/businessProcess/dataObject.vue.js'
import Group from '@/vue/shared/plugins/jointjsVuePlugin/shapes/businessProcess/group.vue.js'
import Annotation from '@/vue/shared/plugins/jointjsVuePlugin/shapes/businessProcess/annotation.vue.js'
import DataStore from '@/vue/shared/plugins/jointjsVuePlugin/shapes/businessProcess/dataStore.vue.js'
import Message from '@/vue/shared/plugins/jointjsVuePlugin/shapes/businessProcess/message.vue.js'
import Activity from '@/vue/shared/plugins/jointjsVuePlugin/shapes/businessProcess/activity.vue.js'

import { types } from '@clientio/rappid-visio'

const { VisioSectionType } = types
const FONT_SIZE = 13

export default function (vsdShape) {
  let element

  const data = vsdShape.getComputedSection(VisioSectionType.Property)
  const masterShape = vsdShape.getMaster()
  const masterShapeName = masterShape.name.toLowerCase()

  switch (masterShapeName) {
    case 'data object': {
      element = new DataObject()
      const collection = data.getProperty('BpmnCollection') // 0 or 1
      if (collection === '1') {
        element.prop('collection', true)
      }
      element.attr('label/fill', vsdShape.getComputedSection(VisioSectionType.Character).rows[0].cells.color)
      element.attr('body/fill', vsdShape.cells.fillForegnd)
      element.attr('body/stroke', vsdShape.cells.lineColor)
      break
    }
    case 'group': {
      element = new Group()
      element.attr('label/fill', vsdShape.getComputedSection(VisioSectionType.Character).rows[0].cells.color)
      element.attr('body/fill', vsdShape.cells.fillForegnd)
      element.attr('body/fillOpacity', vsdShape.cells.fillForegndTrans)
      element.attr('body/stroke', vsdShape.cells.lineColor)
      break
    }
    case 'text annotation': {
      element = new Annotation()
      element.attr('label/fill', vsdShape.getComputedSection(VisioSectionType.Character).rows[0].cells.color)
      element.attr('border/stroke', vsdShape.cells.lineColor)
      break
    }
    case 'data store': {
      element = new DataStore()
      element.attr('label/fill', vsdShape.getComputedSection(VisioSectionType.Character).rows[0].cells.color)
      element.attr('body/fill', vsdShape.cells.fillForegnd)
      element.attr('body/stroke', vsdShape.cells.lineColor)
      element.attr('top/fill', vsdShape.cells.fillForegnd)
      element.attr('top/stroke', vsdShape.cells.lineColor)
      break
    }
    case 'message': {
      element = new Message()
      const name = data.getProperty('BpmnName')
      if (name) {
        element.attr('.label/text', name)
        element.attr('.label/fontSize', FONT_SIZE)
      }
      element.attr('.label/fill', vsdShape.getComputedSection(VisioSectionType.Character).rows[0].cells.color)
      element.attr('.body/fill', vsdShape.cells.fillForegnd)
      element.attr('.body/stroke', vsdShape.cells.lineColor)
      break
    }
    default: {
      element = new Activity()
    }
  }

  const lane = data.getProperty('Function')
  if (lane) {
    element.prop({ lane })
  }

  // Element Name
  const name = data.getProperty('BpmnName')
  if (name) {
    element.attr('label/text', name)
    element.attr('label/fontSize', FONT_SIZE)
  }

  // Size & Position
  const { x, y, width, height } = vsdShape.getPageBBox()
  element.set({
    position: { x, y },
    size: { width, height },
    z: 2
  })

  return element
}
