import generateActions from '@/vue/store/utils/generateActions.vue.js'
import { stakeholderPerspectiveEcoRoles } from './schema.vue.js'
import Vue from 'vue'
import { normalize } from 'normalizr'

const stakeholderPerspectiveEcoRolesAdminEndpoint = (params) => {
  return '/api/admin/ecosystem_roles'
}

const stakeholderPerspectiveEcoRolesMethodSchemas = {
  index: [stakeholderPerspectiveEcoRoles],
  create: stakeholderPerspectiveEcoRoles,
  update: stakeholderPerspectiveEcoRoles,
  destroy: stakeholderPerspectiveEcoRoles
}

export default {
  ...generateActions(
    'stakeholderPerspectiveEcoRolesAdminStore',
    'stakeholder_perspective_eco_role',
    stakeholderPerspectiveEcoRolesAdminEndpoint,
    stakeholderPerspectiveEcoRolesMethodSchemas
  ),
  MOVE_STAKEHOLDER_PERSPECTIVE_ECO_ROLE ({ commit }, { params }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.post(`${stakeholderPerspectiveEcoRolesAdminEndpoint()}/${params.id}/move_item.json`, { move_direction: params.direction })
        .then(response => {
          const normalized = normalize(response.data, [stakeholderPerspectiveEcoRoles])
          commit('ADD_NORMALIZED_DATA', normalized)
          resolve(normalized)
        }, error => {
          reject(error)
        })
    })
  }
}
