import * as joint from '@clientio/rappid'
import _ from 'lodash'
import BusinessProcessElement from '../base/baseBusinessProcessElement.vue.js'

const Annotation = BusinessProcessElement.extend({
  initialize: function () {
    this.on('change:orientation', (cell, value) => {
      this.updateOrientation(value)
    })
    BusinessProcessElement.prototype.initialize.apply(this, arguments)
  },
  updateElementDefaultColors: function (options) {
    this.attr({
      border: {
        stroke: options.default_stroke_colour
      },
      body: {
        fill: options.default_fill_colour
      }
    })
    this.attr({
      label: {
        fill: options.default_text_colour
      }
    })
  },
  updateOrientation (orientation) {
    this.removeAttr('label/textAnchor')
    this.removeAttr('label/refX')
    this.removeAttr('label/refX2')
    this.removeAttr('label/textVerticalAnchor')
    this.removeAttr('label/refY')
    this.removeAttr('label/refY2')
    switch (orientation) {
      case 'top': {
        this.attr('border/annotationD/side', 'top')
        this.attr('label/textAnchor', 'middle')
        this.attr('label/refX', '50%')
        this.attr('label/refY', '5')
        break
      }
      case 'right': {
        this.attr('border/annotationD/side', 'right')
        this.attr('label/textAnchor', 'end')
        this.attr('label/refX', '100%')
        this.attr('label/refY', '10')
        this.attr('label/refX2', '-4')
        break
      }
      case 'bottom': {
        this.attr('border/annotationD/side', 'bottom')
        this.attr('label/textAnchor', 'middle')
        this.attr('label/textVerticalAnchor', 'bottom')
        this.attr('label/refX', '50%')
        this.attr('label/refY', '100%')
        this.attr('label/refY2', '-10')
        break
      }
      case 'left':
      default: {
        this.attr('border/annotationD/side', 'left')
        this.attr('label/textAnchor', 'start')
        this.attr('label/refY', '10')
        this.attr('label/refX', '4')
        break
      }
    }
  },
  markup: joint.shapes.bpmn2.Annotation.prototype.markup,
  defaults: _.defaultsDeep({
    size: { width: 30, height: 25 },
    shape: 'Annotation',
    type: 'businessProcess.Annotation',
    attrs: {
      body: {
        fill: '#ffffff',
        strokeWidth: 0,
        fillOpacity: 1
      },
      border: {
        stroke: '#000000'
      },
      label: {
        text: 'Annotation',
        textWrap: {
          width: -10,
          height: null
        }
      }
    }
  }, joint.shapes.bpmn2.Annotation.prototype.defaults)
})
Annotation.attributes = joint.shapes.bpmn2.Annotation.attributes
export default Annotation
