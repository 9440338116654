import Vue from 'vue'
import { normalize } from 'normalizr'
import generateActions from '@/vue/store/utils/generateActions.vue.js'
import { markets } from './schema.vue.js'

const marketsAdministrationEndpoint = (params) => {
  return '/api/admin/markets'
}

const marketsAdministrationSchemas = {
  index: [markets],
  create: markets,
  update: markets,
  destroy: markets
}

export default {
  ...generateActions(
    'marketsAdministrationStore',
    'markets',
    marketsAdministrationEndpoint,
    marketsAdministrationSchemas
  ),

  MOVE_MARKETS ({ commit }, { id, direction }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.post(`/api/admin/markets/${id}/move_item`, { move_direction: direction }).then(response => {
        commit('ADD_NORMALIZED_DATA', normalize(response.data, [markets]))
        resolve(response)
      }, error => {
        reject(error)
      })
    })
  }
}
