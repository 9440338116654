import _ from 'lodash'
import Vue from 'vue'
import { normalize } from 'normalizr'
import generateActions from '@/vue/store/utils/generateActions.vue.js'
import { checklistItems, links } from './schema.vue.js'

const checklistEndpoint = (params) => {
  return `/api/projects/${params.projectId}/project_scenario_modules/${params.moduleId}/checklist_items`
}

const linksEndpoint = () => {
  return '/api/links'
}

const methodSchemas = {
  index: [checklistItems],
  create: checklistItems,
  update: checklistItems,
  update_positions: [checklistItems],
  destroy: checklistItems
}

const linkSchemas = {
  update: links,
  destroy: links,
  index: [links]
}

export default {
  ...generateActions(
    'checklistStore',
    'checklist_item',
    checklistEndpoint,
    methodSchemas
  ),
  ...generateActions(
    'checklistStore',
    'link',
    linksEndpoint,
    linkSchemas
  ),
  EXPORT_CHECKLISTS ({ commit, dispatch }, { params, exportType }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .get(
          `${checklistEndpoint(params)}/export.${exportType}`, {
            responseType: 'blob'
          })
        .then(
          response => {
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
    })
  },
  UPLOAD_CHECKLIST_CSV ({ commit, dispatch, state }, { params, payload }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .post(
          `${checklistEndpoint(params)}/import_upload.json`,
          payload
        )
        .then(
          response => {
            commit('ADD_NORMALIZED_DATA', normalize(response.data.default, [checklistItems]))
            dispatch('stakeholdersStore/ADD_STAKEHOLDERS', response.data.stakeholders, { root: true })
            dispatch('tagsStore/ADD_TAGS', response.data.tags, { root: true })
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
    })
  },
  CREATE_DEFAULT_CHECKLIST_ITEM ({ dispatch, state }, { params }) {
    const highest = _.maxBy(_.values(state.checklistItems), 'position')
    const position = _.get(highest, 'position', -1) + 1
    return dispatch('CREATE_CHECKLIST_ITEM', {
      params,
      payload: {
        name: 'Untitled',
        stakeholder_ids: [],
        description: '',
        link_title: '',
        link_url: '',
        status: '',
        position: position
      }
    })
  },
  UPDATE_LOCAL_CHECKLIST_ITEMS ({ commit, state }, payload) {
    commit('ADD_NORMALIZED_DATA', normalize(payload, [checklistItems]))
    return new Promise((resolve) => resolve())
  },
  CREATE_LINK ({ commit, dispatch, getters }, { payload }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .post('/api/links.json', payload)
        .then(
          response => {
            commit('ADD_NORMALIZED_DATA', normalize(response.data, links))
            const checklistItem = getters.checklistItemsById(response.data.linkable_id)
            const normalized = normalize({
              id: checklistItem.id,
              links: _.concat(checklistItem.links, [response.data.id])
            }, checklistItems)
            commit('ADD_NORMALIZED_DATA', normalized)
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
    })
  }
}
