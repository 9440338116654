import Activity from '@/vue/shared/plugins/jointjsVuePlugin/shapes/businessProcess/activity.vue.js'

import { types } from '@clientio/rappid-visio'

const { VisioSectionType } = types
const FONT_SIZE = 13
const ICON_TYPES = {
  receive: 'receive',
  service: 'service',
  send: 'send',
  manual: 'manual',
  user: 'user',
  none: 'none',
  script: 'script',
  'business rule': 'business-rule',
  'instantiating receive': 'receive'

}

export default function (vsdShape) {
  const data = vsdShape.getComputedSection(VisioSectionType.Property)
  const element = new Activity()
  const boundaryType = data.getProperty('BpmnBoundaryType')
  const activityType = data.getProperty('BpmnTaskType')
  const isCollapsed = data.getProperty('BpmnIsCollapsed') === '1'

  // markers
  const markers = []
  const loopType = data.getProperty('BpmnLoopType')
  const isAdHoc = data.getProperty('BpmnAdHoc') === '1'
  const isForCompensation = data.getProperty('BpmnIsForCompensation') === '1'

  // Styling
  element.attr('label/fill', vsdShape.getComputedSection(VisioSectionType.Character).rows[0].cells.color)
  element.attr('background/fill', vsdShape.cells.fillForegnd)
  element.attr('border/stroke', vsdShape.cells.lineColor)

  // task icon
  if (!isCollapsed && activityType) {
    const icon = activityType.toLowerCase()
    const iconType = ICON_TYPES[icon]
    element.prop('icon_type', iconType)
  }

  // markers
  switch (loopType) {
    case 'Standard': {
      markers.push('loop')
      break
    }
    case 'Parallel MultiInstance': {
      markers.push('parallel')
      break
    }
    case 'Sequential MultiInstance': {
      markers.push('sequential')
      break
    }
  }

  if (isForCompensation) {
    markers.push('compensation')
  }
  if (isCollapsed) {
    markers.push('sub-process')
  }
  if (isAdHoc) {
    markers.push('ad-hoc')
  }

  element.prop('markers', markers)

  switch (boundaryType) {
    case 'Call': {
      element.prop('activity_type', 'thick')
      break
    }
    case 'Transaction': {
      element.prop('activity_type', 'double')
      break
    }
    case 'Event': {
      element.prop('activity_type', 'single')
      break
    }
    case 'Default':
    default: {
      element.prop('activity_type', 'single')
      break
    }
  }
  const lane = data.getProperty('Function')
  if (lane) {
    element.prop({ lane })
  }

  // Element Name
  const name = data.getProperty('BpmnName')
  if (name) {
    element.attr('label/text', name)
    element.attr('label/fontSize', FONT_SIZE)
  }

  // Size & Position
  const { x, y, width, height } = vsdShape.getPageBBox()
  element.set({
    position: { x, y },
    size: { width, height },
    z: 2
  })

  return element
}
