
import generateGetters from '@/vue/store/utils/generateGetters.vue.js'
import {
  dataMaps,
  dataMapCatalogPosition,
  dataMapDetailMappings,
  dataMapCategories,
  dataMapCategoryMappings,
  dataMapCatalogs,
  userPermissions,
  rolePermissions
} from './schema.vue.js'
import { indexes } from './indexes.vue.js'
import _ from 'lodash'

export default {
  ...generateGetters(dataMaps),
  ...generateGetters(dataMapCatalogs, indexes),
  ...generateGetters(dataMapCatalogPosition, indexes),
  ...generateGetters(dataMapDetailMappings, indexes),
  ...generateGetters(dataMapCategories, indexes),
  ...generateGetters(dataMapCategoryMappings, indexes),
  ...generateGetters(userPermissions, indexes),
  ...generateGetters(rolePermissions, indexes),

  userDisplayPermissionByDataMapId: (state) => (dataMapId) => {
    let userPerm = {}
    const dataMapUserPermissions = _.filter(state.userPermissions, { data_map_id: dataMapId })
    const dataMapRolePermissions = _.filter(state.rolePermissions, { data_map_id: dataMapId })
    const permLookUp = {
      read: 1,
      write: 2,
      admin: 3
    }
    userPerm = _.reduce(dataMapUserPermissions, (obj, value) => {
      obj[value.user_id] = value.permission_key
      return obj
    }, userPerm)

    userPerm = _.reduce(dataMapRolePermissions, (obj, value) => {
      _.each(value.user_ids, (id) => {
        if (obj[id]) {
          if (permLookUp[value.permission_key] > permLookUp[obj[id]]) {
            obj[id] = value.permission_key
          }
        } else {
          obj[id] = value.permission_key
        }
      })
      return obj
    }, userPerm)

    return userPerm
  },

  rolePermissionsByDataMapId: (state) => (dataMapId) => {
    return _.filter(state.rolePermissions, { data_map_id: dataMapId })
  },
  userPermissionsByDataMapId: (state) => (dataMapId) => {
    return _.filter(state.userPermissions, { data_map_id: dataMapId })
  }
}
