import generateActions from '@/vue/store/utils/generateActions.vue.js'
import { system, systemCompanies, systemEmailTemplates } from './schema.vue.js'
import Vue from 'vue'
import { normalize } from 'normalizr'

const systemAdminCompaniesAdminEndpoint = (params) => {
  return '/api/admin/companies/quick_index'
}

const systemAdminCompaniesMethodSchemas = {
  index: [systemCompanies]
}

export default {

  ...generateActions(
    'systemAdminStore',
    'system_companies',
    systemAdminCompaniesAdminEndpoint,
    systemAdminCompaniesMethodSchemas
  ),

  LOAD_SYSTEM ({ state, commit }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .get('/api/admin/systems.json')
        .then(
          response => {
            commit('ADD_NORMALIZED_DATA', normalize(response.data, system))
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
    })
  },
  LOAD_EMAIL_TEMPLATE ({ state, commit }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .get('/api/admin/email_templates.json')
        .then(
          response => {
            commit('ADD_NORMALIZED_DATA', normalize(response.data, systemEmailTemplates))
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
    })
  },
  UPLOAD_IMAGE ({ state, commit }, file) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .post('/api/admin/systems/upload_image.json', file)
        .then(
          response => {
            commit('ADD_NORMALIZED_DATA', normalize(response.data, system))
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
    })
  },
  UPDATE_SYSTEM_EMAIL_TEMPLATES ({ state, commit }, template) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .put('/api/admin/email_templates.json', template)
        .then(
          response => {
            commit('ADD_NORMALIZED_DATA', normalize(response.data, systemEmailTemplates))
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
    })
  },
  UPDATE_SYSTEM_SETTINGS ({ state, commit }, template) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .put('/api/admin/systems.json', template)
        .then(
          response => {
            commit('ADD_NORMALIZED_DATA', normalize(response.data, system))
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
    })
  }
}
