import generateIndexes from '@/vue/store/utils/generateIndexes.vue.js'
import { entityAttributes } from './schema.vue.js'

const staticEntityAttributesIndexProperties = [
  'module_type_id',
  'key'
]

export const indexes = {
  [entityAttributes.key]: generateIndexes(staticEntityAttributesIndexProperties)
}
