import generateActions from '@/vue/store/utils/generateActions.vue.js'
import { releaseNotes, releaseNoteImages } from './schema.vue.js'

const releaseNotesAdminEndpoint = (params) => {
  return '/api/admin/release_notes'
}

const releaseNoteImagesAdminEndpoint = (params) => {
  return `/api/admin/release_notes/${params.release_note_id}/release_note_images`
}

const releaseNotesMethodSchemas = {
  index: [releaseNotes],
  get: releaseNotes,
  create: releaseNotes,
  update: releaseNotes,
  destroy: releaseNotes
}

const releaseNoteImagesMethodSchemas = {
  index: [releaseNoteImages],
  create: releaseNoteImages,
  destroy: releaseNoteImages
}

export default {
  ...generateActions(
    'releaseNotesAdminStore',
    'release_note',
    releaseNotesAdminEndpoint,
    releaseNotesMethodSchemas
  ),
  ...generateActions(
    'releaseNotesAdminStore',
    'release_note_image',
    releaseNoteImagesAdminEndpoint,
    releaseNoteImagesMethodSchemas
  )
}
