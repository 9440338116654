import generateActions from '@/vue/store/utils/generateActions.vue.js'
import { users, roles, emailTemplates, usersWithResultCount } from './schema.vue.js'
import Vue from 'vue'
import { normalize } from 'normalizr'

const usersAdminEndpoint = (params) => {
  return '/api/admin/users'
}

const rolesAdminEndpoint = (params) => {
  return '/api/admin/roles'
}

const userByIdAdminEndpoint = (params) => {
  return `/api/admin/users/${params.userId}`
}

const usersMethodSchemas = {
  index: usersWithResultCount,
  quick_index: [users],
  get: users,
  create: users,
  update: users,
  destroy: users
}

const rolesMethodSchemas = {
  index: [roles]
}

export default {
  ...generateActions(
    'usersAdminStore',
    'user',
    usersAdminEndpoint,
    usersMethodSchemas
  ),
  ...generateActions(
    'usersAdminStore',
    'role',
    rolesAdminEndpoint,
    rolesMethodSchemas
  ),
  UPDATE_USER_LOGO ({ commit }, { params, payload }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.post(`${userByIdAdminEndpoint(params)}/upload_profile_image.json`, payload)
        .then(response => {
          const normalized = normalize(response.data, users)
          commit('ADD_NORMALIZED_DATA', normalized)
          resolve(normalized)
        }, error => {
          reject(error)
        })
    })
  },
  REMOVE_USER_LOGO ({ commit }, { params }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.delete(`${userByIdAdminEndpoint(params)}/remove_profile_image.json`)
        .then(response => {
          const normalized = normalize(response.data, users)
          commit('ADD_NORMALIZED_DATA', normalized)
          resolve(normalized)
        }, error => {
          reject(error)
        })
    })
  },
  RESEND_INVITE ({ commit }, { params }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .post(`${userByIdAdminEndpoint(params)}/resend_invitation.json`)
        .then(
          response => {
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
    })
  },
  LOAD_EMAIL_TEMPLATE ({ state, commit }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .get('/api/admin/email_templates.json')
        .then(
          response => {
            commit('ADD_NORMALIZED_DATA', normalize(response.data, emailTemplates))
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
    })
  }
}
