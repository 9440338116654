import generateIndexes from '@/vue/store/utils/generateIndexes.vue.js'
import {
  stakeholders,
  stakeholderRoles
} from './schema.vue.js'

const staticStakeholderIndexProperties = ['project_id']
const dynamicStakeholderIndexProps = ['stakeholder_role_ids']
const staticStakeholderRolesIndexProperties = ['project_id']

export const indexes = {
  [stakeholders.key]: generateIndexes(staticStakeholderIndexProperties, dynamicStakeholderIndexProps),
  [stakeholderRoles.key]: generateIndexes(staticStakeholderRolesIndexProperties)
}
