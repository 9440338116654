import actions from './dataInterfaceStore/actions.vue.js'
import getters from './dataInterfaceStore/getters.vue.js'
import mutations from './dataInterfaceStore/mutations.vue.js'
import initialState from './dataInterfaceStore/initialState.vue.js'

export default {
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions
}
