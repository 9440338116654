import _ from 'lodash'
import { _globalMutes } from '../../mutation-types.vue'
export default {
  [_globalMutes.SET_X_SESSION_TOKEN] (state, token) {
    state.xSessionToken = token
    state.xSession = true
  },
  [_globalMutes.CLEAR_X_SESSION_TOKEN] (state) {
    state.xSessionToken = null
    state.xSession = false
  },
  SET_ACCESS_TOKEN (state, token) {
    state.accessToken = token
  },
  [_globalMutes.BEGIN_UPLOAD_REQUEST] (state) {
    state.uploadRequestsInProgress = state.uploadRequestsInProgress + 1
  },
  [_globalMutes.RESOLVE_UPLOAD_REQUEST] (state) {
    state.uploadRequestsInProgress = state.uploadRequestsInProgress - 1
  },
  [_globalMutes.BEGIN_DOWNLOAD_REQUEST] (state) {
    state.downloadRequestsInProgress = state.downloadRequestsInProgress + 1
  },
  [_globalMutes.RESOLVE_DOWNLOAD_REQUEST] (state) {
    state.downloadRequestsInProgress = state.downloadRequestsInProgress - 1
  },
  ADD_TOKEN_SUBSCRIBER (state, fn) {
    state.tokenSubscribers.push(fn)
  },
  CLEAR_TOKEN_SUBSCRIBERS (state) {
    state.tokenSubscribers = []
  },
  SET_ITEM_PARAMS (state, params) {
    state.itemParams = params
  },
  CLEAR_ITEM_PARAMS (state) {
    state.itemParams = null
  },
  [_globalMutes.SHOW_SUCCESS] (state, msg) {
    state.successMessage = msg
    state.successSnackbar = true
  },
  [_globalMutes.HIDE_SUCCESS] (state) {
    state.successMessage = ''
    state.successSnackbar = false
  },
  [_globalMutes.SHOW_UPDATE_NOTIFICATION] (state) {
    state.showUpdateAvailable = true
  },
  [_globalMutes.HIDE_UPDATE_NOTIFICATION] (state) {
    state.showUpdateAvailable = false
  },
  [_globalMutes.SET_HEADER_BAR_TITLE] (state, value) {
    state.headerBarTitle = value
  },
  [_globalMutes.INCREMENT_REQUEST_COUNT] (state, key) {
    if (!_.has(state.requestCounter, key)) {
      state.requestCounter[key] = 0
    }
    state.requestCounter[key]++
  },
  [_globalMutes.DECREMENT_REQUEST_COUNT] (state, key) {
    if (state.requestCounter[key] > 0) {
      state.requestCounter[key]--
    }
  },
  [_globalMutes.SET_RELEASE_NOTES_VISIBLE] (state, value) {
    state.releaseNotesVisible = value
  },
  [_globalMutes.SET_ALERT] (state, { alertId, alertText }) {
    state.alertId = alertId
    state.alertText = alertText
  },
  [_globalMutes.CLEAR_ALERT] (state) {
    state.alertId = null
    state.alertText = null
  },
  [_globalMutes.CLOSE_ALERT] (state) {
    state.closedAlertIds.push(state.alertId)
    state.alertId = null
    state.alertText = null
  }
}
