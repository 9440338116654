const initialState = () => {
  return {
    selectedView: 'categories',
    previouslySelectedViews: [],
    searchString: '',
    searchMessage: '',
    selectedCmUserStoryId: '',
    focusedCmUserStoryId: null,
    selectedCmIterationId: '',
    focusedCmIterationId: null,
    selectedCmCategoryId: '',
    focusedCmCategoryId: null,
    cmCategorysLoaded: false,
    cmIterationsLoaded: false,
    cmUserStoryItemsLoaded: false
  }
}
export default initialState
