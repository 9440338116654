import _ from 'lodash'
import Vue from 'vue'
import { normalize } from 'normalizr'
import { imageViewers } from './schema.vue.js'

const imageViewersEndpoint = params => (
  `/api/projects/${params.projectId}/project_scenario_modules/${params.moduleId}/image_viewer`
)

export default {
  LOAD_IMAGE_VIEWER ({ commit }, { params }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.get(imageViewersEndpoint(params)).then(response => {
        commit('ADD_NORMALIZED_DATA', normalize(response.data, imageViewers))
        resolve(response)
      }, error => {
        reject(error)
      })
    })
  },
  UPDATE_IMAGE_VIEWER ({ commit }, { params, payload }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.put(imageViewersEndpoint(params), payload).then(response => {
        const normalized = normalize(response.data, imageViewers)
        commit('ADD_NORMALIZED_DATA', normalized)
        resolve(normalized)
      }, error => {
        reject(error)
      })
    })
  },
  UPDATE_IMAGE_VIEWER_PROPERTIES ({ commit, dispatch }, { params, payload }) {
    if (!_.has(payload, 'id')) { return }
    commit('ADD_NORMALIZED_DATA', normalize(payload, imageViewers))
    return dispatch('UPDATE_IMAGE_VIEWER', { params, payload })
  },
  EXPORT_IMAGE_VIEWER ({ commit, dispatch }, { params, exportType }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .get(
          `${imageViewersEndpoint(params)}/export.${exportType}`, {
            responseType: 'blob'
          })
        .then(
          response => {
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
    })
  }
}
