import _ from 'lodash'
import initialState from './initialState.vue.js'

export default {
  RESET_STATE (state) {
    _.assign(state, initialState())
  },
  SET_SELECTED_DETAIL_ID (state, id) {
    state.selectedDetailId = id
  },
  SET_OPEN_PANEL_SECTION (state, panel) {
    state.openPanelSection = panel
  },
  SET_SELECTED_CATALOG_ID (state, msg) {
    state.selectedCatalogId = msg
  },
  SET_COLOUR_FILTER (state, value) {
    state.dataInterfaceItemColourFilter = value
  },
  SET_VIEW_MODE (state, viewMode) {
    state.viewMode = viewMode
    state.loadedViews = _.union(state.loadedViews, [viewMode])
  },
  SET_ANIMATING (state, animating) {
    state.animating = animating
  },
  RESET_COLOUR_FILTER (state) {
    state.dataInterfaceItemColourFilter = initialState().dataInterfaceItemColourFilter
  },
  SET_PATH_TO_ITEM (state, pathIds) {
    state.pathIds = pathIds
  },
  CLEAR_SELECTED_ITEM_ID (state) {
    state.selectedDetailId = null
  },
  CLEAR_PATH (state) {
    state.pathIds = []
  },
  ANIMATE_DETAIL (state, detail) {
    state.animate_detail = detail
  },
  CLEAR_ANIMATE_DETAIL (state) {
    state.animate_detail = null
  },
  SET_LOADING (state, loading) {
    state.loading = loading
  }
}
