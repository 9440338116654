import _ from 'lodash'
import initialState from './initialState.vue.js'

export default {
  RESET_STATE (state) {
    _.assign(state, initialState())
  },
  SET_UPLOAD_DIALOG_VISIBLE (state, value) {
    state.uploadDialogVisible = value
  }
}
