import Event from '@/vue/shared/plugins/jointjsVuePlugin/shapes/businessProcess/event.vue.js'
import { types } from '@clientio/rappid-visio'
const { VisioSectionType } = types

const ICON_TYPES = {
  none: 'none',
  message: 'message1',
  terminate: 'termination1',
  timer: 'timer1',
  error: 'error1',
  cancel: 'cancel1',
  compensation: 'compensation1',
  conditional: 'conditional1',
  link: 'link1',
  signal: 'signal1',
  multiple: 'multiple1',
  escalation: 'escalation1',
  'parallel multiple': 'parallel1'
}
const ICON_TYPES2 = {
  none: 'none',
  message: 'message2',
  terminate: 'termination2',
  timer: 'timer2',
  error: 'error2',
  cancel: 'cancel2',
  compensation: 'compensation2',
  conditional: 'conditional2',
  link: 'link2',
  signal: 'signal2',
  multiple: 'multiple2',
  escalation: 'escalation2',
  'parallel multiple': 'parallel2'
}
const FONT_SIZE = 13
export default function (vsdShape) {
  const data = vsdShape.getComputedSection(VisioSectionType.Property)
  const element = new Event()
  const eventType = data.getProperty('BpmnEventType')
  const icon = (data.getProperty('BpmnTriggerOrResult') || 'none').toLowerCase()
  const iconType = ICON_TYPES[icon]
  const iconType2 = ICON_TYPES2[icon]

  const lane = data.getProperty('Function')
  if (lane) {
    element.prop({ lane })
  }

  // Element Name
  const name = data.getProperty('BpmnName')
  if (name) {
    element.attr('label/text', name)
    element.attr('label/fontSize', FONT_SIZE)
  }

  // Size & Position
  const { x, y, width, height } = vsdShape.getPageBBox()
  element.set({
    position: { x, y },
    size: { width, height },
    z: 2
  })
  switch (eventType) {
    case 'Start': {
      element.prop('event_type', 'single')
      element.prop('icon_type', iconType)
      break
    }
    case 'Start (Non-Interrupting)': {
      element.prop('event_type', 'single')
      element.prop('interrupting', 'dashed')
      element.prop('icon_type', iconType)
      break
    }
    case 'Intermediate': {
      element.prop('event_type', 'double')
      element.prop('icon_type', iconType)
      break
    }
    case 'Intermediate (Non-Interrupting)': {
      element.prop('event_type', 'double')
      element.prop('interrupting', 'dashed')
      element.prop('icon_type', iconType)
      break
    }
    case 'Intermediate (Throwing)': {
      element.prop('event_type', 'double')
      element.prop('icon_type', iconType2)
      break
    }
    case 'End': {
      element.prop('event_type', 'thick')
      element.prop('icon_type', iconType2)
      break
    }
  }
  element.attr('label/fill', vsdShape.getComputedSection(VisioSectionType.Character).rows[0].cells.color)
  element.attr('border/stroke', vsdShape.cells.lineColor)

  return element
}
