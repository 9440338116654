import { schema } from 'normalizr'
export const links = new schema.Entity('links')
export const stakeholders = new schema.Entity('stakeholders', { links: [links] })
export const stakeholderRoles = new schema.Entity('stakeholderRoles')
export const stakeholderMapItems = new schema.Entity('stakeholderMapItems')
export const stakeholderAssignedItems = new schema.Entity('stakeholderAssignedItems')
export const uploadedCsvItems = new schema.Entity('uploadedCsvItems', {
  stakeholder_roles: [stakeholderRoles],
  stakeholders: [stakeholders]
})
