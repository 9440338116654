
import generateGetters from '@/vue/store/utils/generateGetters.vue.js'
import { users, roles, emailTemplates } from './schema.vue.js'
import _ from 'lodash'

export default {
  ...generateGetters(users, null),
  ...generateGetters(roles, null),
  ...generateGetters(emailTemplates, null),
  theEmailTemplate: (state) => (_.first(_.values(state.emailTemplates)))
}
