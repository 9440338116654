
import generateGetters from '@/vue/store/utils/generateGetters.vue.js'
import {
  companies,
  removedProjects,
  roles,
  users,
  emailTemplates
} from './schema.vue.js'

export default {
  ...generateGetters(companies),
  ...generateGetters(removedProjects),
  ...generateGetters(roles),
  ...generateGetters(users),
  ...generateGetters(emailTemplates)
}
