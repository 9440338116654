
import actions from './tagsStore/actions.vue.js'
import getters from './tagsStore/getters.vue.js'
import mutations from './tagsStore/mutations.vue.js'
import initialState from './tagsStore/initialState.vue.js'

export default {
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions
}
