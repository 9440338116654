const initialState = () => {
  return {
    projectsCache: {},
    viewMode: 'grid',
    orderColumn: 'last_event_date',
    orderDirection: 'DESC',
    searchString: ''
  }
}
export default initialState
