const initialState = () => {
  return {
    openPanelSection: 'properties',
    dataItem: null,
    selectedDetailId: null,
    selectedCatalogId: null,
    pathIds: [],
    viewMode: 'catalog',
    loadedViews: [],
    animating: false,
    colourFilterActive: false,
    dataInterfaceItemColourFilter: {
      tag_ids: [],
      tag_category_ids: [],
      tag_category_tag_ids: [],
      stakeholder_ids: [],
      stakeholder_role_ids: [],
      stakeholder_role_stakeholder_ids: [],
      product_and_service_item_ids: [],
      product_and_service_category_ids: [],
      product_and_service_category_item_ids: [],
      data_map_category_ids: [],
      data_catalog_ids: [],
      levels: [],
      operator: 'OR'
    },
    animate_detail: null,
    loading: false
  }
}
export default initialState
