
import generateGetters from '@/vue/store/utils/generateGetters.vue.js'
import {
  busiestHours,
  recentProjects,
  mostViewedProjects,
  trialsEndingCompanies,
  recentInviteUsers,
  mostRecentUserEvents
} from './schema.vue.js'

export default {
  ...generateGetters(busiestHours),
  ...generateGetters(recentProjects),
  ...generateGetters(mostViewedProjects),
  ...generateGetters(trialsEndingCompanies),
  ...generateGetters(recentInviteUsers),
  ...generateGetters(mostRecentUserEvents)
}
