import Vue from 'vue'
import { normalize } from 'normalizr'
import generateActions from '@/vue/store/utils/generateActions.vue.js'
import {
  tags,
  tagCategories,
  tagAssignedItems,
  tagMapItems
} from './schema.vue.js'

const tagsEndpoint = params => (`/api/projects/${params.projectId}/tags`)
const categoriesEndpoint = params => (`/api/projects/${params.projectId}/tag_categories`)
const tagMapItemsEndpoint = params => (`/api/projects/${params.projectId}/tag_map_items`)
const tagAssignedItemsEndpoint = params => (`/api/projects/${params.projectId}/tags/assigned_items_index`)

export default {
  ...generateActions(
    'tagsStore',
    'tag',
    tagsEndpoint,
    {
      index: [tags],
      create: tags,
      update: tags,
      destroy: tags,
      update_positions: [tags]
    }
  ),

  ...generateActions(
    'tagsStore',
    'tag_map_item',
    tagMapItemsEndpoint,
    {
      index: tagMapItems
    }
  ),
  ...generateActions(
    'tagsStore',
    'tag_category',
    categoriesEndpoint,
    {
      index: [tagCategories],
      create: tagCategories,
      update: tagCategories,
      update_positions: [tagCategories],
      destroy: tagCategories
    }
  ),
  ...generateActions(
    'tagsStore',
    'tag_assigned_item',
    tagAssignedItemsEndpoint,
    {
      index: [tagAssignedItems]
    }
  ),

  EXPORT_TAGS ({ commit, dispatch }, { params, exportType }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .get(
          `${tagsEndpoint(params)}/export.${exportType}`, {
            responseType: 'blob'
          })
        .then(
          response => {
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
    })
  },

  UPLOAD_TAG_CSV ({ commit }, { params, payload }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .post(
          `${tagsEndpoint(params)}/import_upload.json`,
          payload
        )
        .then(
          response => {
            commit('ADD_NORMALIZED_DATA', normalize(response.data, [tags]))
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
    })
  },

  ADD_TAGS ({ commit }, payload) {
    commit('ADD_NORMALIZED_DATA', normalize(payload, [tags]))
    return new Promise(resolve => resolve())
  },

  UPDATE_LOCAL_TAGS ({ commit }, payload) {
    commit('ADD_NORMALIZED_DATA', normalize(payload, [tags]))
    return new Promise(resolve => resolve())
  }
}
