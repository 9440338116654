import importEvent from './importEvent.vue'
import importArtifact from './importArtifact.vue'
import importActivity from './importActivity.vue'
import importGateway from './importGateway.vue'
import importPool from './importPool.vue'
import importLane from './importLane.vue'
import importFlow from './importFlow.vue'

export default {
  Event: importEvent,
  Artifact: importArtifact,
  Task: importActivity,
  'Sub-Process': importActivity,
  'Expanded Sub-Process': importActivity,
  Gateway: importGateway,
  Pool: importPool,
  Lane: importLane,
  Flow: importFlow

}
