import _ from 'lodash'
export default {
  SET_SELECTED_ELEMENTS (state, { treeName, type, elements }) {
    state.selectedElements = _.reject(state.selectedElements, { treeName })
    state.selectedElements = _.concat(state.selectedElements, _.map(elements, (element) => {
      return _.assign({}, element, { treeName, type })
    }))
  },
  SET_SELECTED_CATALOG_ID (state, { treeName, catalogId }) {
    state.selectedCatalogIds = _.reject(state.selectedCatalogIds, { treeName })
    state.selectedCatalogIds = _.concat(state.selectedCatalogIds, _.assign({}, { treeName, catalogId }))
  },
  CLEAR_SELECTED_CATALOG_ID (state, { treeName }) {
    state.selectedCatalogIds = _.reject(state.selectedCatalogIds, { treeName })
  },
  CLEAR_SELECTED_ELEMENTS (state) {
    state.selectedElements = []
  }
}
