import { createNamespacedHelpers } from 'vuex'
const profileGetters = createNamespacedHelpers('profileState')

export default function () {
  return {
    computed: {
      ...profileGetters.mapGetters([
        'currentUser',
        'currentCompany',
        'system'
      ])
    }
  }
}
