import addNamespace from './utils/addNamespace.vue.js'

export const _globalMutes = {
  BEGIN_UPLOAD_REQUEST: 'BEGIN_UPLOAD_REQUEST',
  RESOLVE_UPLOAD_REQUEST: 'RESOLVE_UPLOAD_REQUEST',
  BEGIN_DOWNLOAD_REQUEST: 'BEGIN_DOWNLOAD_REQUEST',
  RESOLVE_DOWNLOAD_REQUEST: 'RESOLVE_DOWNLOAD_REQUEST',
  SET_X_SESSION_TOKEN: 'SET_X_SESSION_TOKEN',
  CLEAR_X_SESSION_TOKEN: 'CLEAR_X_SESSION_TOKEN',
  SHOW_SUCCESS: 'SHOW_SUCCESS',
  HIDE_SUCCESS: 'HIDE_SUCCESS',
  SHOW_UPDATE_NOTIFICATION: 'SHOW_UPDATE_NOTIFICATION',
  HIDE_UPDATE_NOTIFICATION: 'HIDE_UPDATE_NOTIFICATION',
  SET_HEADER_BAR_TITLE: 'SET_HEADER_BAR_TITLE',
  INCREMENT_REQUEST_COUNT: 'INCREMENT_REQUEST_COUNT',
  DECREMENT_REQUEST_COUNT: 'DECREMENT_REQUEST_COUNT',
  CLEAR_REQUEST_COUNT: 'CLEAR_REQUEST_COUNT',
  REMOVE_VALID_NOTIFICATION: 'REMOVE_VALID_NOTIFICATION',
  SET_RELEASE_NOTES_VISIBLE: 'SET_RELEASE_NOTES_VISIBLE',
  SET_ALERT: 'SET_ALERT',
  CLEAR_ALERT: 'CLEAR_ALERT',
  CLOSE_ALERT: 'CLOSE_ALERT'
}

export const _profileMutes = {
  SET_COMPANY_LOGO: 'SET_COMPANY_LOGO',
  SET_SYSTEM: 'SET_SYSTEM',
  SET_CURRENT_USER: 'SET_CURRENT_USER',
  SET_PROFILE_PICTURE: 'UPDATE_PROFILE_PICTURE',
  SET_CURRENT_COMPANY: 'SET_CURRENT_COMPANY'
}

export const _genericMutes = {
  WEBSOCKET_CREATE: 'WEBSOCKET_CREATE',
  WEBSOCKET_UPDATE: 'WEBSOCKET_UPDATE',
  WEBSOCKET_DESTROY: 'WEBSOCKET_DESTROY',
  WEBSOCKET_NORMALIZED_CREATE: 'WEBSOCKET_NORMALIZED_CREATE',
  WEBSOCKET_NORMALIZED_UPDATE: 'WEBSOCKET_NORMALIZED_UPDATE',
  WEBSOCKET_NORMALIZED_DESTROY: 'WEBSOCKET_NORMALIZED_DESTROY'
}
export const globalMutes = _globalMutes
export const profileMutes = addNamespace('profileState', _profileMutes)
