import Vue from 'vue'
import { normalize } from 'normalizr'
import generateActions from '@/vue/store/utils/generateActions.vue.js'
import {
  dataMaps,
  dataMapCatalogs,
  dataMapCatalogPosition,
  dataMapDetailMappings,
  dataMapCategories,
  dataMapCategoryMappings,
  rolePermissions,
  userPermissions
} from './schema.vue.js'

const dataMapsEndpoint = () => {
  return '/api/data_maps'
}

const dataMapCatalogPositionEndpoint = (params) => {
  return `/api/data_maps/${params.dataMapId}/data_map_data_catalogs`
}

const dataMapCatalogsEndpoint = (params) => {
  return `/api/data_maps/${params.dataMapId}/data_catalogs`
}

const dataMapDetailMappingsEndpoint = (params) => {
  return `/api/data_maps/${params.dataMapId}/data_map_detail_mappings`
}

const dataMapCategoriesEndpoint = (params) => {
  return `/api/data_maps/${params.dataMapId}/data_map_categories`
}

const dataMapCategoryMappingsEndpoint = (params) => {
  return `/api/data_maps/${params.dataMapId}/data_map_category_mappings`
}

const rolePermissionsEndpoint = params => `/api/data_maps/${params.dataMapId}/role_permissions`
const userPermissionsEndpoint = params => `/api/data_maps/${params.dataMapId}/user_permissions`

const dataMapsMethodSchemas = {
  index: [dataMaps],
  create: dataMaps,
  destroy: dataMaps,
  update: dataMaps,
  get: dataMaps
}

const rolePermissionMethodSchemas = {
  index: [rolePermissions],
  create: rolePermissions,
  update: rolePermissions,
  destroy: rolePermissions
}

const userPermissionMethodSchemas = {
  index: [userPermissions],
  create: userPermissions,
  update: userPermissions,
  destroy: userPermissions
}

const dataMapCatalogPositionSchemas = {
  index: [dataMapCatalogPosition],
  create: dataMapCatalogPosition,
  destroy: dataMapCatalogPosition,
  update: dataMapCatalogPosition,
  update_positions: [dataMapCatalogPosition]
}

const dataMapDetailMappingsSchemas = {
  index: [dataMapDetailMappings],
  create: dataMapDetailMappings,
  destroy: dataMapDetailMappings,
  update: dataMapDetailMappings
}

const dataMapCategoriesSchemas = {
  index: [dataMapCategories],
  create: dataMapCategories,
  destroy: dataMapCategories,
  update: dataMapCategories
}

const dataMapCategoryMappingsSchemas = {
  index: [dataMapCategoryMappings],
  create: dataMapCategoryMappings,
  destroy: dataMapCategoryMappings,
  update: dataMapCategoryMappings
}

export default {
  ...generateActions(
    'dataMapsStore',
    'data_map',
    dataMapsEndpoint,
    dataMapsMethodSchemas
  ),
  ...generateActions(
    'dataMapsStore',
    'role_permission',
    rolePermissionsEndpoint,
    rolePermissionMethodSchemas
  ),
  ...generateActions(
    'dataMapsStore',
    'user_permission',
    userPermissionsEndpoint,
    userPermissionMethodSchemas
  ),
  ...generateActions(
    'dataMapsStore',
    'data_map_catalog_position',
    dataMapCatalogPositionEndpoint,
    dataMapCatalogPositionSchemas
  ),
  ...generateActions(
    'dataMapsStore',
    'data_map_detail_mapping',
    dataMapDetailMappingsEndpoint,
    dataMapDetailMappingsSchemas
  ),
  ...generateActions(
    'dataMapsStore',
    'data_map_category',
    dataMapCategoriesEndpoint,
    dataMapCategoriesSchemas
  ),
  ...generateActions(
    'dataMapsStore',
    'data_map_category_mapping',
    dataMapCategoryMappingsEndpoint,
    dataMapCategoryMappingsSchemas
  ),
  ...generateActions(
    'dataMapsStore',
    'data_map_catalog',
    dataMapCatalogsEndpoint,
    { index: [dataMapCatalogs] }
  ),

  UPLOAD_DATA_MAP_IMAGE ({ commit }, { params, payload }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.post(`/api/data_maps/${params.dataMapId}/upload_image.json`, payload).then(response => {
        const normalized = normalize(response.data, dataMaps)
        commit('ADD_NORMALIZED_DATA', normalized)
        resolve(normalized)
      }, error => {
        reject(error)
      })
    })
  },

  COPY_DATA_MAP ({ state, commit }, { payload }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .post(`/api/data_maps/${payload.id}/clone.json`, payload)
        .then(
          response => {
            const normalized = normalize(response.data, dataMaps)
            commit('ADD_NORMALIZED_DATA', normalized)
            resolve(normalized)
          },
          error => {
            reject(error)
          }
        )
    })
  },

  COPY_TO_GLOBAL_DATA_MAP ({ state, commit }, { payload }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .post(`/api/data_maps/${payload.id}/create_global_from.json`, payload)
        .then(
          response => {
            const normalized = normalize(response.data, dataMaps)
            commit('ADD_NORMALIZED_DATA', normalized)
            resolve(normalized)
          },
          error => {
            reject(error)
          }
        )
    })
  },

  COPY_TO_LOCAL_DATA_MAP ({ state, commit }, { payload }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .post(`/api/data_maps/${payload.id}/create_local_from.json`, payload)
        .then(
          response => {
            const normalized = normalize(response.data, dataMaps)
            commit('ADD_NORMALIZED_DATA', normalized)
            resolve(normalized)
          },
          error => {
            reject(error)
          }
        )
    })
  },

  UPLOAD_DATA_MAP_JSON ({ commit, state }, { payload }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .post('/api/data_maps/file_upload.json',
          payload
        )
        .then(
          response => {
            const normalized = normalize(response.data, dataMaps)
            commit('ADD_NORMALIZED_DATA', normalized)
            resolve(normalized)
          },
          error => {
            reject(error)
          }
        )
    })
  },

  UPLOAD_DATA_MAP_DETAIL_MAPPING_TEMPLATE ({ state, commit }, { params, payload }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .post(`/api/data_maps/${params.mapId}/data_map_detail_mappings/file_upload.json`, payload)
        .then(
          response => {
            const normalized = normalize(response.data.mappings, [dataMapDetailMappings])
            commit('ADD_NORMALIZED_DATA', normalized)
            resolve(normalized)
          },
          error => {
            reject(error)
          }
        )
    })
  },

  UPLOAD_DATA_MAP_CATEGORY_MAPPING_TEMPLATE ({ state, commit }, { params, payload }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .post(`/api/data_maps/${params.mapId}/data_map_category_mappings/file_upload.json`, payload)
        .then(
          response => {
            const normalized = normalize(response.data.mappings, [dataMapCategoryMappings])
            commit('ADD_NORMALIZED_DATA', normalized)
            resolve(normalized)
          },
          error => {
            reject(error)
          }
        )
    })
  }
}
