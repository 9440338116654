import generateIndexes from '@/vue/store/utils/generateIndexes.vue.js'
import { imageViewers } from './schema.vue.js'

const staticImageViewersIndexProperties = [
  'module_id'
]

export const indexes = {
  [imageViewers.key]: generateIndexes(staticImageViewersIndexProperties)
}
