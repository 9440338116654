
import generateGetters from '@/vue/store/utils/generateGetters.vue.js'
import { entityValues } from './schema.vue.js'
import { indexes } from './indexes.vue.js'
import _ from 'lodash'

const matchingIds = (colourFilter, state) => {
  const filterFn = (colourFilter.operator === 'AND') ? _.intersection : _.union
  let matching = []

  if (colourFilter.stakeholder_ids.length > 0) {
    _.each(colourFilter.stakeholder_ids, (shId) => {
      matching.push(state.indexes.entityValuesByStakeholderIds[shId])
    })
  }

  // We need to union the matching elements here, as the element will match
  // if it links ANY stakeholder that links the role. This is independant of the selected
  // operator for the filter as a whole
  if (colourFilter.stakeholder_ids_from_roles.length > 0) {
    _.each(colourFilter.stakeholder_ids_from_roles, (roleStakeholderIds) => {
      const roleMatchesByStakeholder = []
      _.each(roleStakeholderIds, (shId) => {
        roleMatchesByStakeholder.push(state.indexes.entityValuesByStakeholderIds[shId])
      })
      matching.push(_.union(...roleMatchesByStakeholder))
    })
  }

  if (colourFilter.product_and_service_item_ids.length > 0) {
    _.each(colourFilter.product_and_service_item_ids, (psItemId) => {
      matching.push(state.indexes.entityValuesByProductAndServiceItemIds[psItemId])
    })
  }

  if (colourFilter.product_and_service_item_ids_from_categories.length > 0) {
    _.each(colourFilter.product_and_service_item_ids_from_categories, (catPsItemIds) => {
      const catMatchesByProduct = []
      _.each(catPsItemIds, (psItemId) => {
        catMatchesByProduct.push(state.indexes.entityValuesByProductAndServiceItemIds[psItemId])
      })
      matching.push(_.union(...catMatchesByProduct))
    })
  }

  if (colourFilter.tag_ids.length > 0) {
    _.each(colourFilter.tag_ids, (tagId) => {
      matching.push(state.indexes.entityValuesByTagIds[tagId])
    })
  }

  if (colourFilter.tag_ids_from_categories.length > 0) {
    _.each(colourFilter.tag_ids_from_categories, (catTagIds) => {
      const catMatchesByTag = []
      _.each(catTagIds, (catTagId) => {
        catMatchesByTag.push(state.indexes.entityValuesByTagIds[catTagId])
      })
      matching.push(_.union(...catMatchesByTag))
    })
  }

  if (colourFilter.assignments.length > 0) {
    _.each(colourFilter.assignments, (assignment) => {
      switch (assignment) {
        case 'stakeholder_ids':
          matching.push(_.flatten(_.values(state.indexes.entityValuesByStakeholderIds)))
          break
        case 'product_and_service_item_ids':
          matching.push(_.flatten(_.values(state.indexes.entityValuesByProductAndServiceItemIds)))
          break
        case 'tag_ids':
          matching.push(_.flatten(_.values(state.indexes.entityValuesByTagIds)))
          break
        case 'data_catalog_detail_entity_value_ids':
          matching.push(_.flatten(_.values(state.indexes.entityValuesByDataCatalogDetailEntityValueIds)))
          break
      }
    })
  }

  matching = _.map(matching, (match) => {
    return _.compact(match)
  })

  const filteredIds = filterFn(...matching)

  return filteredIds
}

const isActive = (colorFilter) => {
  return (
    !!colorFilter.assignments.length ||
    !!colorFilter.tag_ids.length ||
    !!colorFilter.tag_category_ids.length ||
    !!colorFilter.tag_ids_from_categories.length ||
    !!colorFilter.stakeholder_ids.length ||
    !!colorFilter.stakeholder_role_ids.length ||
    !!colorFilter.stakeholder_ids_from_roles.length ||
    !!colorFilter.product_and_service_item_ids.length ||
    !!colorFilter.product_and_service_category_ids.length ||
    !!colorFilter.product_and_service_item_ids_from_categories.length
  )
}

export default {
  ...generateGetters(entityValues, indexes),
  entityValuesByModuleAndAttributeId: (state, getters) => (moduleId, entityAttributeId) => {
    const byModuleIds = _.get(state, `indexes.entityValuesByModuleId[${moduleId}]`, [])
    const byAttributeIds = _.get(state, `indexes.entityValuesByEntityAttributeId[${entityAttributeId}]`, [])

    const validIds = _.intersection(byModuleIds, byAttributeIds)
    return getters.entityValuesByIds(validIds)
  },
  entityValuesByModuleAndAttributeIds: (state, getters) => (moduleId, entityAttributeIds) => {
    const byModuleIds = _.get(state, `indexes.entityValuesByModuleId[${moduleId}]`, [])
    let byAttributeIds = _.map(entityAttributeIds, (entityAttributeId) => {
      return _.get(state, `indexes.entityValuesByEntityAttributeId[${entityAttributeId}]`, [])
    })
    byAttributeIds = _.flatten(byAttributeIds)

    const validIds = _.intersection(byModuleIds, byAttributeIds)
    return getters.entityValuesByIds(validIds)
  },
  filteredEntityValuesByModuleId: (state, getters) => (moduleId, colorFilter) => {
    let validIds = _.get(state, `indexes.entityValuesByModuleId[${moduleId}]`, [])

    if (isActive(colorFilter)) {
      validIds = _.intersection(validIds, matchingIds(colorFilter, state))
    }
    return getters.entityValuesByIds(validIds)
  },
  filteredEntityValuesByModuleAndAttributeId: (state, getters) => (moduleId, entityAttributeId, colorFilter) => {
    const byModuleIds = _.get(state, `indexes.entityValuesByModuleId[${moduleId}]`, [])
    const byAttributeIds = _.get(state, `indexes.entityValuesByEntityAttributeId[${entityAttributeId}]`, [])

    let validIds = _.intersection(byModuleIds, byAttributeIds)

    if (isActive(colorFilter)) {
      validIds = _.intersection(validIds, matchingIds(colorFilter, state))
    }
    return getters.entityValuesByIds(validIds)
  }
}
