import generateIndexes from '@/vue/store/utils/generateIndexes.vue.js'
import {
  dataCatalogDetails,
  dataCatalogGroups,
  rolePermissions,
  userPermissions
} from './schema.vue.js'

const dynamicCatalogDetailsIndexProperties = ['data_catalog_group_id', 'parent_id']
const staticCatalogDetailsIndexProperties = ['data_catalog_id', 'parent_id', 'level', 'data_map_category_ids']
const staticCatalogGroupsIndexProperties = ['data_catalog_id']
const staticRolePermissionsProperties = ['data_catalog_id', 'user_ids', 'role_id']
const staticUserPermissionsProperties = ['data_catalog_id']

export const indexes = {
  [dataCatalogDetails.key]: generateIndexes(staticCatalogDetailsIndexProperties, dynamicCatalogDetailsIndexProperties),
  [dataCatalogGroups.key]: generateIndexes(staticCatalogGroupsIndexProperties),
  [rolePermissions.key]: generateIndexes(staticRolePermissionsProperties, [], 'role_id'),
  [userPermissions.key]: generateIndexes(staticUserPermissionsProperties)
}
