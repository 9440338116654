import generateStateGetters from '@/vue/store/utils/generateStateGetters.vue.js'
export default {
  focusedCmUserStoryId: (state) => state.focusedCmUserStoryId,
  selectedCmUserStoryId: (state) => state.selectedCmUserStoryId,
  focusedCmIterationId: (state) => state.focusedCmIterationId,
  selectedCmIterationId: (state) => state.selectedCmIterationId,
  focusedCmCategoryId: (state) => state.focusedCmCategoryId,
  selectedCmCategoryId: (state) => state.selectedCmCategoryId,
  searchString: (state) => state.searchString,
  searchMessage: (state) => state.searchMessage,
  selectedView: (state) => state.selectedView,
  previouslySelectedViews: (state) => state.previouslySelectedViews,
  ...generateStateGetters()
}
