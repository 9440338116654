import generateIndexes from '@/vue/store/utils/generateIndexes.vue.js'
import {
  tags,
  tagCategories
} from './schema.vue.js'

const staticTagIndexProperties = ['project_id']
const dynamicTagsIndexProperties = ['tag_category_ids']
const staticTagCategoriesIndexProperties = ['project_id']

export const indexes = {
  [tags.key]: generateIndexes(staticTagIndexProperties, dynamicTagsIndexProperties),
  [tagCategories.key]: generateIndexes(staticTagCategoriesIndexProperties)
}
