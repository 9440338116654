import Vue from 'vue'
import { normalize } from 'normalizr'
import generateActions from '@/vue/store/utils/generateActions.vue.js'
import {
  companies,
  removedProjects,
  roles,
  users,
  emailTemplates
} from './schema.vue.js'

const removedProjectsCompanyEndpoint = () => {
  return '/api/company_admin/removed_projects'
}

const rolesCompanyEndpoint = () => {
  return '/api/company_admin/roles'
}

const usersCompanyEndpoint = () => {
  return '/api/company_admin/users'
}

const removedProjectsMethodSchemas = {
  index: [removedProjects],
  update: removedProjects
}

const rolesMethodSchemas = {
  index: [roles],
  get: roles,
  create: roles,
  update: roles,
  destroy: roles
}

const usersMethodSchemas = {
  index: [users],
  get: users,
  create: users,
  update: users,
  destroy: users
}
export default {
  ...generateActions(
    'companyAdminPageStore',
    'removed_project',
    removedProjectsCompanyEndpoint,
    removedProjectsMethodSchemas
  ),
  ...generateActions(
    'companyAdminPageStore',
    'role',
    rolesCompanyEndpoint,
    rolesMethodSchemas
  ),
  ...generateActions(
    'companyAdminPageStore',
    'user',
    usersCompanyEndpoint,
    usersMethodSchemas
  ),

  LOAD_EMAIL_TEMPLATE ({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.get('/api/company_admin/email_templates.json')
        .then(response => {
          const normalized = normalize(response.data, emailTemplates)
          commit('ADD_NORMALIZED_DATA', normalized)
          resolve(normalized)
        }, error => {
          reject(error)
        })
    })
  },

  LOAD_COMPANY ({ state, commit }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .get('/api/company_admin/company.json')
        .then(
          response => {
            const normalized = normalize(response.data, companies)
            commit('ADD_NORMALIZED_DATA', normalized)
            resolve(normalized)
          },
          error => {
            reject(error)
          }
        )
    })
  },

  UPDATE_COMPANY ({ state, commit }, data) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .put('/api/company_admin/company.json', data)
        .then(
          response => {
            const normalized = normalize(response.data, companies)
            commit('ADD_NORMALIZED_DATA', normalized)
            resolve(normalized)
          },
          error => {
            reject(error)
          }
        )
    })
  },

  REMOVE_USER ({ state, commit }, { payload }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .put(`/api/company_admin/users/${payload.id}`, payload)
        .then(
          response => {
            const normalized = normalize(response.data, users)
            commit('REMOVE_NORMALIZED_DATA', normalized)
            resolve(normalized)
          },
          error => {
            reject(error)
          }
        )
    })
  },

  RESTORE_REMOVED_PROJECTS ({ state, commit }, { payload }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .put(`/api/company_admin/removed_projects/${payload.id}`)
        .then(
          response => {
            const normalized = normalize(response.data, removedProjects)
            commit('REMOVE_NORMALIZED_DATA', normalized)
            resolve(normalized)
          },
          error => {
            reject(error)
          }
        )
    })
  },

  ADD_LICENCE ({ state, commit }, { payload, company }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .post(`/api/company_admin/users/${payload.id}/add_licence.json`, { id: payload.id })
        .then(
          response => {
            const normalized = normalize(response.data, users)
            commit('ADD_NORMALIZED_DATA', normalized)
            commit('ADD_NORMALIZED_DATA', {
              entities: {
                companies: { [company.id]: { licences_used: company.licences_used + 1 } }
              }
            })
            resolve(normalized)
          },
          error => {
            reject(error)
          }
        )
    })
  },

  REMOVE_LICENCE ({ state, commit }, { payload, company }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .post(`/api/company_admin/users/${payload.id}/remove_licence.json`, { id: payload.id })
        .then(
          response => {
            const normalized = normalize(response.data, users)
            commit('ADD_NORMALIZED_DATA', normalized)
            commit('ADD_NORMALIZED_DATA', {
              entities: {
                companies: { [company.id]: { licences_used: company.licences_used - 1 } }
              }
            })
            resolve(normalized)
          },
          error => {
            reject(error)
          }
        )
    })
  },

  UPDATE_COMPANY_LOGO ({ state, commit }, { payload, company }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .post('/api/company_admin/company/upload_logo.json', payload)
        .then(
          response => {
            const normalized = normalize(response.data, companies)
            commit('ADD_NORMALIZED_DATA', normalized)
            resolve(normalized)
          },
          error => {
            reject(error)
          }
        )
    })
  },

  REMOVE_COMPANY_LOGO ({ state, commit }, { company }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .delete('/api/company_admin/company/remove_logo.json')
        .then(
          response => {
            const normalized = normalize(response.data, companies)
            commit('ADD_NORMALIZED_DATA', normalized)
            resolve(normalized)
          },
          error => {
            reject(error)
          }
        )
    })
  },

  RESEND_INVITE ({ commit }, data) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .post(`/api/company_admin/users/${data.id}/resend_invitation.json`)
        .then(
          response => {
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
    })
  }
}
