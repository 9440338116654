import * as joint from '@clientio/rappid'
import { types } from '@clientio/rappid-visio'

const { VisioSectionType } = types

export default function (vsdShape) {
  const data = vsdShape.getComputedSection(VisioSectionType.Property)
  const elementAttrs = vsdShape.toElementAttributes()
  const element = new joint.dia.Element({
    laneId: data.getProperty('BpmnId'),
    laneLabel: data.getProperty('BpmnName'),
    parentPoolName: data.getProperty('BpmnPoolName'),
    lanePosition: elementAttrs.position.y,
    laneSize: elementAttrs.size.height,
    els: []
  })

  return element
}
