import generateIndexes from '@/vue/store/utils/generateIndexes.vue.js'
import { entityValueLinks } from './schema.vue.js'

const staticEntityValueLinksIndexProperties = [
  'entity_value_id',
  'module_id'
]

export const indexes = {
  [entityValueLinks.key]: generateIndexes(staticEntityValueLinksIndexProperties)
}
