import Vue from 'vue'
import axios from 'axios'
import Vuetify from 'vuetify'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import JointjsVuePlugin from '@/vue/shared/plugins/jointjsVuePlugin.vue.js'
import interceptorSetup from '@/vue/shared/services/http/interceptorSetup.vue.js'
import VueClipboard from 'vue-clipboard2'
import ErrorMixin from '@/vue/shared/services/errors/errorMixin.vue.js'
import AccessControlMixin from '@/vue/shared/services/accessControl/accessControlMixin.vue.js'
import NoTranslateIcon from '@/vue/shared/directives/noTranslateIcon.js'
import Tooltip from '@/vue/shared/directives/tooltip.vue.js'
import TooltipLink from '@/vue/shared/directives/tooltipLink.vue.js'
import OnElementOverflow from '@/vue/shared/directives/onElementOverflow.vue.js'
import profileGettersMixin from '@/vue/shared/services/global/profileGettersMixin.vue.js'
import entityNamesMixin from '@/vue/shared/services/entityMap/entityNamesMixin.vue.js'
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'


Vue.prototype.$tours = {}
Vue.use(Vuex)
Vue.use(VueRouter)
Vue.use(Vuetify)
Vue.use(JointjsVuePlugin)

VueClipboard.config.autoSetContainer = true //Makes work in modals
Vue.use(VueClipboard)
Vue.mixin(ErrorMixin())
Vue.mixin(AccessControlMixin())
Vue.mixin(profileGettersMixin())
Vue.mixin(entityNamesMixin()) // Depends on profileGettersMixin to present before

Vue.directive('no-translate-icon', NoTranslateIcon)
Vue.directive('tooltip', Tooltip)
Vue.directive('tooltip-link', TooltipLink)
Vue.directive('on-element-overflow', OnElementOverflow)
if(ENV === 'development')
{
  Vue.config.devtools = true
  Vue.config.performance = true
  console.log('Performance Monitoring Enabled')
}
Vue.prototype.$http = axios.create({
  baseURL: API_BASE
})
Vue.prototype.$http.defaults.xsrfCookieName = "CSRF-TOKEN";
Vue.prototype.$http.defaults.xsrfHeaderName = "X-CSRF-TOKEN";
Vue.prototype.$http.defaults.withCredentials = true

Sentry.init({
  release: RELEASE_TAG,
  dsn: 'https://' + SENTRY_KEY + '@sentry.io/' + SENTRY_PROJECT_ID,
  integrations: [
    new Integrations.Vue({
      Vue, 
      attachProps: true, 
      logErrors: (ENV === 'development' || ENV === 'staging'),
      tracing: true,
      tracingOptions: {
        trackComponents: true
      }
    })    
  ],
  tracesSampleRate: 0.05
})
interceptorSetup()
