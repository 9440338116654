import { schema } from 'normalizr'
export const links = new schema.Entity('links')
export const productsAndServices = new schema.Entity('productsAndServices', { links: [links] })
export const productsAndServicesCategories = new schema.Entity('productsAndServicesCategories')
export const productsAndServicesMapItems = new schema.Entity('productsAndServicesMapItems')
export const productsAndServicesAssignedItems = new schema.Entity('productsAndServicesAssignedItems')
export const uploadedCsvItems = new schema.Entity('uploadedCsvItems', {
  product_and_service_item_categories: [productsAndServicesCategories],
  product_and_service_items: [productsAndServices]
})
