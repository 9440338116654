import actions from './usersAdminStore/actions.vue.js'
import getters from './usersAdminStore/getters.vue.js'
import mutations from './usersAdminStore/mutations.vue.js'
import initialState from './usersAdminStore/initialState.vue.js'

export default {
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions
}
