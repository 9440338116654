import * as joint from '@clientio/rappid'
import _ from 'lodash'
import BusinessProcessElement from '../base/baseBusinessProcessElement.vue.js'

const Group = BusinessProcessElement.extend({
  markup: joint.shapes.bpmn2.Group.prototype.markup,
  defaults: _.defaultsDeep({
    size: { width: 30, height: 25 },
    shape: 'Group',
    type: 'businessProcess.Group',
    attrs: {
      body: {
        fill: '#ffffff',
        stroke: '#000000',
        fillOpacity: 1
      },
      label: {
        textVerticalPosition: 'top',
        textVerticalAnchor: 'top',
        text: 'Group',
        textWrap: {
          width: -10,
          height: null
        }
      }
    }
  }, joint.shapes.bpmn2.Group.prototype.defaults),
  updateElementLabelVerticalPosition (vPosition) {
    // Normalize property format
    this.removeAttr('label/text-vertical-anchor')
    this.removeAttr('label/ref-y')
    this.removeAttr('label/text-vertical-position')

    switch (vPosition) {
      case 'top' : {
        this.attr('label/textVerticalPosition', 'top')
        this.attr('label/textVerticalAnchor', 'top')
        this.attr('label/refY', '6')
        break
      }
      case 'middle' : {
        this.attr('label/textVerticalPosition', 'middle')
        this.attr('label/textVerticalAnchor', 'middle')
        this.attr('label/refY', '50%')
        break
      }
      case 'bottom' : {
        this.attr('label/textVerticalPosition', 'bottom')
        this.attr('label/textVerticalAnchor', 'bottom')
        this.attr('label/refY', '95%')
        break
      }
      default: {
        this.attr('label/textVerticalPosition', 'top')
        this.attr('label/textVerticalAnchor', 'top')
        this.attr('label/refY', '6')
        break
      }
    }
  }
})
Group.attributes = joint.shapes.bpmn2.Group.attributes

export default Group
