import Vue from 'vue'

export default {
  SET_VIEW_MODE (state, value) {
    state.viewMode = value
  },
  SET_ORDER_COLUMN (state, value) {
    state.orderColumn = value
  },
  SET_ORDER_DIRECTION (state, value) {
    state.orderDirection = value
  },
  SET_PROJECT_CACHE (state, { key, ids }) {
    Vue.set(state.projectsCache, key, ids)
  },
  SET_SEARCH_STRING (state, value) {
    state.searchString = value
  }
}
