import actions from './featureNotificationsAdminStore/actions.vue.js'
import getters from './featureNotificationsAdminStore/getters.vue.js'
import mutations from './featureNotificationsAdminStore/mutations.vue.js'
import initialState from './featureNotificationsAdminStore/initialState.vue.js'

export default {
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions
}
