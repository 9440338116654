const initialState = () => {
  return {
    shapesPanelMinified: false,
    selectedLayerId: null,
    openPanelSection: 'properties',
    view: 'diagram',
    hiddenLayerIds: []
  }
}
export default initialState
