import _ from 'lodash'
import Vue from 'vue'
import { normalize } from 'normalizr'
import generateActions from '@/vue/store/utils/generateActions.vue.js'
import { cmCategorys, cmIterations, cmUserStoryItems, cmUserStoryItemStakeholders, links } from './schema.vue.js'

const cmUserStoryEndpoint = (params) => {
  return `/api/projects/${params.projectId}/project_scenario_modules/${params.moduleId}/change_management_story_items`
}
const cmUserStoryStakeholderEndpoint = (params) => {
  return `/api/projects/${params.projectId}/project_scenario_modules/${params.moduleId}/change_management_story_items/${params.userStoryId}/change_management_story_item_stakeholders`
}
const cmIterationsEndpoint = (params) => {
  return `/api/projects/${params.projectId}/project_scenario_modules/${params.moduleId}/change_management_iterations`
}
const cmCategorysEndpoint = (params) => {
  return `/api/projects/${params.projectId}/project_scenario_modules/${params.moduleId}/change_management_categories`
}
const linksEndpoint = () => {
  return '/api/links'
}

const methodSchemas = {
  index: [cmUserStoryItems],
  create: cmUserStoryItems,
  update: cmUserStoryItems,
  update_positions: [cmUserStoryItems],
  destroy: cmUserStoryItems
}

const iterationsMethodSchemas = {
  index: [cmIterations],
  create: cmIterations,
  update: cmIterations,
  update_positions: [cmIterations],
  destroy: cmIterations
}

const categoriesMethodSchemas = {
  index: [cmCategorys],
  create: cmCategorys,
  update: cmCategorys,
  update_positions: [cmCategorys],
  destroy: cmCategorys
}

const stakeholdersMethodSchemas = {
  create: cmUserStoryItemStakeholders,
  update: cmUserStoryItemStakeholders,
  destroy: cmUserStoryItemStakeholders
}

const linkSchemas = {
  update: links,
  destroy: links,
  index: [links]
}

export default {
  ...generateActions(
    'changeManagementStore',
    'cm_user_story_item',
    cmUserStoryEndpoint,
    methodSchemas
  ),
  ...generateActions(
    'changeManagementStore',
    'cm_iteration',
    cmIterationsEndpoint,
    iterationsMethodSchemas
  ),
  ...generateActions(
    'changeManagementStore',
    'cm_category',
    cmCategorysEndpoint,
    categoriesMethodSchemas
  ),
  ...generateActions(
    'changeManagementStore',
    'cm_user_story_item_stakeholder',
    cmUserStoryStakeholderEndpoint,
    stakeholdersMethodSchemas
  ),
  ...generateActions(
    'changeManagementStore',
    'link',
    linksEndpoint,
    linkSchemas
  ),
  EXPORT_CHANGE_MANAGMENT ({ commit, dispatch }, { params, exportType }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .get(
          `${cmUserStoryEndpoint(params)}/export.${exportType}`, {
            responseType: 'blob'
          })
        .then(
          response => {
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
    })
  },
  EXPORT_CHANGE_MANAGMENT_ITERATION ({ commit, dispatch }, { params, iterationId }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .get(
          `${cmIterationsEndpoint(params)}/export.json?iteration_id=${iterationId}`, {
            responseType: 'blob'
          })
        .then(
          response => {
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
    })
  },
  CREATE_DEFAULT_CM_USER_STORY_ITEM ({ dispatch, state }, { params }) {
    const highest = _.maxBy(_.values(state.cmUserStoryItems), 'position')
    const position = _.get(highest, 'position', -1) + 1
    return dispatch('CREATE_CM_USER_STORY_ITEM', {
      params,
      payload: {
        stakeholder_id: null,
        change_management_category_id: null,
        data_rules: '',
        acceptance_criteria: '',
        exceptions: '',
        comments: '',
        change_type: '',
        link_url: null,
        link_title: null,
        benefit_score: 0,
        rating_reason: '',
        complexity_score: 0,
        rating_score: '',
        stakeholders: [],
        position: position
      }
    })
  },
  CREATE_DEFAULT_CM_ITERATION ({ dispatch, state }, { params }) {
    const highest = _.maxBy(_.values(state.cmIterations), 'position')
    const position = _.get(highest, 'position', -1) + 1
    return dispatch('CREATE_CM_ITERATION', {
      params,
      payload: {
        name: 'Untitled',
        hours: 0,
        link_title: '',
        link_url: '',
        position: position
      }
    })
  },
  CREATE_DEFAULT_CM_CATEGORY ({ dispatch, state }, { params }) {
    const highest = _.maxBy(_.values(state.cmCategorys), 'position')
    const position = _.get(highest, 'position', -1) + 1
    return dispatch('CREATE_CM_CATEGORY', {
      params,
      payload: {
        name: 'Untitled',
        acronym: 'NA',
        description: '',
        link_title: '',
        link_url: '',
        position: position
      }
    })
  },
  CREATE_DEFAULT_CM_USER_STORY_ITEM_STAKEHOLDER ({ dispatch, state }, { params }) {
    return dispatch('CREATE_CM_USER_STORY_ITEM_STAKEHOLDER', {
      params,
      payload: {
        stakeholder_id: null,
        change_management_story_item_id: params.userStoryId,
        hours_required: 0
      }
    })
  },
  UPDATE_LOCAL_CM_CATEGORYS ({ commit, state }, payload) {
    commit('ADD_NORMALIZED_DATA', normalize(payload, [cmCategorys]))
    return new Promise((resolve) => resolve())
  },

  UPDATE_LOCAL_CM_ITERATIONS ({ commit, state }, payload) {
    commit('ADD_NORMALIZED_DATA', normalize(payload, [cmIterations]))
    return new Promise((resolve) => resolve())
  },

  UPDATE_LOCAL_CM_USER_STORY_ITEMS ({ commit, state }, payload) {
    commit('ADD_NORMALIZED_DATA', normalize(payload, [cmUserStoryItems]))
    return new Promise((resolve) => resolve())
  },
  CREATE_LINK_CATEGORY ({ commit, dispatch, getters }, { payload }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .post('/api/links.json', payload)
        .then(
          response => {
            commit('ADD_NORMALIZED_DATA', normalize(response.data, links))
            const item = getters.cmCategorysById(response.data.linkable_id)
            const normalized = normalize({
              id: item.id,
              links: _.concat(item.links, [response.data.id])
            }, cmCategorys)
            commit('ADD_NORMALIZED_DATA', normalized)
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
    })
  },
  CREATE_LINK_ITERATION ({ commit, dispatch, getters }, { payload }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .post('/api/links.json', payload)
        .then(
          response => {
            commit('ADD_NORMALIZED_DATA', normalize(response.data, links))
            const item = getters.cmIterationsById(response.data.linkable_id)
            const normalized = normalize({
              id: item.id,
              links: _.concat(item.links, [response.data.id])
            }, cmIterations)
            commit('ADD_NORMALIZED_DATA', normalized)
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
    })
  },
  CREATE_LINK_USER_STORY ({ commit, dispatch, getters }, { payload }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .post('/api/links.json', payload)
        .then(
          response => {
            commit('ADD_NORMALIZED_DATA', normalize(response.data, links))
            const item = getters.cmUserStoryItemsById(response.data.linkable_id)
            const normalized = normalize({
              id: item.id,
              links: _.concat(item.links, [response.data.id])
            }, cmUserStoryItems)
            commit('ADD_NORMALIZED_DATA', normalized)
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
    })
  }
}
