import Vue from 'vue'
import { normalize } from 'normalizr'
import generateActions from '@/vue/store/utils/generateActions.vue.js'
import {
  assessments,
  assessmentStatuses,
  responseStatuses,
  rolePermissions,
  userPermissions,
  questionTypes,
  questions,
  questionOptions,
  answers,
  answerOptions,
  sections,
  groups,
  responses,
  myResponses,
  myAnswers,
  userResponses,
  userAnswers
} from './schema.vue.js'

const assessmentsEndpoint = () => ('/api/assessments')
const assessmentStatusesEndpoint = () => ('/api/assessment_statuses')
const responseStatusesEndpoint = () => ('/api/response_statuses')
const questionTypesEndpoint = () => ('/api/assessment_question_types')
const questionsEndpoint = params => (`/api/assessments/${params.assessmentId}/questions`)
const questionOptionsEndpoint = params => (`/api/assessments/${params.assessmentId}/question_options`)
const rolePermissionsEndpoint = params => (`/api/assessments/${params.assessmentId}/role_permissions`)
const userPermissionsEndpoint = params => (`/api/assessments/${params.assessmentId}/user_permissions`)
const sectionsEndpoint = params => (`/api/assessments/${params.assessmentId}/sections`)
const groupsEndpoint = params => (`/api/assessments/${params.assessmentId}/groups`)
const responsesEndpoint = params => (`/api/assessments/${params.assessmentId}/responses`)
const answersEndpoint = params => (`/api/assessments/${params.assessmentId}/responses/${params.responseId}/answers`)
const answerOptionsEndpoint = params => (`/api/assessments/${params.assessmentId}/responses/${params.responseId}/answer_options`)
const myResponsesEndpoint = params => (`/api/assessments/${params.assessmentId}/current_response`)
const myAnswersEndpoint = params => (`/api/assessments/${params.assessmentId}/current_response/answers`)
const userResponsesEndpoint = params => (`/api/assessments/${params.assessmentId}/responses`)
const userAnswersEndpoint = params => (`/api/assessments/${params.assessmentId}/responses/${params.responseId}/answers`)

const assessmentMethodSchemas = {
  get: assessments,
  index: [assessments],
  create: assessments,
  update: assessments,
  destroy: assessments
}

const assessmentStatusMethodSchemas = {
  index: [assessmentStatuses]
}

const responseStatusMethodSchemas = {
  index: [responseStatuses]
}

const questionTypesMethodSchemas = {
  index: [questionTypes]
}

const questionMethodSchemas = {
  index: [questions],
  create: questions,
  update: questions,
  destroy: questions,
  update_positions: [questions]
}

const rolePermissionMethodSchemas = {
  index: [rolePermissions],
  create: rolePermissions,
  update: rolePermissions,
  destroy: rolePermissions
}

const userPermissionMethodSchemas = {
  index: [userPermissions],
  create: userPermissions,
  update: userPermissions,
  destroy: userPermissions
}

const questionOptionMethodSchemas = {
  index: [questionOptions],
  create: questionOptions,
  update: questionOptions,
  destroy: questionOptions,
  update_positions: [questionOptions]
}

const sectionMethodSchemas = {
  index: [sections],
  create: sections,
  update: sections,
  destroy: sections,
  update_positions: [sections]
}

const groupMethodSchemas = {
  index: [groups],
  create: groups,
  update: groups,
  destroy: groups,
  update_positions: [groups]
}

const responseMethodSchemas = {
  index: [responses],
  get: responses,
  create: responses,
  update: responses,
  destroy: responses
}

const answerMethodSchemas = {
  index: [answers],
  create: answers,
  update: answers,
  destroy: answers
}

const answerOptionMethodSchemas = {
  index: [answerOptions],
  create: answerOptions,
  update: answerOptions,
  destroy: answerOptions
}

const myResponseMethodSchemas = {
  index: myResponses,
  create: myResponses,
  destroy: myResponses
}

const myAnswerMethodSchemas = {
  index: [myAnswers],
  create: myAnswers,
  update: myAnswers,
  destroy: myAnswers
}

const userResponseMethodSchemas = {
  index: [userResponses],
  get: responses,
  create: userResponses,
  destroy: userResponses
}

const userAnswerMethodSchemas = {
  index: [userAnswers],
  create: userAnswers,
  update: userAnswers,
  destroy: userAnswers
}

export default {
  ...generateActions(
    'assessmentStore',
    'assessment',
    assessmentsEndpoint,
    assessmentMethodSchemas
  ),
  ...generateActions(
    'assessmentStore',
    'assessment_status',
    assessmentStatusesEndpoint,
    assessmentStatusMethodSchemas
  ),
  ...generateActions(
    'assessmentStore',
    'response_status',
    responseStatusesEndpoint,
    responseStatusMethodSchemas
  ),
  ...generateActions(
    'assessmentStore',
    'question_type',
    questionTypesEndpoint,
    questionTypesMethodSchemas
  ),
  ...generateActions(
    'assessmentStore',
    'question',
    questionsEndpoint,
    questionMethodSchemas
  ),
  ...generateActions(
    'assessmentStore',
    'question_option',
    questionOptionsEndpoint,
    questionOptionMethodSchemas
  ),
  ...generateActions(
    'assessmentStore',
    'role_permission',
    rolePermissionsEndpoint,
    rolePermissionMethodSchemas
  ),
  ...generateActions(
    'assessmentStore',
    'user_permission',
    userPermissionsEndpoint,
    userPermissionMethodSchemas
  ),
  ...generateActions(
    'assessmentStore',
    'section',
    sectionsEndpoint,
    sectionMethodSchemas
  ),
  ...generateActions(
    'assessmentStore',
    'group',
    groupsEndpoint,
    groupMethodSchemas
  ),
  ...generateActions(
    'assessmentStore',
    'response',
    responsesEndpoint,
    responseMethodSchemas
  ),
  ...generateActions(
    'assessmentStore',
    'answer',
    answersEndpoint,
    answerMethodSchemas
  ),
  ...generateActions(
    'assessmentStore',
    'answer_option',
    answerOptionsEndpoint,
    answerOptionMethodSchemas
  ),
  ...generateActions(
    'assessmentStore',
    'my_response',
    myResponsesEndpoint,
    myResponseMethodSchemas
  ),
  ...generateActions(
    'assessmentStore',
    'my_answer',
    myAnswersEndpoint,
    myAnswerMethodSchemas
  ),
  ...generateActions(
    'assessmentStore',
    'user_response',
    userResponsesEndpoint,
    userResponseMethodSchemas
  ),
  ...generateActions(
    'assessmentStore',
    'user_answer',
    userAnswersEndpoint,
    userAnswerMethodSchemas
  ),
  UPDATE_MY_RESPONSE ({ commit }, { params, payload }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.put(`/api/assessments/${params.assessmentId}/current_response.json`, payload).then(response => {
        const normalized = normalize(response.data, myResponses)
        commit('ADD_NORMALIZED_DATA', normalized)
        resolve(normalized)
      }, error => {
        reject(error)
      })
    })
  },
  UPLOAD_ASSESSMENT_TEMPLATE ({ commit, state }, { payload }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .post('/api/assessments/import.json',
          payload
        )
        .then(
          response => {
            const normalized = normalize(response.data, assessments)
            commit('ADD_NORMALIZED_DATA', normalized)
            resolve(normalized)
          },
          error => {
            reject(error)
          }
        )
    })
  },
  DOWNLOAD_RESPONSES_CSV ({ state }, { assessmentId }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .get(`api/assessments/${assessmentId}/export.csv`, {
          responseType: 'blob'
        })
        .then(
          response => {
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
    })
  },
  DOWNLOAD_RESPONSE_PDF ({ state }, { assessmentId, responseId }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .get(`api/assessments/${assessmentId}/responses/${responseId}/export.pdf`, {
          responseType: 'blob'
        })
        .then(
          response => {
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
    })
  },
  UPDATE_LOCAL_QUESTION_PROPERTIES ({ commit, state }, payload) {
    commit('ADD_NORMALIZED_DATA', normalize(payload, [questions]))
    return new Promise((resolve) => resolve())
  }
}
