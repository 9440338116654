import actions from './notificationsStore/actions.vue.js'
import getters from './notificationsStore/getters.vue.js'
import mutations from './notificationsStore/mutations.vue.js'
import initialState from './notificationsStore/initialState.vue.js'

export default {
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions
}
