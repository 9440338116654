import _ from 'lodash'
import generateGetters from '@/vue/store/utils/generateGetters.vue.js'
import { indexes } from './indexes.vue.js'
import {
  projects,
  roles,
  sections,
  moduleTypes,
  moduleStatuses,
  moduleCategories,
  markets,
  statuses,
  projectResources,
  projectHistoryEvents,
  projectDataMaps,
  projectDataCatalogs,
  userPermissions,
  rolePermissions,
  associatedDataElements
} from './schema.vue.js'

export default {
  ...generateGetters(projects),
  ...generateGetters(roles),
  ...generateGetters(sections, indexes),
  ...generateGetters(moduleTypes),
  ...generateGetters(moduleStatuses),
  ...generateGetters(moduleCategories),
  ...generateGetters(markets),
  ...generateGetters(statuses),
  ...generateGetters(projectResources, indexes),
  ...generateGetters(projectHistoryEvents, indexes),
  ...generateGetters(projectDataMaps, indexes),
  ...generateGetters(userPermissions, indexes),
  ...generateGetters(rolePermissions, indexes),
  ...generateGetters(projectDataCatalogs),
  ...generateGetters(associatedDataElements, indexes),

  filteredAssociatedDataElementsByProjectId: (state, getters) => (projectId, filterTerms) => {
    const elementSets = [
      _.get(state, `indexes.associatedDataElementsByProjectId[${projectId}]`, [])
    ]

    if (filterTerms.filteredDataMapId) { elementSets.push(_.get(state, `indexes.associatedDataElementsByDataMapId[${filterTerms.filteredDataMapId}]`, [])) }
    if (filterTerms.filteredDataCatalogId) { elementSets.push(_.get(state, `indexes.associatedDataElementsByDataCatalogId[${filterTerms.filteredDataCatalogId}]`, [])) }
    if (filterTerms.filteredDetailId) { elementSets.push(_.get(state, `indexes.associatedDataElementsByDataCatalogDetailId[${filterTerms.filteredDetailId}]`, [])) }

    const validIds = _.intersection(...elementSets)

    return getters.associatedDataElementsByIds(validIds)
  },

  userDisplayPermissionByProjectId: (state) => (projectId) => {
    let userPerm = {}
    const projectUserPermissions = _.filter(state.userPermissions, { project_id: projectId })
    const projectRolePermissions = _.filter(state.rolePermissions, { project_id: projectId })
    const permLookUp = {
      read: 1,
      write: 2,
      admin: 3
    }
    userPerm = _.reduce(projectUserPermissions, (obj, value) => {
      obj[value.user_id] = value.permission_key
      return obj
    }, userPerm)

    userPerm = _.reduce(projectRolePermissions, (obj, value) => {
      _.each(value.user_ids, (id) => {
        if (obj[id]) {
          if (permLookUp[value.permission_key] > permLookUp[obj[id]]) {
            obj[id] = value.permission_key
          }
        } else {
          obj[id] = value.permission_key
        }
      })
      return obj
    }, userPerm)

    return userPerm
  },

  rolePermissionsByProjectId: (state) => (projectId) => {
    return _.filter(state.rolePermissions, { project_id: projectId })
  },
  userPermissionsByProjectId: (state) => (projectId) => {
    return _.filter(state.userPermissions, { project_id: projectId })
  },

  moduleTypesByCategoryId: state => categoryId => {
    const moduleTypes = _.values(state.moduleTypes)

    if (categoryId === null) {
      return moduleTypes
    } else {
      return _.filter(moduleTypes, moduleType => {
        return _.includes(moduleType.scenario_module_category_ids, categoryId)
      })
    }
  }
}
