import Vue from 'vue'
import _ from 'lodash'
import { normalize } from 'normalizr'
import generateActions from '@/vue/store/utils/generateActions.vue.js'
import { kanbanItems, kanbanLanes, links } from './schema.vue.js'

const kanbanItemsEndpoint = (params) => {
  return `/api/projects/${params.projectId}/project_scenario_modules/${params.moduleId}/kanban_items`
}
const kanbanLanesEndpoint = (params) => {
  return `/api/projects/${params.projectId}/project_scenario_modules/${params.moduleId}/kanban_lanes`
}
const linksEndpoint = () => {
  return '/api/links'
}

const kanbanItemSchemas = {
  index: [kanbanItems],
  create: kanbanItems,
  update: kanbanItems,
  destroy: kanbanItems,
  update_positions: [kanbanItems]
}

const kanbanLaneSchemas = {
  index: [kanbanLanes],
  create: kanbanLanes,
  update: kanbanLanes,
  destroy: kanbanLanes,
  update_positions: [kanbanLanes]
}

const linkSchemas = {
  update: links,
  destroy: links,
  index: [links]
}

export default {
  ...generateActions(
    'kanbanStore',
    'kanban_item',
    kanbanItemsEndpoint,
    kanbanItemSchemas
  ),

  ...generateActions(
    'kanbanStore',
    'kanban_lane',
    kanbanLanesEndpoint,
    kanbanLaneSchemas
  ),

  ...generateActions(
    'kanbanStore',
    'link',
    linksEndpoint,
    linkSchemas
  ),

  CREATE_LINK ({ commit, dispatch, getters }, { payload }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .post('/api/links.json', payload)
        .then(
          response => {
            commit('ADD_NORMALIZED_DATA', normalize(response.data, links))
            const canvasItem = getters.kanbanItemsById(response.data.linkable_id)
            const normalized = normalize({
              id: canvasItem.id,
              links: _.concat(canvasItem.links, [response.data.id])
            }, kanbanItems)
            commit('ADD_NORMALIZED_DATA', normalized)
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
    })
  },

  EXPORT_KANBAN ({ commit, dispatch }, { params, exportType }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .get(
          `/api/projects/${params.projectId}/project_scenario_modules/${params.moduleId}/kanban_items/export.${exportType}`,
          {
            responseType: 'blob'
          }
        )
        .then(
          response => {
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
    })
  },

  UPDATE_LOCAL_KANBAN_ITEMS ({ commit, state }, payload) {
    commit('ADD_NORMALIZED_DATA', normalize(payload, [kanbanItems]))
    return new Promise((resolve) => resolve())
  },
  UPDATE_LOCAL_KANBAN_LANES ({ commit, state }, payload) {
    commit('ADD_NORMALIZED_DATA', normalize(payload, [kanbanLanes]))
    return new Promise((resolve) => resolve())
  }
}
