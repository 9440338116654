import _ from 'lodash'
import Vue from 'vue'
import { normalize } from 'normalizr'
import generateActions from '@/vue/store/utils/generateActions.vue.js'
import {
  productsAndServices,
  productsAndServicesCategories,
  productsAndServicesMapItems,
  productsAndServicesAssignedItems,
  uploadedCsvItems,
  links
} from './schema.vue.js'

const productsEndpoint = params => (`/api/projects/${params.projectId}/product_and_service_items`)
const categoriesEndpoint = params => (`/api/projects/${params.projectId}/product_and_service_item_categories`)
const productsMapItemsEndpoint = params => (`/api/projects/${params.projectId}/product_and_service_map_items`)
const productsAssignedItemsEndpoint = params => (`/api/projects/${params.projectId}/product_and_service_items/assigned_items_index`)
const linksEndpoint = () => {
  return '/api/links'
}

const linkSchemas = {
  update: links,
  destroy: links,
  index: [links]
}

export default {
  ...generateActions(
    'productsAndServicesStore',
    'product_and_service',
    productsEndpoint,
    {
      index: [productsAndServices],
      create: productsAndServices,
      update: productsAndServices,
      update_positions: [productsAndServices],
      destroy: productsAndServices
    }
  ),
  ...generateActions(
    'productsAndServicesStore',
    'product_and_service_category',
    categoriesEndpoint,
    {
      index: [productsAndServicesCategories],
      create: productsAndServicesCategories,
      update: productsAndServicesCategories,
      update_positions: [productsAndServicesCategories],
      destroy: productsAndServicesCategories
    }
  ),
  ...generateActions(
    'productsAndServicesStore',
    'product_and_service_map_item',
    productsMapItemsEndpoint,
    {
      index: productsAndServicesMapItems
    }
  ),
  ...generateActions(
    'productsAndServicesStore',
    'product_and_service_assigned_item',
    productsAssignedItemsEndpoint,
    {
      index: [productsAndServicesAssignedItems]
    }
  ),
  ...generateActions(
    'productsAndServicesStore',
    'link',
    linksEndpoint,
    linkSchemas
  ),

  EXPORT_PRODUCT_AND_SERVICES ({ commit, dispatch }, { params, exportType }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .get(
          `${productsEndpoint(params)}/export.${exportType}`, {
            responseType: 'blob'
          })
        .then(
          response => {
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
    })
  },

  UPLOAD_PRODUCT_AND_SERVICE_CSV ({ commit }, { params, payload }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .post(
          `${productsEndpoint(params)}/import_upload.json`,
          payload
        )
        .then(
          response => {
            commit('ADD_NORMALIZED_DATA', normalize(response.data, uploadedCsvItems))
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
    })
  },
  CREATE_DEFAULT_PRODUCT_AND_SERVICE ({ dispatch, state }, { params }) {
    const highest = _.maxBy(_.values(state.productsAndServices), 'position')
    const position = _.get(highest, 'position', -1) + 1
    return dispatch('CREATE_PRODUCT_AND_SERVICE', {
      params,
      payload: {
        name: 'Untitled',
        description: '',
        link_title: '',
        link_url: '',
        position: position
      }
    })
  },
  UPDATE_LOCAL_PRODUCT_ITEMS ({ commit, state }, payload) {
    commit('ADD_NORMALIZED_DATA', normalize(payload, [productsAndServices]))
    return new Promise((resolve) => resolve())
  },
  ADD_PRODUCT_AND_SERVICES ({ commit }, payload) {
    commit('ADD_NORMALIZED_DATA', normalize(payload, [productsAndServices]))
    return new Promise(resolve => resolve())
  },
  CREATE_LINK ({ commit, dispatch, getters }, { payload }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .post('/api/links.json', payload)
        .then(
          response => {
            commit('ADD_NORMALIZED_DATA', normalize(response.data, links))
            const product = getters.productsAndServicesById(response.data.linkable_id)
            const normalized = normalize({
              id: product.id,
              links: _.concat(product.links, [response.data.id])
            }, productsAndServices)
            commit('ADD_NORMALIZED_DATA', normalized)
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
    })
  }
}
