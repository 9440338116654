import * as joint from '@clientio/rappid'
import _ from 'lodash'
import BusinessProcessElement from '../base/baseBusinessProcessElement.vue.js'

/** Legacy Shape. Not in BPMN 2.0 */
const Message = BusinessProcessElement.extend({
  displayModeAttributes: {
    stencil: {
      size: { width: 40, height: 25 },
      attrs: {
        '.label': {
          text: ''
        }
      }
    },
    preview: {
      size: { width: 90, height: 60 },
      position: { x: 10, y: 2 }
    },
    canvas: {
      size: { width: 90, height: 60 }
    }
  },
  setText: function (text) {
    this.attr({
      '.label': {
        text: text
      }
    })
  },
  getText: function (text) {
    return this.attr('.label/text') || this.get('shape')
  },
  updateElementDefaultColors (colorScheme) {
    this.attr({
      '.body': {
        fill: colorScheme.default_fill_colour,
        stroke: colorScheme.default_stroke_colour
      },
      text: {
        fill: colorScheme.default_text_colour
      }
    })
  },
  markup: joint.shapes.bpmn.Message.prototype.markup,
  defaults: _.defaultsDeep({
    size: { width: 30, height: 25 },
    shape: 'Message',
    type: 'businessProcess.Message',
    attrs: {
      '.body': {
        fill: '#ffffff',
        stroke: '#000000',
        fillOpacity: 1
      },
      '.label': {
        text: 'Message'
      },
      text: {
        fill: '#000000',
        'font-family': 'Roboto,sans-serif'
      }
    }
  }, joint.shapes.bpmn.Message.prototype.defaults)
})

export default Message
