import Vue from 'vue'
import _ from 'lodash'
import counters from './interceptors/counters.vue.js'
import headers from './interceptors/headers.vue.js'
import responseCodes from './interceptors/responseCodes.vue.js'

const interceptors = [
  responseCodes, // Must come before counters.
  counters,
  headers
]

export default function interceptorSetup () {
  _.each(interceptors, (ic) => {
    if (!!ic.request && ic.request.length) {
      Vue.prototype.$http.interceptors.request.use(...ic.request)
    }
    if (!!ic.response && ic.response.length) {
      Vue.prototype.$http.interceptors.response.use(...ic.response)
    }
  })
}
