import { VisioArchive, types } from '@clientio/rappid-visio'
import _ from 'lodash'
import { Draft07 } from 'json-schema-library'
import * as bpmnShapeHandlers from './visioShapeTemplates/businessProcess/index.vue.js'
const { VisioSectionType } = types

export default class {
  paper = null
  graph = null
  paperScroller = null
  entityAttributes = []
  selectedLayerId = null
  skippedImportShape = false
  bulkUpdateElements = (elements) => (Promise.resolve(elements))

  initialize ({
    paper,
    graph,
    paperScroller,
    entityAttributes,
    selectedLayerId
  }) {
    this.paper = paper
    this.graph = graph
    this.paperScroller = paperScroller
    this.entityAttributes = entityAttributes
    this.selectedLayerId = selectedLayerId
  }

  destroy () {
    this.paper = null
    this.graph = null
    this.paperScroller = null
    this.skippedImportShape = false
    this.entityAttributes = []
    this.selectedLayerId = null
  }

  importVisio (file) {
    this.skippedImportShape = false
    return new Promise((resolve, reject) => {
      return VisioArchive.fromURL(file).then(function (archive) {
        const page = archive.document.getPages()[0]
        this.paper.freeze()
        return page.getContent().then(function (content) {
          const ctx = this
          const cells = content.toGraphCells({
            importShape: this.#importBpmnShape.bind(this),
            importConnect: this.#importBpmnConnect.bind(this),
            importLabels: this.#importBpmnLabels.bind(this),
            onImagesLoad: () => {
              ctx.#addBpmnCellsToGraph(page, cells).then(() => {
                resolve(this.skippedImportShape)
              })
            }
          })
        }.bind(this))
      }.bind(this)).catch((error) => {
        reject(error)
      })
    })
  }

  #cellToDefaultJSON (cell) {
    // Set ID to null for new cells.
    const type = cell.get('type')
    const entityAttribute = _.find(this.entityAttributes, { key: type })
    const cellTitle = cell.isLink() ? cell.prop('labels/0/attrs/text/text') : cell.getText()
    const defaultProps = new Draft07(entityAttribute.json_schema).getTemplate({
      entity_attribute_id: entityAttribute.id,
      title: cellTitle,
      type: entityAttribute.key,
      layer_id: this.selectedLayerId,
      mode: 'canvas',
      id: null,
      ...cell.toJSON()
    })
    cell.prop(defaultProps, { silent: true })
    return cell.toJSON()
  }

  // BPMN Functions
  #importBpmnShape (vsdShape) {
    const data = vsdShape.getComputedSection(VisioSectionType.Property)
    if (!data) { return null }

    const shapeType = data.getProperty('BpmnElementType')
    const shapeHandler = bpmnShapeHandlers.default[shapeType] ? bpmnShapeHandlers.default[shapeType](vsdShape) : null
    if (!shapeHandler) { throw (new Error('Non BPMN Basic Shape detectected in Visio Diagram')) }
    return shapeHandler
  }

  #importBpmnConnect (vsdConnect, sourceElement, targetElement) {
    if (!sourceElement || !targetElement) { return null }
    return bpmnShapeHandlers.default.Flow(vsdConnect, sourceElement, targetElement)
  }

  #importBpmnLabels (vsdShape, link) {
    const text = vsdShape.getText()
    if (text) {
      link.labels([{
        attrs: {
          text: {
            text,
            fontFamily: 'sans-serif'
          }
        }
      }])
    }
  }

  #addBpmnCellsToGraph (page, cells) {
    const ctx = this
    const elements = _.filter(cells, c => c.isElement())
    const links = _.filter(cells, c => c.isLink())
    const poolElements = _.filter(elements, (el) => (!!el.get('poolId')))
    const laneElements = _.filter(elements, (el) => (el.get('parentPoolName')))
    const elementsWithoutLanes = _.differenceBy(elements, laneElements, 'id')

    _.each(poolElements, (poolEl) => {
      let poolLaneElements = _.filter(elements, (laneEl) => (poolEl.get('poolName') === laneEl.get('parentPoolName')))
      poolLaneElements = _.sortBy(poolLaneElements, (laneElement) => (laneElement.get('lanePosition')))

      const poolLaneNames = _.map(poolLaneElements, (el) => (el.get('laneLabel')))
      let poolEmbededElements = _.filter(elements, (el) => (el.get('lane') &&
      _.includes(poolLaneNames, el.get('lane')) &&
      (el.attributes.position.y >= poolEl.get('dimensions').y1 && el.attributes.position.y <= poolEl.get('dimensions').y2) &&
      (el.attributes.position.x >= poolEl.get('dimensions').x1 && el.attributes.position.x <= poolEl.get('dimensions').x2)))

      poolEmbededElements = _.sortBy(poolEmbededElements, [el => el.attributes.position.y])
      const poolElementsBBox = ctx.graph.getCellsBBox(poolEmbededElements) || ctx.graph.getCellsBBox([poolEl])
      let { x, y, width, height } = poolElementsBBox.inflate(90, 30)
      if (!poolEmbededElements.length) {
        x = poolEl.get('dimensions').x1
        y = poolEl.get('dimensions').y1
      }
      poolEl.set('z', -1)
      poolEl.position(x, y)
      poolEl.size(width, height)

      const poolEmbededElementIds = _.map(poolEmbededElements, 'jsid')
      const embeddedLinks = _.filter(links, (link) => (_.includes(poolEmbededElementIds, _.get(link, 'attributes.source.id', false) || _.get(link, 'attributes.target.id', false))))
      // Embed elements and links into pool
      _.each(_.concat(poolEmbededElements, embeddedLinks), (cell) => {
        poolEl.embed(cell)
      })

      const lanesNEW = _.map(poolLaneElements, (laneElement) => ({
        label: laneElement.get('laneLabel'),
        cells: _.filter(poolEmbededElements, (el) => el.get('lane')),
        size: laneElement.get('laneSize')
      }))

      poolEl.set('lanes', lanesNEW)
    })

    const cellsJSON = _.map(_.concat(elementsWithoutLanes, links), (cell) => this.#cellToDefaultJSON(cell))
    return this.bulkUpdateElements(cellsJSON).then((response) => {
      const cells = _.map(response.result, (id) => (response.entities.entityValues[id]))
      ctx.graph.addCells(cells, { preventEventTrigger: true, preventGraphTrigger: true })
      ctx.paper.setDimensions(page.width, page.height)
      ctx.paper.unfreeze()
      ctx.paperScroller.zoomToFit()
    })
  }
}
