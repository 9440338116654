import Vue from 'vue'
import _ from 'lodash'

let disableIconTranslate = (el) => {    
  let icons = el.querySelectorAll('i')
  _.each(icons, (icon) => {
    if (icon.classList.contains('notranslate')) {
      return //continue
    } else {
      icon.className += ' notranslate'
    }
  })  
}

export default {
  bind(el) {
    disableIconTranslate(el)
  },
  inserted(el) {
    disableIconTranslate(el)
  },
  componentUpdated(el, binding) {
    Vue.nextTick(() => {          
      disableIconTranslate(el)
      if(binding.value) {
        let relatedEls = document.querySelectorAll(binding.value)
        _.map(relatedEls, (relatedEl) => {
          disableIconTranslate(relatedEl)
        })

      }
    })
  }
}

