
import generateGetters from '@/vue/store/utils/generateGetters.vue.js'
import { cmUserStoryItems, cmUserStoryItemStakeholders, cmCategorys, cmIterations, links } from './schema.vue.js'
import { indexes } from './indexes.vue.js'

export default {
  ...generateGetters(cmUserStoryItems, indexes),
  ...generateGetters(cmUserStoryItemStakeholders, indexes),
  ...generateGetters(cmCategorys, indexes),
  ...generateGetters(cmIterations, indexes),
  ...generateGetters(links)
}
