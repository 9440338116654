import { VisioArchive } from '@clientio/rappid-visio'
import VisioElement from './shapes/tr3dent/visioElement.vue.js'
import Link from './shapes/tr3dent/link.vue.js'
import _ from 'lodash'
import { Draft07 } from 'json-schema-library'

const refXMap = {
  start: '1%',
  middle: '50%',
  end: '99%'
}

const refYMap = {
  top: '1%',
  middle: '50%',
  bottom: '99%'
}

export default class {
  paper = null
  graph = null
  paperScroller = null
  entityAttributes = []
  selectedLayerId = null
  skippedImportShape = false
  bulkUpdateElements = (elements) => (Promise.resolve(elements))

  initialize ({
    paper,
    graph,
    paperScroller,
    entityAttributes,
    selectedLayerId
  }) {
    this.paper = paper
    this.graph = graph
    this.paperScroller = paperScroller
    this.entityAttributes = entityAttributes
    this.selectedLayerId = selectedLayerId
  }

  destroy () {
    this.paper = null
    this.graph = null
    this.paperScroller = null
    this.skippedImportShape = false
    this.entityAttributes = []
    this.selectedLayerId = null
  }

  importVisio (file) {
    this.skippedImportShape = false
    return new Promise(resolve => {
      VisioArchive.fromURL(file).then(function (archive) {
        const page = archive.document.getPages()[0]
        this.paper.freeze()
        return page.getContent().then(function (content) {
          const ctx = this
          const cells = content.toGraphCells({
            importShape: this.#importShape.bind(this),
            importConnect: this.#importConnect.bind(this),
            importLabels: this.#importLabels.bind(this),
            onImagesLoad: () => {
              ctx.#addCellsToGraph(page, cells).then(() => {
                resolve(this.skippedImportShape)
              })
            }
          })
        }.bind(this))
      }.bind(this))
    })
  }

  #addCellsToGraph (page, cells) {
    const ctx = this
    const cellsJSON = _.map(cells, (cell) => this.#cellToDefaultJSON(cell))

    return this.bulkUpdateElements(cellsJSON).then((response) => {
      const cells = _.map(response.result, (id) => (response.entities.entityValues[id]))
      ctx.graph.addCells(cells, { preventEventTrigger: true })
      ctx.paper.setDimensions(page.width, page.height)
      ctx.paper.unfreeze()
      ctx.paperScroller.zoomToFit()
    })
  }

  #cellToDefaultJSON (cell) {
    // Set ID to null for new cells.
    const type = cell.get('type')
    const entityAttribute = _.find(this.entityAttributes, { key: type })
    const cellTitle = cell.isLink() ? cell.prop('labels/0/attrs/text/text') : cell.getText()
    const defaultProps = new Draft07(entityAttribute.json_schema).getTemplate({
      entity_attribute_id: entityAttribute.id,
      title: cellTitle,
      type: entityAttribute.key,
      layer_id: this.selectedLayerId,
      mode: 'canvas',
      id: null,
      ...cell.toJSON()
    })
    cell.prop(defaultProps, { silent: true })
    return cell.toJSON()
  }

  #importShape (vsdShape) {
    let attributes = null
    try {
      attributes = vsdShape.toElementAttributes()
    } catch (e) {
      this.skippedImportShape = true
      return null
    }

    if (!attributes) { return null }

    const shapeAttrKey = _.find(_.keys(attributes.attrs), name => name.includes('foreground'))
    const textAttrKey = _.find(_.keys(attributes.attrs), name => name.includes('text'))
    const textAttrsName = textAttrKey || 'text'

    attributes.TEXT_ATTR_PATH = textAttrsName
    attributes.SHAPE_ATTR_PATH = shapeAttrKey || 'foreground'

    // Need to remove the text markup as we don't want to wrap it in the scalable tag.
    const textTags = [..._.remove(attributes.markup, { text: true })]
    const processQueue = [...attributes.markup]
    while (processQueue.length) {
      const tag = processQueue.shift()
      if (!tag.children) { continue }
      textTags.push(..._.remove(tag.children, { text: true }))
      processQueue.push(...tag.children)
    }

    attributes.markup = [{ tagName: 'g', className: 'scalable', children: attributes.markup, selector: 'scalable' }]

    /**
     * If both the outline and fill of the vsdshape shape are defaulted to white, then it is more than likely the shape has used a Visio default
     * style which for unknown reasons is not extracted from the Visio import. Will default these shapes to a light grey
     * fill with black outline to make them pop more against the white canvas.
     */
    const visioShapeFill = _.get(vsdShape, 'cells.fillForegnd', '#ffffff')
    const visioShapeOutline = _.get(vsdShape, 'cells.lineColor', '#ffffff')
    if (visioShapeFill === '#ffffff' && visioShapeOutline === '#ffffff') {
      attributes.attrs[shapeAttrKey].fill = '#D3D3D3'
      attributes.attrs[shapeAttrKey].stroke = '#000000'
    }

    /** Text attribute modifications */
    _.forEach(textTags, (wrappedTextTag) => {
      const textTag = _.first(wrappedTextTag.children)
      attributes.markup.push(_.pick(textTag, ['tagName', 'selector']))

      const textAttrs = attributes.attrs[textTag.selector]

      textAttrs.refX = _.get(refXMap, textAttrs.textAnchor, '50%')
      textAttrs.refY = _.get(refYMap, textAttrs.textVerticalAnchor, '50%')
      textAttrs.textWrap = {
        width: -10,
        ellipsis: true,
        text: textAttrs.text || ''
      }

      const rotate = this.#extractTransformRotationDeg(wrappedTextTag.attributes.transform)
      textAttrs.transform = `rotate(${rotate})`
      delete textAttrs.text
      delete textAttrs.x
      delete textAttrs.y
      delete textAttrs.annotations
      /**
       * If there is an available foreground object in the attrs associated with the text,
       * we should apply the ref to that rather than the inherited ref to the shape
       */
      if (attributes.attrs[`${textTag.selector.split('-')[0]}-foreground`]) {
        textAttrs.ref = `${textTag.selector.split('-')[0]}-foreground`
      }
    })

    if (_.isEmpty(textTags)) {
      // Create default attributes for placheolder text tag
      attributes.markup.push({ tagName: 'text', selector: 'text' })
      attributes.attrs.text = {
        'font-weight': 'Normal',
        fontSize: 13,
        fill: '#000000',
        refX: '50%',
        refY: '50%',
        textVerticalAnchor: 'middle',
        textAnchor: 'middle',
        textWrap: {
          width: -10,
          height: '50%',
          ellipsis: true,
          text: ''
        }
      }
    }

    return new VisioElement(attributes)
  }

  #importConnect (vsdConnect, sourceElement, targetElement) {
    if (!sourceElement || !targetElement) { return null }
    const { z } = vsdConnect.toLinkAttributes(sourceElement, targetElement)

    const link = new Link({
      z,
      source: { id: sourceElement.id },
      target: { id: targetElement.id }
    })
    link.connector('normal')
    link.router('manhattan')
    return link
  }

  #importLabels (vsdShape, link) {
    const text = vsdShape.getText()
    if (!text) { return }
    link.label(0, {
      attrs: {
        text: {
          text
        }
      }
    })
  }

  #extractTransformRotationDeg (transformProperty) {
    const rotateMatch = transformProperty.match(/rotate\((.*?)\) rotate\((.*?)\)/)
    let rotateVal = 0
    if (rotateMatch && rotateMatch.length === 3) {
      const firstRotate = rotateMatch[1].split(/[ ,]+/)
      const firstRotateInt = parseInt(firstRotate[0])
      if (!_.isNaN(firstRotateInt)) { rotateVal += firstRotateInt }

      const secondRotate = rotateMatch[2].split(/[ ,]+/)
      const secondRotateInt = parseInt(secondRotate[0])
      if (!_.isNaN(secondRotateInt)) { rotateVal += secondRotateInt }
    }
    return rotateVal
  }
}
