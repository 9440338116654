
import getters from './canvasModuleState/getters.vue.js'
import mutations from './canvasModuleState/mutations.vue.js'
import initialState from './canvasModuleState/initialState.vue.js'

export default {
  namespaced: true,
  state: initialState(),
  getters,
  mutations
}
