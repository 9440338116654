import Vue from 'vue'
import _ from 'lodash'
const generateStateMutations = () => {
  return {
    SET_PERSISTANT_STATE: (state, { key, value }) => {
      if (!_.has(state, 'persistance')) {
        Vue.set(state, 'persistance', {})
      }
      let existingData = {}
      if (_.has(state.persistance, key)) {
        existingData = state.persistance[key]
      }

      Vue.set(state.persistance, key, _.assign({}, existingData, value))
    }
  }
}
export default generateStateMutations
