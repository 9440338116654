
import actions from './entityAttributesStore/actions.vue.js'
import getters from './entityAttributesStore/getters.vue.js'
import mutations from './entityAttributesStore/mutations.vue.js'
import initialState from './entityAttributesStore/initialState.vue.js'

export default {
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions
}
