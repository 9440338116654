import _ from 'lodash'
import axios from 'axios'
import { Draft07 } from 'json-schema-library'
import * as joint from '@clientio/rappid'
import haloConfig from './haloConfig.vue.js'
import ExportCss from './export.raw.css'
import * as alignmentIcon from '@/assets/images/alignment.png'

const defaultPaperOptions = {
  gridSize: 10,
  drawGrid: false,
  defaultRouter: { name: 'normal' },
  validateConnection: (cellViewS, magnetS, cellViewT, magnetT, end) => {
    const view = (end === 'target' ? cellViewT : cellViewS)
    if (cellViewS === cellViewT) return false
    if (view.model.prop('celltype') === 'cantTouchThis') return false
    if (joint.dia.HighlighterView.get(view, 'layer-highlight')) { return false }
    return !view.model.isLink()
  },
  linkPinning: false,
  frontParentOnly: false,
  embeddingMode: false
}

const defaultHighlightParams = {
  padding: 10,
  rx: 5,
  ry: 5,
  attrs: {
    'stroke-width': 3,
    'stroke-dasharray': '0',
    stroke: '#FFFFFF'
  }
}

const alignCalcMap = {
  'align-left': {
    axis: 'x',
    calculate: (selBox, elSize) => selBox.x
  },
  'align-right': {
    axis: 'x',
    calculate: (selBox, elSize) => selBox.x + selBox.width - elSize.width
  },
  'align-top': {
    axis: 'y',
    calculate: (selBox, elSize) => selBox.y
  },
  'align-bottom': {
    axis: 'y',
    calculate: (selBox, elSize) => selBox.y + selBox.height - elSize.height
  },
  'align-v-center': {
    axis: 'x',
    calculate: (selBox, elSize) => selBox.x + ((selBox.width - elSize.width) / 2)
  },
  'align-h-center': {
    axis: 'y',
    calculate: (selBox, elSize) => selBox.y + ((selBox.height - elSize.height) / 2)
  }
}

export default class Diagrams {
  rootVue = null
  graph = null
  paper = null
  keyboard = null
  selection = null
  commandManager = null
  paperScroller = null
  halo = null
  freeTransform = null
  snaplines = null
  toolbarMenu = null
  loaded = false
  moduleEntityValue = null
  debouncers = {}
  eventHandlers = {}
  imageBlobs = {}
  elementsToDelete = []
  elementsToUpdate = []
  scrollIteration = 0
  prevDirection = null
  cachedInteractive = null
  promiseQueue = []
  shapeFactories = []
  shapes = []

  createElement = (element) => (Promise.resolve(element))
  cloneElement = (element) => (Promise.resolve(element))
  updateElement = (element) => (Promise.resolve(element))
  bulkUpdateElements = () => (Promise.resolve())
  destroyElement = (element) => (Promise.resolve(element))
  bulkDestroyElements = () => (Promise.resolve())
  uploadImage = () => (Promise.resolve())

  constructor (Vue) {
    this.rootVue = Vue
  }

  /* Public Methods (Canvas) */

  initialize ({
    htmlElement,
    graphOptions = {},
    paperOptions = {},
    shapeFactories,
    entityAttributes,
    moduleEntityValue,
    layerEntityValues
  }) {
    this.moduleEntityValue = moduleEntityValue
    this.entityAttributes = entityAttributes
    this.layerEntityValues = layerEntityValues
    this.shapeFactories = shapeFactories

    this.shapes = _.reduce(this.shapeFactories, (shapes, ShapeFactory) => {
      return _.merge(shapes, new ShapeFactory().namespacedShapePrototypes())
    }, {})

    const showGridlines = _.get(this.moduleEntityValue, 'show_gridlines', false)
    const defaultConnectionType = _.get(this.moduleEntityValue, 'default_connection_type', 'normal')
    const embeddingMode = _.get(this.moduleEntityValue, 'embedding_enabled', false)
    const clientRect = htmlElement.getBoundingClientRect()
    const defaultAnchor = _.get(this.moduleEntityValue, 'default_anchor', { name: 'center' })
    const defaultConnectionPoint = _.get(this.moduleEntityValue, 'default_connection_point', {
      name: 'bbox',
      args: {
        offset: 8,
        stroke: true
      }
    })

    const optionsFromValues = {
      gridSize: showGridlines ? 10 : 1,
      drawGrid: showGridlines,
      embeddingMode,
      width: clientRect.width,
      height: clientRect.height,
      defaultAnchor,
      defaultConnectionPoint,
      validateEmbedding: function (childView, parentView) {
        return parentView.model.get('embedding_enabled') &&
        !childView.model.get('embedding_enabled') &&
        !joint.dia.HighlighterView.get(parentView, 'layer-highlight')
      }
    }
    const linkEntityAttribute = _.find(this.entityAttributes, { key: _.get(this.moduleEntityValue, 'default_link_key', 'tr3dent.Link') })
    const defaultLinkProps = new Draft07(linkEntityAttribute.json_schema).getTemplate({
      entity_attribute_id: linkEntityAttribute.id,
      type: linkEntityAttribute.key,
      mode: 'canvas',
      connector: defaultConnectionType,
      id: null
    })
    const LinkConstructor = _.get(this.shapes, linkEntityAttribute.key, null)
    const defaultLink = new LinkConstructor(defaultLinkProps)
    defaultLink.connector(defaultConnectionType, {
      type: 'gap'
    })
    this.graph = new joint.dia.Graph(graphOptions, { cellNamespace: this.shapes })

    const combinedPaperOptions = _.assign(
      defaultPaperOptions,
      optionsFromValues,
      paperOptions,
      {
        defaultLink,
        model: this.graph,
        cellViewNamespace: this.shapes
      }
    )
    this.cachedInteractive = paperOptions.interactive
    combinedPaperOptions.interactive = (cellView) => {
      return this.#modelInteractive(cellView.model) &&
        !joint.dia.HighlighterView.get(cellView, 'layer-highlight')
    }

    this.paper = new joint.dia.Paper(combinedPaperOptions)
    this.keyboard = new joint.ui.Keyboard()
    this.selection = new joint.ui.Selection({
      paper: this.paper,
      graph: this.graph,
      boxContent: '',
      filter: ['tr3dent.XAxis',
        'tr3dent.YAxis',
        'tr3dent.QuadTopLeft',
        'tr3dent.QuadTopRight',
        'tr3dent.QuadBottomLeft',
        'tr3dent.QuadBottomRight'],
      strictSelection: true
    })
    this.selection.addHandle({
      name: 'align-shape',
      position: 'ne',
      icon: alignmentIcon.default
    })

    this.commandManager = new joint.dia.CommandManager({
      graph: this.graph,
      cmdBeforeAdd: (cmdName, cell, args, options) => {
        if (!args) { return false }
        if (cell.isLink() && !cell.isConnected()) { return false }
        if (cmdName === 'remove' && cell.get('data_catalog_detail_entity_value_ids') && cell.get('data_catalog_detail_entity_value_ids').length && !options.preventEventTrigger) {
          cell.set('data_catalog_detail_entity_value_ids', [])
        }
        if (cmdName === 'remove' && !!cell.get('image') && !!cell.get('image').url) {
          axios.get(cell.get('image').url, { responseType: 'blob' }).then((response) => {
            /* It would be better to set this on the cell, but cmdBeforeAdd cannot handle async operations. */
            this.imageBlobs[cell.get('image').url] = response.data
          })
        }
        return !!args.forceCommandManager || (cmdName !== 'change:target' && cmdName !== 'change:type')
      }
    })
    const app = document.querySelector('[data-app]')
    this.paperScroller = new joint.ui.PaperScroller({
      paper: this.paper,
      autoResizePaper: true,
      scrollWhileDragging: true,
      borderless: true,
      padding: {
        left: app.clientWidth,
        right: app.clientWidth,
        top: app.clientHeight,
        bottom: app.clientHeight
      }
    })

    this.snaplines = new joint.ui.Snaplines({ paper: this.paper, distance: 10 })

    this.#bindEventHandlers()
    this.paper.el.addEventListener('wheel', this.#preventSelectionBoxEvents.bind(this))
    this.paper.el.style.backgroundColor = 'transparent'
    this.paperScroller.$el.appendTo(htmlElement)

    this.loaded = true
  }

  destroy () {
    this.paper.el.removeEventListener('wheel', this.#preventSelectionBoxEvents.bind(this))
    this.graph.off()
    this.paper.off()
    this.keyboard.off()
    this.selection.collection.off()
    this.selection.off()
    this.commandManager.off()
    this.paperScroller.off()
    if (this.toolbarMenu) { this.toolbarMenu.off() }

    this.graph.clear()
    this.paper.remove()

    this.graph = null
    this.paper = null
    this.keyboard = null
    this.selection = null
    this.commandManager = null
    this.paperScroller = null
    this.snaplines = null
    this.stencil = null
    this.toolbarMenu = null
    this.loaded = false
    this.halo = null
    this.freeTransform = null
    this.entityAttributes = []
    this.layerEntityValues = null
    this.moduleEntityValue = null
    this.cachedInteractive = null
    this.debouncers = {}
    this.imageBlobs = {}
    this.promiseQueue = []
    this.elementsToDelete = []
    this.elementsToUpdate = []
    this.#emit('destroyed')
    this.eventHandlers = {}
  }

  on (eventName, eventHandler) {
    if (!_.has(this.eventHandlers, eventName)) {
      this.eventHandlers[eventName] = []
    }
    this.eventHandlers[eventName].push(eventHandler)
  }

  off (eventName, eventHandler) {
    if (!eventHandler) {
      this.eventHandlers[eventName] = []
    } else {
      _.pull(this.eventHandlers[eventName], eventHandler)
    }
  }

  loadGraphJson (jsonData) {
    const ctx = this
    const santizedData = ctx.#sanitizeJsonData(jsonData)
    ctx.graph.fromJSON({
      cells: santizedData
    })

    ctx.paperScroller.adjustPaper()

    if (ctx.graph.getCells().length > 0) {
      ctx.paperScroller.zoomToFit({
        padding: 50,
        minScaleX: 0.2,
        minScaleY: 0.2,
        maxScaleX: 5,
        maxScaleY: 5
      })
    } else {
      ctx.paperScroller.center()
    }

    if (ctx.paper.options.drawGrid) {
      ctx.snaplines.startListening()
    }
    ctx.selection.collection.reset()
    this.#emit('loaded')
  }

  zoomIn () {
    const currentZoomLevel = this.paperScroller.zoom()
    let zoomLevel = (currentZoomLevel + 0.2)
    zoomLevel = Math.round(zoomLevel * 10) / 10
    if (zoomLevel > 5) {
      zoomLevel = 5
    }
    this.paperScroller.zoom(0.2, { max: 5, grid: 0.2 })
    this.#emit('zoom', zoomLevel)
  }

  zoomOut () {
    const currentZoomLevel = this.paperScroller.zoom()
    let zoomLevel = (currentZoomLevel - 0.2)
    zoomLevel = (Math.round(zoomLevel * 10) / 10)
    this.paperScroller.zoom(-0.2, { min: 0.2, grid: 0.2 })
    this.#emit('zoom', zoomLevel)
  }

  fitContent () {
    this.paperScroller.zoomToFit({
      padding: 50,
      minScaleX: 0.2,
      minScaleY: 0.2,
      maxScaleX: 5,
      maxScaleY: 5
    })
  }

  sendToBack () {
    this.graph.startBatch('selection')
    this.selection.collection.models.forEach(function (model) { model.toBack() })
    _.chain(this.graph.getCells())
      .filter((cell) => cell.get('celltype') === 'cantTouchThis')
      .map((cell) => cell.toBack())
      .value()
    this.graph.stopBatch('selection')
  }

  sendToFront () {
    this.graph.startBatch('selection')
    this.selection.collection.models.forEach(function (model) { model.toFront() })
    this.graph.stopBatch('selection')
  }

  print () {
    this.#clearPaperTools()
    this.paper.print({
      sheet: {
        width: 297,
        height: 210
      },
      margin: { left: 0.1, top: 0.1, bottom: 0.1, right: 0.1 },
      ready: function (pages, send, opt) {
        send(pages)
      }
    })
  }

  toggleComments () {
    this.#emit('show-comments')
  }

  toggleProperties () {
    this.#emit('show-properties')
  }

  undo () {
    this.commandManager.undo()
  }

  redo () {
    this.commandManager.redo()
  }

  /**
   * Called by external components for automated select and transition for a shape.
   */
  selectItem (jsid, meta) {
    const ctx = this
    const cell = ctx.graph.getCell(jsid)
    ctx.paperScroller.transitionToRect(cell.getBBox(), {
      visibility: 0.5,
      timingFunction: 'ease-out',
      delay: '10ms',
      scaleGrid: 0.2
    })
    ctx.selection.collection.reset([cell])
  }

  selectItemsByCondition (condition) {
    this.#clearPaperTools()
    const cells = this.graph.getCells()
    const selectedCells = _.filter(cells, (cell) => condition(cell))
    this.selection.collection.reset(selectedCells)
  }

  setPaperAttributes (moduleEntityValue) {
    this.moduleEntityValue = moduleEntityValue
    // Gridlines
    this.paper.options.drawGrid = moduleEntityValue.show_gridlines
    this.paper.options.gridSize = moduleEntityValue.show_gridlines ? 10 : 1
    !moduleEntityValue.show_gridlines ? this.paper.clearGrid() : this.paper.drawGrid()
    // Snaplines
    if (moduleEntityValue.show_snaplines) {
      this.snaplines.startListening()
      this.paper.options.snaplines = this.snaplines
    } else {
      this.snaplines.stopListening()
      this.paper.options.snaplines = null
    }
    // Connection Type
    this.paper.options.defaultLink.connector(moduleEntityValue.default_connection_type, { type: 'gap' })
  }

  setElementAttributes (elementAttributes) {
    const attrs = _.castArray(elementAttributes)
    if (attrs.length > 1) { this.graph.startBatch('bulkChange') }
    _.each(attrs, (attr) => {
      const element = this.graph.getCell(attr.jsid)
      element.set(attr)
    })
    if (attrs.length > 1) { this.graph.stopBatch('bulkChange') }
  }

  showCells (condition) {
    const cells = this.graph.getCells()
    const matchingCells = _.filter(cells, (cell) => condition(cell))

    this.selection.collection.reset()
    this.#clearPaperTools()

    _.forEach(matchingCells, (cell) => {
      const cellView = this.paper.findViewByModel(cell)
      joint.dia.HighlighterView.remove(cellView, 'layer-highlight')
    })
  }

  hideCells (condition) {
    const cells = this.graph.getCells()
    const matchingCells = _.filter(cells, (cell) => condition(cell))
    this.selection.collection.reset()
    this.#clearPaperTools()

    _.forEach(matchingCells, (cell) => {
      const cellView = this.paper.findViewByModel(cell)
      joint.highlighters.addClass.add(cellView, 'root', 'layer-highlight', { className: 'diagram-highlight-opacity' })
    })
  }

  highlightCells (condition, highlightName, highlightParams) {
    const cells = this.graph.getCells()
    const matchingCells = _.filter(cells, (cell) => condition(cell))
    _.forEach(matchingCells, (cell) => {
      const cellView = this.paper.findViewByModel(cell)
      joint.highlighters.mask.add(
        cellView,
        { selector: 'root' },
        highlightName,
        _.merge(defaultHighlightParams, highlightParams, cell.highlightParams || {})
      )
    })
  }

  unhighlightCells (condition, highlightName) {
    const cells = this.graph.getCells()
    const matchingCells = _.filter(cells, (cell) => condition(cell))
    _.forEach(matchingCells, (cell) => {
      const cellView = this.paper.findViewByModel(cell)
      joint.dia.HighlighterView.remove(cellView, highlightName)
    })
  }

  filterLinks (condition) {
    const links = this.graph.getLinks()
    const filteredLinks = _.reject(links, (link) => condition(link))
    const unFilteredLinks = _.filter(links, (link) => condition(link))

    _.forEach(filteredLinks, (link) => {
      const linkView = link.findView(this.paper)
      joint.highlighters.addClass.add(linkView, 'root', 'filterLinks', { className: 'filter-links-css' })
    })
    _.forEach(unFilteredLinks, (link) => {
      const linkView = link.findView(this.paper)
      joint.dia.HighlighterView.remove(linkView, 'filterLinks')
    })
  }

  #bindEventHandlers () {
    /* Graph Events */

    this.graph.on('add', this.#graphOnAdd.bind(this))
    this.graph.on('remove', this.#graphOnRemove.bind(this))
    this.graph.on('change', this.#graphOnChange.bind(this))
    this.graph.on('change:type', this.#cellChangeType.bind(this))
    this.graph.on('change:locked', this.#cellChangeLocked.bind(this))

    /* Paper Events */

    this.paper.on('link:connect', this.#paperOnLinkConnect.bind(this))
    this.paper.on('blank:pointerdown', this.#paperOnBlankPointerDown.bind(this))
    this.paper.on('blank:contextmenu', this.#paperOnBlankContextMenu.bind(this))
    this.paper.on('blank:pointerup', this.#paperOnBlankPointerUp.bind(this))
    this.paper.on('blank:mousewheel', this.#paperOnBlankMouseWheel.bind(this))
    this.paper.on('cell:mousewheel', this.#paperOnCellMouseWheel.bind(this))
    this.paper.on('cell:pointerdown', this.#paperOnCellPointerDown.bind(this))
    this.paper.on('cell:mouseover', this.#paperOnCellMouseOver.bind(this))
    this.paper.on('cell:mouseenter', this.#paperOnCellMouseEnter.bind(this))
    this.paper.on('cell:mouseleave', this.#paperOnCellMouseLeave.bind(this))
    this.paper.on('cell:mouseleave', () => {
      this.#emit('element-mouseleave')
    })
    this.paper.on('cell:contextmenu', this.#paperOnCellContextMenu.bind(this))
    this.paper.on('link:pointerup', this.#paperOnLinkPointerUp.bind(this))
    this.paper.on('element:pointerup', this.#paperOnElementPointerUp.bind(this))

    /* Command Manager Events */

    this.commandManager.on('stack', this.#commandManagerChange.bind(this))

    /* Scroller Events */

    this.paperScroller.on('pan:start', this.#scrollerPanStart.bind(this))
    this.paperScroller.on('pan:start', () => {
      this.#emit('pan-start')
    })
    this.paperScroller.on('pan:stop', this.#scrollerPanEnd.bind(this))
    this.paperScroller.on('pan:stop', () => {
      this.#emit('pan-stop')
    })
    /* Selection Box Events */
    this.selection.on('selection-box:pointerdown', this.#selectionPointerDown.bind(this))
    this.selection.on('selection-box:pointerup', this.#selectionPointerUp.bind(this))
    this.selection.on('selection-box:pointermove', this.#selectionPointerMove.bind(this))
    this.selection.on('action:align-shape:pointerdown', this.#selectionAlignShape.bind(this))
    /* Selection Element Events */

    this.selection.collection.on('add', this.#selectionCollectionAdd.bind(this))
    this.selection.collection.on('reset remove', this.#selectionCollectionChange.bind(this))
    this.selection.collection.on('change', this.#selectionElementChange.bind(this))

    /* Keyboard Events */
    this.keyboard.on('keydown:up keydown:down keydown:left keydown:right', this.#onArrowKeyDown.bind(this))
    this.keyboard.on('keyup:up keyup:down keyup:left keyup:right', this.#onArrowKeyUp.bind(this))
  }

  /* Private Helpers */

  #sanitizeJsonData (jsonData) {
    const validCellIds = _.map(jsonData, 'jsid')
    return _.reduce(jsonData, (sanitisedCells, cell) => {
      const cellLayerId = _.get(cell, 'layer_id', null)
      if (_.includes(['businessProcess.Flow', 'tr3dent.Link'], cell.type)) {
        // Fix links
        const sourceId = _.get(cell, 'source.id')
        const targetId = _.get(cell, 'target.id')
        if (sourceId && targetId &&
          (!_.includes(validCellIds, sourceId) ||
           !_.includes(validCellIds, targetId))) {
          this.destroyElement(cell)
        } else {
          sanitisedCells.push(cell)
        }
      } else if (cell.embeds && cell.embeds.length) {
        // Fix embeds
        const validEmbeds = _.intersection(validCellIds, cell.embeds)
        if (validEmbeds.length !== cell.embeds.length) {
          cell.embeds = validEmbeds
          this.updateElement(cell)
        }
        sanitisedCells.push(cell)
      } else if (cell.celltype !== 'cantTouchThis' && (!cellLayerId || !_.find(this.layerEntityValues, { id: cellLayerId }))) {
        cell.layer_id = _.get(_.first(this.layerEntityValues), 'id', null)
        this.updateElement(cell)
        sanitisedCells.push(cell)
      } else {
        // Valid Cell
        sanitisedCells.push(cell)
      }
      return sanitisedCells
    }, [])
  }

  #updateGraphState () {
    const ctx = this
    if (!ctx.debouncers.graph) {
      ctx.debouncers.graph = _.debounce(() => {
        ctx.#queueGraphSave()
      }, 700)
    }

    ctx.debouncers.graph()
  }

  #queueGraphSave () {
    if (this.promiseQueue.length <= 0) { // Immediate Save.
      const promise = this.#graphSavePromise()
      this.promiseQueue.push(promise)
    } else if (this.promiseQueue.length === 1) { // Save in progress, defer this one till it completes.
      const promise = this.promiseQueue[0].then(this.#graphSavePromise.bind(this))
      this.promiseQueue.push(promise)
    } // else drop save, one is already in progress, and another queued
  }

  #graphSavePromise () {
    const ctx = this
    if (!ctx.graph) { return Promise.resolve() }
    const elementToUpdateIds = _.map(this.elementsToUpdate, 'id')
    const cellsToUpdate = _.filter(ctx.graph.toJSON().cells, (cell) => (_.includes(elementToUpdateIds, cell.jsid)))
    this.elementsToUpdate = []
    return ctx.bulkUpdateElements(cellsToUpdate).then(() => {
      let deletePromise = Promise.resolve()
      const deleteQueue = _.map(ctx.elementsToDelete, (el) => (el.toJSON()))
      if (deleteQueue.length > 0) {
        ctx.elementsToDelete = []
        deletePromise = ctx.bulkDestroyElements(deleteQueue)
      }
      return deletePromise.then(() => {
        this.promiseQueue.shift()
        ctx.#updateDiagramSnapshot()
      })
    }, () => {
      this.promiseQueue = []
    })
  }

  #modelInteractive (model) {
    return this.cachedInteractive(model) &&
    model.get('celltype') !== 'cantTouchThis' &&
    !model.get('locked')
  }

  /* Graph Event Handlers */

  #graphOnAdd (cell, model, meta) {
    if (!this.#modelInteractive(cell)) { return }
    if (cell.isLink() && !cell.isConnected()) { return }
    if (meta.preventEventTrigger) { return }

    const promise = meta.cloned ? this.cloneElement : this.createElement
    promise(cell.toJSON()).then((savedCellData) => {
      const savedCell = this.graph.getCell(savedCellData.jsid)
      const originalImageUrl = _.get(savedCell.get('image'), 'url')
      savedCell.prop(savedCellData, { silent: true })

      if (savedCell.get('type') === 'tr3dent.Image' && meta.cloned) {
        savedCell.setImageIfExists()
      }

      if (originalImageUrl && this.imageBlobs[originalImageUrl]) {
        this.#uploadImageFromBlob(savedCell, this.imageBlobs[originalImageUrl])
        delete this.imageBlobs[originalImageUrl]
      }

      const savedCellView = this.paper.findViewByModel(savedCell)
      savedCellView.pointerup({})

      this.#updateDiagramSnapshot()
    })
  }

  #graphOnRemove (cell, model, meta) {
    if (!this.#modelInteractive(cell)) { return }
    if (cell.isLink() && !cell.isConnected()) { return }
    if (_.get(meta, 'preventEventTrigger', false)) { return }
    if (!_.get(meta, 'silentRemove', false)) {
      this.selection.collection.reset()
    }
    this.elementsToDelete = _.unionBy(this.elementsToDelete, [cell], 'id')
    this.#updateGraphState()
  }

  #graphOnChange (cell) {
    if (!this.#modelInteractive(cell)) { return }
    if (cell.isLink() && !cell.isConnected()) { return }
    if (!cell.get('id')) { return }
    if (!cell.changed) { return }
    this.elementsToUpdate = _.unionBy(this.elementsToUpdate, [cell], 'id')
    this.#updateGraphState()
  }

  #cellChangeType (cell, type) {
    const entityAttribute = _.find(this.entityAttributes, { key: type })
    const defaultProps = new Draft07(entityAttribute.json_schema).getTemplate({
      entity_attribute_id: entityAttribute.id,
      project_scenario_module_id: cell.attributes.project_scenario_module_id,
      type: entityAttribute.key,
      id: null
    })
    const Shape = _.get(this.shapes, type, null)
    const newShape = new Shape(defaultProps)
    _.unionAssign(newShape.attributes, cell.attributes, { omit: ['refPoints', 'entity_attribute_id', 'jsid'] })

    this.commandManager.initBatchCommand()
    cell.set('type', cell.previous('type'), { silent: true })
    this.graph.addCell(newShape, { preventEventTrigger: true })

    _.each(this.graph.getConnectedLinks(cell), (link) => {
      if (link.getTargetCell().get('jsid') === cell.get('jsid')) {
        link.target(newShape, { forceCommandManager: true })
      } else if (link.getSourceCell().get('jsid') === cell.get('jsid')) {
        link.source(newShape)
      }
    })
    cell.remove({ preventEventTrigger: true })
    this.commandManager.storeBatchCommand()
    this.#graphOnChange(newShape)
  }

  #cellChangeLocked (cell) {
    this.selection.collection.reset(this.selection.collection.models)
    this.elementsToUpdate = _.unionBy(this.elementsToUpdate, [cell], 'id')
    this.#updateGraphState()
  }

  /* Paper Event Handlers */

  #paperOnLinkConnect (cellView, model, meta) {
    const cell = cellView.model
    const promise = cell.get('id') ? this.updateElement : this.createElement
    promise(cell.toJSON()).then((savedCellData) => {
      const savedCell = this.graph.getCell(savedCellData.jsid)
      savedCell.prop(savedCellData, { silent: true })
      savedCell.adjustVertices(cell)

      this.#updateDiagramSnapshot()

      const savedCellView = this.paper.findViewByModel(savedCell)
      savedCellView.pointerup({})
    })
  }

  #paperOnBlankPointerDown (evt, x, y) {
    this.selection.startSelecting(evt, x, y)
  }

  #paperOnBlankContextMenu (evt, x, y) {
    this.paperScroller.startPanning(evt, x, y)
  }

  #paperOnBlankPointerUp (evt) {
    this.#clearPaperTools()
  }

  #paperOnBlankMouseWheel (evt, ox, oy, delta) {
    this.#mouseWheelScroll(evt, ox, oy, delta)
  }

  #paperOnCellMouseWheel (cellView, evt, ox, oy, delta) {
    this.#mouseWheelScroll(evt, ox, oy, delta)
  }

  #paperOnCellMouseOver (evt) {
    this.#emit('element-mouseover', evt)
  }

  #paperOnCellPointerDown (cellView, evt, x, y) {
    this.#clearPaperTools()
    if (!this.paper.options.interactive(cellView)) {
      return this.#paperOnBlankPointerDown(evt, x, y)
    }
  }

  #paperOnCellMouseEnter (cellView) {
    const model = cellView.model
    if (!model) { return }
    if (model.get('mode') === 'stencil') { return }
    if (model.get('cellType') === 'cantTouchThis') { return }
    if (joint.dia.HighlighterView.get(cellView, 'layer-highlight')) { return }

    if (model.isLink()) {
      const linkSelected = this.selection.collection.find(function (cell) { return cell.id === model.id })
      if (linkSelected) { return }
      joint.highlighters.mask.add(
        cellView,
        { selector: 'root' },
        'link-highlight',
        {
          deep: true,
          padding: 2,
          attrs: {
            stroke: '#FEB663',
            'stroke-width': 2,
            'stroke-linejoin': 'round',
            'stroke-linecap': 'round'
          }
        })
    } else {
      cellView.highlight()
    }
  }

  #paperOnCellMouseLeave (cellView) {
    const model = cellView.model
    if (!model) { return }
    if (model.get('mode') === 'stencil') { return }
    if (model.get('cellType') === 'cantTouchThis') { return }
    if (joint.dia.HighlighterView.get(cellView, 'layer-highlight')) { return }
    if (model.isLink() && joint.dia.HighlighterView.get(cellView, 'link-highlight')) {
      joint.dia.HighlighterView.get(cellView, 'link-highlight').remove()
    } else {
      cellView.unhighlight()
    }
  }

  #paperOnCellContextMenu (cellView, evt, x, y) {
    this.paperScroller.startPanning(evt, x, y)
  }

  #paperOnLinkPointerUp (linkView) {
    if (linkView.model.isConnected()) {
      joint.highlighters.mask.remove(linkView)
      this.selection.collection.reset([linkView.model])
      // If interactive box is provided by linkTools
      if (this.paper.options.interactive(linkView)) {
        this.selection.destroySelectionBox(linkView.model)
      }
      this.toggleProperties()
    }
  }

  #paperOnElementPointerUp (elementView, evt) {
    const model = elementView.model
    if (model.get('mode') === 'stencil') { return }
    if (joint.dia.HighlighterView.get(elementView, 'layer-highlight')) { return }

    // Selection
    if (this.keyboard.isActive('ctrl meta', evt)) {
      this.selection.collection.add(model)
    } else {
      this.selection.collection.reset([model], evt)
    }
    this.toggleProperties()
  }

  /* Command Manager Event Handlers */

  #commandManagerChange () {
    this.#emit('command', {
      hasUndo: this.commandManager.hasUndo(),
      hasRedo: this.commandManager.hasRedo()
    })
  }

  /* Scroller Event Handlers */

  #scrollerPanStart () {
    this.paperScroller.setCursor('grab')
  }

  #scrollerPanEnd () {
    this.paperScroller.setCursor('default')
  }

  /* Selection Event Handlers */

  #selectionPointerDown (cellView, evt, x, y) {
    if (evt.originalEvent.button === 2) {
      evt.preventDefault()
      this.paperScroller.startPanning(evt, x, y)
    }
  }

  #selectionPointerUp (cellView, evt) {
    if (evt.originalEvent.button === 2) { return }
    if (!cellView) { return }
    const moved = cellView.model.get('isMoving')
    // If not moved, was click and not drag
    if (moved) {
      cellView.model.set('isMoving', false, { silent: true })
      return
    }
    if (this.selection.collection.length > 1 &&
        this.keyboard.isActive('ctrl meta', evt)) {
      this.selection.collection.remove(cellView.model)
    }
  }

  #selectionPointerMove (cellView) {
    cellView.model.set('isMoving', true, { silent: true })
  }

  #selectionAlignShape (evt) {
    this.toolbarMenu = new joint.ui.ContextToolbar({
      target: evt.target,
      vertical: true,
      tools: [
        { action: 'align-left', content: 'Align Left' },
        { action: 'align-right', content: 'Align Right' },
        { action: 'align-top', content: 'Align Top' },
        { action: 'align-bottom', content: 'Align Bottom' },
        { action: 'align-v-center', content: 'Center Vertically' },
        { action: 'align-h-center', content: 'Center Horizontally' }
      ]
    })
    this.toolbarMenu.on({
      'action:align-left': () => this.#alignItems('align-left'),
      'action:align-right': () => this.#alignItems('align-right'),
      'action:align-top': () => this.#alignItems('align-top'),
      'action:align-bottom': () => this.#alignItems('align-bottom'),
      'action:align-v-center': () => this.#alignItems('align-v-center'),
      'action:align-h-center': () => this.#alignItems('align-h-center'),
      all: () => this.toolbarMenu.remove()
    })
    this.toolbarMenu.render()
  }

  /* Selection Element Event Handlers */
  #selectionCollectionAdd (cell, collection, meta) {
    this.#selectionCollectionChange(collection, meta)
  }

  #selectionCollectionChange (collection, meta) {
    const selectionInteractive = _.every(this.selection.collection.models, (cell) => {
      return this.#modelInteractive(cell)
    })
    if (this.selection.collection.length <= 0) {
      const cantTouchThisCells = _.filter(this.graph.getCells(), (cell) => { return cell.get('celltype') === 'cantTouchThis' })
      this.selection.collection.reset(_.orderBy(cantTouchThisCells, [cell => cell.get('entity_order')], ['asc']), { silent: true })
      meta.cantTouchThisCells = true
    } else if (this.selection.collection.length === 1) {
      const model = this.selection.collection.models[0]
      const view = this.paper.findViewByModel(model)
      if (!view) { return }
      this.#clearPaperTools()
      if (selectionInteractive) {
        if ((this.shapes.businessProcess &&
          model instanceof this.shapes.businessProcess.Pool &&
          meta.target &&
          meta.target.getAttribute('laneGroupId'))) {
          this.#initializePoolLaneBoundary(view, meta)
        } else if (model.isLink()) {
          this.#initializeLinkToolsView(view)
        } else {
          this.#initializeHaloFreeTransform(view)
        }
      }
      meta.selectionEl = this.paper.findViewByModel(model).$el[0]
    } else if (this.selection.collection.length > 1 && !this.cachedInteractive()) {
      this.selection.collection.reset()
    }

    if (this.selection.collection.length > 1) {
      // Selection Wrapper
      const selectionWrapperEl = this.selection.$el[0].querySelector('.selection-wrapper')
      meta.selectionEl = selectionWrapperEl
      selectionWrapperEl.classList.toggle('readonly', !selectionInteractive)
    }
    this.#emit('select', {
      collection: this.selection.collection.toArray(),
      meta: meta
    })
  }

  onLayerChange (layerId) {
    if (_.some(this.selection.collection.models, (el) => (el.get('layer_id') === layerId))) {
      this.selection.collection.reset(this.selection.collection.models)
    }
  }

  #selectionElementChange (cell) {
    const meta = {}
    if (cell.get('celltype') === 'cantTouchThis') {
      meta.cantTouchThisCells = true
    }
    this.#emit('select', {
      collection: this.selection.collection.toArray(),
      meta: meta
    })
  }

  /* Keyboard */

  #onArrowKeyDown (e) {
    e.preventDefault()

    if (this.prevDirection && this.prevDirection !== e.code) {
      this.scrollIteration = 0
    }
    this.prevDirection = e.code

    this.scrollIteration++

    let dx = 0
    let dy = 0

    if (e.code === 'ArrowUp') { dy = -1 }
    if (e.code === 'ArrowDown') { dy = 1 }
    if (e.code === 'ArrowRight') { dx = 1 }
    if (e.code === 'ArrowLeft') { dx = -1 }

    // Quadratic Ease Out Function
    const easing = (iteration) => {
      return 1 - (1 - iteration) * (1 - iteration)
    }

    const maxSpeed = 75
    const easedDistance = easing(this.scrollIteration / maxSpeed) * maxSpeed
    const distance = Math.floor(easedDistance, maxSpeed)

    dy *= distance
    dx *= distance

    const selectedEls = this.selection.collection.toArray()
    if (selectedEls.length &&
      _.some(selectedEls, (c) => c.isElement())
    ) {
      _.each(selectedEls, (el) => {
        el.translate(dx, dy)
        if (!this.paperScroller.isElementVisible(el, { strict: true })) {
          const currentCenter = this.paperScroller.getCenter()
          this.paperScroller.center(currentCenter.x + dx, currentCenter.y + dy)
        }
      })
    } else {
      const currentCenter = this.paperScroller.getCenter()
      this.paperScroller.center(currentCenter.x + dx, currentCenter.y + dy)
    }
  }

  #onArrowKeyUp (e) {
    this.scrollIteration = 0
    this.prevDirection = null
  }

  /* Private Methods */

  #emit (eventName, payload) {
    if (_.has(this.eventHandlers, eventName)) {
      _.each(this.eventHandlers[eventName], (cb) => cb(payload))
    }
  }

  #clearPaperTools () {
    joint.ui.Halo.clear(this.paper)
    joint.ui.FreeTransform.clear(this.paper)
    joint.ui.Popup.close()
    this.paper.removeTools()
  }

  #mouseWheelScroll (evt, ox, oy, delta) {
    evt.preventDefault()
    this.paperScroller.zoom(delta * 0.2, { min: 0.2, max: 5, grid: 0.2, ox: ox, oy: oy })
  }

  #alignItems (direction) {
    const elements = this.selection.collection.toArray()
    const selectionBox = this.graph.getCellsBBox(elements)

    this.graph.startBatch('alignment')
    elements.forEach((element) => {
      const alignCalc = alignCalcMap[direction]
      element.prop(
        ['position', alignCalc.axis],
        alignCalc.calculate(selectionBox, element.size())
      )
    })
    this.graph.stopBatch('alignment')
  }

  #uploadImageFromBlob (cell, imageBlob) {
    const ctx = this
    const formData = new FormData()

    formData.append('image', imageBlob, `${cell.id}-image.png`)
    formData.append('id', cell.get('id'))
    ctx.uploadImage(formData).then((response) => {
      cell.set(_.pick(response.data, ['jsid', 'image']))
    })
  }

  #updateDiagramSnapshot () {
    const ctx = this
    if (!this.debouncers.snapshot) {
      this.debouncers.snapshot = _.debounce(function () {
        ctx.paper.toPNG((imageData) => {
          const formData = new FormData()
          const blob = joint.util.dataUriToBlob(imageData)
          formData.append('image', blob, 'diagram.png')
          formData.append('id', ctx.moduleEntityValue.id)
          ctx.uploadImage(formData)
        }, {
          padding: 10,
          backgroundColor: 'transparent',
          useComputedStyles: false,
          stylesheet: ExportCss
        })
      }, 2000)
    }

    this.debouncers.snapshot()
  }

  #initializeLinkToolsView (linkView) {
    if (!this.paper.options.interactive(linkView)) { return }
    const ns = joint.linkTools
    const toolsView = new joint.dia.ToolsView({
      name: 'link-pointerdown',
      tools: [
        new ns.SourceAnchor(),
        new ns.TargetAnchor(),
        new ns.Vertices(),
        new ns.Boundary({ padding: 15 }),
        new ns.Remove({
          offset: -40,
          distance: 60
        })
      ]
    })

    linkView.addTools(toolsView)
  }

  #initializeHaloFreeTransform (cellView) {
    if (!this.paper.options.interactive(cellView)) { return }
    /* eslint-disable-next-line no-new */
    new joint.ui.Tooltip({
      rootTarget: this.paper.$el,
      target: '[data-tooltip]',
      direction: 'auto',
      padding: 10
    })

    this.halo = new joint.ui.Halo({
      cellView: cellView,
      theme: 'material',
      boxContent: false,
      handles: haloConfig
    })
    this.halo.render()

    this.halo.on('action:remove:pointerup', function (evt) {
      evt.stopPropagation()
      this.commandManager.initBatchCommand()
      this.halo.options.cellView.model.remove()
      this.commandManager.storeBatchCommand()
    }.bind(this))

    this.halo.on('action:clone:pointerup', function (evt, x, y) {
      evt.stopPropagation()
      this.#clearPaperTools()
      const source = this.halo.options.cellView.model
      const clonedCells = source.clone({ deep: true })
      const center = _.first(clonedCells).getBBox().center()
      const tx = x - center.x
      const ty = y - center.y
      _.each(clonedCells, (clone) => {
        clone.translate(tx, ty, {
          deep: true,
          silent: true
        })

        if (!clone.get('parent') && source.get('parent')) {
          const parent = this.graph.getCell(source.get('parent'))
          const parentBBox = parent.getBBox()
          const cloneBBox = clone.getBBox()

          if (parentBBox.containsPoint(cloneBBox.origin()) &&
              parentBBox.containsPoint(cloneBBox.topRight()) &&
              parentBBox.containsPoint(cloneBBox.corner()) &&
              parentBBox.containsPoint(cloneBBox.bottomLeft())) {
            parent.embed(clone)
          }
        }
      })

      this.graph.addCells(clonedCells, { cloned: true })
    }.bind(this))

    // Free transform
    this.freeTransform = new joint.ui.FreeTransform({
      allowRotation: false,
      preserveAspectRatio: cellView.model.get('preserveAspectRatio'),
      cellView: cellView
    }).render()

    this.freeTransform.on('resize:stop', (evt) => {
      const cellView = this.freeTransform.options.cellView
      const cell = this.freeTransform.options.cell
      if (cellView && cell.isElement()) {
        const bBox = cell.getBBox()
        const links = this.graph.getConnectedLinks(cell)
        _.forEach(links, (link) => {
          const verticesToRemove = _.filter(link.get('vertices'), (vertex) => bBox.containsPoint(vertex))
          link.set('vertices', _.difference(link.get('vertices'), verticesToRemove))
        })
      }
    })
  }

  #initializePoolLaneBoundary (cellView, meta) {
    if (!this.paper.options.interactive(cellView)) { return }
    const laneId = meta.target.getAttribute('laneGroupId')
    const boundaryTool = new joint.elementTools.SwimlaneBoundary({
      laneId: laneId,
      padding: 0,
      attributes: {
        fill: 'none',
        strokeWidth: 3,
        stroke: '#3498db'
      }
    })
    const transformTool = new joint.elementTools.SwimlaneTransform({
      laneId: laneId,
      minSize: 60,
      padding: 0
    })
    const elementToolsView = new joint.dia.ToolsView({
      tools: [boundaryTool, transformTool]
    })
    this.selection.destroySelectionBox(cellView.model)
    cellView.addTools(elementToolsView)
  }

  /*
    This passes through the mousewheel event from a selection-box through
    to the paper element. It is necessary as the selection-box does not have any
    support for the mousewheel event, and defaults to a canvas scroll if
    left unintercepted.
  */

  #preventSelectionBoxEvents (event) {
    if (event.target.classList.contains('selection-box')) {
      event.preventDefault()
      const clonedEvent = new event.constructor(event.type, event)
      this.paper.el.dispatchEvent(clonedEvent)
    }
  }
}
