import Vue from 'vue'
import Vuex from 'vuex'

/* Refactored */

/* -- Stores */
/* -- (Entity stores, used across multiple pages/components) */
import entityAttributesStore from './public/_stores/entityAttributesStore.vue.js'
import entityValuesStore from './public/_stores/entityValuesStore.vue.js'
import entityValueLinksStore from './public/_stores/entityValueLinksStore.vue.js'
import dataCatalogDetailsEntityValuesStore from './public/_stores/dataCatalogDetailsEntityValuesStore.vue.js'
import assessmentStore from './public/_stores/assessmentStore.vue.js'
import releaseNotesStore from './public/_stores/releaseNotesStore.vue.js'
import projectsStore from './public/_stores/projectsStore.vue.js'
import modulesStore from './public/_stores/modulesStore.vue.js'
import stakeholdersStore from './public/_stores/stakeholdersStore.vue.js'
import productsAndServicesStore from './public/_stores/productsAndServicesStore.vue.js'
import checklistStore from './public/_stores/checklistStore.vue.js'
import kanbanStore from './public/_stores/kanbanStore.vue.js'
import releaseNotesAdminStore from './admin/_stores/releaseNotesAdminStore.vue.js'
import removedProjectsAdminStore from './admin/_stores/removedProjectsAdminStore.vue.js'
import systemAnalyticsAdminStore from './admin/_stores/systemAnalyticsAdminStore.vue'
import imageViewersStore from './public/_stores/imageViewersStore.vue.js'
import notificationsStore from './public/_stores/notificationsStore.vue.js'
import dataInterfaceStore from './public/_stores/dataInterfaceStore.vue.js'
import dataMapStore from './public/_stores/dataMapStore.vue.js'
import dataCatalogStore from './public/_stores/dataCatalogStore.vue.js'
import changeManagementStore from './public/_stores/changeManagementStore.vue.js'
import stakeholderPerspectiveEcoRolesAdminStore from './admin/_stores/stakeholderPerspectiveEcoRolesAdminStore.vue.js'
import usersAndRolesStore from './public/_stores/usersAndRolesStore.vue.js'
import profilePageStore from './public/_stores/profilePageStore.vue'
import moduleStatusesAdminStore from './admin/_stores/moduleStatusesAdminStore.vue.js'
import projectStatusesAdminStore from './admin/_stores/projectStatusesAdminStore.vue.js'
import marketsAdministrationStore from './admin/_stores/marketsAdministrationStore.vue.js'
import moduleCategoriesAdminStore from './admin/_stores/moduleCategoriesAdminStore.vue.js'
import modulesAdminStore from './admin/_stores/modulesAdminStore.vue.js'
import featureNotificationAdminStore from './admin/_stores/featureNotificationAdminStore.vue.js'
import systemAdminStore from './admin/_stores/systemAdminStore.vue'
import usersAdminStore from './admin/_stores/usersAdminPage.vue.js'
import companiesAdminStore from './admin/_stores/companiesAdminStore.vue.js'
import companyAdminPageStore from './companyAdmin/_stores/companyAdminPageStore.vue.js'
import tagsStore from './public/_stores/tagsStore.vue.js'
import commentsStore from './public/_stores/commentsStore.vue.js'
/* -- State */
/* -- (Page or component state, used to share state more easily across objects, dont contain actions) */
import textModuleState from './public/_state/textModuleState.vue.js'
import diagramModuleState from './public/_state/diagramModuleState.vue.js'
import canvasModuleState from './public/_state/canvasModuleState.vue.js'
import appNavigationMenu from './public/_state/appNavigationMenu.vue.js'
import stakeholdersView from './public/_state/stakeholdersView.vue.js'
import tagsView from './public/_state/tagsView.vue.js'
import productsAndServicesView from './public/_state/productsAndServicesView.vue.js'
import checklistModule from './public/_state/checklistModule.vue.js'
import kanbanModule from './public/_state/kanbanModule.vue.js'
import changeManagementModule from './public/_state/changeManagementModule.vue.js'
import assessmentStructureAdminPage from './public/_state/assessmentStructureAdminPage.vue.js'
import projectHomePage from './public/_state/projectHomePage.vue.js'
import teamHomePage from './public/_state/teamHomePage.vue.js'
import exampleHomePage from './public/_state/exampleHomePage.vue.js'
import templateHomePage from './public/_state/templateHomePage.vue.js'
import projectPage from './public/_state/projectPage.vue.js'
import modulePage from './public/_state/modulePage.vue.js'
import releaseNotesAdminPage from './admin/_state/releaseNotesAdminPage.vue.js'
import imageViewerModule from './public/_state/imageViewerModule.vue.js'
import dataInterfaceModule from './public/_state/dataInterfaceModule.vue.js'
import notificationsPage from './public/_state/notificationsPage.vue.js'
import dataCatalogPage from './public/_state/dataCatalogPage.vue.js'
import dataCatalogManagementPage from './public/_state/dataCatalogManagementPage.vue.js'
import dataMapPage from './public/_state/dataMapPage.vue.js'
import dataMapManagementPage from './public/_state/dataMapManagementPage.vue.js'
import dataMapCategoryMappingPage from './public/_state/dataMapCategoryMappingPage.vue.js'
import dataMapElementMappingPage from './public/_state/dataMapElementMappingPage.vue.js'
import companyDetailAdminPage from './admin/_state/companyDetailAdminPage.vue.js'

/* To Be Refactored */
import globalState from './shared/global.vue.js'
import profileState from './shared/profile.vue.js'
import reqestCounterPlugin from './plugins/requestCounterPlugin.vue.js'
/* istanbul ignore next */
Vue.use(Vuex)

export default new Vuex.Store({
  strict: ENV === 'development',
  plugins: [reqestCounterPlugin],
  modules: {
    globalState,
    textModuleState,
    diagramModuleState,
    canvasModuleState,
    entityAttributesStore,
    entityValuesStore,
    entityValueLinksStore,
    dataCatalogDetailsEntityValuesStore,
    assessmentStore,
    assessmentStructureAdminPage,
    appNavigationMenu,
    checklistStore,
    checklistModule,
    companyDetailAdminPage,
    companiesAdminStore,
    companyAdminPageStore,
    dataMapStore,
    dataCatalogStore,
    dataMapPage,
    featureNotificationAdminStore,
    projectHomePage,
    teamHomePage,
    exampleHomePage,
    templateHomePage,
    projectPage,
    modulePage,
    imageViewersStore,
    imageViewerModule,
    dataInterfaceStore,
    dataInterfaceModule,
    kanbanModule,
    kanbanStore,
    marketsAdministrationStore,
    moduleCategoriesAdminStore,
    modulesAdminStore,
    notificationsStore,
    notificationsPage,
    projectsStore,
    modulesStore,
    productsAndServicesStore,
    productsAndServicesView,
    profilePageStore,
    moduleStatusesAdminStore,
    projectStatusesAdminStore,
    removedProjectsAdminStore,
    releaseNotesStore,
    releaseNotesAdminStore,
    releaseNotesAdminPage,
    changeManagementModule,
    changeManagementStore,
    stakeholderPerspectiveEcoRolesAdminStore,
    stakeholdersStore,
    stakeholdersView,
    systemAdminStore,
    systemAnalyticsAdminStore,
    usersAdminStore,
    dataCatalogPage,
    dataCatalogManagementPage,
    dataMapManagementPage,
    dataMapCategoryMappingPage,
    dataMapElementMappingPage,
    usersAndRolesStore,
    tagsStore,
    tagsView,
    profileState,
    commentsStore
  }
})
