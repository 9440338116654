import generateActions from '@/vue/store/utils/generateActions.vue.js'
import { users, roles, permissions } from './schema.vue.js'

const usersEndpoint = () => ('/api/users')
const rolesEndpoint = () => ('/api/roles')
const permissionsEndpoint = () => ('/api/permissions')

const rolesMethodSchemas = {
  index: [roles]
}

const usersMethodSchemas = {
  index: [users]
}

export default {
  ...generateActions(
    'userAndRolesStore',
    'role',
    rolesEndpoint,
    rolesMethodSchemas
  ),
  ...generateActions(
    'userAndRolesStore',
    'user',
    usersEndpoint,
    usersMethodSchemas
  ),
  ...generateActions(
    'userAndRolesStore',
    'permission',
    permissionsEndpoint,
    { index: [permissions] }
  )
}
