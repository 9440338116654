
import generateGetters from '@/vue/store/utils/generateGetters.vue.js'
import { kanbanItems, kanbanLanes, links } from './schema.vue.js'
import { indexes } from './indexes.vue.js'
import _ from 'lodash'

const matchingIds = (colourFilter, state) => {
  const filterFn = (colourFilter.operator === 'AND') ? _.intersection : _.union
  let matching = []

  if (colourFilter.stakeholder_ids.length > 0) {
    _.each(colourFilter.stakeholder_ids, (shId) => {
      matching.push(state.indexes.kanbanItemsByStakeholderIds[shId])
    })
  }

  if (colourFilter.product_and_service_item_ids.length > 0) {
    _.each(colourFilter.product_and_service_item_ids, (psItemId) => {
      matching.push(state.indexes.kanbanItemsByProductAndServiceItemIds[psItemId])
    })
  }

  if (colourFilter.tag_ids.length > 0) {
    _.each(colourFilter.tag_ids, (shId) => {
      matching.push(state.indexes.kanbanItemsByTagIds[shId])
    })
  }

  if (colourFilter.user_ids.length > 0) {
    _.each(colourFilter.user_ids, (userId) => {
      matching.push(state.indexes.kanbanItemsByUserIds[userId])
    })
  }

  matching = _.map(matching, (match) => {
    return _.compact(match)
  })

  const filteredIds = filterFn(...matching)

  return filteredIds
}

const isActive = (colorFilter) => {
  return (
    !!colorFilter.tag_ids.length ||
    !!colorFilter.stakeholder_ids.length ||
    !!colorFilter.stakeholder_role_ids.length ||
    !!colorFilter.product_and_service_item_ids.length ||
    !!colorFilter.product_and_service_category_ids.length ||
    !!colorFilter.user_ids.length
  )
}

export default {
  ...generateGetters(kanbanItems, indexes),
  ...generateGetters(kanbanLanes, indexes),
  ...generateGetters(links),

  filteredKanbanItemsByModuleAndLaneId: (state) => (moduleId, kanbanLaneId, colorFilter) => {
    const itemsByModule = _.get(state, `indexes.kanbanItemsByModuleId[${moduleId}]`, [])
    const itemsByLane = _.get(state, `indexes.kanbanItemsByKanbanLaneId[${kanbanLaneId}]`, [])

    let validIds = _.intersection(itemsByModule, itemsByLane)

    if (isActive(colorFilter)) {
      validIds = _.intersection(validIds, matchingIds(colorFilter, state))
    }
    const items = _.map(validIds, (id) => _.get(state, `kanbanItems.[${id}]`, null))
    return _.compact(items)
  }
}
