import _ from 'lodash'
import initialState from './initialState.vue.js'
import moment from 'moment'
import generateStateMutations from '@/vue/store/utils/generateStateMutations.vue.js'

const saveLocalState = (state, routeParams) => {
  const key = `p${routeParams.projectId}m${routeParams.moduleId}diagramModules`
  const payload = JSON.stringify({
    shapesPanelMinified: state.shapesPanelMinified,
    created: moment().valueOf()
  })
  localStorage.setItem(key, payload)
}

export default {
  RESET_STATE (state) {
    _.assign(state, initialState())
  },
  SET_SHAPES_PANEL_VISIBILITY (state, options) {
    state.shapesPanelMinified = options.payload
    saveLocalState(state, options.params)
  },
  SET_OPEN_PANEL_SECTION (state, value) {
    state.openPanelSection = value
  },
  SET_VIEW (state, value) {
    state.view = value
  },
  SET_SELECTED_LAYER_ID (state, value) {
    state.selectedLayerId = value
  },
  TOGGLE_LAYER_VISIBILITY (state, layerId) {
    state.hiddenLayerIds = _.xor(state.hiddenLayerIds, [layerId])
  },
  LOAD_LOCAL (state, routeParams) {
    const key = `p${routeParams.projectId}m${routeParams.moduleId}diagramModules`
    if (localStorage.getItem(key) !== null) {
      const data = JSON.parse(localStorage.getItem(key))
      const savedAt = moment(data.created)
      if (savedAt.diff(moment(), 'hours') < 24) {
        state.shapesPanelMinified = data.shapesPanelMinified
      }
    }
  },
  ...generateStateMutations()
}
