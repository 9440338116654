<template>
  <v-snackbar
    v-model="activeState"
    :timeout="6000"
    top
    color="success"
    :multi-line="false"
    class="font-weight-medium custom-snackbar"
    app
  >
    {{ successMessage }}
    <v-btn
      dark
      text
      test-hook="close-btn"
      @click="activeState = false"
    >
      Close
    </v-btn>
  </v-snackbar>
</template>
<script>
import { mapGetters } from 'vuex'
import { globalMutes } from '@/vue/store/mutation-types.vue.js'

export default {
  name: 'TheSuccessSnackbar',
  computed: {
    ...mapGetters([
      'successMessage',
      'successSnackbar'
    ]),
    activeState: {
      get () {
        return this.successSnackbar
      },
      set (value) {
        if (!value) {
          this.$store.commit(globalMutes.HIDE_SUCCESS)
        }
      }
    }
  }
}
</script>
