import generateIndexes from '@/vue/store/utils/generateIndexes.vue.js'
import {
  comments
} from './schema.vue.js'

const staticCommentsIndexProperties = ['project_id', 'module_id', 'commentable_id', 'commentable_type', 'comment_level_type']

export const indexes = {
  [comments.key]: generateIndexes(staticCommentsIndexProperties)
}
