import { normalize } from 'normalizr'
import { entityValueLinks } from './schema.vue.js'
import generateActions from '@/vue/store/utils/generateActions.vue.js'

const entityValueLinkEndpoint = (params) => {
  return `/api/projects/${params.projectId}/project_scenario_modules/${params.moduleId}/entity_value_links`
}

const methodSchemas = {
  index: [entityValueLinks],
  create: entityValueLinks,
  update: entityValueLinks,
  destroy: entityValueLinks
}

export default {
  ...generateActions(
    'entityValueLinksStore',
    'entity_value_link',
    entityValueLinkEndpoint,
    methodSchemas
  ),
  UPDATE_LOCAL_ENTITY_VALUE_LINKS ({ commit, state }, payload) {
    commit('ADD_NORMALIZED_DATA', normalize(payload, [entityValueLinks]))
    return new Promise((resolve) => resolve())
  }
}
