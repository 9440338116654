import Vue from 'vue'
import { normalize } from 'normalizr'
import { entityValues } from './schema.vue.js'
import generateActions from '@/vue/store/utils/generateActions.vue.js'
import _ from 'lodash'

const entityValueEndpoint = (params) => {
  return `/api/projects/${params.projectId}/project_scenario_modules/${params.moduleId}/entity_values`
}

const methodSchemas = {
  index: [entityValues],
  create: entityValues,
  update: entityValues,
  update_positions: [entityValues],
  destroy: entityValues,
  clone: entityValues
}

export default {
  ...generateActions(
    'entityValuesStore',
    'entity_value',
    entityValueEndpoint,
    methodSchemas
  ),
  EXPORT_MODULE ({ commit, dispatch }, { params, exportType }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .get(
          `${entityValueEndpoint(params)}/export.${exportType}`, {
            responseType: 'blob'
          })
        .then(
          response => {
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
    })
  },
  UPLOAD_CSV ({ commit, dispatch }, { params, payload }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .post(
          `${entityValueEndpoint(params)}/import_upload.json`,
          payload
        )
        .then(
          response => {
            commit('ADD_NORMALIZED_DATA', normalize(response.data.default, [entityValues]))
            dispatch('stakeholdersStore/ADD_STAKEHOLDERS', response.data.stakeholders, { root: true })
            dispatch('tagsStore/ADD_TAGS', response.data.tags, { root: true })
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
    })
  },
  CLONE_ENTITY_VALUE ({ commit, dispatch }, { params, payload }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .post(
          `${entityValueEndpoint(params)}/clone.json`,
          payload
        )
        .then(
          response => {
            const normalized = normalize(response.data.default, methodSchemas.clone)
            commit('ADD_NORMALIZED_DATA', normalized)
            dispatch('dataCatalogDetailsEntityValuesStore/ADD_DATA_CATALOG_DETAILS_ENTITY_VALUES', response.data.data_catalog_detail_entity_values, { root: true })
            dispatch('entityValueLinksStore/UPDATE_LOCAL_ENTITY_VALUE_LINKS', response.data.links, { root: true })
            resolve(normalized)
          },
          error => {
            reject(error)
          }
        )
    })
  },
  BULK_CREATE_ENTITY_VALUES ({ commit, dispatch }, { params, payload }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .post(
          `${entityValueEndpoint(params)}/bulk_create.json`,
          payload
        )
        .then(
          response => {
            const normalized = normalize(response.data, [entityValues])
            commit('ADD_NORMALIZED_DATA', normalized)
            resolve(normalized)
          },
          error => {
            reject(error)
          }
        )
    })
  },
  BULK_UPDATE_ENTITY_VALUE_PROPERTIES ({ commit, dispatch }, { params, payload }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .post(
          `${entityValueEndpoint(params)}/bulk_update.json`,
          payload
        )
        .then(
          response => {
            const normalized = normalize(response.data, [entityValues])
            commit('ADD_NORMALIZED_DATA', normalized)
            resolve(normalized)
          },
          error => {
            reject(error)
          }
        )
    })
  },
  BULK_DELETE_ENTITY_VALUES ({ commit, dispatch }, { params, payload }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .post(
          `${entityValueEndpoint(params)}/bulk_destroy.json`,
          payload
        )
        .then(
          response => {
            const normalized = normalize(response.data, [entityValues])
            commit('REMOVE_NORMALIZED_DATA', normalized)
            resolve(normalized)
          },
          error => {
            reject(error)
          }
        )
    })
  },
  UPDATE_LOCAL_ENTITY_VALUES ({ commit, state }, payload) {
    commit('ADD_NORMALIZED_DATA', normalize(payload, [entityValues]))
    return new Promise((resolve) => resolve())
  },
  UPDATE_ENTITY_VALUE_POSITIONS ({ commit, state }, { params, payload }) {
    commit('ADD_NORMALIZED_DATA', normalize(payload, methodSchemas.update_positions))
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .post(`${entityValueEndpoint(params)}/update_positions.json`, payload)
        .then(
          response => {
            if (!_.isObject(response.data) || _.isEmpty(response.data)) { return resolve(response.data) }
            const normalized = normalize(response.data, methodSchemas.update_positions)
            commit('REMOVE_NORMALIZED_DATA', normalized)
            commit('ADD_NORMALIZED_DATA', normalized)
            resolve(normalized)
          },
          error => {
            reject(error)
          }
        )
    })
  }
}
