import * as joint from '@clientio/rappid'
import _ from 'lodash'
import BusinessProcessElement from '../base/baseBusinessProcessElement.vue.js'

const Pool = BusinessProcessElement.extend({
  initialize: function () {
    BusinessProcessElement.prototype.initialize.apply(this, arguments)

    this.on('change:headerSize', (cell, value) => {
      this.buildMarkup()
    })
    this.on('change:lanes', (cell, value) => {
      this.buildMarkup()
    })
    this.on('change:textVerticalAnchor', (cell, verticalPosition) => {
      this.updateElementLabelVerticalPosition(verticalPosition)
    })

    this.on('change:textHorizontalAnchor', (cell, horizontalPosition) => {
      this.updateElementLabelHorizontalPosition(horizontalPosition)
    })

    this.on('change:laneLabelTextAlignment', (cell, position) => {
      this.attr('laneLabels/labelAlignment', position)
    })
    this.buildMarkup()
  },
  updateElementDefaultColors: function (options) {
    this.attr({
      lanes: {
        fill: options.default_fill_colour,
        stroke: options.default_stroke_colour
      },
      laneHeaders: {
        fill: options.default_fill_colour,
        stroke: options.default_stroke_colour
      },
      header: {
        fill: options.default_fill_colour,
        stroke: options.default_stroke_colour
      },
      body: {
        fill: options.default_fill_colour,
        stroke: options.default_stroke_colour
      },
      laneLabels: {
        fill: options.default_text_colour
      },
      headerLabel: {
        fill: options.default_text_colour
      }
    })
  },
  updateElementLabelVerticalPosition (vPosition) {
    // Normalize property format
    this.removeAttr('headerLabel/text-vertical-anchor')
    this.removeAttr('headerLabel/ref-x')
    this.removeAttr('headerLabel/ref-x2')
    this.removeAttr('headerLabel/text-vertical-position')

    this.attr('headerLabel/textVerticalPosition', vPosition)
    switch (vPosition) {
      case 'top' : {
        this.attr('headerLabel/textVerticalAnchor', 'top')
        this.attr('headerLabel/refX2', '-13')
        break
      }
      case 'middle' : {
        this.attr('headerLabel/textVerticalAnchor', 'middle')
        this.attr('headerLabel/refX2', '0')
        break
      }
      case 'bottom' : {
        this.attr('headerLabel/textVerticalAnchor', 'bottom')
        this.attr('headerLabel/refX2', '18')
        break
      }
      default: {
        this.attr('headerLabel/textVerticalAnchor', 'middle')
        this.attr('headerLabel/refX2', '0')
        break
      }
    }
  },
  updateElementLabelHorizontalPosition (hPosition) {
    // Normalize property format
    this.removeAttr('headerLabel/text-anchor')
    this.removeAttr('headerLabel/ref-y')
    this.removeAttr('headerLabel/text-position')

    switch (hPosition) {
      case 'end' : {
        this.attr('headerLabel/textPosition', 'end')
        this.attr('headerLabel/textAnchor', 'end')
        this.attr('headerLabel/refY', '0%')
        this.attr('headerLabel/refY2', '10')
        break
      }
      case 'middle' : {
        this.attr('headerLabel/textPosition', 'middle')
        this.attr('headerLabel/textAnchor', 'middle')
        this.attr('headerLabel/refY', '50%')
        this.attr('headerLabel/refY2', '0')
        break
      }
      case 'start' : {
        this.attr('headerLabel/textPosition', 'start')
        this.attr('headerLabel/textAnchor', 'start')
        this.attr('headerLabel/refY', '100%')
        this.attr('headerLabel/refY2', '-10')
        break
      }
      default: {
        this.attr('headerLabel/textPosition', 'middle')
        this.attr('headerLabel/textAnchor', 'middle')
        this.attr('headerLabel/refY', '50%')
        this.attr('headerLabel/refY2', '0')
        break
      }
    }
  },
  getText: function (text) {
    return this.attr('headerLabel/text') || this.get('shape')
  },
  setText: function (text) {
    this.attr({
      headerLabel: {
        text: text
      }
    })
  },
  displayModeAttributes: {
    stencil: {
      size: { width: 40, height: 40 },
      attrs: {
        headerLabel: {
          text: ''
        }
      }
    },
    preview: {
      size: { width: 90, height: 60 },
      position: { x: 10, y: 10 }
    },
    canvas: {
      size: { width: 90, height: 60 }
    }
  },
  markup: joint.shapes.bpmn2.HeaderedPool.prototype.markup,
  defaults: _.defaultsDeep({
    size: { width: 40, height: 30 },
    shape: 'Pool',
    type: 'businessProcess.Pool',
    embedding_enabled: true,
    attrs: {
      header: {
        strokeWidth: 1
      },
      laneHeaders: {
        strokeWidth: 1
      },
      lanes: {
        strokeWidth: 1
      },
      headerLabel: {
        'font-weight': 'Normal',
        'font-family': 'Roboto,sans-serif',
        text: 'Pool'
      },
      root: {
        magnet: false
      }
    }
  }, joint.shapes.bpmn2.HeaderedPool.prototype.defaults)
})
Pool.attributes = joint.shapes.bpmn2.HeaderedPool.attributes
Pool.prototype.buildMarkup = joint.shapes.bpmn2.HeaderedPool.prototype.buildMarkup
Pool.prototype.buildLanesMarkupRecursively = joint.shapes.bpmn2.HeaderedPool.prototype.buildLanesMarkupRecursively
Pool.prototype.buildMilestones = joint.shapes.bpmn2.HeaderedPool.prototype.buildMilestones
Pool.prototype.getLaneGroupMarkup = joint.shapes.bpmn2.HeaderedPool.prototype.getLaneGroupMarkup
Pool.prototype.getLaneMarkup = joint.shapes.bpmn2.HeaderedPool.prototype.getLaneMarkup
Pool.prototype.getHeaderMarkup = joint.shapes.bpmn2.HeaderedPool.prototype.getHeaderMarkup
Pool.prototype.getLabelMarkup = joint.shapes.bpmn2.HeaderedPool.prototype.getLabelMarkup
Pool.prototype.addLaneGroupMetrics = joint.shapes.bpmn2.HeaderedPool.prototype.addLaneGroupMetrics
Pool.prototype.autoresize = joint.shapes.bpmn2.HeaderedPool.prototype.autoresize
Pool.prototype.getMinimalSize = joint.shapes.bpmn2.HeaderedPool.prototype.getMinimalSize
Pool.prototype.getLaneContainerPosition = joint.shapes.bpmn2.HeaderedPool.prototype.getLaneContainerPosition
Pool.prototype.getLaneHeight = joint.shapes.bpmn2.HeaderedPool.prototype.getLaneHeight
Pool.prototype.getLaneWidth = joint.shapes.bpmn2.HeaderedPool.prototype.getLaneWidth
Pool.prototype.getParentIndexesArray = joint.shapes.bpmn2.HeaderedPool.prototype.getParentIndexesArray
Pool.prototype.getLanesFromPoint = joint.shapes.bpmn2.HeaderedPool.prototype.getLanesFromPoint
Pool.prototype.getLaneBBox = joint.shapes.bpmn2.HeaderedPool.prototype.getLaneBBox
Pool.prototype.getLanePath = joint.shapes.bpmn2.HeaderedPool.prototype.getLanePath
Pool.prototype.getFlexAndFixedLaneSizesWithinGroup = joint.shapes.bpmn2.HeaderedPool.prototype.getFlexAndFixedLaneSizesWithinGroup

export default Pool
