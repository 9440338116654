<template>
  <v-row
    no-gutters
    class="fill-height pa-4"
    @click.prevent
  >
    <v-col cols="12">
      <v-card
        v-for="note in publishedReleaseNotes"
        :key="note.id"
        flat
        nw-hook="release-card"
        class="release-card my-4 pa-2"
        elevation="3"
      >
        <v-card-title>
          <v-row
            no-gutters
            align="center"
            class="flex-nowrap"
          >
            <v-col
              class="text-heading-6 text-left"
              cols="9"
            >
              {{ note.title }}
            </v-col>
            <v-col
              cols="3"
              class="text-right text-subtitle-1"
            >
              <a @click="goToReleaseNote(note.viewUrl)">Learn More</a>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="text-left">
          <!-- eslint-disable vue/no-v-html -->
          <div
            class="ws-preserve text-body-1 text-left default--text"
            v-html="note.cleanSummary"
          />
          <!-- eslint-enable vue/no-v-html -->
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <div class="text-body-1">
            Release Date: {{ note.release_note_date }}
          </div>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import DOMPurify from 'dompurify'
import moment from 'moment'
import filters from '@/vue/shared/filters/filters.vue.js'
import _ from 'lodash'
import { createNamespacedHelpers } from 'vuex'

const releaseNotesStoreGetters = createNamespacedHelpers('releaseNotesStore')

export default {
  name: 'ReleaseNotes',
  filters: {
    datestring: filters.timestampToDatetimeString
  },
  computed: {
    ...releaseNotesStoreGetters.mapGetters([
      'allReleaseNotes'
    ]),
    publishedReleaseNotes () {
      return _.chain(this.allReleaseNotes)
        .orderBy('release_note_date', 'desc')
        .map(note => _.assign({}, note, {
          viewUrl: `/#/release-notes/${note.id}`,
          release_note_date: moment(note.release_note_date).format('dddd, MMMM Do'),
          cleanSummary: DOMPurify.sanitize(note.summary),
          summary: null
        }))
        .filter({ published: true })
        .value()
    }
  },
  mounted () {
    const ctx = this
    ctx.$store.dispatch('releaseNotesStore/LOAD_RELEASE_NOTES')
  },
  methods: {
    goToReleaseNote (url) {
      window.open(url, '_blank')
    }
  }
}
</script>
<style lang="scss" scoped>
  .text-button-container {
    width:50%;
  }
  .release-card {
    .v-divider {
      border-color: black;
    }

    .add-btn {
      color: $light-alpha;
    }

    .ws-preserve {
      white-space: pre-wrap;
    }
  }

</style>
