import { dataCatalogDetailsEntityValues } from './schema.vue.js'
import generateActions from '@/vue/store/utils/generateActions.vue.js'
import { normalize } from 'normalizr'
const dataCatalogDetailsEntityValueEndpoint = (params) => {
  return `/api/projects/${params.projectId}/project_scenario_modules/${params.moduleId}/data_catalog_details_entity_values`
}

const methodSchemas = {
  index: [dataCatalogDetailsEntityValues],
  create: dataCatalogDetailsEntityValues,
  destroy: dataCatalogDetailsEntityValues
}

export default {
  ...generateActions(
    'dataCatalogDetailsEntityValuesStore',
    'data_catalog_details_entity_value',
    dataCatalogDetailsEntityValueEndpoint,
    methodSchemas
  ),
  ADD_DATA_CATALOG_DETAILS_ENTITY_VALUES ({ commit }, payload) {
    commit('ADD_NORMALIZED_DATA', normalize(payload, [dataCatalogDetailsEntityValues]))
    return new Promise(resolve => resolve())
  }
}
