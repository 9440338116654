import _ from 'lodash'

function unionAssign (dest, source, options = {}) {
  let keys = _.keys(dest)
  if (options.omit && options.omit.length) {
    keys = _.difference(keys, options.omit)
  }

  return _.assignWith(dest, _.pick(source, keys), (destVal, sourceVal) => {
    if (_.isPlainObject(sourceVal) && _.isPlainObject(destVal)) {
      return unionAssign(destVal, sourceVal, options)
    }
    return sourceVal
  })
}

_.mixin({ unionAssign: unionAssign })
