import * as joint from '@clientio/rappid'
import _ from 'lodash'

export default joint.dia.Element.extend({
  // Initialize
  defaults: _.defaultsDeep({
    type: 'tr3dent.Tr3dentElement'
  }, joint.dia.Element.prototype.defaults),
  idAttribute: 'jsid',
  displayModeAttributes: {
    stencil: {
      size: { width: 40, height: 40 },
      attrs: {
        label: {
          textWrap: {
            text: ''
          }
        }
      }
    },
    stakeholderStencil: {
      size: { width: 20, height: 20 },
      attrs: {
        label: {
          refX: null,
          refY: null,
          refX2: 25,
          refY2: 10,
          textAnchor: 'start',
          textWrap: {
            width: null
          }
        }
      }
    },
    preview: {
      size: { width: 90, height: 90 },
      attrs: {
        label: {
          'font-weight': 'Normal',
          'font-family': 'Roboto,sans-serif',
          fontSize: 13,
          refX: '50%',
          refY: '50%',
          refX2: 0,
          refY2: 0,
          textAnchor: 'middle',
          textVerticalAnchor: 'middle',
          textWrap: {
            width: -10,
            height: null
          }
        }
      },
      position: { x: 10, y: 10 }
    },
    canvas: {
      size: { width: 90, height: 90 },
      attrs: {
        label: {
          'font-weight': 'Normal',
          'font-family': 'Roboto,sans-serif',
          fontSize: 13,
          refX: '50%',
          refY: '50%',
          refX2: 0,
          refY2: 0,
          textAnchor: 'middle',
          textVerticalAnchor: 'middle',
          textWrap: {
            width: -10,
            height: null
          }
        }
      }
    }
  },

  initialize: function (params) {
    joint.dia.Element.prototype.initialize.apply(this, arguments)
    this._initializeListeners()
  },
  // Listeners
  _initializeListeners: function () {
    const ctx = this

    ctx.on('add', (cell, collection, options) => {
      if (options.stencil) {
        ctx.set('id', null, { silent: true })
      }
    })
    ctx.on('change:textVerticalAnchor', (cell, verticalPosition) => {
      ctx.updateElementLabelVerticalPosition(verticalPosition)
    })

    ctx.on('change:textHorizontalAnchor', (cell, horizontalPosition) => {
      ctx.updateElementLabelHorizontalPosition(horizontalPosition)
    })

    ctx.on('change:title', (cell, textValue) => {
      ctx.setText(textValue)
    })

    ctx.on('change:mode', (cell, mode) => {
      ctx.setMode(mode)
    })
  },
  // Functions
  updateElementDefaultColors: function (options) {
    this.attr({
      body: {
        fill: options.default_fill_colour,
        stroke: options.default_stroke_colour
      }
    })
    this.attr({
      label: {
        fill: options.default_text_colour
      }
    })
  },

  updateElementLabelVerticalPosition: function (vPosition) {
    // Normalize property format
    this.removeAttr('label/text-vertical-anchor')
    this.removeAttr('label/ref-y')
    this.removeAttr('label/text-vertical-position')

    switch (vPosition) {
      case 'top' : {
        this.attr('label/textVerticalPosition', 'top')
        this.attr('label/textVerticalAnchor', 'top')
        this.attr('label/refY', '5%')
        break
      }
      case 'middle' : {
        this.attr('label/textVerticalPosition', 'middle')
        this.attr('label/textVerticalAnchor', 'middle')
        this.attr('label/refY', '50%')
        break
      }
      case 'bottom' : {
        this.attr('label/textVerticalPosition', 'bottom')
        this.attr('label/textVerticalAnchor', 'bottom')
        this.attr('label/refY', '95%')
        break
      }
      default: {
        this.attr('label/textVerticalPosition', 'middle')
        this.attr('label/textVerticalAnchor', 'middle')
        this.attr('label/refY', '50%')
        break
      }
    }
  },

  updateElementLabelHorizontalPosition: function (hPosition) {
    // Normalize property format
    this.removeAttr('label/text-anchor')
    this.removeAttr('label/ref-x')
    this.removeAttr('label/text-position')

    switch (hPosition) {
      case 'start' : {
        this.attr('label/textPosition', 'start')
        this.attr('label/textAnchor', 'start')
        this.attr('label/refX', '0%')
        this.attr('label/refX2', '2')
        break
      }
      case 'middle' : {
        this.attr('label/textPosition', 'middle')
        this.attr('label/textAnchor', 'middle')
        this.attr('label/refX', '50%')
        break
      }
      case 'end' : {
        this.attr('label/textPosition', 'end')
        this.attr('label/textAnchor', 'end')
        this.attr('label/refX', '100%')
        this.attr('label/refX2', '-2')
        break
      }
      default: {
        this.attr('label/textPosition', 'middle')
        this.attr('label/textAnchor', 'middle')
        this.attr('label/refX', '50%')
        break
      }
    }
  },
  setMode: function (mode) {
    this.prop(this.displayModeAttributes[mode])
  },
  setShapeFill: function (fillColor) {
    this.attr({
      body: {
        fill: fillColor
      }
    })
  },
  setShapeOutline: function (outlineColor) {
    this.attr({
      body: {
        stroke: outlineColor
      }
    })
  },
  setShapeColor: function (colorScheme) {
    this.attr({
      body: {
        fill: colorScheme.fill,
        stroke: colorScheme.stroke
      }
    })
    this.attr({
      label: {
        fill: colorScheme.textColour
      }
    })
  },
  setText: function (text) {
    this.attr('label/textWrap/text', text)
  },
  getText: function () {
    return this.prop('label/textWrap/text') || this.get('shape')
  }
})
