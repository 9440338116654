const initialState = () => {
  return {
    openPanelSection: 'properties',
    searchString: '',
    selectedItemId: '',
    focusedItemId: null,
    checklistItemColourFilter: {
      tag_ids: [],
      stakeholder_ids: [],
      stakeholder_role_ids: [],
      stakeholder_role_stakeholder_ids: [],
      operator: 'OR'
    }
  }
}
export default initialState
