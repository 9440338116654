import _ from 'lodash'
import * as Sentry from '@sentry/browser'

export default function () {
  return {
    methods: {
      handleError (error) {
        let serverMessage = ''
        let extraInfo = null
        if (error && error.response) {
          if (error.response.status === 402) {
            return
          }
          // Axios Error, error response from server
          serverMessage = `Server Error Occured -
          Headers: ${JSON.stringify(error.response.headers)}
          Status: ${error.response.status}`
          extraInfo = error.response.data
          Sentry.captureEvent({
            message: serverMessage,
            level: Sentry.Severity.Error,
            extra: extraInfo
          })
        } else if (error && error.request) {
          // Axios Error, no response from server
          serverMessage = `Request Error Occured - ${JSON.stringify(error.request)}`
          Sentry.captureMessage(serverMessage, Sentry.Severity.Error)
        } else if (error && error.message) {
          // Error Object
          Sentry.captureException(error)
          if (ENV === 'development') {
            throw (error)
          }
        } else if (error && _.isString(error)) {
          // Error String
          serverMessage = `Error Occured - ${error}`
          Sentry.captureMessage(serverMessage, Sentry.Severity.Error)
        } else {
          // Best Guess
          serverMessage = 'Unknown error Occured'
          Sentry.captureMessage(serverMessage, Sentry.Severity.Error)
        }
      }
    }
  }
}
