import generateIndexes from '@/vue/store/utils/generateIndexes.vue.js'
import { dataCatalogDetailsEntityValues } from './schema.vue.js'

const staticEntityValuesIndexProperties = [
  'entity_value_id',
  'data_catalog_detail_id',
  'data_map_id',
  'module_id'
]

export const indexes = {
  [dataCatalogDetailsEntityValues.key]: generateIndexes(staticEntityValuesIndexProperties)
}
