const initialState = () => {
  return {
    accessToken: null,
    xSessionToken: null,
    xSession: false,
    tokenSubscribers: [],
    uploadRequestsInProgress: 0,
    downloadRequestsInProgress: 0,
    successSnackbar: false,
    successMessage: '',
    showUpdateAvailable: false,
    headerBarTitle: 'Loading...',
    requestCounter: {},
    releaseNotesVisible: false,
    alertText: null,
    alertId: null,
    closedAlertIds: [],
    itemParams: null
  }
}
export default initialState
