import tippy from 'tippy.js'
import _ from 'lodash'

const buildParmas = (value) => {
  if (value && value.link_title && value.link_url) {
    /* Link */
    const link = document.createElement('a')
    link.setAttribute('href', value.link_url)
    link.setAttribute('target', !value.open_url_new_tab ? '_self' : '_blank')
    link.setAttribute('class', 'tooltip-link')

    /* Text */
    const span = document.createElement('span')
    const text = document.createTextNode(value.link_title)
    span.setAttribute('class', 'tooltip-link-text')
    span.appendChild(text)

    /* Icon */
    const icon = document.createElement('i')
    icon.setAttribute('class', [
      'v-icon',
      'notranslate',
      'ml-1',
      'material-icons',
      'tooltip-link-icon'
    ].join(' '))
    const iconType = document.createTextNode('open_in_new')
    icon.appendChild(iconType)

    link.appendChild(span)
    link.appendChild(icon)
    return {
      content: link.outerHTML,
      role: 'link'
    }
  } else {
    return {
      content: '<div>Add Link</div>',
      role: 'tooltip'
    }
  }
}

export default {
  inserted (el, binding) {
    tippy(el, {
      ignoreAttributes: true,
      interactive: true,
      appendTo: document.body,
      allowHTML: true,
      placement: 'bottom',
      arrow: true,
      delay: [350, null],
      duration: [300, 350],
      ...buildParmas(binding.value)
    })
    const disabled = el.getAttribute('disabled')
    if (disabled) {
      el._tippy.disable()
    }
  },
  unbind (el) {
    el._tippy && el._tippy.destroy()
  },
  update (el, binding, vnode) {
    if (!el._tippy) { return }
    let disabled = _.get(vnode, 'componentOptions.propsData.disabled', null)
    /* Effort to emulate tippy on vue component not worth it */
    /* istanbul ignore else  */
    if (!_.isBoolean(disabled)) {
      disabled = el.getAttribute('disabled')
    }
    if (el._tippy.state.isEnabled && disabled) {
      el._tippy.disable()
    } else if (!el._tippy.state.isEnabled && !disabled) {
      el._tippy.enable()
    }
    const params = buildParmas(binding.value)
    if (el._tippy.props.content !== params.content) {
      el._tippy.setProps(params)
    }
  }
}
