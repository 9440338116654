import addNamespace from './utils/addNamespace.vue.js'

export const _profileActs = {
  LOAD_SYSTEM: 'LOAD_SYSTEM',
  LOAD_CURRENT_USER: 'LOAD_CURRENT_USER',
  UPDATE_CURRENT_USER: 'UPDATE_CURRENT_USER',
  REGISTER_VIEWED_PROJECT: 'REGISTER_VIEWED_PROJECT',
  REGISTER_VIEWED_NOTIFICATION: 'REGISTER_VIEWED_NOTIFICATION',
  UPDATE_PROFILE_PICTURE: 'UPDATE_PROFILE_PICTURE',
  REMOVE_PROFILE_PICTURE: 'REMOVE_PROFILE_PICTURE',
  RESET_PASSWORD: 'RESET_PASSWORD',
  REMOVE_CURRENT_USER: 'REMOVE_CURRENT_USER',
  LOAD_CURRENT_COMPANY: 'LOAD_CURRENT_COMPANY'
}

export const profileActs = addNamespace('profileState', _profileActs)
