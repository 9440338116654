import generateIndexes from '@/vue/store/utils/generateIndexes.vue.js'
import {
  productsAndServices,
  productsAndServicesCategories
} from './schema.vue.js'

const staticProductsIndexProperties = ['project_id']
const dynamicProductsIndexProps = ['product_and_service_item_category_ids']
const staticProductCategoriesIndexProperties = ['project_id']

export const indexes = {
  [productsAndServices.key]: generateIndexes(staticProductsIndexProperties, dynamicProductsIndexProps),
  [productsAndServicesCategories.key]: generateIndexes(staticProductCategoriesIndexProperties)
}
