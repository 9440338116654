const initialState = () => {
  return {
    viewMode: 'list',
    searchString: '',
    searchMessage: '',
    focusedStakeholderId: null,
    selectedStakeholderId: null
  }
}
export default initialState
