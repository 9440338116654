import _ from 'lodash'
import * as joint from '@clientio/rappid'
export default class Stencil {
  stencils = {}
  moduleEntityValue = null

  renderStencil ({
    htmlElement,
    moduleEntityValue,
    graph,
    paper,
    stencilKey,
    stencilGroupKey = 'default',
    shapeInstances,
    cellNamespacePrototypes,
    gridLayout
  }) {
    if (!this.stencils[stencilKey] || !this.stencils[stencilKey][stencilGroupKey]) {
      const stencil = this.#createStencil(graph, paper, cellNamespacePrototypes)
      _.set(this.stencils, [stencilKey, stencilGroupKey], _.get(this.stencils, [stencilKey, stencilGroupKey], {}))
      this.stencils[stencilKey][stencilGroupKey] = {
        stencil,
        gridLayout,
        colorScheme: moduleEntityValue,
        cellNamespacePrototypes
      }
      stencil.render().$el.appendTo(htmlElement)
    }
    this.#loadStencil(stencilKey, stencilGroupKey, shapeInstances)
  }

  destroyStencil (stencilKey, stencilGroupKey) {
    if (!this.stencils[stencilKey] || !this.stencils[stencilKey][stencilGroupKey]) { return }
    delete this.stencils[stencilKey][stencilGroupKey]
  }

  setColorScheme (newColorScheme) {
    for (const stencilKey in this.stencils) {
      for (const stencilGroupKey in this.stencils[stencilKey]) {
        this.stencils[stencilKey][stencilGroupKey].colorScheme = newColorScheme
        this.#setStencilColorScheme(stencilKey, stencilGroupKey)
      }
    }
  }

  getStencil (stencilKey, stencilGroupKey) {
    return this.stencils[stencilKey][stencilGroupKey].stencil
  }

  /* Private Methods */
  #createStencil (graph, paper, shapePrototypes) {
    return new joint.ui.Stencil({
      graph: graph,
      paper: paper,
      height: 800,
      width: 240,
      paperOptions: function () {
        return {
          cellViewNamespace: shapePrototypes
        }
      },
      dragStartClone: (cell) => {
        joint.ui.Popup.close()
        const clone = cell.clone()
        if (clone.get('mode') !== 'stakeholderStencil') {
          clone.set('title', clone.get('shape'))
        }
        clone.set('mode', 'canvas')
        return clone
      },
      dragEndClone: (cell) => {
        return cell.clone()
      }
    })
  }

  #layoutStencil (stencil, gridLayout) {
    const stencilGraph = stencil.getGraph()
    const stencilPaper = stencil.getPaper()
    joint.layout.GridLayout.layout(stencilGraph, gridLayout)
    stencilPaper.fitToContent(0, 0, 5)
  }

  #loadStencil (stencilKey, stencilGroupKey, stencilShapes) {
    const stencil = this.stencils[stencilKey][stencilGroupKey].stencil
    const gridLayout = this.stencils[stencilKey][stencilGroupKey].gridLayout
    const cellNamespacePrototypes = this.stencils[stencilKey][stencilGroupKey].cellNamespacePrototypes

    stencil.load(stencilShapes)

    this.#layoutStencil(stencil, gridLayout)
    stencil.getPaper().on('element:mouseover', (cellView) => {
      this.#stencilElementMouseOver(cellView, cellNamespacePrototypes)
    })
    stencil.getPaper().on('element:mouseout', this.#stencilElementMouseOut)
    this.#setStencilColorScheme(stencilKey, stencilGroupKey)
  }

  #setStencilColorScheme (stencilKey, stencilGroupKey) {
    const stencilData = this.stencils[stencilKey][stencilGroupKey]
    const stencil = stencilData.stencil
    const colorScheme = stencilData.colorScheme
    const groupCells = stencil.getGraph().getCells()

    _.each(groupCells, (cell) => {
      if (typeof cell.updateElementDefaultColors === 'function') {
        cell.updateElementDefaultColors(colorScheme)
      }
    })
  }

  #stencilElementMouseOver (cellView, cellNamespacePrototypes) {
    const previewShape = cellView.model.clone()
    if (previewShape.get('mode') !== 'stakeholderStencil') {
      previewShape.setText(previewShape.get('shape'))
    }
    previewShape.set('mode', 'preview')
    const size = previewShape.get('size')
    const previewDiagramWidth = size.width + 20
    const previewDiagramHeight = size.height + 40
    new joint.ui.Popup({
      position: 'right',
      anchor: 'left',
      content: function (el) {
        const previewGraph = new joint.dia.Graph()
        const previewPaper = new joint.dia.Paper({
          width: previewDiagramWidth,
          height: previewDiagramHeight,
          gridSize: 1,
          model: previewGraph,
          cellViewNamespace: cellNamespacePrototypes
        })
        el.append(previewPaper.el)
        previewGraph.addCells([previewShape])
        previewPaper.fitToContent(0, 0, 20)
      },
      target: cellView.$el
    }).render()
  }

  #stencilElementMouseOut () {
    joint.ui.Popup.close()
  }
}
