import Vue from 'vue'
import generateActions from '@/vue/store/utils/generateActions.vue.js'
import { normalize } from 'normalizr'
import { removedProjects } from './schema.vue.js'

const removedProjectsAdminEndpoint = (params) => {
  return '/api/admin/removed_projects'
}

const removedProjectsMethodSchemas = {
  index: [removedProjects],
  destroy: removedProjects
}

export default {
  ...generateActions(
    'removedProjectsAdminStore',
    'removed_project',
    removedProjectsAdminEndpoint,
    removedProjectsMethodSchemas
  ),
  RESTORE_REMOVED_PROJECT ({ commit, dispatch, state }, { params }) {
    const baseEndpoint = removedProjectsAdminEndpoint()
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .put(`${baseEndpoint}/${params.id}.json`)
        .then(
          response => {
            const normalized = normalize(response.data, removedProjects)
            commit('REMOVE_NORMALIZED_DATA', normalized)
            resolve(normalized)
          },
          error => {
            reject(error)
          }
        )
    })
  }
}
