import generateActions from '@/vue/store/utils/generateActions.vue.js'
import { companies, companiesWithResultCount, users, modules, providers, emailTemplates, roles, removedProjects } from './schema.vue.js'
import Vue from 'vue'
import { normalize } from 'normalizr'

const companyDetailAdminEndpoint = (params) => {
  return '/api/admin/companies'
}
const usersAdminEndpoint = (params) => {
  return `/api/admin/companies/${params.companyId}/users`
}
const modulesAdminEndpoint = (params) => {
  return '/api/admin/scenario_modules'
}
const providersEndpoint = (params) => {
  return `/api/admin/companies/${params.companyId}/providers`
}
const rolesEndpoint = (params) => {
  return `/api/admin/companies/${params.companyId}/roles`
}
const removedProjectsEndpoint = (params) => {
  return `/api/admin/companies/${params.companyId}/projects/removed_projects`
}
const projectsEndpoint = (params) => {
  return `/api/admin/companies/${params.companyId}/projects`
}

const companiesSchemas = {
  index: companiesWithResultCount,
  quick_index: [companies],
  get: companies,
  create: companies,
  update: companies,
  destroy: companies
}

const usersSchemas = {
  index: [users],
  get: users,
  create: users,
  update: users,
  destroy: users
}

const modulesSchemas = {
  index: [modules],
  get: modules,
  create: modules,
  update: modules,
  destroy: modules
}

const providersSchemas = {
  index: [providers],
  get: providers,
  create: providers,
  update: providers,
  destroy: providers
}
const rolesSchemas = {
  index: [roles],
  get: roles,
  create: roles,
  update: roles,
  destroy: roles
}
const removedProjectsSchemas = {
  index: [removedProjects],
  get: removedProjects,
  update: removedProjects
}
export default {
  ...generateActions(
    'companiesAdminStore',
    'company',
    companyDetailAdminEndpoint,
    companiesSchemas
  ),
  ...generateActions(
    'companiesAdminStore',
    'user',
    usersAdminEndpoint,
    usersSchemas
  ),
  ...generateActions(
    'companiesAdminStore',
    'module',
    modulesAdminEndpoint,
    modulesSchemas
  ),
  ...generateActions(
    'companiesAdminStore',
    'provider',
    providersEndpoint,
    providersSchemas
  ),
  ...generateActions(
    'companiesAdminStore',
    'role',
    rolesEndpoint,
    rolesSchemas
  ),
  ...generateActions(
    'companiesAdminStore',
    'removed_project',
    removedProjectsEndpoint,
    removedProjectsSchemas
  ),
  LOAD_EMAIL_TEMPLATE ({ state, commit }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .get('/api/admin/email_templates.json')
        .then(
          response => {
            commit('ADD_NORMALIZED_DATA', normalize(response.data, emailTemplates))
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
    })
  },
  UPLOAD_USER_CSV ({ state, commit }, { params, payload }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .post(`${usersAdminEndpoint(params)}/import_users_csv.json`, payload)
        .then(
          response => {
            commit('ADD_NORMALIZED_DATA', normalize(response.data, [users]))
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
    })
  },
  UPLOAD_ROLE_CSV ({ state, commit }, { params, payload }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .post(`${rolesEndpoint(params)}/import_roles_csv.json`, payload)
        .then(
          response => {
            commit('ADD_NORMALIZED_DATA', normalize(response.data, [roles]))
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
    })
  },

  RESTORE_REMOVED_PROJECT ({ commit, dispatch, state }, { params }) {
    const baseEndpoint = projectsEndpoint(params)
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .put(`${baseEndpoint}/${params.id}.json`)
        .then(
          response => {
            commit('REMOVE_NORMALIZED_DATA', normalize(response.data, removedProjects))
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
    })
  },
  UPDATE_COMPANY_LOGO ({ state, commit }, data) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .post(`/api/admin/companies/${data.id}/upload_logo.json`, data.file)
        .then(
          response => {
            commit('ADD_NORMALIZED_DATA', normalize(response.data, companies))
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
    })
  },
  REMOVE_COMPANY_LOGO ({ state, commit }, company) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .delete(`/api/admin/companies/${company.id}/remove_logo.json`)
        .then(
          response => {
            commit('ADD_NORMALIZED_DATA', normalize(response.data, companies))
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
    })
  },
  CLONE_COMPANY ({ commit }, { params, payload }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .post(`${companyDetailAdminEndpoint()}/${params.id}/clone`, payload)
        .then(
          response => {
            commit('ADD_NORMALIZED_DATA', normalize(response.data, [companies]))
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
    })
  },
  REMOVE_USER_FROM_COMPANY ({ commit, dispatch, state }, { params, payload }) {
    const baseEndpoint = usersAdminEndpoint(params)
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .put(`${baseEndpoint}/${params.id}.json`, payload)
        .then(
          response => {
            commit('REMOVE_NORMALIZED_DATA', normalize(response.data, users))
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
    })
  },
  UPDATE_LOCAL_COMPANY ({ commit, state }, payload) {
    commit('ADD_NORMALIZED_DATA', normalize(payload, [companies]))
    return new Promise((resolve) => resolve())
  }
}
