import * as joint from '@clientio/rappid'
import _ from 'lodash'
import BusinessProcessElement from '../base/baseBusinessProcessElement.vue.js'

const Activity = BusinessProcessElement.extend({
  initialize: function () {
    this.on('change:activity_type', (cell, value) => {
      this.attr('border/borderType', value)
    })
    this.on('change:sub_process', (cell, value) => {
      this.attr('border/borderStyle', value)
    })
    this.on('change:icon_type', (cell, value) => {
      this.attr('icon/iconType', value)
    })
    this.on('change:markers', (cell, value) => {
      this.attr('markers/iconTypes', value, { rewrite: true })
    })
    BusinessProcessElement.prototype.initialize.apply(this, arguments)
  },
  displayModeAttributes: {
    stencil: {
      size: { width: 40, height: 30 },
      attrs: {
        label: {
          text: ''
        }
      }
    },
    preview: {
      size: { width: 90, height: 70 },
      position: { x: 10, y: 10 }
    },
    canvas: {
      size: { width: 90, height: 70 }
    }
  },
  updateElementDefaultColors: function (options) {
    this.attr({
      border: {
        stroke: options.default_stroke_colour
      },
      background: {
        fill: options.default_fill_colour
      }
    })
    this.attr({
      label: {
        fill: options.default_text_colour
      }
    })
  },
  markup: joint.shapes.bpmn2.Activity.prototype.markup,
  defaults: _.defaultsDeep({
    size: { width: 30, height: 25 },
    shape: 'Activity',
    type: 'businessProcess.Activity',
    attrs: {
      background: {
        fill: '#ffffff',
        fillOpacity: 1
      },
      border: {
        stroke: '#000000'
      },
      label: {
        text: 'Activity',
        textWrap: {
          width: -10,
          height: null
        }
      }
    }
  }, joint.shapes.bpmn2.Activity.prototype.defaults)
})
Activity.attributes = joint.shapes.bpmn2.Activity.attributes
Activity.ACTIVITY_TYPE_ICONS = joint.shapes.bpmn2.Activity.ACTIVITY_TYPE_ICONS
Activity.ACTIVITY_MARKER_ICONS = joint.shapes.bpmn2.Activity.ACTIVITY_MARKER_ICONS

export default Activity
