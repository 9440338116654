import _ from 'lodash'
export default class AccessControl {
  static checkCompanyPermission (permissionKey, company) {
    if (!company) { return false }
    return _.get(company.permissions, permissionKey, false)
  }

  static checkProjectPermission (permissionKey, project, company, user) {
    if (!project || !company || !user) { return false }

    let skipCompanyCheck = project.entity_type === 'project_sample'
    if (permissionKey === 'read') {
      skipCompanyCheck = skipCompanyCheck || user.shared_user
    }

    let hasPermission = true
    if (!skipCompanyCheck && _.includes(['read', 'write'], permissionKey)) {
      hasPermission = AccessControl.checkCompanyPermission(permissionKey, company) && hasPermission
    }
    if (!skipCompanyCheck && permissionKey === 'admin') {
      hasPermission = AccessControl.checkCompanyPermission('write', company) && hasPermission
    }
    hasPermission = _.get(project.permissions, permissionKey, false) && hasPermission
    return hasPermission
  }

  static checkModulePermission (permissionKey, project, module, company, user) {
    if (!module) { return false }
    if (permissionKey !== 'read' && module.locked) { return false }
    return AccessControl.checkProjectPermission(permissionKey, project, company, user)
  }

  static checkDataMapPermission (permissionKey, dataMap, company, user) {
    if (!dataMap || !company || !user) { return false }

    const skipCompanyCheck = dataMap.company_id === null
    let hasPermission = true

    if (!skipCompanyCheck && _.includes(['read', 'write'], permissionKey)) {
      hasPermission = AccessControl.checkCompanyPermission(permissionKey, company) && hasPermission
    }
    if (!skipCompanyCheck && permissionKey === 'admin') {
      hasPermission = AccessControl.checkCompanyPermission('write', company) && hasPermission
    }
    if (user.shared_user) {
      hasPermission = false
    }

    hasPermission = _.get(dataMap.permissions, permissionKey, false) && hasPermission
    return hasPermission
  }

  static checkDataCatalogPermission (permissionKey, dataCatalog, company, user) {
    if (!dataCatalog || !company || !user) { return false }

    const skipCompanyCheck = dataCatalog.company_id === null
    let hasPermission = true

    if (!skipCompanyCheck && _.includes(['read', 'write'], permissionKey)) {
      hasPermission = AccessControl.checkCompanyPermission(permissionKey, company) && hasPermission
    }
    if (!skipCompanyCheck && permissionKey === 'admin') {
      hasPermission = AccessControl.checkCompanyPermission('write', company) && hasPermission
    }
    if (user.shared_user) {
      hasPermission = false
    }

    hasPermission = _.get(dataCatalog.permissions, permissionKey, false) && hasPermission
    return hasPermission
  }

  static checkAssessmentPermission (permissionKey, assessment, company, user) {
    if (!assessment || !company || !user) { return false }

    let hasPermission = true
    hasPermission = AccessControl.checkCompanyPermission('write', company) && hasPermission
    hasPermission = _.get(assessment.permissions, permissionKey, false) && hasPermission
    return hasPermission
  }
}
