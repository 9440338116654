import AccessControl from './accessControl.vue'
export default function () {
  return {
    methods: {
      checkCompanyPermission (permissionKey, company) {
        return AccessControl.checkCompanyPermission(permissionKey, company)
      },
      checkProjectPermission (permissionKey, project, company, user) {
        return AccessControl.checkProjectPermission(permissionKey, project, company, user)
      },
      checkModulePermission  (permissionKey, project, module, company, user) {
        return AccessControl.checkModulePermission(permissionKey, project, module, company, user)
      },
      checkDataCatalogPermission (permissionKey, dataCatalog, company, user) {
        return AccessControl.checkDataCatalogPermission(permissionKey, dataCatalog, company, user)
      },
      checkDataMapPermission (permissionKey, dataMap, company, user) {
        return AccessControl.checkDataMapPermission(permissionKey, dataMap, company, user)
      },
      checkAssessmentPermission (permissionKey, assessment, company, user) {
        return AccessControl.checkAssessmentPermission(permissionKey, assessment, company, user)
      }
    }
  }
}
