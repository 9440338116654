const urlMap = {
  Bpmns: 'bpmn',
  BusinessCanvases: 'business-canvas',
  ChangeManagementStories: 'change-management-stories',
  Checklists: 'checklists',
  DataInterfaces: 'data-map',
  Drivers: 'drivers',
  Ecosystems: 'ecosystem',
  FreeTexts: 'text',
  EmbeddedSuites: 'embedded-suites',
  ImageViewers: 'image-viewer',
  Kanbans: 'kanban',
  KeyParticipants: 'stakeholders',
  LeanCanvases: 'lean-canvas',
  LearnedLessons: 'lessons-learned',
  Mappings: 'frameworx',
  MiroIntegrations: 'miro-boards',
  MissionModelCanvases: 'mission-model-canvas',
  OverallValues: 'overall-values',
  PlatformModelCanvases: 'platform-model-canvas',
  ProblemStatements: 'problem-statements',
  ProcessFlows: 'process-flow',
  ProductAndServices: 'products-and-services',
  Quadrants: 'quadrant',
  Risks: 'risks',
  StakeholderPerspectives: 'stakeholder-perspectives',
  StakeholderRoles: 'stakeholder-roles',
  SwotCanvases: 'swot-canvas',
  UserStories: 'user-stories'
}

export default function (key) {
  return urlMap[key] || key
}
