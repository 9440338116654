<template>
  <v-snackbar
    v-if="active"
    transition="slide-x-reverse-transition"
    class="alert-bar ma-0"
    content-class="effect--dark"
    width="430"
    right
    top
    :timeout="-1"
    app
    :value="active"
  >
    <v-icon
      class="notranslate alert-bar__icon mr-2"
      dense
    >
      info
    </v-icon>{{ text }}
    <template #action="{ attrs }">
      <v-btn
        class="v-btn--tertiary effect--dark"
        depressed
        v-bind="attrs"
        @click="$emit('close')"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'AlertBar',
  props: {
    text: {
      required: false,
      type: String,
      default: ''
    },
    active: {
      required: true,
      type: Boolean
    }
  }
}
</script>

<style lang="scss" scoped>
.alert-bar {
  z-index: 6;
  ::v-deep.v-snack__wrapper{
    top: 54px;
    width: 430px;
    background-color: $dark-delta !important;
  }

  &__icon {
    color: $warning !important;
  }
}
</style>
