const isTruncated = (el, callback) => {
  // Update truncated status after timeout to fix bug with Vuetify tooltip.
  // If tooltip is immediately disabled while it's activating, it never disappears.
  setTimeout(() => {
    const status = el.offsetWidth < el.scrollWidth || el.offsetHeight < el.scrollHeight
    callback(status)
  }, 60)
}

export default {
  bind (el, binding, vnode) {
    isTruncated(el, binding.value)
  },
  componentUpdated (el, binding, vnode) {
    isTruncated(el, binding.value)
  }
}
