
import getters from './teamHomePage/getters.vue.js'
import mutations from './teamHomePage/mutations.vue.js'
import initialState from './teamHomePage/initialState.vue.js'

export default {
  namespaced: true,
  state: initialState(),
  getters,
  mutations
}
