<template>
  <div
    v-if="saving"
    class="text-center saving-spinner pa-2"
  >
    <v-progress-circular
      indeterminate
      size="20"
      width="2"
      test-hook="spinner"
    />
    <span class="pl-1">Saving...</span>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SavingSpinner',
  computed: {
    ...mapGetters([
      'saving'
    ])
  }
}
</script>
<style lang="scss" scoped>
.saving-spinner {
  color: $dark-delta;
  position: fixed;
  z-index: 100;
  bottom: 50px;
  right: 20px;
}
</style>
