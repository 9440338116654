import * as joint from '@clientio/rappid'
import Tr3dentElement from './baseElement.vue.js'
import _ from 'lodash'

export default Tr3dentElement.extend({
  displayModeAttributes: {
    stencil: {
      size: { width: 40, height: 30 },
      attrs: {
        label: {
          text: ''
        }
      }
    },
    preview: {
      size: { width: 130, height: 90 },
      position: { x: 10, y: 10 }
    },
    canvas: {
      size: { width: 130, height: 90 }
    }
  },
  defaults: _.defaultsDeep({
    type: 'businessProcess.Element'
  }, joint.dia.Element.prototype.defaults),
  setText: function (text) {
    this.attr('label/text', text)
  },
  updateElementLabelVerticalPosition: function (vPosition) {
    // Normalize property format
    this.removeAttr('label/text-vertical-anchor')
    this.removeAttr('label/ref-y')
    this.removeAttr('label/text-vertical-position')

    switch (vPosition) {
      case 'top' : {
        this.attr('label/textVerticalPosition', 'top')
        this.attr('label/textVerticalAnchor', 'top')
        this.attr('label/refY', '5%')
        break
      }
      case 'middle' : {
        this.attr('label/textVerticalPosition', 'middle')
        this.attr('label/textVerticalAnchor', 'middle')
        this.attr('label/refY', '50%')
        break
      }
      case 'bottom' : {
        this.attr('label/textVerticalPosition', 'bottom')
        this.attr('label/textVerticalAnchor', 'bottom')
        this.attr('label/refY', '95%')
        break
      }
      default: {
        this.attr('label/textVerticalPosition', 'middle')
        this.attr('label/textVerticalAnchor', 'middle')
        this.attr('label/refY', '50%')
        break
      }
    }
  },

  updateElementLabelHorizontalPosition: function (hPosition) {
    // Normalize property format
    this.removeAttr('label/text-anchor')
    this.removeAttr('label/ref-x')
    this.removeAttr('label/text-position')

    switch (hPosition) {
      case 'start' : {
        this.attr('label/textPosition', 'start')
        this.attr('label/textAnchor', 'start')
        this.attr('label/refX', '0%')
        this.attr('label/refX2', '2')
        break
      }
      case 'middle' : {
        this.attr('label/textPosition', 'middle')
        this.attr('label/textAnchor', 'middle')
        this.attr('label/refX', '50%')
        break
      }
      case 'end' : {
        this.attr('label/textPosition', 'end')
        this.attr('label/textAnchor', 'end')
        this.attr('label/refX', '100%')
        this.attr('label/refX2', '-2')
        break
      }
      default: {
        this.attr('label/textPosition', 'middle')
        this.attr('label/textAnchor', 'middle')
        this.attr('label/refX', '50%')
        break
      }
    }
  },
  getText: function (text) {
    return this.attr('label/text') || this.get('shape')
  }
})
