import generateIndexes from '@/vue/store/utils/generateIndexes.vue.js'
import { kanbanItems, kanbanLanes } from './schema.vue.js'

const staticKanbanItemProperties = [
  'module_id'
]

const staticKanbanLaneProperties = [
  'module_id'
]

const dynamicKanbanItemProperties = [
  'stakeholder_ids',
  'product_and_service_item_ids',
  'user_ids',
  'kanban_lane_id',
  'tag_ids'
]

export const indexes = {
  [kanbanItems.key]: generateIndexes(staticKanbanItemProperties, dynamicKanbanItemProperties),
  [kanbanLanes.key]: generateIndexes(staticKanbanLaneProperties)
}
