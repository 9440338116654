import * as joint from '@clientio/rappid'
import _ from 'lodash'
import Tr3dentElement from '../base/baseElement.vue.js'

export default Tr3dentElement.extend({
  initialize: function () {
    this.on('change:shapeOutline', (cell, value) => {
      this.attr(`${this.get('SHAPE_ATTR_PATH')}/stroke`, value)
    })
    this.on('change:shapeFill', (cell, value) => {
      this.attr(`${this.get('SHAPE_ATTR_PATH')}/fill`, value)
    })
    this.on('change:shapeTransparency', (cell, value) => {
      this.attr(`${this.get('SHAPE_ATTR_PATH')}/fillOpacity`, value)
    })
    this.on('change:shapeOutlineWidth', (cell, value) => {
      this.removeAttr(`${this.get('SHAPE_ATTR_PATH')}/stroke-width`)
      this.attr(`${this.get('SHAPE_ATTR_PATH')}/strokeWidth`, value)
    })
    this.on('change:shapeOutlineStyle', (cell, value) => {
      this.removeAttr(`${this.get('SHAPE_ATTR_PATH')}/stroke-dasharray`)
      this.attr(`${this.get('SHAPE_ATTR_PATH')}/strokeDasharray`, value)
    })
    this.on('change:textColor', (cell, value) => {
      this.attr(`${this.get('TEXT_ATTR_PATH')}/fill`, value)
    })
    this.on('change:textFontSize', (cell, value) => {
      this.removeAttr(`${this.get('TEXT_ATTR_PATH')}/font-size`)
      this.attr(`${this.get('TEXT_ATTR_PATH')}/fontSize`, value)
    })
    Tr3dentElement.prototype.initialize.apply(this, arguments)
  },
  setText: function (text) {
    this.attr(`${this.get('TEXT_ATTR_PATH')}/textWrap/text`, text)
  },
  getText: function () {
    return this.attr(`${this.get('TEXT_ATTR_PATH')}/textWrap/text`) || this.get('shape')
  },
  updateElementLabelHorizontalPositionFn: function (hPosition) {
    const textAttrPath = this.get('TEXT_ATTR_PATH')
    // Normalize property format
    this.removeAttr(`${textAttrPath}/text-anchor`)
    this.removeAttr(`${textAttrPath}/ref-x`)
    this.removeAttr(`${textAttrPath}/text-position`)

    switch (hPosition) {
      case 'start' : {
        this.attr(`${textAttrPath}/textPosition`, 'start')
        this.attr(`${textAttrPath}/textAnchor`, 'start')
        this.attr(`${textAttrPath}/refX`, '5%')
        break
      }
      case 'middle' : {
        this.attr(`${textAttrPath}/textPosition`, 'middle')
        this.attr(`${textAttrPath}/textAnchor`, 'middle')
        this.attr(`${textAttrPath}/refX`, '50%')
        break
      }
      case 'end' : {
        this.attr(`${textAttrPath}/textPosition`, 'end')
        this.attr(`${textAttrPath}/textAnchor`, 'end')
        this.attr(`${textAttrPath}/refX`, '95%')
        break
      }
      default: {
        this.attr(`${textAttrPath}/textPosition`, 'middle')
        this.attr(`${textAttrPath}/textAnchor`, 'middle')
        this.attr(`${textAttrPath}/refX`, '50%')
        break
      }
    }
  },
  updateElementLabelVerticalPositionFn: function (vPosition) {
    const textAttrPath = this.get('TEXT_ATTR_PATH')
    // Normalize property format
    this.removeAttr(`${textAttrPath}/text-vertical-anchor`)
    this.removeAttr(`${textAttrPath}/ref-y`)
    this.removeAttr(`${textAttrPath}/text-vertical-position`)

    switch (vPosition) {
      case 'top' : {
        this.attr(`${textAttrPath}/textVerticalPosition`, 'top')
        this.attr(`${textAttrPath}/textVerticalAnchor`, 'top')
        this.attr(`${textAttrPath}/refY`, '5%')
        break
      }
      case 'middle' : {
        this.attr(`${textAttrPath}/textVerticalPosition`, 'middle')
        this.attr(`${textAttrPath}/textVerticalAnchor`, 'middle')
        this.attr(`${textAttrPath}/refY`, '50%')
        break
      }
      case 'bottom' : {
        this.attr(`${textAttrPath}/textVerticalPosition`, 'bottom')
        this.attr(`${textAttrPath}/textVerticalAnchor`, 'bottom')
        this.attr(`${textAttrPath}/refY`, '95%')
        break
      }
      default: {
        this.attr(`${textAttrPath}/textVerticalPosition`, 'middle')
        this.attr(`${textAttrPath}/textVerticalAnchor`, 'middle')
        this.attr(`${textAttrPath}/refY`, '50%')
        break
      }
    }
  },
  defaults: _.defaultsDeep({
    type: 'tr3dent.VisioElement',
    shape: 'Visio Element',
    attrs: {}
  }, joint.dia.Element.prototype.defaults)
})
