import _ from 'lodash'
import initialState from './initialState.vue.js'
import generateStateMutations from '@/vue/store/utils/generateStateMutations.vue.js'
export default {
  RESET_STATE (state) {
    _.assign(state, initialState())
  },
  SET_FOCUSED_SECTION_ID (state, id) {
    state.focusedSectionId = id
  },
  ...generateStateMutations()
}
