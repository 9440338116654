
import actions from './commentsStore/actions.vue.js'
import getters from './commentsStore/getters.vue.js'
import mutations from './commentsStore/mutations.vue.js'
import initialState from './commentsStore/initialState.vue.js'

export default {
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions
}
