import Vue from 'vue'
import _ from 'lodash'
const generateMutations = (indexes = {}) => {
  return {
    ADD_NORMALIZED_DATA: (state, normalizedData) => {
      for (const entityKey in normalizedData.entities) {
        if (!_.has(state, entityKey)) {
          if (normalizedData.sockets) { continue }
          Vue.set(state, entityKey, normalizedData.entities[entityKey])
          if (_.has(indexes, `${entityKey}.addIndex`)) {
            _.each(state[entityKey], (entity) => {
              indexes[entityKey].addIndex(entityKey, state, entity)
            })
          }
        } else {
          for (const entityId in normalizedData.entities[entityKey]) {
            const entity = normalizedData.entities[entityKey][entityId]
            if (!_.has(state[entityKey], entityId)) {
              Vue.set(state[entityKey], entityId, entity)
              if (_.has(indexes, `${entityKey}.addIndex`)) {
                indexes[entityKey].addIndex(entityKey, state, entity)
              }
            } else {
              if (normalizedData.sockets) {
                if (!_.has(state[entityKey][entityId], 'updated_at')) { continue }
                if (!_.has(entity, 'updated_at')) { continue }
                const oldUpdated = new Date(state[entityKey][entityId].updated_at).getTime()
                const newUpdated = new Date(entity.updated_at).getTime()
                if (newUpdated <= oldUpdated) { continue }
              }
              state[entityKey][entityId] = _.assign({}, state[entityKey][entityId], entity)
              if (_.has(indexes, `${entityKey}.updateIndex`)) {
                indexes[entityKey].updateIndex(entityKey, state, state[entityKey][entityId])
              }
            }
          }
        }
      }
    },
    REMOVE_NORMALIZED_DATA: (state, normalizedData) => {
      for (const entityKey in normalizedData.entities) {
        for (const entityId in normalizedData.entities[entityKey]) {
          if (_.has(state, `${entityKey}.${entityId}`)) {
            if (_.has(indexes, `${entityKey}.removeIndex`)) {
              indexes[entityKey].removeIndex(entityKey, state, state[entityKey][entityId])
            }
            Vue.delete(state[entityKey], entityId)
          }
        }
      }
    }
  }
}
export default generateMutations
