import _ from 'lodash'

const generateGetters = (schema, indexes = {}) => {
  const key = schema.key
  let indexedProperties = []
  if (_.has(indexes, key)) {
    indexedProperties = indexes[key].indexedProperties
  }

  const getters = {
    [_.camelCase(`${key}`)]: (state) => state[key],
    [_.camelCase(`all_${key}`)]: (state) => _.values(state[key]),
    [_.camelCase(`${key}_by_id`)]: (state) => (id) => _.get(state, `[${key}][${id}]`, {}),
    [_.camelCase(`${key}_by_ids`)]: (state) => (ids) => {
      const entities = _.map(ids, (id) => _.get(state, `[${key}][${id}]`, null))
      return _.compact(entities)
    }
  }

  _.reduce(indexedProperties, (gettersObj, indexProp) => {
    const indexKey = _.camelCase(`${key}_by_${indexProp}`)
    gettersObj[indexKey] = (state) => (indexId) => {
      if (!_.has(state, `indexes.${indexKey}.${indexId}`)) { return [] }
      return _.map(state.indexes[indexKey][indexId], (id) => state[key][id])
    }

    return gettersObj
  }, getters)
  return getters
}

export default generateGetters
